import dayjs from 'dayjs';
import { DiplomateReevaluation, DiplomateReevaluationEntity, Member } from 'models/types';

import { getDiplomateRole } from './member';

export const DIPLOMATE_REEVALUATION_REF = 'api::diplomate-reevaluation.diplomate-reevaluation';
export const DIPLOMATE_REEVALUATION_DIRECTORY_PATH = `Diplomate Reevaluation Application/${new Date().getFullYear()}`;

export const REEVALUATION_POINTS_FIELDS = {
  agmAttendancePoints: { label: 'AGM Attendance', max: 10 },
  examQuestionContributionPoints: { label: 'Contributing new exam questions', max: 30 },
  committeeWorkPoints: { label: 'Work for ECVIM-CA Committees', max: 20 },
  residentSupervisionPoints: { label: 'Resident supervision', max: 20 },
  conferenceOrWebinarAttendancePoints: { label: 'Conference and Webinar Attendance', max: 90 },
  presentationPoints: { label: 'Presentations', max: 60 },
  publicationPoints: { label: 'Publications', max: 60 },
  peerReviewingPoints: { label: 'Peer-reviewing', max: 10 },
  additionalActivityPoints: { label: 'Additional Activities', max: 30 },
  collegeActivityParticipationPoints: { label: 'Participation in College Activities', max: undefined },
};

export function isDiplomateReevaluationEntityActive(diplomateReevaluationEntity: DiplomateReevaluationEntity) {
  const reevaluationYear = diplomateReevaluationEntity.attributes?.reevaluationYear!;
  const currentYear = new Date().getFullYear();
  return currentYear <= reevaluationYear;
}

export function isDiplomateReevaluationSubmitted(diplomateReevaluationEntity: DiplomateReevaluationEntity) {
  return !!diplomateReevaluationEntity.attributes?.reevaluationSubmissionDate;
}

export function getMaxPointsNote(max: number | undefined) {
  return max ? ` (max ${max})` : '';
}

export function getCurrentReevaluationYear() {
  return dayjs().subtract(3, 'month').year();
}

export function getNextReevaluationYearForMember(member: Member, diplomateReevaluations: DiplomateReevaluation[]) {
  const diplomate = getDiplomateRole(member)!;
  const currentYear = new Date().getFullYear();

  if (diplomate.reevaluationdate) {
    const nextReevaluationDateYear = new Date(diplomate.reevaluationdate).getFullYear();
    if (nextReevaluationDateYear >= currentYear) {
      return nextReevaluationDateYear;
    }
  }

  const lastDiplomateReevaluationYears = diplomateReevaluations.map((dr) => dr.reevaluationYear);
  if (diplomate.since) {
    lastDiplomateReevaluationYears.push(new Date(diplomate.since).getFullYear());
  }

  if (lastDiplomateReevaluationYears.length == 0) {
    return undefined;
  }

  const lastDiplomateReevaluationYear = Math.max(...lastDiplomateReevaluationYears);
  return Math.max(lastDiplomateReevaluationYear + 5, currentYear);
}
