import { ValidatorFn, Validators } from '@angular/forms';
import {
  CertifyingExam,
  Enum_Certifyingexam_Typeofsubmission,
  Enum_Componentmasterdataspeciality_Name,
  UploadFileEntity,
  UploadFileEntityResponse,
  UploadFileRelationResponseCollection,
} from 'models/types';

export enum Required {
  No = 0,
  Now,
  Later,
}

type NonNullable<T> = T extends null | undefined ? never : T;

type CredentialValueType<K extends keyof CertifyingExam> = NonNullable<CertifyingExam[K]> extends
  | UploadFileRelationResponseCollection
  | UploadFileEntityResponse
  ? UploadFileEntity[]
  : NonNullable<CertifyingExam[K]>;

interface CredentialDefinition<K extends keyof CertifyingExam> {
  default: CredentialValueType<K>;
  required?: Required;
}

type CredentialDefinitions = Partial<
  Record<
    'allSpecialities' | Enum_Componentmasterdataspeciality_Name,
    Partial<
      Record<
        'allTypes' | Enum_Certifyingexam_Typeofsubmission,
        { [K in keyof Partial<CertifyingExam>]: CredentialDefinition<K> }
      >
    >
  >
>;

interface Credential<K extends keyof CertifyingExam> extends CredentialDefinition<K> {
  name: keyof CertifyingExam;
  validators: ValidatorFn[];
}

export type Credentials = {
  [K in keyof Partial<CertifyingExam>]: Credential<K>;
};

const CREDENTIAL_DEFINITIONS: CredentialDefinitions = {
  allSpecialities: {
    allTypes: {
      programmeDirectorLetter: { default: [], required: Required.Now },
      previousCorrespondence: { default: [], required: Required.Now },

      titlePreviousCaseSummary: { default: '' },

      verificationOfTraining: { default: [], required: Required.Later },
      presentationLog: { default: [], required: Required.Later },
      publications: { default: [], required: Required.Later },
      publicationLinks: { default: '' },
    },

    [Enum_Certifyingexam_Typeofsubmission.FirstSubmission]: {
      geResult: { default: [], required: Required.Now },
      caseSummary: { default: [], required: Required.Now },
      caseLog: { default: [], required: Required.Now },
      caseLogSummary: { default: [], required: Required.Now },
    },

    [Enum_Certifyingexam_Typeofsubmission.ReSubmission]: {
      geResult: { default: [] },
      caseLog: { default: [] },
      caseLogSummary: { default: [] },
    },
  },

  [Enum_Componentmasterdataspeciality_Name.Cardiology]: {
    [Enum_Certifyingexam_Typeofsubmission.FirstSubmission]: {
      multipleChoiceQuestionnaire: { default: [], required: Required.Now },
      procedureLog: { default: [], required: Required.Now },
    },
    [Enum_Certifyingexam_Typeofsubmission.ReSubmission]: {
      multipleChoiceQuestionnaire: { default: [] },
      procedureLog: { default: [] },
    },
  },

  [Enum_Componentmasterdataspeciality_Name.Oncology]: {
    allTypes: {
      credentialsSubmissionSummaryForm: { default: [], required: Required.Now },
    },
    [Enum_Certifyingexam_Typeofsubmission.FirstSubmission]: {
      procedureLog: { default: [], required: Required.Now },
    },
    [Enum_Certifyingexam_Typeofsubmission.ReSubmission]: {
      procedureLog: { default: [] },
    },
  },

  [Enum_Componentmasterdataspeciality_Name.InternalMedicine]: {
    [Enum_Certifyingexam_Typeofsubmission.FirstSubmission]: {
      multipleChoiceQuestionnaire: { default: [], required: Required.Now },
    },
    [Enum_Certifyingexam_Typeofsubmission.ReSubmission]: {
      multipleChoiceQuestionnaire: { default: [] },
    },
  },
};

export function getCredentialsForCertifyingExam(certifyingExam: CertifyingExam): Credentials {
  if (!certifyingExam.typeOfSubmission) {
    throw new Error('typeOfSubmission must be set for credentials');
  }

  const credentials = {
    ...CREDENTIAL_DEFINITIONS.allSpecialities?.allTypes,
    ...CREDENTIAL_DEFINITIONS.allSpecialities?.[certifyingExam.typeOfSubmission],
    ...CREDENTIAL_DEFINITIONS[certifyingExam.speciality!.Name!]?.allTypes,
    ...CREDENTIAL_DEFINITIONS[certifyingExam.speciality!.Name!]?.[certifyingExam.typeOfSubmission],
  };

  return Object.fromEntries(
    Object.entries(credentials).map(([credentialName, credential]) => [
      credentialName,
      {
        required: Required.No,
        ...credential,
        name: credentialName as keyof Credentials,
        validators: credential.required
          ? Array.isArray(credential.default)
            ? [Validators.required, Validators.minLength(1)]
            : [Validators.required]
          : [],
      },
    ])
  );
}

export const SUBMIT_LATER_ENDING = 'Submitted';
export function getSubmitLaterFieldName(fieldName: keyof CertifyingExam) {
  return (fieldName + SUBMIT_LATER_ENDING) as keyof CertifyingExam;
}

export function isSubmitLaterEnabled(credentials: Credentials, fieldName: keyof Credentials) {
  return credentials[fieldName]?.required == Required.Later;
}

export function isSubmitLaterSelected(certifyingExam: CertifyingExam, fieldName: keyof Credentials) {
  const submitLaterFieldname = getSubmitLaterFieldName(fieldName);
  const fieldSubmitted = certifyingExam[submitLaterFieldname];
  return fieldSubmitted !== null && fieldSubmitted !== undefined && !fieldSubmitted;
}
