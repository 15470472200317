import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  CertifyingExamInput,
  Enum_Certifyingexam_Applicationresult,
  Enum_Certifyingexam_Typeofsubmission,
  MemberEntity,
} from 'models/types';
import { getCurrentCertifyingExamApplicationYear } from 'src/app/utils/exam';
import { getFormByPath, isFormRequired } from 'src/app/utils/form';
import { enumValueToString } from 'src/app/utils/utils';

enum TypeOfSubmissionExtension {
  PreviouslyAccepted = 'Previously Accepted',
}

type EnumCredentialsType = Enum_Certifyingexam_Typeofsubmission | TypeOfSubmissionExtension;
const EnumCredentialsType = { ...Enum_Certifyingexam_Typeofsubmission, ...TypeOfSubmissionExtension };

@Component({
  selector: 'app-certifying-exam-data-verification-form',
  templateUrl: './certifying-exam-data-verification-form.component.html',
  styleUrls: ['./certifying-exam-data-verification-form.component.scss'],
})
export class CertifyingExamDataVerificationFormComponent implements OnInit, OnChanges {
  @Input()
  memberEntity!: MemberEntity;

  @Input()
  disabled = true;

  loading = false;

  dataVerificationForm = this.fb.group({
    attempt: this.fb.control(1, [Validators.required, Validators.min(1), Validators.max(4)]),
    credentialsType: this.fb.control<EnumCredentialsType | null>(null, Validators.required),
  });

  get isValid() {
    return this.dataVerificationForm.valid;
  }

  get values(): CertifyingExamInput {
    const { credentialsType, ...formValues } = this.dataVerificationForm.value;
    const values = formValues as CertifyingExamInput;

    if (formValues.attempt! > 1 || credentialsType === EnumCredentialsType.PreviouslyAccepted) {
      values.applicationResult = Enum_Certifyingexam_Applicationresult.PreviouslyAccepted;
    } else {
      values.typeOfSubmission = credentialsType;
    }

    values.examYear = getCurrentCertifyingExamApplicationYear();
    values.speciality = { Name: this.member.Speciality![0]!.Name };

    return values;
  }

  get member() {
    return this.memberEntity.attributes!;
  }

  get credentialsTypeOptions() {
    return Object.values(EnumCredentialsType);
  }

  get isCredentialsTypeNecessary() {
    return (this.dataVerificationForm.get('attempt')?.value ?? 1) > 1;
  }

  get genericErrorMessage() {
    return 'This field is required!';
  }

  get attemptOptions() {
    return [1, 2, 3, 4];
  }

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.dataVerificationForm.get('attempt')?.valueChanges.subscribe((attempt) => {
      const credentialsType = this.dataVerificationForm.get('credentialsType');
      if (attempt == 1) {
        credentialsType?.setValidators(Validators.required);
      } else {
        credentialsType?.setValidators(null);
      }
      credentialsType?.updateValueAndValidity();
    });

    if (this.disabled) {
      this.dataVerificationForm.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled'] && !changes['disabled'].firstChange) {
      const disabled = changes['disabled'].currentValue;
      if (disabled) {
        this.dataVerificationForm.disable();
      } else {
        this.dataVerificationForm.enable();
      }
    }
  }

  getFormByPath(path: string) {
    return getFormByPath(this.dataVerificationForm, path);
  }

  isFormRequired(path: string) {
    return isFormRequired(this.dataVerificationForm, path);
  }

  displayCredentialsTypeOption(option: string) {
    return enumValueToString(option);
  }
}
