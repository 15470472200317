import { NgModule } from '@angular/core';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { APOLLO_FLAGS, APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { ExtractFiles } from 'apollo-angular/http/types';

import { environment } from '../environments/environment';
import { AuthService } from './services/auth.service';
import { extractFiles, isExtractableFile } from './utils/extract-files';

const uri = `${environment.strapiBackendUrl}/graphql`;

export function createApollo(httpLink: HttpLink, authService: AuthService) {
  const basic = setContext((operation, context) => ({
    headers: {
      Accept: 'charset=utf-8',
    },
  }));

  const auth = setContext((operation, { headers }) => {
    if (!authService.isLoggedIn) {
      return {};
    }

    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${authService.token}`,
      },
    };
  });

  const link = ApolloLink.from([
    basic,
    auth,
    httpLink.create({
      uri: uri,
      extractFiles: ((value: unknown) => extractFiles(value, isExtractableFile)) as ExtractFiles,
    }),
  ]);

  const cache = new InMemoryCache();

  return {
    link,
    cache,
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_FLAGS,
      useValue: {
        useMutationLoading: true,
      },
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, AuthService],
    },
  ],
})
export class GraphQLModule {}
