import { Component, Input, OnInit } from '@angular/core';

import { Member } from '../../../../models/types';
import { getFullnameForMember, getResidentRole, getSpecialityTextForMember } from '../../utils/member';

interface TableDataItem {
  title: string;
  value: string | number;
}

@Component({
  selector: 'app-resident-details',
  templateUrl: './resident-details.component.html',
  styleUrls: ['./resident-details.component.scss'],
})
export class ResidentDetailsComponent implements OnInit {
  @Input()
  member!: Member;

  residentDetailsTableDisplayedColumns: string[] = ['title', 'value'];
  residentDetailsTableData: TableDataItem[] = [];

  constructor() {}

  ngOnInit(): void {
    const role = getResidentRole(this.member);
    if (this.member && role) {
      this.residentDetailsTableData = [
        { title: 'Name', value: getFullnameForMember(this.member) },
        { title: 'Residency start date', value: role.Since },
        { title: 'Residency end date', value: role.Until },
        { title: 'Speciality', value: getSpecialityTextForMember(this.member) },
        { title: 'University', value: this.member?.Employer?.data?.attributes?.Name },
        {
          title: 'Programme Director',
          value: getFullnameForMember(this.member.enrolment?.data?.attributes?.director?.data?.attributes),
        },
        { title: 'Supervisor 1', value: getFullnameForMember(role.Adviser?.data?.attributes) },
      ];

      const supervisor2 = role.secondaryadvisor?.data?.attributes;
      if (supervisor2) {
        this.residentDetailsTableData.push({ title: 'Supervisor 2', value: getFullnameForMember(supervisor2) });
      }
    }
  }
}
