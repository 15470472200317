<mat-table [dataSource]="fileTableData">
  <ng-container matColumnDef="filename">
    <mat-header-cell *matHeaderCellDef>Filename</mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element.filename }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="userFilename" *ngIf="existsUserFilename">
    <mat-header-cell *matHeaderCellDef>User Filename</mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element.userFilename }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="link">
    <mat-header-cell *matHeaderCellDef>File</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <a [href]="element.link" target="_blank"><mat-icon>attach_file</mat-icon></a>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="filesTableDisplayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: filesTableDisplayedColumns"></mat-row>
</mat-table>

<div class="no-files" *ngIf="files.length === 0">No files uploaded!</div>
