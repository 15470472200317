import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { DiplomateReevaluationEntity } from 'models/types';
import { Observable } from 'rxjs';
import { DiplomateReevaluationService } from 'src/app/services/diplomate-reevaluation.service';
import { MemberService } from 'src/app/services/member.service';
import { getCurrentReevaluationYear } from 'src/app/utils/diplomate-reevaluation';

@Injectable({
  providedIn: 'root',
})
export class DiplomateReevaluationsAsReevaluationsChairResolver implements Resolve<DiplomateReevaluationEntity[]> {
  constructor(
    private memberService: MemberService,
    private diplomateReevaluationService: DiplomateReevaluationService
  ) {
    //pass
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DiplomateReevaluationEntity[]> {
    return this.diplomateReevaluationService.getDiplomateReevaluationsForReviewAsReevaluationsChair(
      this.memberService.currentMemberId!,
      getCurrentReevaluationYear()
    );
  }
}
