import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { Required } from 'src/app/utils/credentials';

@Component({
  selector: 'app-required-note',
  templateUrl: './required-note.component.html',
  styleUrls: ['./required-note.component.scss'],
})
export class RequiredNoteComponent implements OnInit {
  @Input()
  status: Required | undefined = undefined;

  RequiredEnum = Required; // Expose enum in component class

  hideText = false;

  constructor(private responsive: BreakpointObserver) {}

  ngOnInit(): void {
    this.responsive.observe([Breakpoints.XSmall]).subscribe((result) => {
      this.hideText = false;
      if (result.matches) {
        this.hideText = true;
      }
    });
  }
}
