import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { tap } from 'rxjs';

import { MemberService } from '../services/member.service';
import { ProfileService } from '../services/profile.service';

@Injectable({
  providedIn: 'root',
})
//   | {
//       member: null;
//       user: UsersPermissionsMe | null;
//     }
//   | {
//       member: MemberEntity | null;
//       user: UsersPermissionsMe;
//     }
// >
export class MemberResolverService implements Resolve<ReturnType<ProfileService['getCurrentMember']>> {
  constructor(private profileService: ProfileService, private memberService: MemberService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.profileService.getCurrentMember().pipe(tap(({ member }) => this.memberService.setMember(member)));
  }
}
