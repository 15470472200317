import { Component, OnDestroy, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import {
  CertifyingExamEntity,
  CertifyingExamInput,
  Enum_Componentmasterdataspeciality_Name,
  UploadFileEntity,
} from 'models/types';
import { map, Observable, Subject } from 'rxjs';
import { CertifyingExamApplicationResultFormComponent } from 'src/app/components/exam-application/certifying-exam-application-result-form/certifying-exam-application-result-form.component';
import { ReviewCardComponent } from 'src/app/components/review/review-card/review-card.component';
import { CertifyingExamService } from 'src/app/services/certifying-exam.service';
import {
  Credentials,
  getCredentialsForCertifyingExam,
  isSubmitLaterSelected,
  Required,
} from 'src/app/utils/credentials';
import { getFullnameForMember } from 'src/app/utils/member';
import { getWorkStatusForFinalReviewer, getWorkStatusForReviewer, ReviewType, WorkStatus } from 'src/app/utils/review';

@Component({
  selector: 'app-certifying-exam-review',
  templateUrl: './certifying-exam-review.component.html',
  styleUrls: ['./certifying-exam-review.component.scss'],
})
export class CertifyingExamReviewComponent implements OnDestroy {
  @ViewChildren(ReviewCardComponent)
  reviewCards: QueryList<ReviewCardComponent> | undefined = undefined;

  @ViewChild(CertifyingExamApplicationResultFormComponent)
  applicationResultForm!: CertifyingExamApplicationResultFormComponent;

  private destroyed$ = new Subject<void>();

  certifyingExamEntity!: CertifyingExamEntity;

  previousCertifyingExamEntities$!: Observable<CertifyingExamEntity[]>;

  loading = false;

  get certifyingExam() {
    return this.certifyingExamEntity.attributes!;
  }

  get credentials() {
    return getCredentialsForCertifyingExam(this.certifyingExam);
  }

  get residentFullname() {
    return getFullnameForMember(this.certifyingExam.resident?.data?.attributes);
  }

  get reviewType() {
    return this.activatedRoute.snapshot.paramMap.get('type') as ReviewType;
  }

  get isViewer() {
    return this.reviewType === ReviewType.View;
  }

  get isFinalReviewer() {
    return this.reviewType === ReviewType.Final;
  }

  get isReviewer1() {
    return this.reviewType === ReviewType.First;
  }

  get isReviewer2() {
    return this.reviewType === ReviewType.Second;
  }

  get isReview1Visible() {
    return this.isFinalReviewer || this.isReviewer1 || this.isViewer;
  }

  get isReview2Visible() {
    return this.isFinalReviewer || this.isReviewer2 || this.isViewer;
  }

  get isReview2CaseSummaryVisible() {
    return (
      this.isReview2Visible &&
      ![
        Enum_Componentmasterdataspeciality_Name.Oncology,
        Enum_Componentmasterdataspeciality_Name.InternalMedicine,
      ].includes(this.certifyingExamSpeciality)
    );
  }

  get isFinalReviewVisible() {
    return this.isFinalReviewer || this.isViewer;
  }

  get isReview1Disabled() {
    return this.loading || this.isReview1Completed || this.isFinalReviewer || this.isViewer;
  }

  get isReview2Disabled() {
    return this.loading || this.isReview2Completed || this.isFinalReviewer || this.isViewer;
  }

  get isFinalReviewDisabled() {
    return (
      this.loading || this.isReviewCompleted || !this.isReview1Completed || !this.isReview2Completed || this.isViewer
    );
  }

  get isReviewCompleted() {
    return this.finalReviewerWorkStatus === WorkStatus.Finished;
  }

  private get isReview1Completed() {
    return this.reviewer1WorkStatus === WorkStatus.Finished;
  }

  private get isReview2Completed() {
    return this.reviewer2WorkStatus === WorkStatus.Finished;
  }

  get reviewer1WorkStatus() {
    return getWorkStatusForReviewer(this.certifyingExam, 1);
  }

  get reviewer2WorkStatus() {
    return getWorkStatusForReviewer(this.certifyingExam, 2);
  }

  get finalReviewerWorkStatus() {
    return getWorkStatusForFinalReviewer(this.certifyingExam);
  }

  get reviewForms() {
    return this.reviewCards ? this.reviewCards.map((reviewCard) => reviewCard.reviewForm) : [];
  }

  get isReviewValid() {
    return (
      ((!this.isFinalReviewer || this.applicationResultForm?.isValid) &&
        this.reviewForms?.map((reviewForm) => reviewForm.isValid).every((b) => !!b)) ??
      false
    );
  }

  get certifyingExamSpeciality() {
    const speciality = this.certifyingExam.speciality?.Name;
    if (!speciality) {
      throw new Error('speciality is not set');
    }
    return speciality;
  }

  get reviewFormsValues(): CertifyingExamInput {
    return Object.assign({}, ...this.reviewForms.filter((rfc) => !rfc.disabled).map((rfc) => rfc.values));
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private certifyingExamService: CertifyingExamService,
    private snackbar: MatSnackBar
  ) {
    this.certifyingExamEntity = this.activatedRoute.snapshot.data['certifyingExam'] as CertifyingExamEntity;
    this.previousCertifyingExamEntities$ = this.certifyingExamService
      .getCertifyingExamsForReviewByResident(this.certifyingExam.resident?.data?.id!, this.certifyingExam.examYear)
      .pipe(map((result) => result.data.certifyingExams.data));
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$.unsubscribe();
  }

  convertFileToArray(file: UploadFileEntity | undefined | null) {
    return [file].filter((f) => !!f) as UploadFileEntity[];
  }

  saveReview(lock = false): void {
    if (!this.certifyingExamEntity?.id) {
      return;
    }

    let certifyingExamInput = this.reviewFormsValues;

    if (this.isFinalReviewer) {
      certifyingExamInput = { ...certifyingExamInput, ...this.applicationResultForm.values };
    }

    if (lock) {
      const reviewStatusField = this.isFinalReviewer
        ? 'reviewStatus'
        : this.isReviewer1
        ? 'reviewer1Status'
        : 'reviewer2Status';
      certifyingExamInput[reviewStatusField] = true;

      if (this.isFinalReviewer) {
        certifyingExamInput.caseSummaryReviewStatus = true;
      }
    }

    this.certifyingExamService.updateCertifyingExamReview(this.certifyingExamEntity.id, certifyingExamInput).subscribe({
      next: (result) => {
        this.loading = result.loading;
        if (result.data) {
          this.certifyingExamEntity = result.data.updateCertifyingExam.data!;
          this.snackbar.open('Your review data was updated successfully!', undefined, { duration: 4000 });
        }
      },
    });
  }

  getCredential(fieldName: string) {
    if (!(fieldName in this.credentials)) {
      return undefined;
    }
    return this.credentials[fieldName as keyof Credentials];
  }

  isSubmitLaterSelected(fieldName: keyof Credentials) {
    return isSubmitLaterSelected(this.certifyingExam, fieldName);
  }

  isCredentialRequired(fieldName: string) {
    const credential = this.getCredential(fieldName);
    return credential?.required ?? Required.Now;
  }
}
