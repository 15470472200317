<footer>
  <div class="footer-content">
    <div>
      <div class="menu-item"><a routerLink="/privacypolicy">Privacy Policy</a></div>
      <div class="menu-item"><a routerLink="/legal-notice">Legal Notice</a></div>
      <div class="menu-item"><a routerLink="/termsandconditions">Terms and Conditions</a></div>
    </div>
    <p class="copyright">© 2023 ECVIM-CA - European College of Veterinary Internal Medicine - Companion Animals</p>
  </div>
</footer>
