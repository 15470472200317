import { Component, Input } from '@angular/core';
import { UploadFileEntity } from 'models/types';

interface TableDataItem {
  filename: string;
  userFilename: string;
  link: string;
}

@Component({
  selector: 'app-review-file-upload',
  templateUrl: './review-file-upload.component.html',
  styleUrls: ['./review-file-upload.component.scss'],
})
export class ReviewFileUploadComponent {
  @Input()
  files: UploadFileEntity[] = [];

  get filesTableDisplayedColumns() {
    const columns = ['filename'];
    if (this.existsUserFilename) {
      columns.push('userFilename');
    }
    columns.push('link');
    return columns;
  }

  get fileTableData() {
    return this.files.map(
      (file) =>
        ({
          filename: file.attributes?.name ?? '',
          userFilename: file.attributes?.alternativeText ?? '',
          link: file.attributes?.url ?? '',
        } as TableDataItem)
    );
  }

  get existsUserFilename() {
    return this.files.some((file) => !!file.attributes?.alternativeText);
  }
}
