import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Enum_Payment_Type, GeneralExamEntity, MemberInput, Page } from 'models/types';
import { Subject, takeUntil, tap } from 'rxjs';
import { GeneralExamApplicationFormComponent } from 'src/app/components/exam-application/general-exam-application-form/general-exam-application-form.component';
import { GeneralExamDataVerificationFormComponent } from 'src/app/components/exam-application/general-exam-data-verification-form/general-exam-data-verification-form.component';
import { MemberFormComponent } from 'src/app/components/member-form/member-form.component';
import { GeneralExamService } from 'src/app/services/general-exam.service';
import { MemberService } from 'src/app/services/member.service';
import { ProfileService } from 'src/app/services/profile.service';
import {
  getCurrentGeneralExamApplicationYear,
  isGeneralExamApplicationActive,
  isGeneralExamApplicationSubmitted,
} from 'src/app/utils/exam';

@Component({
  selector: 'app-general-exam-application-submission',
  templateUrl: './general-exam-application-submission.component.html',
  styleUrls: ['./general-exam-application-submission.component.scss'],
})
export class GeneralExamApplicationSubmissionComponent implements OnDestroy {
  @ViewChild(MemberFormComponent)
  memberForm!: MemberFormComponent;

  @ViewChild(GeneralExamApplicationFormComponent)
  generalExamApplicationForm: GeneralExamApplicationFormComponent | undefined = undefined;

  @ViewChild(GeneralExamDataVerificationFormComponent)
  generalExamDataVerficationForm: GeneralExamDataVerificationFormComponent | undefined = undefined;

  cmsPage: Page | undefined = undefined;

  memberFormDisabled = true;
  loading = false;

  generalExams: GeneralExamEntity[] = [];

  get activeGeneralExamEntity() {
    return this.generalExams.find((gee) => isGeneralExamApplicationActive(gee));
  }

  set activeGeneralExamEntity(activeGeneralExamEntity: GeneralExamEntity | undefined) {
    if (activeGeneralExamEntity) {
      this.generalExams = this.generalExams.filter((gee) => gee !== this.activeGeneralExamEntity);
      this.generalExams.push(activeGeneralExamEntity);
    }
  }

  get relatedPayments() {
    return this.activeGeneralExamEntity?.attributes?.payments?.data ?? [];
  }

  get member() {
    return this.memberService.currentMember!;
  }

  get memberEntity() {
    return this.memberService.currentMemberEntity!;
  }

  get activeGeneralExamId() {
    return this.activeGeneralExamEntity?.id;
  }

  get isApplicationSubmitted() {
    return !!this.activeGeneralExamEntity && isGeneralExamApplicationSubmitted(this.activeGeneralExamEntity);
  }

  get isApplicationLocked() {
    return !this.memberService.isResident || this.isApplicationSubmitted;
  }

  get currentExamYear() {
    return getCurrentGeneralExamApplicationYear();
  }

  private destroyed$ = new Subject<void>();

  constructor(
    private memberService: MemberService,
    private profileService: ProfileService,
    private snackbar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private generalExamService: GeneralExamService
  ) {
    this.activatedRoute.data
      .pipe(
        takeUntil(this.destroyed$),
        tap((data) => {
          this.generalExams = data['generalExams'] as GeneralExamEntity[];
          this.cmsPage = data['page'] as Page | undefined;
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$.unsubscribe();
  }

  enableMemberFormEditMode(): void {
    this.memberFormDisabled = false;
    this.memberForm?.memberForm.markAllAsTouched();
  }

  submitGeneralExamDataVerification() {
    if (!this.activeGeneralExamEntity && this.generalExamDataVerficationForm?.isValid && this.memberEntity) {
      const generalExamInput = this.generalExamDataVerficationForm.values;

      this.generalExamService.createGeneralExamForMember(this.memberEntity.id!, generalExamInput).subscribe({
        next: (result) => {
          this.loading = result.loading;

          if (result.data) {
            const generalExamEntity = result.data.createGeneralExam.data ?? undefined;
            this.activeGeneralExamEntity = generalExamEntity;
          }
        },
      });
    }
  }

  saveMemberForm(): void {
    if (!this.memberForm.memberFormInvalid) {
      this.profileService
        .updateMember(this.memberService.currentMemberId!, this.memberForm?.memberFormValues as MemberInput)
        .subscribe({
          next: (result) => {
            this.loading = result.loading;
            if (result.data) {
              this.memberService.setMember(result.data.updateMember.data!);
              this.snackbar.open('Your profile was updated successfully!', undefined, { duration: 4000 });
            }
          },
        });
    }
  }

  submitGeneralExamApplicationForm(): void {
    if (this.activeGeneralExamEntity && this.generalExamApplicationForm?.isValid) {
      const generalExamInput = this.generalExamApplicationForm.values;

      this.generalExamService.updateGeneralExam(this.activeGeneralExamId!, generalExamInput).subscribe({
        next: (result) => {
          this.loading = result.loading;

          if (result.data) {
            this.activeGeneralExamEntity = result.data.updateGeneralExam.data ?? undefined;
            this.snackbar.open('Your exam application was successfully submitted!', undefined, { duration: 4000 });
          }
        },
      });
    }
  }
}
