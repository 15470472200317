import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationItem, Page } from 'models/types';
import { defer, filter, map, Observable } from 'rxjs';

@Component({
  selector: 'app-cms-page',
  templateUrl: './cms-page.component.html',
  styleUrls: ['./cms-page.component.scss'],
})
export class CmsPageComponent {
  page$ = defer(() =>
    this.activatedRoute.data.pipe(
      map(
        (data) =>
          (data['page'] as Page | undefined) ||
          (data['navigationItem'] as NavigationItem | undefined)?.related?.attributes
      ),
      filter((page) => !!page),
      map((page) => page!)
    )
  );

  constructor(private activatedRoute: ActivatedRoute) {}
}
