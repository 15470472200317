<form [formGroup]="reevaluationApplicationForm">
  <h2>Diplomate Status</h2>

  <mat-expansion-panel>
    <mat-expansion-panel-header
      ><mat-panel-title>Information about Diplomate status options</mat-panel-title></mat-expansion-panel-header
    >
    <p>The options for ECVIM-CA Diplomate status are (main points summarised):</p>
    <b>Certified Diplomate (active Diplomate)</b>
    <ul>
      <li>Requires payment of the annual ECVIM-CA Diplomate Fee</li>
      <li>Requires Re-evaluation every 5 years</li>
      <li>Entitled to present yourself as a European Specialist to members of the public</li>
    </ul>

    <b>Non-certified Diplomate (non-active Diplomate)</b>
    <ul>
      <li>Not working sufficient hours in the Speciality to maintain active status</li>
      <li>Cannot act as a Programme Director or Resident Advisor</li>
      <li>Requires payment of a reduced annual ECVIM-CA Diplomate Fee</li>
      <li>NOT required to re-evaluate every 5 years</li>
      <li>
        Can use post-nominal letters (DipECVIM-CA) but not present yourself as a European Specialist to members of the
        public
      </li>
      <li>
        Can regain certified status by submitting to ECVIM-CA the details of their clinical activities for the last 12
        months and a statement regarding why they should be able to change their status back to an active Diplomate. The
        Credentials Committee will then decide if this is adequate or if there is the need for more documentation,
        supplementary activity, or examination.
      </li>
    </ul>
    <b>Retired Diplomate</b>
    <ul>
      <li>The person has declared themselves permanently and irrevocably non-certified</li>
      <li>Enforced from 31st December of the year of application</li>
      <li>Cannot hold an ECVIM-CA Committee position or vote at the AGM</li>
      <li>Can use post-nominal letters (Retired DipECVIM-CA)</li>
      <li>NOT required to pay the annual ECVIM-CA Diplomate Fee</li>
      <li>Cannot revert back to certified or non-certified status</li>
    </ul>

    <b>Voluntary cessation of Diplomate Registration</b>
    <ul>
      <li>Must write to the Secretariat 12 months before the AGM and will be published at the AGM</li>
      <li>Cannot revert back to certified or non-certified status</li>
    </ul>

    <p>
      <b>Please Note: </b>Diplomates considering a status change should ideally inform the College about the career
      break beforehand especially if the break is to be more than 2 years.
    </p>
  </mat-expansion-panel>

  <div class="form-row">
    <label class="full-width"
      >Requested Diplomate Status<span *ngIf="isFormRequired('requestedDiplomateStatus')"> *</span></label
    >
    <p>
      If you select "non-certified" or retired, please give a reason in the text box of "Reasons for unfulfillment" here
      below.
    </p>
    <mat-form-field appearance="fill">
      <mat-label>Requested Diplomate Status</mat-label>
      <mat-select formControlName="requestedDiplomateStatus">
        <mat-option
          *ngFor="let requestedDiplomateStatusOption of requestedDiplomateStatusOptions"
          [value]="requestedDiplomateStatusOption"
          >{{ requestedDiplomateStatusOption }}</mat-option
        >
      </mat-select>
      <mat-error *ngIf="!getFormByPath('requestedDiplomateStatus')?.valid">{{ genericErrorMessage }}</mat-error>
    </mat-form-field>
  </div>

  <h2>Re-evaluation Period</h2>
  <ul>
    <li>The Re-evaluation period is normally <b>5 years</b> in duration.</li>
    <li>
      Please state your re-evaluation dates but <b>make sure that they do not overlap</b> your previous re-evaluation
      period.
    </li>
  </ul>

  <b>First-Time re-evaluation applications:</b>
  <ul>
    <li>March of the year when passed Certifying Exam - time of submission of re-evaluation</li>
  </ul>

  <b>Repeat re-evaluation applications:</b>
  <ul>
    <li>
      Date of the end of your previous evaluation period until the time of submission of re-evaluation (max 31st October
      and max. 5 years)
    </li>
  </ul>

  <b>Parental/Sickness allowance:</b>
  <ul>
    <li>
      Over a 5-year re-evaluation period, extensions will be granted for maternity or paternity leave, serious health
      issues and exceptional circumstances subject to review by the Re-evaluation Committee. However, a Diplomate must
      have practised the speciality for at least three continuous years or the equivalent of three years during the
      preceding five-year period.
    </li>
  </ul>

  <p>
    Please contact the Chair of the ECVIM-CA Committee (via
    <a href="mailto:diplomates@ecvim-ca.college">diplomates@ecvim-ca.college</a>) if you would like to discuss these
    options further, or if you are concerned that re-evaluation may not be possible.
  </p>

  <div class="form-row">
    <label class="full-width"
      >Reevaluation Period<span *ngIf="isFormRequired('reevaluationPeriodStart')"> *</span></label
    >
    <mat-form-field appearance="fill">
      <mat-label>Start</mat-label>
      <input
        matInput
        [matDatepicker]="reevaluationPeriodStartPicker"
        formControlName="reevaluationPeriodStart"
        placeholder="YYYY-MM-DD" />
      <mat-datepicker-toggle matSuffix [for]="reevaluationPeriodStartPicker"></mat-datepicker-toggle>
      <mat-datepicker #reevaluationPeriodStartPicker></mat-datepicker>
      <mat-error *ngIf="!getFormByPath('reevaluationPeriodStart')?.valid">{{ genericErrorMessage }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>End</mat-label>
      <input
        matInput
        [matDatepicker]="reevaluationPeriodEndPicker"
        formControlName="reevaluationPeriodEnd"
        placeholder="YYYY-MM-DD" />
      <mat-datepicker-toggle matSuffix [for]="reevaluationPeriodEndPicker"></mat-datepicker-toggle>
      <mat-datepicker #reevaluationPeriodEndPicker></mat-datepicker>
      <mat-error *ngIf="!getFormByPath('reevaluationPeriodEnd')?.valid">{{ genericErrorMessage }}</mat-error>
    </mat-form-field>
  </div>

  <h2>EBVS Compulsory Requirements</h2>

  <p>Working within the Speciality for 5 years (minimum 24 hours/ week):</p>

  <div>
    Due to the changing nature of work as a Specialist, many work types may be acceptable to count towards the required
    weekly hours working in the speciality. Examples include:
  </div>
  <ul>
    <li>Working in academia (e.g., teaching, research, working as a head of department)</li>
    <li>Clinical private practice</li>
    <li>Teleconsulting</li>
    <li>Working in private industry (e.g., teaching, research)</li>
  </ul>

  <p>
    Please describe the type of work that you are doing in the comments box in this section on the application form.
    Parental or sickness leave is covered also.
  </p>

  <div>AGM Attendance:</div>
  <ul>
    <li>
      The Specialist is required to attend at least 2 AGMs during the 5-year re-evaluation period (you will be able to
      tick boxes for these on the form).
    </li>
  </ul>

  <p>
    <b>Please Note: </b>There is a box on the application form which allows you to give reasons why you may not have
    fulfilled the compulsory requirements.
  </p>

  <div class="form-row">
    <label>Work Hours<span *ngIf="isFormRequired('workHoursPerWeek')"> *</span></label>
    <p>
      Number of hours working per week in the Speciality during your period of reevaluation (minimum 24 hours/ week)
    </p>
    <mat-form-field appearance="fill">
      <mat-label>Work Hours</mat-label>
      <input matInput formControlName="workHoursPerWeek" type="number" />
      <mat-error *ngIf="!getFormByPath('workHoursPerWeek')!.valid">{{ genericErrorMessage }}</mat-error>
    </mat-form-field>
  </div>

  <div class="form-row">
    <label>Work Types<span *ngIf="isFormRequired('workTypes')"> *</span></label>
    <p>
      Due to the changing nature of work as a Specialist, many work types may be acceptable to count towards the
      required weekly hours working in the speciality. Please choose the ones that match.
    </p>
    <mat-form-field appearance="fill">
      <mat-label>Work Types</mat-label>
      <mat-select formControlName="workTypes" multiple>
        <mat-option *ngFor="let workType of workTypeOptions" [value]="workType">{{ workType }}</mat-option>
      </mat-select>
      <mat-error *ngIf="!getFormByPath('workTypes')!.valid">{{ genericErrorMessage }}</mat-error>
    </mat-form-field>
  </div>

  <div class="form-row">
    <label>Work Description<span *ngIf="isFormRequired('workDescription')"> *</span></label>
    <p>
      Please describe the type of work that you have been doing within the Speciality during your period of reevaluation
    </p>
    <mat-form-field appearance="fill">
      <mat-label>Work Description</mat-label>
      <textarea matInput formControlName="workDescription"></textarea>
      <mat-error *ngIf="!getFormByPath('workDescription')!.valid">{{ genericErrorMessage }}</mat-error>
    </mat-form-field>
  </div>

  <div class="form-row">
    <label>AGM Attendance<span *ngIf="isFormRequired('agmAttendanceYears')"> *</span></label>
    <p>Please select the years you attended the AGM (minimum two of five years)</p>
    <div formArrayName="agmAttendanceYears">
      <mat-checkbox
        *ngFor="let agmAttendanceYear of agmAttendanceYearOptions; let i = index"
        color="primary"
        [formControlName]="i"
        >{{ agmAttendanceYear }}</mat-checkbox
      >
    </div>
  </div>

  <div class="form-row">
    <label>Reasons for unfulfillment<span *ngIf="isFormRequired('unfulfillmentReasons')"> *</span></label>
    <p>If you have not fulfilled the compulsory requirements, you can give reasons here.</p>
    <mat-form-field appearance="fill">
      <mat-label>Reasons for unfulfillment</mat-label>
      <textarea matInput formControlName="unfulfillmentReasons"></textarea>
      <mat-error *ngIf="!getFormByPath('unfulfillmentReasons')!.valid">{{ genericErrorMessage }}</mat-error>
    </mat-form-field>
  </div>

  <h2>Speciality Points Summary</h2>
  <p>
    Re-accreditation requires the applicant to have accrued at least 100 points within the re-evaluation period, which
    must not overlap with the previous re-evaluation period.
  </p>
  <p>
    Please complete the attached Excel spreadsheet to calculate the points that you have gained. Once complete, please
    upload the Excel sheet using the button below, and fill in the totals for each category on the application form. The
    spreadsheet contains 5 sections (A-E), as shown below (category name and points in brackets). Please see the
    guidelines in the spreadsheet to see how many points you can claim for each individual item.
  </p>

  <b>Section A: Contributions to the ECVIM-CA</b>
  <ul>
    <li>AGM attendance (A1, max. 10 points)</li>
    <li>Contributing new exam questions (A2, max. 30 points)</li>
    <li>Work for ECVIM-CA Committees (A3, max. 20 points)</li>
    <li>Resident supervision (A4, max. 10 points)</li>
  </ul>

  <b>Section B1: Conference Attendance</b>
  <ul>
    <li>Max 90 points for Page B1 and B2 combined.</li>
  </ul>

  <b>Section B2: Webinar and other CPD Attendance (relevant to veterinary internal medicine)</b>
  <ul>
    <li>
      (B2, max. 40 points). Max 90 points for CPD (Sheet B1 and B2) combined, with a maximum of 40 for online webinars.
    </li>
  </ul>

  <b>Section C: Lectures and Presentations</b>
  <ul>
    <li>
      Presentations (C, max. 60 points)- please note that you cannot count lectures given to veterinary undergraduates
    </li>
  </ul>

  <b>Section D: Publications and Reviewing</b>
  <ul>
    <li>Publications (D1, max. 60 points)</li>
    <li>Peer-reviewing (D2, max. 10 points)</li>
  </ul>

  <p>
    There is no single category for review articles. A meta-analysis or systematic review would be considered a research
    paper. A standard review article for continuing education purposes would be considered a Continuing Education
    Article. Other review articles may contain information presenting data in a case report or case series format.
    Please choose the action into which you believe the article is best placed. Any explanatory comments can be provided
    in the comment boxes at the on the re-evaluation form.
  </p>

  <b>Section E: Additional Activities</b>
  <ul>
    <li>
      Additional activities can be added at your discretion. An explanation of estimated points should be provided.
      These will be considered by the Re-evaluation Committee (E1, max. 20 points)
    </li>
  </ul>

  <p>
    Examples include but are not limited to: editorial work for veterinary publications, organisation of CPD events
    within your area of specialisation, participation in Societies that promote the specialty or subspecialties,
    attendance at rounds in a human hospital.
  </p>
  <ul>
    <li>Participation in College Activities (E2, max. points- not applicable)</li>
  </ul>

  <p>
    Examples include completion of ECVIM-CA Questionnaires (points indicated at the time), helping with the ECVIM-CA
    Examinations (Lead Diplomate 5 points, OCM examiner 5 points, invigilator of any EBVS College 2 points)
  </p>

  <p>
    There is further information regarding the point allocations in the Information Brochure and on the Excel
    Spreadsheet template, which can be downloaded below.
  </p>

  <div class="form-row">
    <div class="file-upload-description">
      <label>Reevaluation Points Details<span *ngIf="isFormRequired('reevaluationPointDetails')"> *</span></label>
      <p>Please upload your Excel Spreadsheet detailing your Re-evaluation Points here.</p>
    </div>

    <div class="form-field">
      <app-file-upload
        formControlName="reevaluationPointDetails"
        allowedTypes="application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,"
        [ref]="diplomateReevaluationRef"
        [refId]="diplomateReevaluationId"
        [maxNumberFiles]="1"
        field="reevaluationPointDetails"
        [path]="diplomateReevaluationDirectoryPath"
        [baseFileName]="getBaseFileName('reevaluationPointDetails')"></app-file-upload>
    </div>
  </div>

  <table mat-table [dataSource]="pointsTableData">
    <ng-container matColumnDef="label">
      <th mat-header-cell *matHeaderCellDef>Category</th>
      <td mat-cell *matCellDef="let element">{{ element.label }}{{ getMaxPointsNote(element.max) }}</td>
      <td mat-footer-cell *matFooterCellDef>Total</td>
    </ng-container>

    <ng-container matColumnDef="points">
      <th mat-header-cell *matHeaderCellDef class="center">Points</th>
      <td mat-cell *matCellDef="let element" class="center">
        <mat-form-field appearance="outline">
          <input matInput type="number" [formControl]="element.formControl" [(ngModel)]="element.formControl.value" />
        </mat-form-field>
      </td>
      <td mat-footer-cell *matFooterCellDef class="center points">{{ totalPoints }}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="pointsTableDisplayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: pointsTableDisplayedColumns"></tr>
    <tr mat-footer-row *matFooterRowDef="pointsTableDisplayedColumns"></tr>
  </table>

  <div class="form-row">
    <label class="full-width"
      >Explanation for Additional Activity Points<span *ngIf="isFormRequired('additionalActivityPointsExplanation')">
        *</span
      ></label
    >
    <mat-form-field appearance="fill">
      <textarea matInput formControlName="additionalActivityPointsExplanation"></textarea>
      <mat-error *ngIf="!getFormByPath('additionalActivityPointsExplanation')!.valid">{{
        genericErrorMessage
      }}</mat-error>
    </mat-form-field>
  </div>

  <h2>Declaration</h2>
  <div>In submitting this application, I certify that I:</div>
  <ol>
    <li>Demonstrate satisfactory moral and ethical standing in the profession</li>
    <li>Practice scientific, evidence-based veterinary medicine, which complies with animal welfare legislation</li>
    <li>Promote continuous improvement in the quality and standard of specialist practice</li>
    <li>Communicate appropriately and effectively with the public and professional colleagues</li>
  </ol>

  <mat-checkbox formControlName="declarationAccepted" color="primary"
    ><label>
      I meet all the specified criteria listed above<span *ngIf="isFormRequired('declarationAccepted')"> *</span>
    </label>
  </mat-checkbox>
</form>
