<form [formGroup]="reviewForm">
  <div class="form-row" *ngIf="isNotesSet">
    <mat-form-field appearance="fill">
      <mat-label>{{ notesLabel }}</mat-label>
      <textarea matInput formControlName="notes"></textarea>
    </mat-form-field>
  </div>
  <div class="form-row" *ngIf="!isCommentHidden">
    <mat-form-field appearance="fill">
      <mat-label>{{ commentLabel }}</mat-label>
      <textarea matInput formControlName="comment"></textarea>
      <mat-error *ngIf="reviewForm.get('comment')?.invalid">{{ errorMessageRequired }}</mat-error>
    </mat-form-field>
  </div>
  <div class="form-row" *ngIf="isAcceptedSet">
    <div class="form-field">
      <mat-button-toggle-group formControlName="accepted">
        <mat-button-toggle [value]="true">Adequate</mat-button-toggle>
        <mat-button-toggle [value]="false">Inadequate</mat-button-toggle>
      </mat-button-toggle-group>
      <mat-error *ngIf="reviewForm.get('accepted')?.invalid">{{ errorMessageRequired }}</mat-error>
    </div>
  </div>
</form>
