<app-grid class="p-2">
  <h1>Profile Settings</h1>
  <mat-card>
    <app-member-form [data]="member" [disabled]="formDisabled"></app-member-form>
    <div style="text-align: right">
      <button
        mat-raised-button
        *ngIf="!formDisabled"
        [disabled]="loading || memberForm?.memberFormInvalid"
        (click)="save()">
        Save <mat-icon *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
      </button>
      <button mat-raised-button *ngIf="formDisabled" (click)="enableEditMode()">Edit</button>
    </div>
  </mat-card>
</app-grid>
