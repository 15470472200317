import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as countries from 'i18n-iso-countries';
import countryDataEn from 'i18n-iso-countries/langs/en.json';
import {
  Enum_Componentmasterdatadiplomat_Pathtodiplomacy,
  Enum_Componentmasterdatadiplomat_Worksector,
  Enum_Componentmasterdataresident_Status,
  Enum_Member_Title,
  InstitutionEntity,
  Member,
  MemberEntity,
} from 'models/types';
import { combineLatest, defer, map, Observable, of, startWith, Subject, takeUntil } from 'rxjs';
import { BaseDataService } from 'src/app/services/base-data.service';
import {
  getDiplomateRoleIndex,
  getFullnameForMember,
  getResidentRoleIndex,
  isDiplomate,
  isDiplomateRole,
  isResident,
  isResidentRole,
} from 'src/app/utils/member';
import { REQUIRED_AND_VALID, VALID_CHARS_VALIDATOR } from 'src/app/utils/validators';

interface Country {
  value: string;
  name: string;
}

@Component({
  selector: 'app-member-form',
  templateUrl: './member-form.component.html',
  styleUrls: ['./member-form.component.scss'],
})
export class MemberFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  data: Partial<Member> | undefined = undefined;

  @Input()
  withDetails = false;

  @Input()
  disabled = false;

  @Input()
  withCheckboxes = false;

  memberForm: FormGroup = this.fb.group({
    Name: this.fb.control('', REQUIRED_AND_VALID),
    middlename: this.fb.control('', VALID_CHARS_VALIDATOR),
    Surname: this.fb.control('', REQUIRED_AND_VALID),
    address: this.fb.group({
      id: this.fb.control(null),
      Street: this.fb.control('', Validators.required),
      Number: this.fb.control('', Validators.required),
      Postcode: this.fb.control('', Validators.required),
      City: this.fb.control('', Validators.required),
      Country: this.fb.control('', Validators.required),
      AdditionalInfo: this.fb.control(''),
      province: this.fb.control(null),
    }),
    title: this.fb.control('', Validators.required),
    citizenship: this.fb.control('', Validators.required),
    GraduationSchool: this.fb.control(null),

    termsAndConditionsAccepted: this.fb.control<boolean | null>(null, Validators.requiredTrue),
    privacyPolicyAccepted: this.fb.control<boolean | null>(null, Validators.requiredTrue),
  });

  institutions$ = defer(() =>
    this.baseDataService.getInstitutions().pipe(
      map((result) => result.data.institutions.data),
      takeUntil(this.destroyed$)
    )
  );

  diplomats$ = defer(() => this.baseDataService.getDiplomats().pipe(takeUntil(this.destroyed$)));

  countries$: Observable<Country[]> = defer(() => {
    const countryNames = countries.getNames('en', { select: 'official' });
    const countryObjects = Object.entries(countryNames).map(([key, val]) => ({
      value: key,
      name: val,
    }));
    return of(countryObjects);
  });

  countryFilterCtrl = new FormControl('');
  filteredCountries$ = defer(() =>
    combineLatest([this.countryFilterCtrl.valueChanges.pipe(startWith('')), this.countries$]).pipe(
      map(([term, countries]) =>
        term ? countries.filter((option) => this.countrySearchFn(option, term)) : countries.slice()
      ),
      takeUntil(this.destroyed$)
    )
  );

  citizenshipFilterCtrl = new FormControl('');
  filteredCitizenships$ = defer(() =>
    combineLatest([this.citizenshipFilterCtrl.valueChanges.pipe(startWith('')), this.countries$]).pipe(
      map(([term, citizenships]) =>
        term ? citizenships.filter((option) => this.countrySearchFn(option, term)) : citizenships.slice()
      ),
      takeUntil(this.destroyed$)
    )
  );

  graduationSchoolFilterCtrl = new FormControl('');
  filteredGraduationSchools$ = defer(() =>
    combineLatest([this.graduationSchoolFilterCtrl.valueChanges.pipe(startWith('')), this.institutions$]).pipe(
      map(([term, institutions]) =>
        term ? institutions.filter((option) => this.institutionSearchFn(option, term)) : institutions.slice()
      ),
      takeUntil(this.destroyed$)
    )
  );

  residenciesFilterCtrl = new FormControl('');
  filteredResidencies$ = defer(() =>
    combineLatest([this.residenciesFilterCtrl.valueChanges.pipe(startWith('')), this.institutions$]).pipe(
      map(([term, institutions]) =>
        term ? institutions.filter((option) => this.institutionSearchFn(option, term)) : institutions.slice()
      ),
      takeUntil(this.destroyed$)
    )
  );

  internshipFilterCtrl = new FormControl('');
  filteredInternshipLocations$ = defer(() =>
    combineLatest([this.internshipFilterCtrl.valueChanges.pipe(startWith('')), this.institutions$]).pipe(
      map(([term, institutions]) =>
        term ? institutions.filter((option) => this.institutionSearchFn(option, term)) : institutions.slice()
      ),
      takeUntil(this.destroyed$)
    )
  );

  adviserFilterCtrl = new FormControl('');
  filteredAdvisers$ = defer(() =>
    combineLatest([this.adviserFilterCtrl.valueChanges.pipe(startWith('')), this.diplomats$]).pipe(
      map(([term, diplomats]) =>
        term ? diplomats.filter((option) => this.diplomateSearchFn(option, term)) : diplomats.slice()
      ),
      takeUntil(this.destroyed$)
    )
  );

  /**
   * Needs to be hardcoded because the generated enum value is not accepted by graphql
   *
   */
  private readonly RESIDENT_STATUS_MAP = new Map<string, string>([
    [Enum_Componentmasterdataresident_Status.InTraining, 'In Training'],
    [Enum_Componentmasterdataresident_Status.InterruptedTraining, 'Interrupted Training'],
    [Enum_Componentmasterdataresident_Status.PostTraining, 'Post Training'],
  ]);

  get residentStatusOptions() {
    return Object.values(Enum_Componentmasterdataresident_Status)
      .filter((status) => this.RESIDENT_STATUS_MAP.has(status))
      .map((status) => this.RESIDENT_STATUS_MAP.get(status)!);
  }

  /**
   * Needs to be hardcoded because the generated enum value is not accepted by graphql
   *
   */
  private readonly PATH_TO_DIPLOMACY_MAP = new Map<string, string>([
    [Enum_Componentmasterdatadiplomat_Pathtodiplomacy.DeFacto, 'De Facto'],
    [Enum_Componentmasterdatadiplomat_Pathtodiplomacy.ByExamination, 'By Examination'],
    [Enum_Componentmasterdatadiplomat_Pathtodiplomacy.AcvimDiplomate, 'ACVIM Diplomate'],
    [Enum_Componentmasterdatadiplomat_Pathtodiplomacy.FoundingDiplomate, 'Founding Diplomate'],
  ]);

  get pathToDiplomacyOptions() {
    return Object.values(Enum_Componentmasterdatadiplomat_Pathtodiplomacy)
      .filter((path) => this.PATH_TO_DIPLOMACY_MAP.has(path))
      .map((path) => this.PATH_TO_DIPLOMACY_MAP.get(path)!);
  }

  get memberFormValues() {
    const value = this.memberForm.value;
    if (value.address?.id === null) {
      delete value.address.id;
    }
    return value;
  }

  get memberFormInvalid() {
    return this.memberForm.invalid;
  }

  get titleOptions() {
    return Object.values(Enum_Member_Title);
  }

  get worksectorOptions() {
    return Object.values(Enum_Componentmasterdatadiplomat_Worksector);
  }

  get isResident() {
    return isResident(this.data as Member);
  }

  get isDiplomat() {
    return isDiplomate(this.data as Member);
  }

  get residentRoleIndex() {
    return getResidentRoleIndex(this.data as Member);
  }

  get diplomatRoleIndex() {
    return getDiplomateRoleIndex(this.data as Member);
  }

  get genericErrorMessage() {
    return 'This field is not valid!';
  }

  private diplomateRoleFormGroup = this.fb.group({
    ['__typename']: this.fb.control('ComponentMasterdataDiplomat'),
    id: this.fb.control(''),
    // TODO: Graduated from vet school
    since: this.fb.control(null),
    PathToDiplomacy: this.fb.control(null, Validators.required),
    worksector: this.fb.control(null, Validators.required),
    residency: this.fb.group({
      id: this.fb.control(null),
    }),
    internship: this.fb.group({
      id: this.fb.control(null),
    }),
  });

  private residentRoleFormGroup = this.fb.group({
    ['__typename']: this.fb.control('ComponentMasterdataResident'),
    id: this.fb.control(''),
    // TODO: Graduated from vet school
    Since: this.fb.control({ value: null, disabled: true }),
    //Until: this.fb.control(null, Validators.required),
    Status: this.fb.control({ value: null, disabled: true }),
    // worksector: this.fb.control(null, Validators.required),
    // TODO: Programme Director
    // internship: this.fb.group({
    //   id: this.fb.control(null, Validators.required),
    // }),
    Adviser: this.fb.group({
      id: this.fb.control({ value: '', disabled: true }),
    }),
  });

  private destroyed$ = new Subject<void>();

  constructor(private fb: FormBuilder, private baseDataService: BaseDataService) {
    countries.registerLocale(countryDataEn);
  }

  ngOnInit(): void {
    if (this.data) {
      // get rid of graphql frozen data
      const patchData = JSON.parse(JSON.stringify(this.data));

      if (this.withDetails) {
        const roleFormGroups = [];

        for (let role of patchData.Role) {
          if (isResidentRole(role)) {
            if (role.Adviser) {
              role.Adviser = { id: role.Adviser?.data?.id };
            }
            if (role.Status) {
              role.Status = this.RESIDENT_STATUS_MAP.get(role.Status) ?? null;
            }
            roleFormGroups.push(this.residentRoleFormGroup);
          } else if (isDiplomateRole(role)) {
            if (role.residency) {
              role.residency = { id: role.residency?.data?.id };
            }
            if (role.internship) {
              role.internship = { id: role.internship?.data?.id };
            }
            if (role.PathToDiplomacy) {
              role.PathToDiplomacy = this.PATH_TO_DIPLOMACY_MAP.get(role.PathToDiplomacy) ?? null;
            }
            roleFormGroups.push(this.diplomateRoleFormGroup);
          } else {
            throw new Error('unknown role type');
          }
        }
        this.memberForm.addControl('Role', this.fb.array<FormGroup>(roleFormGroups));

        if (this.isDiplomat) {
          this.clearValidators(this.residentRoleFormGroup);
        }
      }

      if (!this.withCheckboxes) {
        this.memberForm.get('termsAndConditionsAccepted')?.setValidators(null);
        this.memberForm.get('privacyPolicyAccepted')?.setValidators(null);
      }

      // general data patching
      if (patchData?.GraduationSchool) {
        patchData.GraduationSchool = patchData.GraduationSchool.data?.id;
      }
      this.memberForm.patchValue(patchData);
    }
    if (this.disabled) {
      this.memberForm.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled'] && !changes['disabled'].firstChange) {
      const disabled = changes['disabled'].currentValue;
      if (disabled) {
        this.memberForm.disable();
      } else {
        this.memberForm.enable();
      }
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$.unsubscribe();
  }

  institutionDisplayFn(options: InstitutionEntity[]) {
    return (institution: string | InstitutionEntity) => {
      if (typeof institution === 'string') {
        const ins = options.find((i) => i.id === institution);
        return ins?.attributes?.Name ?? '';
      }
      return institution?.attributes?.Name ?? '';
    };
  }

  institutionSearchFn(option: InstitutionEntity, term: string) {
    return !!option.attributes?.Name?.toLowerCase().includes(term.toLowerCase());
  }

  diplomateDisplayFn(options: MemberEntity[]) {
    return (diplomate: string | MemberEntity | undefined) => {
      if (typeof diplomate === 'string') {
        diplomate = options.find((i) => i.id === diplomate);
      }
      return getFullnameForMember(diplomate?.attributes);
    };
  }

  trackById(index: number, item: InstitutionEntity | MemberEntity) {
    return item.id;
  }

  diplomateSearchFn(option: MemberEntity, term: string) {
    return !!getFullnameForMember(option.attributes).toLowerCase().includes(term.toLowerCase());
  }

  countryDisplayFn(options: Country[]) {
    return (country: string | Country) => {
      if (typeof country === 'string') {
        const c = options.find((i) => i.value === country);
        return c?.name ?? '';
      }
      return country?.name ?? '';
    };
  }

  countryTrackByFn(index: number, country: Country) {
    return country.value as string;
  }

  countrySearchFn(option: Country, term: string) {
    return !!option.name?.toLowerCase().includes(term.toLowerCase());
  }

  clear(formControlName: string) {
    this.getFormByPath(formControlName)?.reset();
  }

  isClearable(formControlName: string) {
    return (
      !this.disabled && !!this.getFormByPath(formControlName)?.value && !this.getFormByPath(formControlName)?.disabled
    );
  }

  clearValidators(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((controlName: string) => {
      const control = formGroup.get(controlName);

      if (control instanceof FormControl) {
        control.clearValidators();
        control.updateValueAndValidity(); // Trigger validation update
      } else if (control instanceof FormGroup) {
        this.clearValidators(control); // Recursively clear validators for nested FormGroup
      }
    });
  }

  getFormByPath(path: string) {
    path = path.replaceAll('Resident', getResidentRoleIndex(this.data as Member)?.toString() ?? 'Resident');
    path = path.replaceAll('Diplomat', getDiplomateRoleIndex(this.data as Member)?.toString() ?? 'Diplomat');

    const parts = path.split('.');
    let name = parts.shift();
    let form = this.memberForm.get(name!);

    if (form === null) {
      console.log(path);
    }

    while (parts.length > 0) {
      name = parts.shift();
      form = form!.get(name!);

      if (form === null) {
        console.log(path);
      }
    }

    return form;
  }
}
