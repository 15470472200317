import { FormGroup, Validators } from '@angular/forms';
import { Member } from 'models/types';

import { getFullnameForMember } from './member';

export function getFormByPath(formGroup: FormGroup, path: string) {
  const parts = path.split('.');
  const name = parts.shift();
  let form = formGroup.get(name!);
  while (parts.length > 0) {
    form = form!.get(parts.shift()!);
  }
  return form;
}

export function isFormRequired(formGroup: FormGroup, path: string) {
  const form = getFormByPath(formGroup, path);
  if (form) {
    return form.hasValidator(Validators.required) || form.hasValidator(Validators.requiredTrue);
  }
  return false;
}

export function getBaseFileName(category: string, field: string, member: Member | undefined | null, year?: number) {
  if (year === undefined) {
    year = new Date().getFullYear();
  }
  const memberName = getFullnameForMember(member, { title: false }).toLowerCase().replaceAll(' ', '-');
  return `${year}_${category}_${field}_${memberName}`;
}
