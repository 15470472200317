import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { defer, map, Subject } from 'rxjs';
import { MemberService } from 'src/app/services/member.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnDestroy {
  newsItems$ = defer(() => this.activatedRoute.data.pipe(map((data) => data['news'])));

  get hasRole() {
    return this.memberService.hasRole;
  }

  private destroyed$ = new Subject<void>();

  constructor(private activatedRoute: ActivatedRoute, private memberService: MemberService) {
    // pass
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$.unsubscribe();
  }
}
