import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { CertifyingExamEntity } from 'models/types';
import { map, merge, Observable } from 'rxjs';
import { CertifyingExamService } from 'src/app/services/certifying-exam.service';
import { CommitteeService } from 'src/app/services/committee.service';

@Injectable({
  providedIn: 'root',
})
export class CertifyingExamForReviewByIdResolver implements Resolve<CertifyingExamEntity> {
  constructor(private certifyingExamService: CertifyingExamService, private router: Router) {
    // pass
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CertifyingExamEntity> {
    if (route.paramMap.has('id')) {
      return this.certifyingExamService.getCertifyingExamForReviewById(route.paramMap.get('id')!).pipe(
        map((res) => {
          const certifyingExamEntity = res.data.certifyingExam.data;
          if (!certifyingExamEntity) {
            this.router.navigate(['/committee-work/credentials-review']);
            throw new Error('no data received');
          }
          return certifyingExamEntity;
        })
      );
    }
    this.router.navigate(['/home']);
    throw new Error('not allowed path');
  }
}
