import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Maybe } from 'models/types';

@Pipe({ name: 'keepHtml', pure: false })
export class EscapeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  transform(content: Maybe<string> | undefined) {
    return this.sanitizer.bypassSecurityTrustHtml(content || '');
  }
}