<h2>General Exam Application</h2>

<form [formGroup]="examApplicationForm">
  <div class="form-entry" *ngIf="generalExam?.attempt === 1">
    <label>Case Summary<span *ngIf="isFormRequired('caseSummary')"> *</span></label>
    <p>Residents must submit one case summary at the time of application for the General Examination.</p>
    <p>
      Detailed instructions on the format and the requirements of the case summaries can be found in the respective
      Information Brochure.
    </p>
    <div class="form-inputs">
      <app-file-upload
        formControlName="caseSummary"
        allowedTypes="application/pdf"
        [ref]="generalExamRef"
        [refId]="generalExamId"
        field="caseSummary"
        [path]="generalExamDirectoryPath"
        [baseFileName]="getBaseFileName('caseSummary')"></app-file-upload>
    </div>
  </div>

  <div class="form-entry">
    <label>Programme Director Letter<span *ngIf="isFormRequired('programmeDirectorLetter')"> *</span></label>
    <p>
      Please submit a signed letter from your Programme Director. A model reference letter is provided in the Residents
      member portal Credentials section.
    </p>
    <div class="form-inputs">
      <app-file-upload
        formControlName="programmeDirectorLetter"
        allowedTypes="application/pdf"
        [ref]="generalExamRef"
        [refId]="generalExamId"
        [maxNumberFiles]="1"
        field="programmeDirectorLetter"
        [path]="generalExamDirectoryPath"
        [baseFileName]="getBaseFileName('programmeDirectorLetter')"></app-file-upload>
    </div>
  </div>

  <div class="form-entry">
    <label>Previous Correspondence<span *ngIf="isFormRequired('previousCorrespondence')"> *</span></label>
    <p>
      Any previous correspondence pertinent to the individual Resident’s training programme and application (e.g. breaks
      in the programme for ill health or personal reasons, or major changes to the programme since enrolment) should be
      included.
    </p>
    <div class="form-inputs">
      <app-file-upload
        formControlName="previousCorrespondence"
        allowedTypes="application/pdf"
        [maxNumberFiles]="1"
        [ref]="generalExamRef"
        [refId]="generalExamId"
        field="previousCorrespondence"
        [path]="generalExamDirectoryPath"
        [baseFileName]="getBaseFileName('previousCorrespondence')"></app-file-upload>
    </div>
  </div>

  <div class="form-entry" *ngIf="generalExam?.attempt === 1">
    <label>Curriculum Vitae<span *ngIf="isFormRequired('curriculumVitae')"> *</span></label>
    <div class="form-inputs">
      <app-file-upload
        formControlName="curriculumVitae"
        allowedTypes="application/pdf"
        [ref]="generalExamRef"
        [refId]="generalExamId"
        [maxNumberFiles]="1"
        field="curriculumVitae"
        [path]="generalExamDirectoryPath"
        [baseFileName]="getBaseFileName('curriculumVitae')"></app-file-upload>
    </div>
  </div>

  <mat-checkbox formControlName="termsAndConditionsAccepted" color="primary"
    ><label>
      I have read and accept the
      <a target="_blank" routerLink="/terms-and-conditions-general-examination"
        >terms and conditions of the ECVIM-CA General Examination</a
      ><span *ngIf="isFormRequired('termsAndConditionsAccepted')"> *</span>
    </label>
  </mat-checkbox>
  <mat-checkbox formControlName="privacyPolicyAccepted" color="primary"
    ><label>
      I have read and accept the <a target="_blank" routerLink="/privacypolicy">privacy policy</a
      ><span *ngIf="isFormRequired('privacyPolicyAccepted')"> *</span>
    </label>
  </mat-checkbox>
  <mat-checkbox formControlName="confidentialityAgreementAccepted" color="primary">
    <label>
      I have read and accept the <a target="_blank" routerLink="/confidentiality-agreement">confidentiality agreement</a
      ><span *ngIf="isFormRequired('confidentialityAgreementAccepted')"> *</span>
    </label>
  </mat-checkbox>
</form>
