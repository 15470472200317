import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss'],
})
export class AuthCallbackComponent implements OnInit {
  constructor(private authService: AuthService) {
    // pass
  }

  ngOnInit(): void {
    if (window.location.search) {
      const httpParams = new HttpParams({ fromString: window.location.search });
      if (httpParams.has('id_token')) {
        this.authService.handleLoginCallback(httpParams);
        return;
      }
    }
    this.authService.login();
  }
}
