import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  CertifyingExamEntity,
  CertifyingExamInput,
  Enum_Certifyingexam_Applicationresult,
  Enum_Certifyingexam_Examparts,
  Enum_Certifyingexam_Typeofsubmission,
  MemberEntity,
  UploadFileEntity,
} from 'models/types';
import {
  CERTIFYING_EXAM_DIRECTORY_PATH,
  CERTIFYING_EXAM_REF,
  getCurrentCertifyingExamApplicationYear,
} from 'src/app/utils/exam';
import { getBaseFileName, getFormByPath, isFormRequired } from 'src/app/utils/form';
import { enumValueToString } from 'src/app/utils/utils';

import { CredentialsSubmissionFormComponent } from '../credentials-submission-form/credentials-submission-form.component';

@Component({
  selector: 'app-certifying-exam-application-form',
  templateUrl: './certifying-exam-application-form.component.html',
  styleUrls: ['./certifying-exam-application-form.component.scss'],
})
export class CertifyingExamApplicationFormComponent implements OnInit, OnChanges {
  @ViewChild(CredentialsSubmissionFormComponent)
  credentialsSubmissionForm: CredentialsSubmissionFormComponent | undefined = undefined;

  @Input()
  memberEntity!: MemberEntity;

  @Input()
  certifyingExamEntity!: CertifyingExamEntity;

  @Input()
  disabled = true;

  loading = false;

  examApplicationForm = this.fb.group({
    examParts: this.fb.control('', Validators.required),

    programmeDirectorLetter: this.fb.control<UploadFileEntity[]>([], [Validators.required, Validators.minLength(1)]),
    previousCorrespondence: this.fb.control<UploadFileEntity[]>([], [Validators.required, Validators.minLength(1)]),

    termsAndConditionsAccepted: this.fb.control(false, Validators.requiredTrue),
    confidentialityAgreementAccepted: this.fb.control(false, Validators.requiredTrue),
    privacyPolicyAccepted: this.fb.control(false, Validators.requiredTrue),
  });

  get member() {
    return this.memberEntity.attributes!;
  }

  get examPartsOptions() {
    return Object.values(Enum_Certifyingexam_Examparts).filter(
      (examPart) =>
        examPart !== Enum_Certifyingexam_Examparts.TheoryOnly &&
        examPart !== Enum_Certifyingexam_Examparts.PracticalOcmOnlyInterpretivePart
    );
  }

  get values(): CertifyingExamInput {
    const values = Object.fromEntries(
      Object.entries(this.examApplicationForm.controls)
        .map(([key, control]) => [key, control.value])
        .filter(([_, value]) => !Array.isArray(value))
    );
    return { ...values, ...this.credentialsSubmissionForm?.values };
  }

  get genericErrorMessage() {
    return 'This field is required!';
  }

  get certifyingExamRef() {
    return CERTIFYING_EXAM_REF;
  }

  get certifyingExamId() {
    return this.certifyingExamEntity.id!;
  }

  get certifyingExam() {
    return this.certifyingExamEntity.attributes!;
  }

  get certifyingExamDirectoryPath() {
    return CERTIFYING_EXAM_DIRECTORY_PATH;
  }

  get currentExamYear() {
    return getCurrentCertifyingExamApplicationYear();
  }

  get isFirstSubmission() {
    return this.certifyingExam?.typeOfSubmission === Enum_Certifyingexam_Typeofsubmission.FirstSubmission;
  }

  get isResubmission() {
    return this.certifyingExam?.typeOfSubmission === Enum_Certifyingexam_Typeofsubmission.ReSubmission;
  }

  get wasPreviouslyAccepted() {
    return (
      this.certifyingExamEntity?.attributes?.applicationResult ==
      Enum_Certifyingexam_Applicationresult.PreviouslyAccepted
    );
  }

  get areCredentialsNecessary() {
    return !this.wasPreviouslyAccepted;
  }

  get isValid() {
    return (
      this.examApplicationForm.valid &&
      (!this.areCredentialsNecessary || this.credentialsSubmissionForm?.credentialsSubmissionForm.valid)
    );
  }

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    const ce = this.certifyingExam;

    if (ce.attempt == 1) {
      const examParts = this.examApplicationForm.get('examParts');
      examParts?.setValue(Enum_Certifyingexam_Examparts.EntireCertifyingExam);
      examParts?.disable();

      if (this.areCredentialsNecessary) {
        const programmeDirectorLetter = this.examApplicationForm.get('programmeDirectorLetter');
        programmeDirectorLetter?.setValidators(null);
        programmeDirectorLetter?.disable();

        const previousCorrespondence = this.examApplicationForm.get('previousCorrespondence');
        previousCorrespondence?.setValidators(null);
        previousCorrespondence?.disable();
      }
    }

    this.examApplicationForm.patchValue({
      examParts: ce.examParts ?? this.examApplicationForm.get('examParts')?.value,

      programmeDirectorLetter: [ce.programmeDirectorLetter?.data].filter((uf) => !!uf) as UploadFileEntity[],
      previousCorrespondence: ce.previousCorrespondence?.data ?? [],

      termsAndConditionsAccepted: ce.termsAndConditionsAccepted ?? false,
      confidentialityAgreementAccepted: ce.confidentialityAgreementAccepted ?? false,
      privacyPolicyAccepted: ce.privacyPolicyAccepted ?? false,
    });

    if (this.disabled) {
      this.examApplicationForm.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled'] && !changes['disabled'].firstChange) {
      const disabled = changes['disabled'].currentValue;
      if (disabled) {
        this.examApplicationForm.disable();
      } else {
        this.examApplicationForm.enable();
      }
    }
  }

  displayExamPartsOption(option: string) {
    return enumValueToString(option);
  }

  getBaseFileName(field: string) {
    return getBaseFileName('certifying-exam', field, this.member);
  }

  getFormByPath(path: string) {
    return getFormByPath(this.examApplicationForm, path);
  }

  isFormRequired(path: string) {
    return isFormRequired(this.examApplicationForm, path);
  }
}
