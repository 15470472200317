import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CertifyingExamInput, Enum_Certifyingexam_Applicationresult } from 'models/types';

@Component({
  selector: 'app-certifying-exam-application-result-form',
  templateUrl: './certifying-exam-application-result-form.component.html',
  styleUrls: ['./certifying-exam-application-result-form.component.scss'],
})
export class CertifyingExamApplicationResultFormComponent implements OnInit, OnChanges {
  @Input()
  applicationResult: string | undefined | null = undefined;

  @Input()
  applicationResultComment: string | undefined | null = undefined;

  @Input()
  fullCredentialsFeeNextApplication: boolean | undefined | null = undefined;

  @Input()
  disabled = true;

  applicationResultForm: FormGroup = this.fb.group({
    applicationResult: this.fb.control('', Validators.required),
    applicationResultComment: this.fb.control(''),
    fullCredentialsFeeNextApplication: this.fb.control(false),
  });

  get applicationResultOptions() {
    return Object.values(Enum_Certifyingexam_Applicationresult).filter(
      (result) =>
        result !== Enum_Certifyingexam_Applicationresult.AcceptedWithOutstandingCredentials &&
        result !== Enum_Certifyingexam_Applicationresult.PreviouslyAccepted
    );
  }

  get values() {
    const values: CertifyingExamInput = this.applicationResultForm.value;
    if (this.isFullCredentialsFeeNextApplicationHidden) {
      values.fullCredentialsFeeNextApplication = false;
    }
    return values;
  }

  get isValid() {
    return !this.applicationResultForm.invalid;
  }

  get isFullCredentialsFeeNextApplicationHidden() {
    const result = this.applicationResultForm.get('applicationResult')?.value;
    return result === Enum_Certifyingexam_Applicationresult.Accepted;
  }

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.applicationResultForm.patchValue({
      applicationResult: this.applicationResult,
      applicationResultComment: this.applicationResultComment,
      fullCredentialsFeeNextApplication: this.fullCredentialsFeeNextApplication,
    });

    if (this.disabled) {
      this.applicationResultForm.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled'] && !changes['disabled'].firstChange) {
      const disabled = changes['disabled'].currentValue;
      if (disabled) {
        this.applicationResultForm.disable();
      } else {
        this.applicationResultForm.enable();
      }
    }
  }
}
