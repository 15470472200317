import { ComponentMasterdataDiplomat, ComponentMasterdataResident, Member, MemberRoleDynamicZone } from 'models/types';

import { enumValueToString } from './utils';

export function getFullnameForMember(
  member: Member | undefined | null,
  showOptions: { title?: boolean; name?: boolean; middlename?: boolean; surname?: boolean } = {}
) {
  return [
    showOptions.title ?? true ? member?.title : '',
    showOptions.name ?? true ? member?.Name : '',
    showOptions.middlename ?? true ? member?.middlename : '',
    showOptions.surname ?? true ? member?.Surname : '',
  ]
    .filter((i) => !!i)
    .join(' ');
}

export function isResident(member: Member | undefined | null) {
  return !!getResidentRole(member);
}

export function isDiplomate(member: Member | undefined | null) {
  return !!getDiplomateRole(member);
}

export function getResidentRole(member: Member | undefined | null) {
  return member?.Role?.find((role) => isResidentRole(role) ?? undefined) as ComponentMasterdataResident | undefined;
}

export function isDiplomateRole(role: MemberRoleDynamicZone | undefined | null) {
  return role?.__typename === 'ComponentMasterdataDiplomat';
}

export function isResidentRole(role: MemberRoleDynamicZone | undefined | null) {
  return role?.__typename === 'ComponentMasterdataResident';
}

export function getDiplomateRole(member: Member | undefined | null) {
  return (member?.Role?.find((role) => isDiplomateRole(role)) ?? undefined) as ComponentMasterdataDiplomat | undefined;
}

export function getResidentRoleIndex(member: Member | undefined | null) {
  return member?.Role?.findIndex((role) => isResidentRole(role));
}

export function getDiplomateRoleIndex(member: Member | undefined | null) {
  return member?.Role?.findIndex((role) => isDiplomateRole(role));
}

export function getSpecialityTextForMember(member: Member | undefined | null) {
  return member?.Speciality?.map((s) => enumValueToString(s?.Name ?? '')).join(', ') ?? '';
}

export function getAudienceForMember(member: Member | undefined | null) {
  return member?.Role?.map((r) => r?.__typename as string).filter((r) => !!r) ?? [];
}
