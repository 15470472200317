<mat-table [dataSource]="payments" multiTemplateDataRows>
  <ng-container *ngIf="columns$ | async as columns">
    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
    <mat-row class="payment-row" *matRowDef="let payments; columns: columns"></mat-row>
    <mat-row class="detail-row" *matRowDef="let row; columns: ['expandedDetail']"></mat-row>

    <ng-container matColumnDef="expand">
      <mat-header-cell *matHeaderCellDef class="mat-body-strong">&nbsp;</mat-header-cell>
      <mat-cell *matCellDef="let payment">
        <button
          mat-icon-button
          aria-label="expand row"
          (click)="expandedPayment = expandedPayment === payment ? null : payment; $event.stopPropagation()">
          <mat-icon *ngIf="expandedPayment !== payment">keyboard_arrow_right</mat-icon>
          <mat-icon *ngIf="expandedPayment === payment">keyboard_arrow_down</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <mat-cell *matCellDef="let payment" [attr.colspan]="columns.length">
        <div class="d-block w-100" [@detailExpand]="payment === expandedPayment ? 'expanded' : 'collapsed'">
          <div class="detail">
            <mat-icon
              *ngIf="!disableStatus && payment.attributes?.paid"
              matTooltip="Payment has been made!"
              matTooltipPosition="above">
              done_all
            </mat-icon>
            <mat-icon
              *ngIf="!disableStatus && !payment.attributes?.paid && !payment.attributes?.canceled"
              matTooltip="Payment has not yet been made!"
              matTooltipPosition="above">
              warning
            </mat-icon>
            <mat-icon
              *ngIf="!disableStatus && !payment.attributes?.paid && payment.attributes?.canceled"
              matTooltip="Payment has been canceled!"
              matTooltipPosition="above">
              cancel
            </mat-icon>
            <div *ngIf="disableStatus"></div>
            <div class="mat-body-strong">
              <b>{{ payment.attributes?.totalAmount | number : "1.2-2" }} {{ payment.attributes?.currency }}</b>
            </div>
          </div>
          <div class="detail" *ngIf="!payment.attributes?.paid && !payment.attributes?.canceled && !disableActions">
            <div></div>
            <a mat-button color="primary" [routerLink]="['/user', 'checkout', payment.id]"> Pay </a>
          </div>
          <div class="detail" *ngIf="payment.attributes?.paid">
            <div></div>
            <div class="mat-body">
              <div>Paid on {{ payment.attributes?.paymentdate }}</div>
              <a target="_blank" [href]="payment.attributes?.invoice?.data?.attributes?.url">{{
                payment.attributes?.invoicenumber
              }}</a>
            </div>
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef class="mat-body-strong">Description</mat-header-cell>
      <mat-cell *matCellDef="let payment">
        <div class="d-block">
          <div>
            {{ prettify(payment.attributes?.type) }}
          </div>
          <div class="mat-small">
            {{ payment.attributes?.subject }}
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="amount">
      <mat-header-cell *matHeaderCellDef class="mat-body-strong">Amount</mat-header-cell>
      <mat-cell *matCellDef="let payment">
        {{ payment.attributes?.totalAmount | number : "1.2-2" }} {{ payment.attributes?.currency }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="duedate">
      <mat-header-cell *matHeaderCellDef class="mat-body-strong">Due date</mat-header-cell>
      <mat-cell *matCellDef="let payment">{{ payment.attributes?.duedate }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef class="mat-body-strong">Status</mat-header-cell>
      <mat-cell *matCellDef="let payment">
        <mat-icon *ngIf="payment.attributes?.paid" matTooltip="Payment has been made!" matTooltipPosition="above">
          done_all
        </mat-icon>
        <mat-icon
          *ngIf="!payment.attributes?.paid && !payment.attributes?.canceled"
          matTooltip="Payment has not yet been made!"
          matTooltipPosition="above">
          warning
        </mat-icon>
        <mat-icon
          *ngIf="!payment.attributes?.paid && payment.attributes?.canceled"
          matTooltip="Payment has been canceled!"
          matTooltipPosition="above">
          cancel
        </mat-icon>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef class="mat-body-strong">Actions</mat-header-cell>
      <mat-cell *matCellDef="let payment">
        <ng-container *ngIf="!payment.attributes?.paid && !payment.attributes?.canceled && !disableActions">
          <a mat-button color="primary" [routerLink]="['/user', 'checkout', payment.id]"> Pay </a>
        </ng-container>
        <ng-container *ngIf="!payment.attributes?.paid && payment.attributes?.canceled"> Canceled </ng-container>
        <div class="mat-body" *ngIf="payment.attributes?.paid">
          <div>Paid on {{ payment.attributes?.paymentdate }}</div>
          <a target="_blank" [href]="payment.attributes?.invoice?.data?.attributes?.url">{{
            payment.attributes?.invoicenumber
          }}</a>
        </div>
      </mat-cell>
    </ng-container>
  </ng-container>
</mat-table>
<div class="no-payments-msg" *ngIf="payments.length === 0">You currently have no open or payed payments!</div>
