<h2>Credentials Online Submission Form</h2>

<p>
  At the time of submission, the Credentials must be completed although additional time may be given to complete
  publications and scientific presentations, and planned rotations/externships.
</p>
<p>Please find the forms and templates for the documents in the Residents member portal Credentials Section.</p>
<p>
  Deadline for Credentials submission is
  <b>15 June {{ currentExamYear - 1 }} by 12:00 (noon) Central European Time</b>. 
</p>

<form [formGroup]="credentialsSubmissionForm">
  <div class="resubmission-text" *ngIf="isResubmission">
    <div>Resubmission should include:</div>
    <ol type="a">
      <li>
        Addendum that reflects the training and experiences that have been directed towards correcting the deficiencies
        noted by the report from the Executive Secretary (form “Addendum for resubmission of Credentials” available in
        Residents member area).
      </li>

      <li>Resubmission of the items that were found to be deficient in the previous application suitably revised.</li>
      <li>
        All pertinent correspondence should be provided. This should include a list of all dates of previous Credentials
        submissions and the most recent Credentials result letter.
      </li>
      <li>
        A scanned copy of an updated letter with an original signature from the Resident’s Programme Director (if the
        Resident has not completed their residency programme), or any ECVIMCA Diplomate who knows them (if the Resident
        has completed their residency programme). Please find the template in the Residents member portal Credentials
        section.
      </li>
    </ol>
  </div>

  <div class="form-entry" *ngIf="getCredential('geResult'); let credential">
    <label>Proof of passing the General Examination<span *ngIf="credential.required"> *</span></label>
    <p>
      If the candidate has previously passed the General Examination, he/she must supply proof of passing the
      examination (General Examination result letter) as well as the information listed below. If he/she wishes to take
      both examinations at the same time he/she will be required to submit an online application for General Examination
      as well as for the Certifying Examination. Please upload a note that you are intending to sit both examinations in
      the same year.
    </p>
    <mat-checkbox
      *ngIf="isSubmitLaterEnabled(credential.name)"
      [formControlName]="getSubmitLaterFieldName(credential.name)"
      color="primary">
      <mat-label>This document will be submitted later</mat-label>
    </mat-checkbox>
    <div class="form-inputs" *ngIf="!isSubmitLaterSelected(credential.name)">
      <app-file-upload
        [formControlName]="credential.name"
        allowedTypes="application/pdf"
        [maxNumberFiles]="1"
        [ref]="certifyingExamRef"
        [refId]="certifyingExamId"
        [field]="credential.name"
        [path]="certifyingExamDirectoryPath"
        [baseFileName]="getBaseFileName(credential.name)"></app-file-upload>
    </div>
  </div>

  <div class="form-entry" *ngIf="getCredential('verificationOfTraining'); let credential">
    <label>Verification of training in externships<span *ngIf="credential.required"> *</span> </label>
    <p>
      Please refer to the externship requirements laid out in your specialty Information Brochure and in the Residents
      member portal Credentials section.
    </p>
    <mat-checkbox
      *ngIf="isSubmitLaterEnabled(credential.name)"
      [formControlName]="getSubmitLaterFieldName(credential.name)"
      color="primary">
      <mat-label>This document will be submitted later</mat-label>
    </mat-checkbox>
    <div class="form-inputs" *ngIf="!isSubmitLaterSelected(credential.name)">
      <app-file-upload
        [formControlName]="credential.name"
        allowedTypes="application/pdf"
        [ref]="certifyingExamRef"
        [refId]="certifyingExamId"
        [field]="credential.name"
        [path]="certifyingExamDirectoryPath"
        [baseFileName]="getBaseFileName(credential.name)"></app-file-upload>
    </div>
  </div>

  <div class="form-entry" *ngIf="getCredential('presentationLog'); let credential">
    <label>Presentation log<span *ngIf="credential.required"> *</span></label>
    <p>
      This document must be verified and signed by your Programme Director. Candidates may be given additional time (up
      to January 15th {{ currentExamYear }}) in which to fulfil the requirements for presentations.
    </p>
    <mat-checkbox
      *ngIf="isSubmitLaterEnabled(credential.name)"
      [formControlName]="getSubmitLaterFieldName(credential.name)"
      color="primary">
      <mat-label>This document will be submitted later</mat-label>
    </mat-checkbox>
    <div class="form-inputs" *ngIf="!isSubmitLaterSelected(credential.name)">
      <app-file-upload
        [formControlName]="credential.name"
        allowedTypes="application/pdf"
        [maxNumberFiles]="1"
        [ref]="certifyingExamRef"
        [refId]="certifyingExamId"
        [field]="credential.name"
        [path]="certifyingExamDirectoryPath"
        [baseFileName]="getBaseFileName(credential.name)"></app-file-upload>
    </div>
  </div>

  <div class="form-entry" *ngIf="getCredential('caseSummary'); let credential">
    <label>Case Summary<span *ngIf="credential.required"> *</span></label>
    <p>
      Candidates must submit their second case summary at the time of submitting their Credentials. The first case
      summary must have been submitted with the application for the General Examination.
    </p>
    <mat-checkbox
      *ngIf="isSubmitLaterEnabled(credential.name)"
      [formControlName]="getSubmitLaterFieldName(credential.name)"
      color="primary">
      <mat-label>This document will be submitted later</mat-label>
    </mat-checkbox>
    <div class="form-inputs" *ngIf="!isSubmitLaterSelected(credential.name)">
      <app-file-upload
        [formControlName]="credential.name"
        allowedTypes="application/pdf"
        [maxNumberFiles]="1"
        [ref]="certifyingExamRef"
        [refId]="certifyingExamId"
        [field]="credential.name"
        [path]="certifyingExamDirectoryPath"
        [baseFileName]="getBaseFileName(credential.name)"></app-file-upload>
    </div>
  </div>

  <div class="form-entry" *ngIf="getCredential('caseLogSummary'); let credential">
    <label>Signed case log summary<span *ngIf="credential.required"> *</span></label>
    <p>This document must be verified and signed by your Programme Director.</p>
    <mat-checkbox
      *ngIf="isSubmitLaterEnabled(credential.name)"
      [formControlName]="getSubmitLaterFieldName(credential.name)"
      color="primary">
      <mat-label>This document will be submitted later</mat-label>
    </mat-checkbox>
    <div class="form-inputs" *ngIf="!isSubmitLaterSelected(credential.name)">
      <app-file-upload
        [formControlName]="credential.name"
        allowedTypes="application/pdf"
        [maxNumberFiles]="1"
        [ref]="certifyingExamRef"
        [refId]="certifyingExamId"
        [field]="credential.name"
        [path]="certifyingExamDirectoryPath"
        [baseFileName]="getBaseFileName(credential.name)"></app-file-upload>
    </div>
  </div>

  <div class="form-entry" *ngIf="getCredential('titlePreviousCaseSummary'); let credential">
    <mat-form-field appearance="fill">
      <mat-label>Title of previous Case Summaries</mat-label>
      <input matInput [formControlName]="credential.name" type="text" />
      <mat-error *ngIf="!getFormByPath(credential.name)?.valid">{{ genericErrorMessage }}</mat-error>
    </mat-form-field>
  </div>

  <div class="form-entry" *ngIf="getCredential('caseLog'); let credential">
    <label>Case Log<span *ngIf="credential.required"> *</span></label>
    <p>
      Please submit one Excel file. Please refer to the Information Brochure of your Specialty and in the Residents
      member portal Credentials section for details.
    </p>
    <mat-checkbox
      *ngIf="isSubmitLaterEnabled(credential.name)"
      [formControlName]="getSubmitLaterFieldName(credential.name)"
      color="primary">
      <mat-label>This document will be submitted later</mat-label>
    </mat-checkbox>
    <div class="form-inputs" *ngIf="!isSubmitLaterSelected(credential.name)">
      <app-file-upload
        [formControlName]="credential.name"
        allowedTypes="application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,"
        [maxNumberFiles]="1"
        [ref]="certifyingExamRef"
        [refId]="certifyingExamId"
        [field]="credential.name"
        [path]="certifyingExamDirectoryPath"
        [baseFileName]="getBaseFileName(credential.name)"></app-file-upload>
    </div>
  </div>

  <div class="form-entry" *ngIf="getCredential('multipleChoiceQuestionnaire'); let credential">
    <label>Multiple Choice Questions<span *ngIf="credential.required"> *</span></label>
    <p>
      Four multiple choice (single best answer) questions should be provided with the application. These questions MUST
      be based on information contained within the reading list. You can find guidance for writing MCQs in the Residents
      member portal Credentials section.
    </p>
    <mat-checkbox
      *ngIf="isSubmitLaterEnabled(credential.name)"
      [formControlName]="getSubmitLaterFieldName(credential.name)"
      color="primary">
      <mat-label>This document will be submitted later</mat-label>
    </mat-checkbox>
    <div class="form-inputs" *ngIf="!isSubmitLaterSelected(credential.name)">
      <app-file-upload
        [formControlName]="credential.name"
        allowedTypes="application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,"
        [maxNumberFiles]="1"
        [ref]="certifyingExamRef"
        [refId]="certifyingExamId"
        [field]="credential.name"
        [path]="certifyingExamDirectoryPath"
        [baseFileName]="getBaseFileName(credential.name)"></app-file-upload>
    </div>
  </div>

  <div class="form-entry" *ngIf="getCredential('programmeDirectorLetter'); let credential">
    <label>Programme Director Letter<span *ngIf="credential.required"> *</span></label>
    <p>
      A scanned copy of the original signed letters from the Programme Director and any supervisor(s) of other
      institutions (e.g. those providing externship training) involved in the training programme. Requests by the
      candidate for these reference letters from supervisors should be made early. A model reference letter is provided
      in the Residents member portal Credentials section.
    </p>
    <mat-checkbox
      *ngIf="isSubmitLaterEnabled(credential.name)"
      [formControlName]="getSubmitLaterFieldName(credential.name)"
      color="primary">
      <mat-label>This document will be submitted later</mat-label>
    </mat-checkbox>
    <div class="form-inputs" *ngIf="!isSubmitLaterSelected(credential.name)">
      <app-file-upload
        [formControlName]="credential.name"
        allowedTypes="application/pdf"
        [ref]="certifyingExamRef"
        [refId]="certifyingExamId"
        [maxNumberFiles]="1"
        [field]="credential.name"
        [path]="certifyingExamDirectoryPath"
        [baseFileName]="getBaseFileName(credential.name)"></app-file-upload>
    </div>
  </div>

  <div class="form-entry" *ngIf="getCredential('procedureLog'); let credential">
    <label>Procedure Log<span *ngIf="credential.required"> *</span></label>
    <p>Please submit the Procedure log signed by your Programme Director.</p>
    <mat-checkbox
      *ngIf="isSubmitLaterEnabled(credential.name)"
      [formControlName]="getSubmitLaterFieldName(credential.name)"
      color="primary">
      <mat-label>This document will be submitted later</mat-label>
    </mat-checkbox>
    <div class="form-inputs" *ngIf="!isSubmitLaterSelected(credential.name)">
      <app-file-upload
        [formControlName]="credential.name"
        allowedTypes="application/pdf"
        [ref]="certifyingExamRef"
        [refId]="certifyingExamId"
        [field]="credential.name"
        [maxNumberFiles]="1"
        [path]="certifyingExamDirectoryPath"
        [baseFileName]="getBaseFileName(credential.name)"></app-file-upload>
    </div>
  </div>

  <div class="form-entry" *ngIf="getCredential('previousCorrespondence'); let credential">
    <label>Previous Correspondence<span *ngIf="credential.required"> *</span></label>
    <p>
      Any previous correspondence pertinent to the individual Resident’s training programme and application (e.g. breaks
      in the programme for ill health or personal reasons, or major changes to the programme since enrolment) should be
      included.
    </p>
    <p>
      Copies of the case summary evaluation letters of the Credential Committee should be submitted as proof of the
      written case summaries.
    </p>
    <mat-checkbox
      *ngIf="isSubmitLaterEnabled(credential.name)"
      [formControlName]="getSubmitLaterFieldName(credential.name)"
      color="primary">
      <mat-label>This document will be submitted later</mat-label>
    </mat-checkbox>
    <div class="form-inputs" *ngIf="!isSubmitLaterSelected(credential.name)">
      <app-file-upload
        [formControlName]="credential.name"
        allowedTypes="application/pdf"
        [ref]="certifyingExamRef"
        [refId]="certifyingExamId"
        [field]="credential.name"
        [path]="certifyingExamDirectoryPath"
        [baseFileName]="getBaseFileName(credential.name)"></app-file-upload>
    </div>
  </div>

  <div class="form-entry" *ngIf="getCredential('credentialsSubmissionSummaryForm'); let credential">
    <label>Credentials Submission Summary Form <span *ngIf="credential.required"> *</span></label>
    <mat-checkbox
      *ngIf="isSubmitLaterEnabled(credential.name)"
      [formControlName]="getSubmitLaterFieldName(credential.name)"
      color="primary">
      <mat-label>This document will be submitted later</mat-label>
    </mat-checkbox>
    <div class="form-inputs" *ngIf="!isSubmitLaterSelected(credential.name)">
      <app-file-upload
        [formControlName]="credential.name"
        allowedTypes="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        [ref]="certifyingExamRef"
        [refId]="certifyingExamId"
        [field]="credential.name"
        [maxNumberFiles]="1"
        [path]="certifyingExamDirectoryPath"
        [baseFileName]="getBaseFileName(credential.name)"></app-file-upload>
    </div>
  </div>

  <div class="form-entry" *ngIf="getCredential('publications'); let credential">
    <label>Publications<span *ngIf="credential.required"> *</span></label>
    <p>
      The types of publications that are acceptable are detailed in Chapter 5.5 of the Information Brochure. Candidates
      may be given additional time (please refer to the current Credentials regulations) in which to fulfil the
      requirements for publications. You can either upload your publications as a file or include the link to your
      publications here below.
    </p>
    <mat-checkbox
      *ngIf="isSubmitLaterEnabled(credential.name)"
      [formControlName]="getSubmitLaterFieldName(credential.name)"
      color="primary">
      <mat-label>This document will be submitted later</mat-label>
    </mat-checkbox>
    <div class="form-inputs" *ngIf="!isSubmitLaterSelected(credential.name)">
      <app-file-upload
        [formControlName]="credential.name"
        allowedTypes="application/pdf"
        [ref]="certifyingExamRef"
        [refId]="certifyingExamId"
        [field]="credential.name"
        [path]="certifyingExamDirectoryPath"
        [baseFileName]="getBaseFileName(credential.name)"></app-file-upload>
      <mat-form-field *ngIf="credentials.publicationLinks" appearance="fill">
        <mat-label>Publication Links</mat-label>
        <input matInput formControlName="publicationLinks" type="text" />
      </mat-form-field>
    </div>
  </div>
</form>
