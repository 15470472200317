<mat-list>
  <ng-container *ngIf="categories.length === 0">
    <div class="no-files">No files available!</div>
  </ng-container>

  <ng-container *ngFor="let category of categories">
    <div mat-subheader *ngIf="category.name.trim()">{{ category.name }}</div>
    <ng-container *ngFor="let document of category.documents">
      <a target="_blank" [href]="document.url">
        <mat-list-item>
          <mat-icon mat-list-icon>description</mat-icon>
          <span mat-line>{{ document.title }}</span>
          <span mat-line *ngIf="document.subtitle?.trim()">{{ document.subtitle }}</span>
        </mat-list-item>
      </a>
    </ng-container>
  </ng-container>
</mat-list>
