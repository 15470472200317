import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { NewsitemEntityResponseCollection } from 'models/types';

export interface NEWSITEMS_RESPONSE_TYPE {
  newsitems: NewsitemEntityResponseCollection;
}

const NEWSITEMS = gql`
  query {
    newsitems {
      data {
        id
        attributes {
          Content
          Title
          publishedAt
          Picture {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  constructor(private apollo: Apollo) {}

  getNewsItems() {
    return this.apollo.query<NEWSITEMS_RESPONSE_TYPE>({
      query: NEWSITEMS,
    });
  }
}
