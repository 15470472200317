import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { DiplomateReevaluationEntity, MemberInput, Page } from 'models/types';
import { Subject, takeUntil, tap } from 'rxjs';
import { DiplomateReevaluationApplicationFormComponent } from 'src/app/components/diplomate-reevaluation/diplomate-reevaluation-application-form/diplomate-reevaluation-application-form.component';
import { DiplomateReevaluationDataVerificationFormComponent } from 'src/app/components/diplomate-reevaluation/diplomate-reevaluation-data-verification-form/diplomate-reevaluation-data-verification-form.component';
import { MemberFormComponent } from 'src/app/components/member-form/member-form.component';
import { DiplomateReevaluationService } from 'src/app/services/diplomate-reevaluation.service';
import { MemberService } from 'src/app/services/member.service';
import { ProfileService } from 'src/app/services/profile.service';
import {
  isDiplomateReevaluationEntityActive,
  isDiplomateReevaluationSubmitted,
} from 'src/app/utils/diplomate-reevaluation';
import { getFullnameForMember, getSpecialityTextForMember } from 'src/app/utils/member';
import { getDateString } from 'src/app/utils/utils';

interface TableDataItem {
  title: string;
  value: string | number;
}

@Component({
  selector: 'app-diplomate-reevaluation-submission',
  templateUrl: './diplomate-reevaluation-submission.component.html',
  styleUrls: ['./diplomate-reevaluation-submission.component.scss'],
})
export class DiplomateReevaluationSubmissionComponent implements OnInit, OnDestroy {
  @ViewChild(MemberFormComponent)
  memberForm!: MemberFormComponent;

  @ViewChild(DiplomateReevaluationDataVerificationFormComponent)
  diplomateReevaluationDataVerficationForm: DiplomateReevaluationDataVerificationFormComponent | undefined = undefined;

  @ViewChild(DiplomateReevaluationApplicationFormComponent)
  diplomateReevaluationApplicationForm: DiplomateReevaluationApplicationFormComponent | undefined = undefined;

  cmsPage: Page | undefined = undefined;

  // member form is disabled by default
  memberFormDisabled = true;

  loading = false;

  diplomateDetailsTableDisplayedColumns: string[] = ['title', 'value'];
  diplomateDetailsTableData: TableDataItem[] = [];

  diplomateReevaluations: DiplomateReevaluationEntity[] = [];

  get activeDiplomateReevaluationEntity() {
    return this.diplomateReevaluations.find((dre) => isDiplomateReevaluationEntityActive(dre));
  }

  set activeDiplomateReevaluationEntity(activeDiplomateReevaluationEntity: DiplomateReevaluationEntity | undefined) {
    if (activeDiplomateReevaluationEntity) {
      this.diplomateReevaluations = this.diplomateReevaluations.filter(
        (dre) => dre !== this.activeDiplomateReevaluationEntity
      );
      this.diplomateReevaluations.push(activeDiplomateReevaluationEntity);
    }
  }

  get member() {
    return this.memberService.currentMember ?? undefined;
  }

  get memberEntity() {
    return this.memberService.currentMemberEntity ?? undefined;
  }

  get activeDiplomateReevaluationId() {
    return this.activeDiplomateReevaluationEntity?.id ?? undefined;
  }

  get isApplicationSubmitted() {
    return (
      !!this.activeDiplomateReevaluationEntity &&
      isDiplomateReevaluationSubmitted(this.activeDiplomateReevaluationEntity)
    );
  }

  get isApplicationLocked() {
    return this.isApplicationSubmitted;
  }

  private destroyed$ = new Subject<void>();

  constructor(
    private memberService: MemberService,
    private profileService: ProfileService,
    private snackbar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private diplomateReevaluationService: DiplomateReevaluationService
  ) {
    this.activatedRoute.data
      .pipe(
        takeUntil(this.destroyed$),
        tap((data) => {
          this.diplomateReevaluations = data['diplomateReevaluations'] as DiplomateReevaluationEntity[];
          this.cmsPage = data['page'] as Page | undefined;
        })
      )
      .subscribe();
  }

  ngOnInit(): void {
    const role = this.memberService.diplomatRole;
    if (this.member && role) {
      this.diplomateDetailsTableData = [
        { title: 'Name', value: getFullnameForMember(this.member) },
        { title: 'Speciality', value: getSpecialityTextForMember(this.member) },
      ];
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$.unsubscribe();
  }

  enableMemberFormEditMode(): void {
    this.memberFormDisabled = false;
    this.memberForm?.memberForm.markAllAsTouched();
  }

  saveMemberForm(): void {
    if (!this.memberForm.memberFormInvalid) {
      this.profileService
        .updateMember(this.memberService.currentMemberId!, this.memberForm?.memberFormValues as MemberInput)
        .subscribe({
          next: (result) => {
            this.loading = result.loading;
            if (result.data) {
              this.memberService.setMember(result.data.updateMember.data!);
              this.snackbar.open('Your profile was updated successfully!', undefined, { duration: 4000 });
            }
          },
        });
    }
  }

  submitDiplomateReevaluationDataVerification() {
    if (
      !this.activeDiplomateReevaluationEntity &&
      this.diplomateReevaluationDataVerficationForm?.isValid &&
      this.memberEntity
    ) {
      const diplomateReevaluationInput = this.diplomateReevaluationDataVerficationForm.values;

      this.diplomateReevaluationService
        .createDiplomateReevaluationForMember(this.memberEntity.id!, diplomateReevaluationInput)
        .subscribe({
          next: (result) => {
            this.loading = result.loading;

            if (result.data) {
              const diplomateReevaluationEntity = result.data.createDiplomateReevaluation.data ?? undefined;
              this.activeDiplomateReevaluationEntity = diplomateReevaluationEntity;
            }
          },
        });
    }
  }

  saveDiplomateReevaluationApplicationForm(lock = false): void {
    if (this.activeDiplomateReevaluationEntity) {
      const diplomateReevaluationInput = this.diplomateReevaluationApplicationForm!.values;

      if (lock) {
        diplomateReevaluationInput.reevaluationSubmissionDate = getDateString(new Date());
      }

      this.diplomateReevaluationService
        .updateDiplomateReevaluation(this.activeDiplomateReevaluationId!, diplomateReevaluationInput)
        .subscribe({
          next: (result) => {
            this.loading = result.loading;

            if (result.data) {
              this.activeDiplomateReevaluationEntity = result.data.updateDiplomateReevaluation.data ?? undefined;
              this.snackbar.open(
                `Your diplomate reevaluation application was successfully ${lock ? 'submitted' : 'updated'}!`,
                undefined,
                {
                  duration: 4000,
                }
              );
            }
          },
        });
    }
  }
}
