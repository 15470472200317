<app-grid class="p-2">
  <mat-card>
    <mat-card-title>Diplomate Re-evaluation Review Overview</mat-card-title>
    <mat-card-content>
      <p>Dear Reviewer,</p>
      <p>
        please find below the list of submissions for your review. Kindly complete your review by January 5th. Click on
        the name to assess each submission individually. Two reviewers will independently review each submission, and
        the work status in the overview will reflect your progress. Once you have evaluated all items, your work status
        will turn to <app-work-status-icon [workStatus]="workStatusEnum.Finished"></app-work-status-icon> and the
        Committee chair will then determine the final application status based on your results.
      </p>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <div id="table-container">
      <table mat-table [dataSource]="diplomateReevaluationsForReviewTableData">
        <ng-container matColumnDef="reviewType">
          <th mat-header-cell *matHeaderCellDef>Review Type</th>
          <td mat-cell *matCellDef="let element">{{ element.reviewType }}</td>
        </ng-container>

        <ng-container matColumnDef="diplomate">
          <th mat-header-cell *matHeaderCellDef>Diplomate</th>
          <td mat-cell *matCellDef="let element">
            <a [routerLink]="element.routerLink" [state]="element.state">{{ element.diplomate }}</a>
          </td>
        </ng-container>

        <ng-container matColumnDef="reviewers">
          <th mat-header-cell *matHeaderCellDef>Responsible Reviewers</th>
          <td mat-cell *matCellDef="let element">
            <div>{{ element.reviewer1 }}</div>
            <div>{{ element.reviewer2 }}</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="reviewStatus">
          <th mat-header-cell *matHeaderCellDef>Work Status</th>
          <td mat-cell *matCellDef="let element">
            <app-work-status-icon [workStatus]="element.reviewStatus"></app-work-status-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="result">
          <th mat-header-cell *matHeaderCellDef>Reevaluation Result</th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.result">{{ element.result }}</div>
            <div *ngIf="element.result === null">Undecided</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="resultLetter">
          <th mat-header-cell *matHeaderCellDef>Letters</th>
          <td mat-cell *matCellDef="let element">
            <div *ngFor="let letter of element.resultLetters">
              <a [href]="letter.link" target="_blank">{{ letter.name }}</a>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="diplomateReevaluationsForReviewTableDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: diplomateReevaluationsForReviewTableDisplayedColumns"></tr>
      </table>
    </div>
    <div class="empty-table-msg" *ngIf="diplomateReevaluationsForReviewTableData.length === 0">
      No submisssions to review!
    </div>
  </mat-card>
</app-grid>
