export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
  MemberRoleDynamicZoneInput: any;
  /** A string used to identify an i18n locale */
  I18NLocaleCode: any;
};

export type Error = {
  __typename?: 'Error';
  code: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};

export type Pagination = {
  __typename?: 'Pagination';
  total: Scalars['Int'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  pageCount: Scalars['Int'];
};

export type ResponseCollectionMeta = {
  __typename?: 'ResponseCollectionMeta';
  pagination: Pagination;
};

export enum PublicationState {
  Live = 'LIVE',
  Preview = 'PREVIEW',
}

export type IdFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  or?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  not?: InputMaybe<IdFilterInput>;
  eq?: InputMaybe<Scalars['ID']>;
  eqi?: InputMaybe<Scalars['ID']>;
  ne?: InputMaybe<Scalars['ID']>;
  nei?: InputMaybe<Scalars['ID']>;
  startsWith?: InputMaybe<Scalars['ID']>;
  endsWith?: InputMaybe<Scalars['ID']>;
  contains?: InputMaybe<Scalars['ID']>;
  notContains?: InputMaybe<Scalars['ID']>;
  containsi?: InputMaybe<Scalars['ID']>;
  notContainsi?: InputMaybe<Scalars['ID']>;
  gt?: InputMaybe<Scalars['ID']>;
  gte?: InputMaybe<Scalars['ID']>;
  lt?: InputMaybe<Scalars['ID']>;
  lte?: InputMaybe<Scalars['ID']>;
  null?: InputMaybe<Scalars['Boolean']>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type BooleanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  not?: InputMaybe<BooleanFilterInput>;
  eq?: InputMaybe<Scalars['Boolean']>;
  eqi?: InputMaybe<Scalars['Boolean']>;
  ne?: InputMaybe<Scalars['Boolean']>;
  nei?: InputMaybe<Scalars['Boolean']>;
  startsWith?: InputMaybe<Scalars['Boolean']>;
  endsWith?: InputMaybe<Scalars['Boolean']>;
  contains?: InputMaybe<Scalars['Boolean']>;
  notContains?: InputMaybe<Scalars['Boolean']>;
  containsi?: InputMaybe<Scalars['Boolean']>;
  notContainsi?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<Scalars['Boolean']>;
  gte?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Boolean']>;
  lte?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
};

export type StringFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  or?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  not?: InputMaybe<StringFilterInput>;
  eq?: InputMaybe<Scalars['String']>;
  eqi?: InputMaybe<Scalars['String']>;
  ne?: InputMaybe<Scalars['String']>;
  nei?: InputMaybe<Scalars['String']>;
  startsWith?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  contains?: InputMaybe<Scalars['String']>;
  notContains?: InputMaybe<Scalars['String']>;
  containsi?: InputMaybe<Scalars['String']>;
  notContainsi?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  null?: InputMaybe<Scalars['Boolean']>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type IntFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  not?: InputMaybe<IntFilterInput>;
  eq?: InputMaybe<Scalars['Int']>;
  eqi?: InputMaybe<Scalars['Int']>;
  ne?: InputMaybe<Scalars['Int']>;
  nei?: InputMaybe<Scalars['Int']>;
  startsWith?: InputMaybe<Scalars['Int']>;
  endsWith?: InputMaybe<Scalars['Int']>;
  contains?: InputMaybe<Scalars['Int']>;
  notContains?: InputMaybe<Scalars['Int']>;
  containsi?: InputMaybe<Scalars['Int']>;
  notContainsi?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  null?: InputMaybe<Scalars['Boolean']>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type FloatFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  not?: InputMaybe<FloatFilterInput>;
  eq?: InputMaybe<Scalars['Float']>;
  eqi?: InputMaybe<Scalars['Float']>;
  ne?: InputMaybe<Scalars['Float']>;
  nei?: InputMaybe<Scalars['Float']>;
  startsWith?: InputMaybe<Scalars['Float']>;
  endsWith?: InputMaybe<Scalars['Float']>;
  contains?: InputMaybe<Scalars['Float']>;
  notContains?: InputMaybe<Scalars['Float']>;
  containsi?: InputMaybe<Scalars['Float']>;
  notContainsi?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  null?: InputMaybe<Scalars['Boolean']>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
};

export type DateFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  not?: InputMaybe<DateFilterInput>;
  eq?: InputMaybe<Scalars['Date']>;
  eqi?: InputMaybe<Scalars['Date']>;
  ne?: InputMaybe<Scalars['Date']>;
  nei?: InputMaybe<Scalars['Date']>;
  startsWith?: InputMaybe<Scalars['Date']>;
  endsWith?: InputMaybe<Scalars['Date']>;
  contains?: InputMaybe<Scalars['Date']>;
  notContains?: InputMaybe<Scalars['Date']>;
  containsi?: InputMaybe<Scalars['Date']>;
  notContainsi?: InputMaybe<Scalars['Date']>;
  gt?: InputMaybe<Scalars['Date']>;
  gte?: InputMaybe<Scalars['Date']>;
  lt?: InputMaybe<Scalars['Date']>;
  lte?: InputMaybe<Scalars['Date']>;
  null?: InputMaybe<Scalars['Boolean']>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
};

export type DateTimeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  or?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  not?: InputMaybe<DateTimeFilterInput>;
  eq?: InputMaybe<Scalars['DateTime']>;
  eqi?: InputMaybe<Scalars['DateTime']>;
  ne?: InputMaybe<Scalars['DateTime']>;
  nei?: InputMaybe<Scalars['DateTime']>;
  startsWith?: InputMaybe<Scalars['DateTime']>;
  endsWith?: InputMaybe<Scalars['DateTime']>;
  contains?: InputMaybe<Scalars['DateTime']>;
  notContains?: InputMaybe<Scalars['DateTime']>;
  containsi?: InputMaybe<Scalars['DateTime']>;
  notContainsi?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  null?: InputMaybe<Scalars['Boolean']>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

export type JsonFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  or?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  not?: InputMaybe<JsonFilterInput>;
  eq?: InputMaybe<Scalars['JSON']>;
  eqi?: InputMaybe<Scalars['JSON']>;
  ne?: InputMaybe<Scalars['JSON']>;
  nei?: InputMaybe<Scalars['JSON']>;
  startsWith?: InputMaybe<Scalars['JSON']>;
  endsWith?: InputMaybe<Scalars['JSON']>;
  contains?: InputMaybe<Scalars['JSON']>;
  notContains?: InputMaybe<Scalars['JSON']>;
  containsi?: InputMaybe<Scalars['JSON']>;
  notContainsi?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  null?: InputMaybe<Scalars['Boolean']>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
};

export type ComponentMasterdataAddressFiltersInput = {
  Street?: InputMaybe<StringFilterInput>;
  Number?: InputMaybe<StringFilterInput>;
  Postcode?: InputMaybe<StringFilterInput>;
  City?: InputMaybe<StringFilterInput>;
  Country?: InputMaybe<StringFilterInput>;
  AdditionalInfo?: InputMaybe<StringFilterInput>;
  province?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentMasterdataAddressFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<ComponentMasterdataAddressFiltersInput>>>;
  not?: InputMaybe<ComponentMasterdataAddressFiltersInput>;
};

export type ComponentMasterdataAddressInput = {
  id?: InputMaybe<Scalars['ID']>;
  Street?: InputMaybe<Scalars['String']>;
  Number?: InputMaybe<Scalars['String']>;
  Postcode?: InputMaybe<Scalars['String']>;
  City?: InputMaybe<Scalars['String']>;
  Country?: InputMaybe<Scalars['String']>;
  AdditionalInfo?: InputMaybe<Scalars['String']>;
  province?: InputMaybe<Scalars['String']>;
};

export type ComponentMasterdataAddress = {
  __typename?: 'ComponentMasterdataAddress';
  id: Scalars['ID'];
  Street?: Maybe<Scalars['String']>;
  Number?: Maybe<Scalars['String']>;
  Postcode?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  AdditionalInfo?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
};

export type ComponentMasterdataCaseFiltersInput = {
  SubmissionDate?: InputMaybe<DateFilterInput>;
  Feedback?: InputMaybe<BooleanFilterInput>;
  Title?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentMasterdataCaseFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<ComponentMasterdataCaseFiltersInput>>>;
  not?: InputMaybe<ComponentMasterdataCaseFiltersInput>;
};

export type ComponentMasterdataCase = {
  __typename?: 'ComponentMasterdataCase';
  id: Scalars['ID'];
  SubmissionDate?: Maybe<Scalars['Date']>;
  Feedback?: Maybe<Scalars['Boolean']>;
  Submission?: Maybe<UploadFileEntityResponse>;
  FeedbackDocument?: Maybe<UploadFileRelationResponseCollection>;
  Title?: Maybe<Scalars['String']>;
};

export type ComponentMasterdataCaseFeedbackDocumentArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentMasterdataCertifyingExamInfoFiltersInput = {
  examYear?: InputMaybe<IntFilterInput>;
  examDatesInternalMedicine?: InputMaybe<ComponentMasterdataDateFiltersInput>;
  examDatesOncology?: InputMaybe<ComponentMasterdataDateFiltersInput>;
  examDatesCardiology?: InputMaybe<ComponentMasterdataDateFiltersInput>;
  examLocation?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentMasterdataCertifyingExamInfoFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<ComponentMasterdataCertifyingExamInfoFiltersInput>>>;
  not?: InputMaybe<ComponentMasterdataCertifyingExamInfoFiltersInput>;
};

export type ComponentMasterdataCertifyingExamInfoInput = {
  id?: InputMaybe<Scalars['ID']>;
  examYear?: InputMaybe<Scalars['Int']>;
  examDatesInternalMedicine?: InputMaybe<Array<InputMaybe<ComponentMasterdataDateInput>>>;
  examDatesOncology?: InputMaybe<Array<InputMaybe<ComponentMasterdataDateInput>>>;
  examDatesCardiology?: InputMaybe<Array<InputMaybe<ComponentMasterdataDateInput>>>;
  examLocation?: InputMaybe<Scalars['String']>;
};

export type ComponentMasterdataCertifyingExamInfo = {
  __typename?: 'ComponentMasterdataCertifyingExamInfo';
  id: Scalars['ID'];
  examYear: Scalars['Int'];
  examDatesInternalMedicine: Array<Maybe<ComponentMasterdataDate>>;
  examDatesOncology: Array<Maybe<ComponentMasterdataDate>>;
  examDatesCardiology: Array<Maybe<ComponentMasterdataDate>>;
  examLocation: Scalars['String'];
};

export type ComponentMasterdataCertifyingExamInfoExamDatesInternalMedicineArgs = {
  filters?: InputMaybe<ComponentMasterdataDateFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentMasterdataCertifyingExamInfoExamDatesOncologyArgs = {
  filters?: InputMaybe<ComponentMasterdataDateFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentMasterdataCertifyingExamInfoExamDatesCardiologyArgs = {
  filters?: InputMaybe<ComponentMasterdataDateFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum Enum_Componentmasterdatacredential_Accepted {
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  ConditionallyAccepted = 'Conditionally_Accepted',
}

export type ComponentMasterdataCredentialFiltersInput = {
  FeePayment?: InputMaybe<DateFilterInput>;
  Submission?: InputMaybe<DateFilterInput>;
  Accepted?: InputMaybe<StringFilterInput>;
  Attempt?: InputMaybe<IntFilterInput>;
  accreditationDate?: InputMaybe<DateFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentMasterdataCredentialFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<ComponentMasterdataCredentialFiltersInput>>>;
  not?: InputMaybe<ComponentMasterdataCredentialFiltersInput>;
};

export type ComponentMasterdataCredential = {
  __typename?: 'ComponentMasterdataCredential';
  id: Scalars['ID'];
  FeePayment?: Maybe<Scalars['Date']>;
  Submission?: Maybe<Scalars['Date']>;
  Accepted?: Maybe<Enum_Componentmasterdatacredential_Accepted>;
  Attempt?: Maybe<Scalars['Int']>;
  Document?: Maybe<UploadFileRelationResponseCollection>;
  accreditationDate?: Maybe<Scalars['Date']>;
};

export type ComponentMasterdataCredentialDocumentArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentMasterdataDateFiltersInput = {
  date?: InputMaybe<DateFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentMasterdataDateFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<ComponentMasterdataDateFiltersInput>>>;
  not?: InputMaybe<ComponentMasterdataDateFiltersInput>;
};

export type ComponentMasterdataDateInput = {
  id?: InputMaybe<Scalars['ID']>;
  date?: InputMaybe<Scalars['Date']>;
};

export type ComponentMasterdataDate = {
  __typename?: 'ComponentMasterdataDate';
  id: Scalars['ID'];
  date: Scalars['Date'];
};

export enum Enum_Componentmasterdatadiplomat_Pathtodiplomacy {
  DeFacto = 'De_Facto',
  ByExamination = 'By_Examination',
  AcvimDiplomate = 'ACVIM_Diplomate',
  FoundingDiplomate = 'Founding_Diplomate',
}

export enum Enum_Componentmasterdatadiplomat_Worksector {
  Academia = 'Academia',
  Government = 'Government',
  Industry = 'Industry',
  Practice = 'Practice',
  Other = 'Other',
}

export enum Enum_Componentmasterdatadiplomat_Status {
  Certified = 'Certified',
  NonCertified = 'Non_certified',
  Retired = 'Retired',
  Honorary = 'Honorary',
}

export type ComponentMasterdataDiplomat = {
  __typename?: 'ComponentMasterdataDiplomat';
  id: Scalars['ID'];
  Reaccreditation?: Maybe<Array<Maybe<ComponentMasterdataCredential>>>;
  residency?: Maybe<InstitutionEntityResponse>;
  PathToDiplomacy?: Maybe<Enum_Componentmasterdatadiplomat_Pathtodiplomacy>;
  internship?: Maybe<InstitutionEntityResponse>;
  reevaluationdate?: Maybe<Scalars['Date']>;
  worksector?: Maybe<Enum_Componentmasterdatadiplomat_Worksector>;
  status?: Maybe<Enum_Componentmasterdatadiplomat_Status>;
  Statuschangehistory?: Maybe<Array<Maybe<ComponentMasterdataStatuschange>>>;
  since?: Maybe<Scalars['Date']>;
};

export type ComponentMasterdataDiplomatReaccreditationArgs = {
  filters?: InputMaybe<ComponentMasterdataCredentialFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentMasterdataDiplomatStatuschangehistoryArgs = {
  filters?: InputMaybe<ComponentMasterdataStatuschangeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentMasterdataGeneralExamInfoFiltersInput = {
  examYear?: InputMaybe<IntFilterInput>;
  examDates?: InputMaybe<ComponentMasterdataDateFiltersInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentMasterdataGeneralExamInfoFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<ComponentMasterdataGeneralExamInfoFiltersInput>>>;
  not?: InputMaybe<ComponentMasterdataGeneralExamInfoFiltersInput>;
};

export type ComponentMasterdataGeneralExamInfoInput = {
  id?: InputMaybe<Scalars['ID']>;
  examYear?: InputMaybe<Scalars['Int']>;
  examDates?: InputMaybe<Array<InputMaybe<ComponentMasterdataDateInput>>>;
};

export type ComponentMasterdataGeneralExamInfo = {
  __typename?: 'ComponentMasterdataGeneralExamInfo';
  id: Scalars['ID'];
  examYear: Scalars['Int'];
  examDates: Array<Maybe<ComponentMasterdataDate>>;
};

export type ComponentMasterdataGeneralExamInfoExamDatesArgs = {
  filters?: InputMaybe<ComponentMasterdataDateFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentMasterdataNoteFiltersInput = {
  text?: InputMaybe<StringFilterInput>;
  subject?: InputMaybe<StringFilterInput>;
  date?: InputMaybe<DateFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentMasterdataNoteFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<ComponentMasterdataNoteFiltersInput>>>;
  not?: InputMaybe<ComponentMasterdataNoteFiltersInput>;
};

export type ComponentMasterdataNote = {
  __typename?: 'ComponentMasterdataNote';
  id: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
  subject: Scalars['String'];
  date?: Maybe<Scalars['Date']>;
};

export type ComponentMasterdataPaymentItemsFiltersInput = {
  description?: InputMaybe<StringFilterInput>;
  amount?: InputMaybe<FloatFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentMasterdataPaymentItemsFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<ComponentMasterdataPaymentItemsFiltersInput>>>;
  not?: InputMaybe<ComponentMasterdataPaymentItemsFiltersInput>;
};

export type ComponentMasterdataPaymentItemsInput = {
  id?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['Float']>;
};

export type ComponentMasterdataPaymentItems = {
  __typename?: 'ComponentMasterdataPaymentItems';
  id: Scalars['ID'];
  description: Scalars['String'];
  amount: Scalars['Float'];
};

export enum Enum_Componentmasterdataresident_Status {
  InTraining = 'In_Training',
  PostTraining = 'Post_Training',
  InterruptedTraining = 'Interrupted_Training',
  Quit = 'Quit',
  Lapsed = 'Lapsed',
  FailedFourthAttempt = 'Failed_Fourth_Attempt',
  RanOutOfTime = 'Ran_out_of_time',
}

export type ComponentMasterdataResident = {
  __typename?: 'ComponentMasterdataResident';
  id: Scalars['ID'];
  Since?: Maybe<Scalars['Date']>;
  Status?: Maybe<Enum_Componentmasterdataresident_Status>;
  Adviser?: Maybe<MemberEntityResponse>;
  Cases?: Maybe<Array<Maybe<ComponentMasterdataCase>>>;
  Until?: Maybe<Scalars['Date']>;
  expectedge?: Maybe<Scalars['Int']>;
  expectedce?: Maybe<Scalars['Int']>;
  secondaryadvisor?: Maybe<MemberEntityResponse>;
  notes?: Maybe<Array<Maybe<ComponentMasterdataNote>>>;
};

export type ComponentMasterdataResidentCasesArgs = {
  filters?: InputMaybe<ComponentMasterdataCaseFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentMasterdataResidentNotesArgs = {
  filters?: InputMaybe<ComponentMasterdataNoteFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum Enum_Componentmasterdataspeciality_Name {
  InternalMedicine = 'Internal_Medicine',
  Oncology = 'Oncology',
  Cardiology = 'Cardiology',
  RadiationOncology = 'Radiation_Oncology',
}

export type ComponentMasterdataSpecialityFiltersInput = {
  Name?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentMasterdataSpecialityFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<ComponentMasterdataSpecialityFiltersInput>>>;
  not?: InputMaybe<ComponentMasterdataSpecialityFiltersInput>;
};

export type ComponentMasterdataSpecialityInput = {
  id?: InputMaybe<Scalars['ID']>;
  Name?: InputMaybe<Enum_Componentmasterdataspeciality_Name>;
};

export type ComponentMasterdataSpeciality = {
  __typename?: 'ComponentMasterdataSpeciality';
  id: Scalars['ID'];
  Name?: Maybe<Enum_Componentmasterdataspeciality_Name>;
};

export enum Enum_Componentmasterdatastatuschange_From {
  Certified = 'Certified',
  NonCertified = 'Non_certified',
  Retired = 'Retired',
  Honorary = 'Honorary',
}

export enum Enum_Componentmasterdatastatuschange_To {
  Certified = 'Certified',
  NonCertified = 'Non_certified',
  Retired = 'Retired',
  Honorary = 'Honorary',
}

export type ComponentMasterdataStatuschangeFiltersInput = {
  from?: InputMaybe<StringFilterInput>;
  to?: InputMaybe<StringFilterInput>;
  reason?: InputMaybe<StringFilterInput>;
  changedate?: InputMaybe<DateFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentMasterdataStatuschangeFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<ComponentMasterdataStatuschangeFiltersInput>>>;
  not?: InputMaybe<ComponentMasterdataStatuschangeFiltersInput>;
};

export type ComponentMasterdataStatuschange = {
  __typename?: 'ComponentMasterdataStatuschange';
  id: Scalars['ID'];
  from?: Maybe<Enum_Componentmasterdatastatuschange_From>;
  to?: Maybe<Enum_Componentmasterdatastatuschange_To>;
  reason?: Maybe<Scalars['String']>;
  changedate?: Maybe<Scalars['Date']>;
};

export enum Enum_Componentmasterdataworksector_Name {
  Academia = 'Academia',
  Government = 'Government',
  Industry = 'Industry',
  Practice = 'Practice',
  Other = 'Other',
}

export type ComponentMasterdataWorkSectorFiltersInput = {
  name?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentMasterdataWorkSectorFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<ComponentMasterdataWorkSectorFiltersInput>>>;
  not?: InputMaybe<ComponentMasterdataWorkSectorFiltersInput>;
};

export type ComponentMasterdataWorkSectorInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Enum_Componentmasterdataworksector_Name>;
};

export type ComponentMasterdataWorkSector = {
  __typename?: 'ComponentMasterdataWorkSector';
  id: Scalars['ID'];
  name: Enum_Componentmasterdataworksector_Name;
};

export type ComponentMasterdataYearFiltersInput = {
  year?: InputMaybe<IntFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentMasterdataYearFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<ComponentMasterdataYearFiltersInput>>>;
  not?: InputMaybe<ComponentMasterdataYearFiltersInput>;
};

export type ComponentMasterdataYearInput = {
  id?: InputMaybe<Scalars['ID']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type ComponentMasterdataYear = {
  __typename?: 'ComponentMasterdataYear';
  id: Scalars['ID'];
  year: Scalars['Int'];
};

export type UploadFileFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  alternativeText?: InputMaybe<StringFilterInput>;
  caption?: InputMaybe<StringFilterInput>;
  width?: InputMaybe<IntFilterInput>;
  height?: InputMaybe<IntFilterInput>;
  formats?: InputMaybe<JsonFilterInput>;
  hash?: InputMaybe<StringFilterInput>;
  ext?: InputMaybe<StringFilterInput>;
  mime?: InputMaybe<StringFilterInput>;
  size?: InputMaybe<FloatFilterInput>;
  url?: InputMaybe<StringFilterInput>;
  previewUrl?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  provider_metadata?: InputMaybe<JsonFilterInput>;
  folder?: InputMaybe<UploadFolderFiltersInput>;
  folderPath?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  not?: InputMaybe<UploadFileFiltersInput>;
};

export type UploadFileInput = {
  name?: InputMaybe<Scalars['String']>;
  alternativeText?: InputMaybe<Scalars['String']>;
  caption?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
  height?: InputMaybe<Scalars['Int']>;
  formats?: InputMaybe<Scalars['JSON']>;
  hash?: InputMaybe<Scalars['String']>;
  ext?: InputMaybe<Scalars['String']>;
  mime?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Float']>;
  url?: InputMaybe<Scalars['String']>;
  previewUrl?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  provider_metadata?: InputMaybe<Scalars['JSON']>;
  folder?: InputMaybe<Scalars['ID']>;
  folderPath?: InputMaybe<Scalars['String']>;
};

export type UploadFile = {
  __typename?: 'UploadFile';
  name: Scalars['String'];
  alternativeText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  formats?: Maybe<Scalars['JSON']>;
  hash: Scalars['String'];
  ext?: Maybe<Scalars['String']>;
  mime: Scalars['String'];
  size: Scalars['Float'];
  url: Scalars['String'];
  previewUrl?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  provider_metadata?: Maybe<Scalars['JSON']>;
  related?: Maybe<Array<Maybe<GenericMorph>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UploadFileEntity = {
  __typename?: 'UploadFileEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<UploadFile>;
};

export type UploadFileEntityResponse = {
  __typename?: 'UploadFileEntityResponse';
  data?: Maybe<UploadFileEntity>;
};

export type UploadFileEntityResponseCollection = {
  __typename?: 'UploadFileEntityResponseCollection';
  data: Array<UploadFileEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFileRelationResponseCollection = {
  __typename?: 'UploadFileRelationResponseCollection';
  data: Array<UploadFileEntity>;
};

export type UploadFolderFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  pathId?: InputMaybe<IntFilterInput>;
  parent?: InputMaybe<UploadFolderFiltersInput>;
  children?: InputMaybe<UploadFolderFiltersInput>;
  files?: InputMaybe<UploadFileFiltersInput>;
  path?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  not?: InputMaybe<UploadFolderFiltersInput>;
};

export type UploadFolderInput = {
  name?: InputMaybe<Scalars['String']>;
  pathId?: InputMaybe<Scalars['Int']>;
  parent?: InputMaybe<Scalars['ID']>;
  children?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  files?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  path?: InputMaybe<Scalars['String']>;
};

export type UploadFolder = {
  __typename?: 'UploadFolder';
  name: Scalars['String'];
  pathId: Scalars['Int'];
  parent?: Maybe<UploadFolderEntityResponse>;
  children?: Maybe<UploadFolderRelationResponseCollection>;
  files?: Maybe<UploadFileRelationResponseCollection>;
  path: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UploadFolderChildrenArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UploadFolderFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UploadFolderEntity = {
  __typename?: 'UploadFolderEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<UploadFolder>;
};

export type UploadFolderEntityResponse = {
  __typename?: 'UploadFolderEntityResponse';
  data?: Maybe<UploadFolderEntity>;
};

export type UploadFolderEntityResponseCollection = {
  __typename?: 'UploadFolderEntityResponseCollection';
  data: Array<UploadFolderEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFolderRelationResponseCollection = {
  __typename?: 'UploadFolderRelationResponseCollection';
  data: Array<UploadFolderEntity>;
};

export type EmailDesignerEmailTemplateFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  templateReferenceId?: InputMaybe<IntFilterInput>;
  design?: InputMaybe<JsonFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  subject?: InputMaybe<StringFilterInput>;
  bodyHtml?: InputMaybe<StringFilterInput>;
  bodyText?: InputMaybe<StringFilterInput>;
  enabled?: InputMaybe<BooleanFilterInput>;
  tags?: InputMaybe<JsonFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<EmailDesignerEmailTemplateFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<EmailDesignerEmailTemplateFiltersInput>>>;
  not?: InputMaybe<EmailDesignerEmailTemplateFiltersInput>;
};

export type EmailDesignerEmailTemplateInput = {
  templateReferenceId?: InputMaybe<Scalars['Int']>;
  design?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
  bodyHtml?: InputMaybe<Scalars['String']>;
  bodyText?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  tags?: InputMaybe<Scalars['JSON']>;
};

export type EmailDesignerEmailTemplate = {
  __typename?: 'EmailDesignerEmailTemplate';
  templateReferenceId?: Maybe<Scalars['Int']>;
  design?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  bodyHtml?: Maybe<Scalars['String']>;
  bodyText?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailDesignerEmailTemplateEntity = {
  __typename?: 'EmailDesignerEmailTemplateEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<EmailDesignerEmailTemplate>;
};

export type EmailDesignerEmailTemplateEntityResponse = {
  __typename?: 'EmailDesignerEmailTemplateEntityResponse';
  data?: Maybe<EmailDesignerEmailTemplateEntity>;
};

export type EmailDesignerEmailTemplateEntityResponseCollection = {
  __typename?: 'EmailDesignerEmailTemplateEntityResponseCollection';
  data: Array<EmailDesignerEmailTemplateEntity>;
  meta: ResponseCollectionMeta;
};

export type I18NLocaleFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  code?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  not?: InputMaybe<I18NLocaleFiltersInput>;
};

export type I18NLocale = {
  __typename?: 'I18NLocale';
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type I18NLocaleEntity = {
  __typename?: 'I18NLocaleEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<I18NLocale>;
};

export type I18NLocaleEntityResponse = {
  __typename?: 'I18NLocaleEntityResponse';
  data?: Maybe<I18NLocaleEntity>;
};

export type I18NLocaleEntityResponseCollection = {
  __typename?: 'I18NLocaleEntityResponseCollection';
  data: Array<I18NLocaleEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsPermissionFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  action?: InputMaybe<StringFilterInput>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  not?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
};

export type UsersPermissionsPermission = {
  __typename?: 'UsersPermissionsPermission';
  action: Scalars['String'];
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UsersPermissionsPermissionEntity = {
  __typename?: 'UsersPermissionsPermissionEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<UsersPermissionsPermission>;
};

export type UsersPermissionsPermissionRelationResponseCollection = {
  __typename?: 'UsersPermissionsPermissionRelationResponseCollection';
  data: Array<UsersPermissionsPermissionEntity>;
};

export type UsersPermissionsRoleFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  type?: InputMaybe<StringFilterInput>;
  permissions?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  users?: InputMaybe<UsersPermissionsUserFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  not?: InputMaybe<UsersPermissionsRoleFiltersInput>;
};

export type UsersPermissionsRoleInput = {
  name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type UsersPermissionsRole = {
  __typename?: 'UsersPermissionsRole';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  permissions?: Maybe<UsersPermissionsPermissionRelationResponseCollection>;
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UsersPermissionsRolePermissionsArgs = {
  filters?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UsersPermissionsRoleUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UsersPermissionsRoleEntity = {
  __typename?: 'UsersPermissionsRoleEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<UsersPermissionsRole>;
};

export type UsersPermissionsRoleEntityResponse = {
  __typename?: 'UsersPermissionsRoleEntityResponse';
  data?: Maybe<UsersPermissionsRoleEntity>;
};

export type UsersPermissionsRoleEntityResponseCollection = {
  __typename?: 'UsersPermissionsRoleEntityResponseCollection';
  data: Array<UsersPermissionsRoleEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsUserFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  username?: InputMaybe<StringFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  password?: InputMaybe<StringFilterInput>;
  resetPasswordToken?: InputMaybe<StringFilterInput>;
  confirmationToken?: InputMaybe<StringFilterInput>;
  confirmed?: InputMaybe<BooleanFilterInput>;
  blocked?: InputMaybe<BooleanFilterInput>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  member?: InputMaybe<MemberFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  not?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type UsersPermissionsUserInput = {
  username?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  resetPasswordToken?: InputMaybe<Scalars['String']>;
  confirmationToken?: InputMaybe<Scalars['String']>;
  confirmed?: InputMaybe<Scalars['Boolean']>;
  blocked?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<Scalars['ID']>;
  member?: InputMaybe<Scalars['ID']>;
};

export type UsersPermissionsUser = {
  __typename?: 'UsersPermissionsUser';
  username: Scalars['String'];
  email: Scalars['String'];
  provider?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  blocked?: Maybe<Scalars['Boolean']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  member?: Maybe<MemberEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UsersPermissionsUserEntity = {
  __typename?: 'UsersPermissionsUserEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<UsersPermissionsUser>;
};

export type UsersPermissionsUserEntityResponse = {
  __typename?: 'UsersPermissionsUserEntityResponse';
  data?: Maybe<UsersPermissionsUserEntity>;
};

export type UsersPermissionsUserEntityResponseCollection = {
  __typename?: 'UsersPermissionsUserEntityResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsUserRelationResponseCollection = {
  __typename?: 'UsersPermissionsUserRelationResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
};

export enum Enum_Certifyingexam_Result {
  Passed = 'Passed',
  StepBack = 'Step_Back',
  FailedTheory = 'Failed_Theory',
  FailedPracticalAndOcm = 'Failed_Practical_and_OCM',
  FailedBoth = 'Failed_Both',
}

export enum Enum_Certifyingexam_Typeofsubmission {
  FirstSubmission = 'First_Submission',
  ReSubmission = 'Re_submission',
}

export enum Enum_Certifyingexam_Examparts {
  EntireCertifyingExam = 'Entire_Certifying_Exam',
  TheoryPart = 'Theory_Part',
  PracticalInterpretativePart = 'Practical_Interpretative_Part',
  TheoryOnly = 'Theory_only',
  PracticalOcmOnlyInterpretivePart = 'Practical_OCM_only_Interpretive_part',
}

export enum Enum_Certifyingexam_Applicationresult {
  Accepted = 'Accepted',
  ConditionallyAccepted = 'Conditionally_Accepted',
  PreviouslyAccepted = 'Previously_Accepted',
  NotAccepted = 'Not_Accepted',
  AcceptedWithOutstandingCredentials = 'Accepted_with_outstanding_credentials',
}

export type CertifyingExamFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  result?: InputMaybe<StringFilterInput>;
  publicationsAccepted?: InputMaybe<BooleanFilterInput>;
  resident?: InputMaybe<MemberFiltersInput>;
  geResultReviewer1Comment?: InputMaybe<StringFilterInput>;
  geResultReviewer1Notes?: InputMaybe<StringFilterInput>;
  geResultReviewer1Accepted?: InputMaybe<BooleanFilterInput>;
  geResultReviewer2Accepted?: InputMaybe<BooleanFilterInput>;
  geResultAccepted?: InputMaybe<BooleanFilterInput>;
  payments?: InputMaybe<PaymentFiltersInput>;
  privacyPolicyAccepted?: InputMaybe<BooleanFilterInput>;
  programmeDirectorLetterComment?: InputMaybe<StringFilterInput>;
  programmeDirectorLetterReviewer1Notes?: InputMaybe<StringFilterInput>;
  programmeDirectorLetterReviewer1Accepted?: InputMaybe<BooleanFilterInput>;
  programmeDirectorLetterReviewer2Accepted?: InputMaybe<BooleanFilterInput>;
  programmeDirectorLetterAccepted?: InputMaybe<BooleanFilterInput>;
  verificationOfTrainingComment?: InputMaybe<StringFilterInput>;
  verificationOfTrainingReviewer1Notes?: InputMaybe<StringFilterInput>;
  verificationOfTrainingReviewer1Accepted?: InputMaybe<BooleanFilterInput>;
  verificationOfTrainingReviewer2Accepted?: InputMaybe<BooleanFilterInput>;
  verificationOfTrainingAccepted?: InputMaybe<BooleanFilterInput>;
  caseLogSummaryComment?: InputMaybe<StringFilterInput>;
  caseLogSummaryReviewer1Notes?: InputMaybe<StringFilterInput>;
  caseLogSummaryReviewer1Accepted?: InputMaybe<BooleanFilterInput>;
  caseLogSummaryReviewer2Accepted?: InputMaybe<BooleanFilterInput>;
  caseLogSummaryAccepted?: InputMaybe<BooleanFilterInput>;
  presentationLogComment?: InputMaybe<StringFilterInput>;
  presentationLogReviewer1Notes?: InputMaybe<StringFilterInput>;
  presentationLogReviewer1Accepted?: InputMaybe<BooleanFilterInput>;
  presentationLogReviewer2Accepted?: InputMaybe<BooleanFilterInput>;
  presentationLogAccepted?: InputMaybe<BooleanFilterInput>;
  caseSummaryReviewer1Comment?: InputMaybe<StringFilterInput>;
  caseSummaryReviewer1Notes?: InputMaybe<StringFilterInput>;
  publicationsComment?: InputMaybe<StringFilterInput>;
  publicationsReviewer1Notes?: InputMaybe<StringFilterInput>;
  publicationsReviewer1Accepted?: InputMaybe<BooleanFilterInput>;
  publicationsReviewer2Accepted?: InputMaybe<BooleanFilterInput>;
  previousCorrespondenceComment?: InputMaybe<StringFilterInput>;
  previousCorrespondenceReviewer1Notes?: InputMaybe<StringFilterInput>;
  previousCorrespondenceReviewer1Accepted?: InputMaybe<BooleanFilterInput>;
  previousCorrespondenceReviewer2Accepted?: InputMaybe<BooleanFilterInput>;
  previousCorrespondenceAccepted?: InputMaybe<BooleanFilterInput>;
  termsAndConditionsAccepted?: InputMaybe<BooleanFilterInput>;
  attempt?: InputMaybe<IntFilterInput>;
  typeOfSubmission?: InputMaybe<StringFilterInput>;
  examParts?: InputMaybe<StringFilterInput>;
  publicationLinks?: InputMaybe<StringFilterInput>;
  caseLogComment?: InputMaybe<StringFilterInput>;
  caseLogReviewer1Notes?: InputMaybe<StringFilterInput>;
  caseLogAccepted?: InputMaybe<BooleanFilterInput>;
  caseLogReviewer1Accepted?: InputMaybe<BooleanFilterInput>;
  caseLogReviewer2Accepted?: InputMaybe<BooleanFilterInput>;
  multipleChoiceQuestionnaireComment?: InputMaybe<StringFilterInput>;
  multipleChoiceQuestionnaireReviewer1Notes?: InputMaybe<StringFilterInput>;
  multipleChoiceQuestionnaireAccepted?: InputMaybe<BooleanFilterInput>;
  multipleChoiceQuestionnaireReviewer1Accepted?: InputMaybe<BooleanFilterInput>;
  multipleChoiceQuestionnaireReviewer2Accepted?: InputMaybe<BooleanFilterInput>;
  confidentialityAgreementAccepted?: InputMaybe<BooleanFilterInput>;
  titlePreviousCaseSummary?: InputMaybe<StringFilterInput>;
  procedureLogComment?: InputMaybe<StringFilterInput>;
  procedureLogReviewer1Notes?: InputMaybe<StringFilterInput>;
  procedureLogAccepted?: InputMaybe<BooleanFilterInput>;
  procedureLogReviewer1Accepted?: InputMaybe<BooleanFilterInput>;
  procedureLogReviewer2Accepted?: InputMaybe<BooleanFilterInput>;
  applicationResult?: InputMaybe<StringFilterInput>;
  reviewer1?: InputMaybe<MemberFiltersInput>;
  reviewer2?: InputMaybe<MemberFiltersInput>;
  applicationResultComment?: InputMaybe<StringFilterInput>;
  geResultReviewer2Comment?: InputMaybe<StringFilterInput>;
  geResultReviewer2Notes?: InputMaybe<StringFilterInput>;
  geResultComment?: InputMaybe<StringFilterInput>;
  caseSummaryReviewer2Comment?: InputMaybe<StringFilterInput>;
  caseSummaryReviewer2Notes?: InputMaybe<StringFilterInput>;
  caseSummaryComment?: InputMaybe<StringFilterInput>;
  programmeDirectorLetterReviewer2Notes?: InputMaybe<StringFilterInput>;
  programmeDirectorLetterReviewer1Comment?: InputMaybe<StringFilterInput>;
  programmeDirectorLetterReviewer2Comment?: InputMaybe<StringFilterInput>;
  verificationOfTrainingReviewer2Notes?: InputMaybe<StringFilterInput>;
  verificationOfTrainingReviewer1Comment?: InputMaybe<StringFilterInput>;
  verificationOfTrainingReviewer2Comment?: InputMaybe<StringFilterInput>;
  caseLogSummaryReviewer2Notes?: InputMaybe<StringFilterInput>;
  caseLogSummaryReviewer1Comment?: InputMaybe<StringFilterInput>;
  caseLogSummaryReviewer2Comment?: InputMaybe<StringFilterInput>;
  presentationLogReviewer2Notes?: InputMaybe<StringFilterInput>;
  presentationLogReviewer1Comment?: InputMaybe<StringFilterInput>;
  presentationLogReviewer2Comment?: InputMaybe<StringFilterInput>;
  publicationsReviewer2Notes?: InputMaybe<StringFilterInput>;
  publicationsReviewer1Comment?: InputMaybe<StringFilterInput>;
  publicationsReviewer2Comment?: InputMaybe<StringFilterInput>;
  previousCorrespondenceReviewer2Notes?: InputMaybe<StringFilterInput>;
  previousCorrespondenceReviewer1Comment?: InputMaybe<StringFilterInput>;
  previousCorrespondenceReviewer2Comment?: InputMaybe<StringFilterInput>;
  caseLogReviewer2Notes?: InputMaybe<StringFilterInput>;
  caseLogReviewer1Comment?: InputMaybe<StringFilterInput>;
  caseLogReviewer2Comment?: InputMaybe<StringFilterInput>;
  multipleChoiceQuestionnaireReviewer2Notes?: InputMaybe<StringFilterInput>;
  multipleChoiceQuestionnaireReviewer1Comment?: InputMaybe<StringFilterInput>;
  multipleChoiceQuestionnaireReviewer2Comment?: InputMaybe<StringFilterInput>;
  procedureLogReviewer2Notes?: InputMaybe<StringFilterInput>;
  procedureLogReviewer1Comment?: InputMaybe<StringFilterInput>;
  procedureLogReviewer2Comment?: InputMaybe<StringFilterInput>;
  reviewer1Status?: InputMaybe<BooleanFilterInput>;
  reviewer2Status?: InputMaybe<BooleanFilterInput>;
  speciality?: InputMaybe<ComponentMasterdataSpecialityFiltersInput>;
  reviewStatus?: InputMaybe<BooleanFilterInput>;
  specialityString?: InputMaybe<StringFilterInput>;
  examYear?: InputMaybe<IntFilterInput>;
  credentialsSubmissionSummaryFormAccepted?: InputMaybe<BooleanFilterInput>;
  credentialsSubmissionSummaryFormComment?: InputMaybe<StringFilterInput>;
  credentialsSubmissionSummaryFormReviewer1Comment?: InputMaybe<StringFilterInput>;
  credentialsSubmissionSummaryFormReviewer2Comment?: InputMaybe<StringFilterInput>;
  credentialsSubmissionSummaryFormReviewer1Notes?: InputMaybe<StringFilterInput>;
  credentialsSubmissionSummaryFormReviewer2Notes?: InputMaybe<StringFilterInput>;
  credentialsSubmissionSummaryFormReviewer1Accepted?: InputMaybe<BooleanFilterInput>;
  credentialsSubmissionSummaryFormReviewer2Accepted?: InputMaybe<BooleanFilterInput>;
  verificationOfTrainingSubmitted?: InputMaybe<BooleanFilterInput>;
  presentationLogSubmitted?: InputMaybe<BooleanFilterInput>;
  publicationsSubmitted?: InputMaybe<BooleanFilterInput>;
  applicationResultPublished?: InputMaybe<BooleanFilterInput>;
  fullCredentialsFeeNextApplication?: InputMaybe<BooleanFilterInput>;
  caseSummaryReviewStatus?: InputMaybe<BooleanFilterInput>;
  caseSummaryReviewPublished?: InputMaybe<BooleanFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<CertifyingExamFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<CertifyingExamFiltersInput>>>;
  not?: InputMaybe<CertifyingExamFiltersInput>;
};

export type CertifyingExamInput = {
  result?: InputMaybe<Enum_Certifyingexam_Result>;
  geResult?: InputMaybe<Scalars['ID']>;
  programmeDirectorLetter?: InputMaybe<Scalars['ID']>;
  verificationOfTraining?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  previousCorrespondence?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  caseLogSummary?: InputMaybe<Scalars['ID']>;
  publications?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  publicationsAccepted?: InputMaybe<Scalars['Boolean']>;
  caseSummary?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  resident?: InputMaybe<Scalars['ID']>;
  geResultReviewer1Comment?: InputMaybe<Scalars['String']>;
  geResultReviewer1Notes?: InputMaybe<Scalars['String']>;
  geResultReviewer1Accepted?: InputMaybe<Scalars['Boolean']>;
  geResultReviewer2Accepted?: InputMaybe<Scalars['Boolean']>;
  geResultAccepted?: InputMaybe<Scalars['Boolean']>;
  payments?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  privacyPolicyAccepted?: InputMaybe<Scalars['Boolean']>;
  programmeDirectorLetterComment?: InputMaybe<Scalars['String']>;
  programmeDirectorLetterReviewer1Notes?: InputMaybe<Scalars['String']>;
  programmeDirectorLetterReviewer1Accepted?: InputMaybe<Scalars['Boolean']>;
  programmeDirectorLetterReviewer2Accepted?: InputMaybe<Scalars['Boolean']>;
  programmeDirectorLetterAccepted?: InputMaybe<Scalars['Boolean']>;
  verificationOfTrainingComment?: InputMaybe<Scalars['String']>;
  verificationOfTrainingReviewer1Notes?: InputMaybe<Scalars['String']>;
  verificationOfTrainingReviewer1Accepted?: InputMaybe<Scalars['Boolean']>;
  verificationOfTrainingReviewer2Accepted?: InputMaybe<Scalars['Boolean']>;
  verificationOfTrainingAccepted?: InputMaybe<Scalars['Boolean']>;
  caseLogSummaryComment?: InputMaybe<Scalars['String']>;
  caseLogSummaryReviewer1Notes?: InputMaybe<Scalars['String']>;
  caseLogSummaryReviewer1Accepted?: InputMaybe<Scalars['Boolean']>;
  caseLogSummaryReviewer2Accepted?: InputMaybe<Scalars['Boolean']>;
  caseLogSummaryAccepted?: InputMaybe<Scalars['Boolean']>;
  presentationLog?: InputMaybe<Scalars['ID']>;
  presentationLogComment?: InputMaybe<Scalars['String']>;
  presentationLogReviewer1Notes?: InputMaybe<Scalars['String']>;
  presentationLogReviewer1Accepted?: InputMaybe<Scalars['Boolean']>;
  presentationLogReviewer2Accepted?: InputMaybe<Scalars['Boolean']>;
  presentationLogAccepted?: InputMaybe<Scalars['Boolean']>;
  caseSummaryReviewer1Comment?: InputMaybe<Scalars['String']>;
  caseSummaryReviewer1Notes?: InputMaybe<Scalars['String']>;
  publicationsComment?: InputMaybe<Scalars['String']>;
  publicationsReviewer1Notes?: InputMaybe<Scalars['String']>;
  publicationsReviewer1Accepted?: InputMaybe<Scalars['Boolean']>;
  publicationsReviewer2Accepted?: InputMaybe<Scalars['Boolean']>;
  previousCorrespondenceComment?: InputMaybe<Scalars['String']>;
  previousCorrespondenceReviewer1Notes?: InputMaybe<Scalars['String']>;
  previousCorrespondenceReviewer1Accepted?: InputMaybe<Scalars['Boolean']>;
  previousCorrespondenceReviewer2Accepted?: InputMaybe<Scalars['Boolean']>;
  previousCorrespondenceAccepted?: InputMaybe<Scalars['Boolean']>;
  termsAndConditionsAccepted?: InputMaybe<Scalars['Boolean']>;
  attempt?: InputMaybe<Scalars['Int']>;
  typeOfSubmission?: InputMaybe<Enum_Certifyingexam_Typeofsubmission>;
  examParts?: InputMaybe<Enum_Certifyingexam_Examparts>;
  publicationLinks?: InputMaybe<Scalars['String']>;
  caseLog?: InputMaybe<Scalars['ID']>;
  caseLogComment?: InputMaybe<Scalars['String']>;
  caseLogReviewer1Notes?: InputMaybe<Scalars['String']>;
  caseLogAccepted?: InputMaybe<Scalars['Boolean']>;
  caseLogReviewer1Accepted?: InputMaybe<Scalars['Boolean']>;
  caseLogReviewer2Accepted?: InputMaybe<Scalars['Boolean']>;
  multipleChoiceQuestionnaire?: InputMaybe<Scalars['ID']>;
  multipleChoiceQuestionnaireComment?: InputMaybe<Scalars['String']>;
  multipleChoiceQuestionnaireReviewer1Notes?: InputMaybe<Scalars['String']>;
  multipleChoiceQuestionnaireAccepted?: InputMaybe<Scalars['Boolean']>;
  multipleChoiceQuestionnaireReviewer1Accepted?: InputMaybe<Scalars['Boolean']>;
  multipleChoiceQuestionnaireReviewer2Accepted?: InputMaybe<Scalars['Boolean']>;
  confidentialityAgreementAccepted?: InputMaybe<Scalars['Boolean']>;
  titlePreviousCaseSummary?: InputMaybe<Scalars['String']>;
  procedureLog?: InputMaybe<Scalars['ID']>;
  procedureLogComment?: InputMaybe<Scalars['String']>;
  procedureLogReviewer1Notes?: InputMaybe<Scalars['String']>;
  procedureLogAccepted?: InputMaybe<Scalars['Boolean']>;
  procedureLogReviewer1Accepted?: InputMaybe<Scalars['Boolean']>;
  procedureLogReviewer2Accepted?: InputMaybe<Scalars['Boolean']>;
  applicationResult?: InputMaybe<Enum_Certifyingexam_Applicationresult>;
  reviewer1?: InputMaybe<Scalars['ID']>;
  reviewer2?: InputMaybe<Scalars['ID']>;
  applicationResultComment?: InputMaybe<Scalars['String']>;
  geResultReviewer2Comment?: InputMaybe<Scalars['String']>;
  geResultReviewer2Notes?: InputMaybe<Scalars['String']>;
  geResultComment?: InputMaybe<Scalars['String']>;
  caseSummaryReviewer2Comment?: InputMaybe<Scalars['String']>;
  caseSummaryReviewer2Notes?: InputMaybe<Scalars['String']>;
  caseSummaryComment?: InputMaybe<Scalars['String']>;
  programmeDirectorLetterReviewer2Notes?: InputMaybe<Scalars['String']>;
  programmeDirectorLetterReviewer1Comment?: InputMaybe<Scalars['String']>;
  programmeDirectorLetterReviewer2Comment?: InputMaybe<Scalars['String']>;
  verificationOfTrainingReviewer2Notes?: InputMaybe<Scalars['String']>;
  verificationOfTrainingReviewer1Comment?: InputMaybe<Scalars['String']>;
  verificationOfTrainingReviewer2Comment?: InputMaybe<Scalars['String']>;
  caseLogSummaryReviewer2Notes?: InputMaybe<Scalars['String']>;
  caseLogSummaryReviewer1Comment?: InputMaybe<Scalars['String']>;
  caseLogSummaryReviewer2Comment?: InputMaybe<Scalars['String']>;
  presentationLogReviewer2Notes?: InputMaybe<Scalars['String']>;
  presentationLogReviewer1Comment?: InputMaybe<Scalars['String']>;
  presentationLogReviewer2Comment?: InputMaybe<Scalars['String']>;
  publicationsReviewer2Notes?: InputMaybe<Scalars['String']>;
  publicationsReviewer1Comment?: InputMaybe<Scalars['String']>;
  publicationsReviewer2Comment?: InputMaybe<Scalars['String']>;
  previousCorrespondenceReviewer2Notes?: InputMaybe<Scalars['String']>;
  previousCorrespondenceReviewer1Comment?: InputMaybe<Scalars['String']>;
  previousCorrespondenceReviewer2Comment?: InputMaybe<Scalars['String']>;
  caseLogReviewer2Notes?: InputMaybe<Scalars['String']>;
  caseLogReviewer1Comment?: InputMaybe<Scalars['String']>;
  caseLogReviewer2Comment?: InputMaybe<Scalars['String']>;
  multipleChoiceQuestionnaireReviewer2Notes?: InputMaybe<Scalars['String']>;
  multipleChoiceQuestionnaireReviewer1Comment?: InputMaybe<Scalars['String']>;
  multipleChoiceQuestionnaireReviewer2Comment?: InputMaybe<Scalars['String']>;
  procedureLogReviewer2Notes?: InputMaybe<Scalars['String']>;
  procedureLogReviewer1Comment?: InputMaybe<Scalars['String']>;
  procedureLogReviewer2Comment?: InputMaybe<Scalars['String']>;
  reviewer1Status?: InputMaybe<Scalars['Boolean']>;
  reviewer2Status?: InputMaybe<Scalars['Boolean']>;
  speciality?: InputMaybe<ComponentMasterdataSpecialityInput>;
  reviewStatus?: InputMaybe<Scalars['Boolean']>;
  applicationResultLetters?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  caseSummaryConclusionLetter?: InputMaybe<Scalars['ID']>;
  specialityString?: InputMaybe<Scalars['String']>;
  examYear?: InputMaybe<Scalars['Int']>;
  credentialsSubmissionSummaryForm?: InputMaybe<Scalars['ID']>;
  credentialsSubmissionSummaryFormAccepted?: InputMaybe<Scalars['Boolean']>;
  credentialsSubmissionSummaryFormComment?: InputMaybe<Scalars['String']>;
  credentialsSubmissionSummaryFormReviewer1Comment?: InputMaybe<Scalars['String']>;
  credentialsSubmissionSummaryFormReviewer2Comment?: InputMaybe<Scalars['String']>;
  credentialsSubmissionSummaryFormReviewer1Notes?: InputMaybe<Scalars['String']>;
  credentialsSubmissionSummaryFormReviewer2Notes?: InputMaybe<Scalars['String']>;
  credentialsSubmissionSummaryFormReviewer1Accepted?: InputMaybe<Scalars['Boolean']>;
  credentialsSubmissionSummaryFormReviewer2Accepted?: InputMaybe<Scalars['Boolean']>;
  verificationOfTrainingSubmitted?: InputMaybe<Scalars['Boolean']>;
  presentationLogSubmitted?: InputMaybe<Scalars['Boolean']>;
  publicationsSubmitted?: InputMaybe<Scalars['Boolean']>;
  applicationResultPublished?: InputMaybe<Scalars['Boolean']>;
  fullCredentialsFeeNextApplication?: InputMaybe<Scalars['Boolean']>;
  caseSummaryReviewStatus?: InputMaybe<Scalars['Boolean']>;
  caseSummaryReviewPublished?: InputMaybe<Scalars['Boolean']>;
};

export type CertifyingExam = {
  __typename?: 'CertifyingExam';
  result?: Maybe<Enum_Certifyingexam_Result>;
  geResult?: Maybe<UploadFileEntityResponse>;
  programmeDirectorLetter?: Maybe<UploadFileEntityResponse>;
  verificationOfTraining?: Maybe<UploadFileRelationResponseCollection>;
  previousCorrespondence?: Maybe<UploadFileRelationResponseCollection>;
  caseLogSummary?: Maybe<UploadFileEntityResponse>;
  publications?: Maybe<UploadFileRelationResponseCollection>;
  publicationsAccepted?: Maybe<Scalars['Boolean']>;
  caseSummary?: Maybe<UploadFileRelationResponseCollection>;
  resident?: Maybe<MemberEntityResponse>;
  geResultReviewer1Comment?: Maybe<Scalars['String']>;
  geResultReviewer1Notes?: Maybe<Scalars['String']>;
  geResultReviewer1Accepted?: Maybe<Scalars['Boolean']>;
  geResultReviewer2Accepted?: Maybe<Scalars['Boolean']>;
  geResultAccepted?: Maybe<Scalars['Boolean']>;
  payments?: Maybe<PaymentRelationResponseCollection>;
  privacyPolicyAccepted?: Maybe<Scalars['Boolean']>;
  programmeDirectorLetterComment?: Maybe<Scalars['String']>;
  programmeDirectorLetterReviewer1Notes?: Maybe<Scalars['String']>;
  programmeDirectorLetterReviewer1Accepted?: Maybe<Scalars['Boolean']>;
  programmeDirectorLetterReviewer2Accepted?: Maybe<Scalars['Boolean']>;
  programmeDirectorLetterAccepted?: Maybe<Scalars['Boolean']>;
  verificationOfTrainingComment?: Maybe<Scalars['String']>;
  verificationOfTrainingReviewer1Notes?: Maybe<Scalars['String']>;
  verificationOfTrainingReviewer1Accepted?: Maybe<Scalars['Boolean']>;
  verificationOfTrainingReviewer2Accepted?: Maybe<Scalars['Boolean']>;
  verificationOfTrainingAccepted?: Maybe<Scalars['Boolean']>;
  caseLogSummaryComment?: Maybe<Scalars['String']>;
  caseLogSummaryReviewer1Notes?: Maybe<Scalars['String']>;
  caseLogSummaryReviewer1Accepted?: Maybe<Scalars['Boolean']>;
  caseLogSummaryReviewer2Accepted?: Maybe<Scalars['Boolean']>;
  caseLogSummaryAccepted?: Maybe<Scalars['Boolean']>;
  presentationLog?: Maybe<UploadFileEntityResponse>;
  presentationLogComment?: Maybe<Scalars['String']>;
  presentationLogReviewer1Notes?: Maybe<Scalars['String']>;
  presentationLogReviewer1Accepted?: Maybe<Scalars['Boolean']>;
  presentationLogReviewer2Accepted?: Maybe<Scalars['Boolean']>;
  presentationLogAccepted?: Maybe<Scalars['Boolean']>;
  caseSummaryReviewer1Comment?: Maybe<Scalars['String']>;
  caseSummaryReviewer1Notes?: Maybe<Scalars['String']>;
  publicationsComment?: Maybe<Scalars['String']>;
  publicationsReviewer1Notes?: Maybe<Scalars['String']>;
  publicationsReviewer1Accepted?: Maybe<Scalars['Boolean']>;
  publicationsReviewer2Accepted?: Maybe<Scalars['Boolean']>;
  previousCorrespondenceComment?: Maybe<Scalars['String']>;
  previousCorrespondenceReviewer1Notes?: Maybe<Scalars['String']>;
  previousCorrespondenceReviewer1Accepted?: Maybe<Scalars['Boolean']>;
  previousCorrespondenceReviewer2Accepted?: Maybe<Scalars['Boolean']>;
  previousCorrespondenceAccepted?: Maybe<Scalars['Boolean']>;
  termsAndConditionsAccepted?: Maybe<Scalars['Boolean']>;
  attempt: Scalars['Int'];
  typeOfSubmission?: Maybe<Enum_Certifyingexam_Typeofsubmission>;
  examParts?: Maybe<Enum_Certifyingexam_Examparts>;
  publicationLinks?: Maybe<Scalars['String']>;
  caseLog?: Maybe<UploadFileEntityResponse>;
  caseLogComment?: Maybe<Scalars['String']>;
  caseLogReviewer1Notes?: Maybe<Scalars['String']>;
  caseLogAccepted?: Maybe<Scalars['Boolean']>;
  caseLogReviewer1Accepted?: Maybe<Scalars['Boolean']>;
  caseLogReviewer2Accepted?: Maybe<Scalars['Boolean']>;
  multipleChoiceQuestionnaire?: Maybe<UploadFileEntityResponse>;
  multipleChoiceQuestionnaireComment?: Maybe<Scalars['String']>;
  multipleChoiceQuestionnaireReviewer1Notes?: Maybe<Scalars['String']>;
  multipleChoiceQuestionnaireAccepted?: Maybe<Scalars['Boolean']>;
  multipleChoiceQuestionnaireReviewer1Accepted?: Maybe<Scalars['Boolean']>;
  multipleChoiceQuestionnaireReviewer2Accepted?: Maybe<Scalars['Boolean']>;
  confidentialityAgreementAccepted?: Maybe<Scalars['Boolean']>;
  titlePreviousCaseSummary?: Maybe<Scalars['String']>;
  procedureLog?: Maybe<UploadFileEntityResponse>;
  procedureLogComment?: Maybe<Scalars['String']>;
  procedureLogReviewer1Notes?: Maybe<Scalars['String']>;
  procedureLogAccepted?: Maybe<Scalars['Boolean']>;
  procedureLogReviewer1Accepted?: Maybe<Scalars['Boolean']>;
  procedureLogReviewer2Accepted?: Maybe<Scalars['Boolean']>;
  applicationResult?: Maybe<Enum_Certifyingexam_Applicationresult>;
  reviewer1?: Maybe<MemberEntityResponse>;
  reviewer2?: Maybe<MemberEntityResponse>;
  applicationResultComment?: Maybe<Scalars['String']>;
  geResultReviewer2Comment?: Maybe<Scalars['String']>;
  geResultReviewer2Notes?: Maybe<Scalars['String']>;
  geResultComment?: Maybe<Scalars['String']>;
  caseSummaryReviewer2Comment?: Maybe<Scalars['String']>;
  caseSummaryReviewer2Notes?: Maybe<Scalars['String']>;
  caseSummaryComment?: Maybe<Scalars['String']>;
  programmeDirectorLetterReviewer2Notes?: Maybe<Scalars['String']>;
  programmeDirectorLetterReviewer1Comment?: Maybe<Scalars['String']>;
  programmeDirectorLetterReviewer2Comment?: Maybe<Scalars['String']>;
  verificationOfTrainingReviewer2Notes?: Maybe<Scalars['String']>;
  verificationOfTrainingReviewer1Comment?: Maybe<Scalars['String']>;
  verificationOfTrainingReviewer2Comment?: Maybe<Scalars['String']>;
  caseLogSummaryReviewer2Notes?: Maybe<Scalars['String']>;
  caseLogSummaryReviewer1Comment?: Maybe<Scalars['String']>;
  caseLogSummaryReviewer2Comment?: Maybe<Scalars['String']>;
  presentationLogReviewer2Notes?: Maybe<Scalars['String']>;
  presentationLogReviewer1Comment?: Maybe<Scalars['String']>;
  presentationLogReviewer2Comment?: Maybe<Scalars['String']>;
  publicationsReviewer2Notes?: Maybe<Scalars['String']>;
  publicationsReviewer1Comment?: Maybe<Scalars['String']>;
  publicationsReviewer2Comment?: Maybe<Scalars['String']>;
  previousCorrespondenceReviewer2Notes?: Maybe<Scalars['String']>;
  previousCorrespondenceReviewer1Comment?: Maybe<Scalars['String']>;
  previousCorrespondenceReviewer2Comment?: Maybe<Scalars['String']>;
  caseLogReviewer2Notes?: Maybe<Scalars['String']>;
  caseLogReviewer1Comment?: Maybe<Scalars['String']>;
  caseLogReviewer2Comment?: Maybe<Scalars['String']>;
  multipleChoiceQuestionnaireReviewer2Notes?: Maybe<Scalars['String']>;
  multipleChoiceQuestionnaireReviewer1Comment?: Maybe<Scalars['String']>;
  multipleChoiceQuestionnaireReviewer2Comment?: Maybe<Scalars['String']>;
  procedureLogReviewer2Notes?: Maybe<Scalars['String']>;
  procedureLogReviewer1Comment?: Maybe<Scalars['String']>;
  procedureLogReviewer2Comment?: Maybe<Scalars['String']>;
  reviewer1Status?: Maybe<Scalars['Boolean']>;
  reviewer2Status?: Maybe<Scalars['Boolean']>;
  speciality?: Maybe<ComponentMasterdataSpeciality>;
  reviewStatus?: Maybe<Scalars['Boolean']>;
  applicationResultLetters?: Maybe<UploadFileRelationResponseCollection>;
  caseSummaryConclusionLetter?: Maybe<UploadFileEntityResponse>;
  examYear: Scalars['Int'];
  credentialsSubmissionSummaryForm?: Maybe<UploadFileEntityResponse>;
  credentialsSubmissionSummaryFormAccepted?: Maybe<Scalars['Boolean']>;
  credentialsSubmissionSummaryFormComment?: Maybe<Scalars['String']>;
  credentialsSubmissionSummaryFormReviewer1Comment?: Maybe<Scalars['String']>;
  credentialsSubmissionSummaryFormReviewer2Comment?: Maybe<Scalars['String']>;
  credentialsSubmissionSummaryFormReviewer1Notes?: Maybe<Scalars['String']>;
  credentialsSubmissionSummaryFormReviewer2Notes?: Maybe<Scalars['String']>;
  credentialsSubmissionSummaryFormReviewer1Accepted?: Maybe<Scalars['Boolean']>;
  credentialsSubmissionSummaryFormReviewer2Accepted?: Maybe<Scalars['Boolean']>;
  verificationOfTrainingSubmitted?: Maybe<Scalars['Boolean']>;
  presentationLogSubmitted?: Maybe<Scalars['Boolean']>;
  publicationsSubmitted?: Maybe<Scalars['Boolean']>;
  applicationResultPublished: Scalars['Boolean'];
  fullCredentialsFeeNextApplication: Scalars['Boolean'];
  caseSummaryReviewStatus?: Maybe<Scalars['Boolean']>;
  caseSummaryReviewPublished: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CertifyingExamVerificationOfTrainingArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CertifyingExamPreviousCorrespondenceArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CertifyingExamPublicationsArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CertifyingExamCaseSummaryArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CertifyingExamPaymentsArgs = {
  filters?: InputMaybe<PaymentFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CertifyingExamApplicationResultLettersArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CertifyingExamEntity = {
  __typename?: 'CertifyingExamEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<CertifyingExam>;
};

export type CertifyingExamEntityResponse = {
  __typename?: 'CertifyingExamEntityResponse';
  data?: Maybe<CertifyingExamEntity>;
};

export type CertifyingExamEntityResponseCollection = {
  __typename?: 'CertifyingExamEntityResponseCollection';
  data: Array<CertifyingExamEntity>;
  meta: ResponseCollectionMeta;
};

export type CertifyingExamRelationResponseCollection = {
  __typename?: 'CertifyingExamRelationResponseCollection';
  data: Array<CertifyingExamEntity>;
};

export type CertifyingExamToolInput = {
  publishApplicationResults?: InputMaybe<Scalars['Boolean']>;
  publishApplicationResultsLog?: InputMaybe<Scalars['String']>;
  examInfos?: InputMaybe<Array<InputMaybe<ComponentMasterdataCertifyingExamInfoInput>>>;
  publishCaseSummaryReviewResults?: InputMaybe<Scalars['Boolean']>;
  publishCaseSummaryReviewResultsLog?: InputMaybe<Scalars['String']>;
};

export type CertifyingExamTool = {
  __typename?: 'CertifyingExamTool';
  publishApplicationResults: Scalars['Boolean'];
  examInfos?: Maybe<Array<Maybe<ComponentMasterdataCertifyingExamInfo>>>;
  publishCaseSummaryReviewResults: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CertifyingExamToolExamInfosArgs = {
  filters?: InputMaybe<ComponentMasterdataCertifyingExamInfoFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CertifyingExamToolEntity = {
  __typename?: 'CertifyingExamToolEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<CertifyingExamTool>;
};

export type CertifyingExamToolEntityResponse = {
  __typename?: 'CertifyingExamToolEntityResponse';
  data?: Maybe<CertifyingExamToolEntity>;
};

export type ComiteeFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  Name?: InputMaybe<StringFilterInput>;
  Chair?: InputMaybe<MemberFiltersInput>;
  Members?: InputMaybe<MemberFiltersInput>;
  speciality?: InputMaybe<ComponentMasterdataSpecialityFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComiteeFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<ComiteeFiltersInput>>>;
  not?: InputMaybe<ComiteeFiltersInput>;
};

export type ComiteeInput = {
  Name?: InputMaybe<Scalars['String']>;
  Chair?: InputMaybe<Scalars['ID']>;
  Members?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  speciality?: InputMaybe<ComponentMasterdataSpecialityInput>;
};

export type Comitee = {
  __typename?: 'Comitee';
  Name?: Maybe<Scalars['String']>;
  Chair?: Maybe<MemberEntityResponse>;
  Members?: Maybe<MemberRelationResponseCollection>;
  speciality?: Maybe<ComponentMasterdataSpeciality>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ComiteeMembersArgs = {
  filters?: InputMaybe<MemberFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComiteeEntity = {
  __typename?: 'ComiteeEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Comitee>;
};

export type ComiteeEntityResponse = {
  __typename?: 'ComiteeEntityResponse';
  data?: Maybe<ComiteeEntity>;
};

export type ComiteeEntityResponseCollection = {
  __typename?: 'ComiteeEntityResponseCollection';
  data: Array<ComiteeEntity>;
  meta: ResponseCollectionMeta;
};

export type ComiteeRelationResponseCollection = {
  __typename?: 'ComiteeRelationResponseCollection';
  data: Array<ComiteeEntity>;
};

export type DataExportToolInput = {
  membershipFeeCreate?: InputMaybe<Scalars['Boolean']>;
  membershipFeeList?: InputMaybe<Scalars['ID']>;
  membershipFeeSubject?: InputMaybe<Scalars['String']>;
  programmesCreate?: InputMaybe<Scalars['Boolean']>;
  programmesList?: InputMaybe<Scalars['ID']>;
  generalExamCreate?: InputMaybe<Scalars['Boolean']>;
  generalExamList?: InputMaybe<Scalars['ID']>;
  generalExamApplicationSubmitted?: InputMaybe<Scalars['Boolean']>;
  certifyingExamCreate?: InputMaybe<Scalars['Boolean']>;
  certifyingExamList?: InputMaybe<Scalars['ID']>;
  certifyingExamApplicationSubmitted?: InputMaybe<Scalars['Boolean']>;
  residentCreate?: InputMaybe<Scalars['Boolean']>;
  residentList?: InputMaybe<Scalars['ID']>;
  diplomateCreate?: InputMaybe<Scalars['Boolean']>;
  diplomateList?: InputMaybe<Scalars['ID']>;
};

export type DataExportTool = {
  __typename?: 'DataExportTool';
  membershipFeeCreate?: Maybe<Scalars['Boolean']>;
  membershipFeeList?: Maybe<UploadFileEntityResponse>;
  membershipFeeSubject?: Maybe<Scalars['String']>;
  programmesCreate?: Maybe<Scalars['Boolean']>;
  programmesList?: Maybe<UploadFileEntityResponse>;
  generalExamCreate?: Maybe<Scalars['Boolean']>;
  generalExamList?: Maybe<UploadFileEntityResponse>;
  generalExamApplicationSubmitted?: Maybe<Scalars['Boolean']>;
  certifyingExamCreate?: Maybe<Scalars['Boolean']>;
  certifyingExamList?: Maybe<UploadFileEntityResponse>;
  certifyingExamApplicationSubmitted?: Maybe<Scalars['Boolean']>;
  residentCreate?: Maybe<Scalars['Boolean']>;
  residentList?: Maybe<UploadFileEntityResponse>;
  diplomateCreate?: Maybe<Scalars['Boolean']>;
  diplomateList?: Maybe<UploadFileEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DataExportToolEntity = {
  __typename?: 'DataExportToolEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<DataExportTool>;
};

export type DataExportToolEntityResponse = {
  __typename?: 'DataExportToolEntityResponse';
  data?: Maybe<DataExportToolEntity>;
};

export enum Enum_Diplomatereevaluation_Requesteddiplomatestatus {
  Certified = 'Certified',
  NonCertified = 'Non_certified',
  Retired = 'Retired',
}

export enum Enum_Diplomatereevaluation_Result {
  Successful = 'Successful',
  Unsuccessful = 'Unsuccessful',
  PendingFurtherDetails = 'Pending_Further_Details',
}

export type DiplomateReevaluationFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  diplomate?: InputMaybe<MemberFiltersInput>;
  reviewer1?: InputMaybe<MemberFiltersInput>;
  speciality?: InputMaybe<ComponentMasterdataSpecialityFiltersInput>;
  reviewer1Status?: InputMaybe<BooleanFilterInput>;
  reviewer2Status?: InputMaybe<BooleanFilterInput>;
  reevaluationDate?: InputMaybe<DateFilterInput>;
  reviewer2?: InputMaybe<MemberFiltersInput>;
  workHoursPerWeek?: InputMaybe<IntFilterInput>;
  workDescription?: InputMaybe<StringFilterInput>;
  agmAttendanceYears?: InputMaybe<ComponentMasterdataYearFiltersInput>;
  unfulfillmentReasons?: InputMaybe<StringFilterInput>;
  agmAttendancePoints?: InputMaybe<FloatFilterInput>;
  examQuestionContributionPoints?: InputMaybe<FloatFilterInput>;
  committeeWorkPoints?: InputMaybe<FloatFilterInput>;
  residentSupervisionPoints?: InputMaybe<FloatFilterInput>;
  presentationPoints?: InputMaybe<FloatFilterInput>;
  publicationPoints?: InputMaybe<FloatFilterInput>;
  peerReviewingPoints?: InputMaybe<FloatFilterInput>;
  additionalActivityPoints?: InputMaybe<FloatFilterInput>;
  collegeActivityParticipationPoints?: InputMaybe<FloatFilterInput>;
  workTypes?: InputMaybe<ComponentMasterdataWorkSectorFiltersInput>;
  reevaluationYear?: InputMaybe<IntFilterInput>;
  reevaluationPeriodStart?: InputMaybe<DateFilterInput>;
  reevaluationPeriodEnd?: InputMaybe<DateFilterInput>;
  reevaluationSubmissionDate?: InputMaybe<DateFilterInput>;
  declarationAccepted?: InputMaybe<BooleanFilterInput>;
  requestedDiplomateStatus?: InputMaybe<StringFilterInput>;
  additionalActivityPointsExplanation?: InputMaybe<StringFilterInput>;
  conferenceOrWebinarAttendancePoints?: InputMaybe<FloatFilterInput>;
  specialityString?: InputMaybe<StringFilterInput>;
  totalPoints?: InputMaybe<FloatFilterInput>;
  reviewStatus?: InputMaybe<BooleanFilterInput>;
  result?: InputMaybe<StringFilterInput>;
  resultComment?: InputMaybe<StringFilterInput>;
  resultNotes?: InputMaybe<StringFilterInput>;
  reevaluationPointDetailsReviewer1Accepted?: InputMaybe<BooleanFilterInput>;
  reevaluationPointDetailsReviewer2Accepted?: InputMaybe<BooleanFilterInput>;
  reevaluationPointDetailsAccepted?: InputMaybe<BooleanFilterInput>;
  reevaluationPointDetailsComment?: InputMaybe<StringFilterInput>;
  reevaluationPointDetailsReviewer1Comment?: InputMaybe<StringFilterInput>;
  reevaluationPointDetailsReviewer2Comment?: InputMaybe<StringFilterInput>;
  reevaluationPointsReviewer1Accepted?: InputMaybe<BooleanFilterInput>;
  reevaluationPointsReviewer2Accepted?: InputMaybe<BooleanFilterInput>;
  reevaluationPointsReviewer1Comment?: InputMaybe<StringFilterInput>;
  reevaluationPointsReviewer2Comment?: InputMaybe<StringFilterInput>;
  reevaluationPointsComment?: InputMaybe<StringFilterInput>;
  reevaluationPointsAccepted?: InputMaybe<BooleanFilterInput>;
  ebvsRequirementsAccepted?: InputMaybe<BooleanFilterInput>;
  ebvsRequirementsComment?: InputMaybe<StringFilterInput>;
  ebvsRequirementsReviewer1Comment?: InputMaybe<StringFilterInput>;
  ebvsRequirementsReviewer2Comment?: InputMaybe<StringFilterInput>;
  ebvsRequirementsReviewer1Accepted?: InputMaybe<BooleanFilterInput>;
  ebvsRequirementsReviewer2Accepted?: InputMaybe<BooleanFilterInput>;
  summaryInfoAccepted?: InputMaybe<BooleanFilterInput>;
  summaryInfoComment?: InputMaybe<StringFilterInput>;
  summaryInfoReviewer1Accepted?: InputMaybe<BooleanFilterInput>;
  summaryInfoReviewer2Accepted?: InputMaybe<BooleanFilterInput>;
  summaryInfoReviewer1Comment?: InputMaybe<StringFilterInput>;
  summaryInfoReviewer2Comment?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<DiplomateReevaluationFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<DiplomateReevaluationFiltersInput>>>;
  not?: InputMaybe<DiplomateReevaluationFiltersInput>;
};

export type DiplomateReevaluationInput = {
  diplomate?: InputMaybe<Scalars['ID']>;
  reviewer1?: InputMaybe<Scalars['ID']>;
  speciality?: InputMaybe<ComponentMasterdataSpecialityInput>;
  reviewer1Status?: InputMaybe<Scalars['Boolean']>;
  reviewer2Status?: InputMaybe<Scalars['Boolean']>;
  reevaluationDate?: InputMaybe<Scalars['Date']>;
  reviewer2?: InputMaybe<Scalars['ID']>;
  workHoursPerWeek?: InputMaybe<Scalars['Int']>;
  workDescription?: InputMaybe<Scalars['String']>;
  agmAttendanceYears?: InputMaybe<Array<InputMaybe<ComponentMasterdataYearInput>>>;
  unfulfillmentReasons?: InputMaybe<Scalars['String']>;
  agmAttendancePoints?: InputMaybe<Scalars['Float']>;
  examQuestionContributionPoints?: InputMaybe<Scalars['Float']>;
  committeeWorkPoints?: InputMaybe<Scalars['Float']>;
  residentSupervisionPoints?: InputMaybe<Scalars['Float']>;
  presentationPoints?: InputMaybe<Scalars['Float']>;
  publicationPoints?: InputMaybe<Scalars['Float']>;
  peerReviewingPoints?: InputMaybe<Scalars['Float']>;
  additionalActivityPoints?: InputMaybe<Scalars['Float']>;
  collegeActivityParticipationPoints?: InputMaybe<Scalars['Float']>;
  reevaluationPointDetails?: InputMaybe<Scalars['ID']>;
  workTypes?: InputMaybe<Array<InputMaybe<ComponentMasterdataWorkSectorInput>>>;
  reevaluationYear?: InputMaybe<Scalars['Int']>;
  reevaluationPeriodStart?: InputMaybe<Scalars['Date']>;
  reevaluationPeriodEnd?: InputMaybe<Scalars['Date']>;
  reevaluationSubmissionDate?: InputMaybe<Scalars['Date']>;
  declarationAccepted?: InputMaybe<Scalars['Boolean']>;
  requestedDiplomateStatus?: InputMaybe<Enum_Diplomatereevaluation_Requesteddiplomatestatus>;
  additionalActivityPointsExplanation?: InputMaybe<Scalars['String']>;
  conferenceOrWebinarAttendancePoints?: InputMaybe<Scalars['Float']>;
  specialityString?: InputMaybe<Scalars['String']>;
  totalPoints?: InputMaybe<Scalars['Float']>;
  reviewStatus?: InputMaybe<Scalars['Boolean']>;
  resultLetters?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  result?: InputMaybe<Enum_Diplomatereevaluation_Result>;
  resultComment?: InputMaybe<Scalars['String']>;
  resultNotes?: InputMaybe<Scalars['String']>;
  reevaluationPointDetailsReviewer1Accepted?: InputMaybe<Scalars['Boolean']>;
  reevaluationPointDetailsReviewer2Accepted?: InputMaybe<Scalars['Boolean']>;
  reevaluationPointDetailsAccepted?: InputMaybe<Scalars['Boolean']>;
  reevaluationPointDetailsComment?: InputMaybe<Scalars['String']>;
  reevaluationPointDetailsReviewer1Comment?: InputMaybe<Scalars['String']>;
  reevaluationPointDetailsReviewer2Comment?: InputMaybe<Scalars['String']>;
  reevaluationPointsReviewer1Accepted?: InputMaybe<Scalars['Boolean']>;
  reevaluationPointsReviewer2Accepted?: InputMaybe<Scalars['Boolean']>;
  reevaluationPointsReviewer1Comment?: InputMaybe<Scalars['String']>;
  reevaluationPointsReviewer2Comment?: InputMaybe<Scalars['String']>;
  reevaluationPointsComment?: InputMaybe<Scalars['String']>;
  reevaluationPointsAccepted?: InputMaybe<Scalars['Boolean']>;
  ebvsRequirementsAccepted?: InputMaybe<Scalars['Boolean']>;
  ebvsRequirementsComment?: InputMaybe<Scalars['String']>;
  ebvsRequirementsReviewer1Comment?: InputMaybe<Scalars['String']>;
  ebvsRequirementsReviewer2Comment?: InputMaybe<Scalars['String']>;
  ebvsRequirementsReviewer1Accepted?: InputMaybe<Scalars['Boolean']>;
  ebvsRequirementsReviewer2Accepted?: InputMaybe<Scalars['Boolean']>;
  summaryInfoAccepted?: InputMaybe<Scalars['Boolean']>;
  summaryInfoComment?: InputMaybe<Scalars['String']>;
  summaryInfoReviewer1Accepted?: InputMaybe<Scalars['Boolean']>;
  summaryInfoReviewer2Accepted?: InputMaybe<Scalars['Boolean']>;
  summaryInfoReviewer1Comment?: InputMaybe<Scalars['String']>;
  summaryInfoReviewer2Comment?: InputMaybe<Scalars['String']>;
  submittedDocuments?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type DiplomateReevaluation = {
  __typename?: 'DiplomateReevaluation';
  diplomate?: Maybe<MemberEntityResponse>;
  reviewer1?: Maybe<MemberEntityResponse>;
  speciality?: Maybe<ComponentMasterdataSpeciality>;
  reviewer1Status?: Maybe<Scalars['Boolean']>;
  reviewer2Status?: Maybe<Scalars['Boolean']>;
  reevaluationDate?: Maybe<Scalars['Date']>;
  reviewer2?: Maybe<MemberEntityResponse>;
  workHoursPerWeek?: Maybe<Scalars['Int']>;
  workDescription?: Maybe<Scalars['String']>;
  agmAttendanceYears?: Maybe<Array<Maybe<ComponentMasterdataYear>>>;
  unfulfillmentReasons?: Maybe<Scalars['String']>;
  agmAttendancePoints?: Maybe<Scalars['Float']>;
  examQuestionContributionPoints?: Maybe<Scalars['Float']>;
  committeeWorkPoints?: Maybe<Scalars['Float']>;
  residentSupervisionPoints?: Maybe<Scalars['Float']>;
  presentationPoints?: Maybe<Scalars['Float']>;
  publicationPoints?: Maybe<Scalars['Float']>;
  peerReviewingPoints?: Maybe<Scalars['Float']>;
  additionalActivityPoints?: Maybe<Scalars['Float']>;
  collegeActivityParticipationPoints?: Maybe<Scalars['Float']>;
  reevaluationPointDetails?: Maybe<UploadFileEntityResponse>;
  workTypes?: Maybe<Array<Maybe<ComponentMasterdataWorkSector>>>;
  reevaluationYear: Scalars['Int'];
  reevaluationPeriodStart?: Maybe<Scalars['Date']>;
  reevaluationPeriodEnd?: Maybe<Scalars['Date']>;
  reevaluationSubmissionDate?: Maybe<Scalars['Date']>;
  declarationAccepted?: Maybe<Scalars['Boolean']>;
  requestedDiplomateStatus?: Maybe<Enum_Diplomatereevaluation_Requesteddiplomatestatus>;
  additionalActivityPointsExplanation?: Maybe<Scalars['String']>;
  conferenceOrWebinarAttendancePoints?: Maybe<Scalars['Float']>;
  totalPoints?: Maybe<Scalars['Float']>;
  reviewStatus?: Maybe<Scalars['Boolean']>;
  resultLetters?: Maybe<UploadFileRelationResponseCollection>;
  result?: Maybe<Enum_Diplomatereevaluation_Result>;
  resultComment?: Maybe<Scalars['String']>;
  resultNotes?: Maybe<Scalars['String']>;
  reevaluationPointDetailsReviewer1Accepted?: Maybe<Scalars['Boolean']>;
  reevaluationPointDetailsReviewer2Accepted?: Maybe<Scalars['Boolean']>;
  reevaluationPointDetailsAccepted?: Maybe<Scalars['Boolean']>;
  reevaluationPointDetailsComment?: Maybe<Scalars['String']>;
  reevaluationPointDetailsReviewer1Comment?: Maybe<Scalars['String']>;
  reevaluationPointDetailsReviewer2Comment?: Maybe<Scalars['String']>;
  reevaluationPointsReviewer1Accepted?: Maybe<Scalars['Boolean']>;
  reevaluationPointsReviewer2Accepted?: Maybe<Scalars['Boolean']>;
  reevaluationPointsReviewer1Comment?: Maybe<Scalars['String']>;
  reevaluationPointsReviewer2Comment?: Maybe<Scalars['String']>;
  reevaluationPointsComment?: Maybe<Scalars['String']>;
  reevaluationPointsAccepted?: Maybe<Scalars['Boolean']>;
  ebvsRequirementsAccepted?: Maybe<Scalars['Boolean']>;
  ebvsRequirementsComment?: Maybe<Scalars['String']>;
  ebvsRequirementsReviewer1Comment?: Maybe<Scalars['String']>;
  ebvsRequirementsReviewer2Comment?: Maybe<Scalars['String']>;
  ebvsRequirementsReviewer1Accepted?: Maybe<Scalars['Boolean']>;
  ebvsRequirementsReviewer2Accepted?: Maybe<Scalars['Boolean']>;
  summaryInfoAccepted?: Maybe<Scalars['Boolean']>;
  summaryInfoComment?: Maybe<Scalars['String']>;
  summaryInfoReviewer1Accepted?: Maybe<Scalars['Boolean']>;
  summaryInfoReviewer2Accepted?: Maybe<Scalars['Boolean']>;
  summaryInfoReviewer1Comment?: Maybe<Scalars['String']>;
  summaryInfoReviewer2Comment?: Maybe<Scalars['String']>;
  submittedDocuments?: Maybe<UploadFileRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DiplomateReevaluationAgmAttendanceYearsArgs = {
  filters?: InputMaybe<ComponentMasterdataYearFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type DiplomateReevaluationWorkTypesArgs = {
  filters?: InputMaybe<ComponentMasterdataWorkSectorFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type DiplomateReevaluationResultLettersArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type DiplomateReevaluationSubmittedDocumentsArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type DiplomateReevaluationEntity = {
  __typename?: 'DiplomateReevaluationEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<DiplomateReevaluation>;
};

export type DiplomateReevaluationEntityResponse = {
  __typename?: 'DiplomateReevaluationEntityResponse';
  data?: Maybe<DiplomateReevaluationEntity>;
};

export type DiplomateReevaluationEntityResponseCollection = {
  __typename?: 'DiplomateReevaluationEntityResponseCollection';
  data: Array<DiplomateReevaluationEntity>;
  meta: ResponseCollectionMeta;
};

export type DiplomateReevaluationRelationResponseCollection = {
  __typename?: 'DiplomateReevaluationRelationResponseCollection';
  data: Array<DiplomateReevaluationEntity>;
};

export enum Enum_Email_To {
  Residents = 'Residents',
  Diplomates = 'Diplomates',
}

export enum Enum_Email_From {
  DiplomatesDiplomatesEcvimCaCollege = 'Diplomates_diplomates_ecvim_ca_college',
  ExamCoordinatorExaminationsEcvimCaCollege = 'exam_coordinator_examinations_ecvim_ca_college',
  ResidencyResidencyEcvimCaCollege = 'Residency_residency_ecvim_ca_college',
  SecretariatSecretariatEcvimCaCollege = 'Secretariat_secretariat_ecvim_ca_college',
}

export type EmailFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  templateReferenceId?: InputMaybe<IntFilterInput>;
  subject?: InputMaybe<StringFilterInput>;
  to?: InputMaybe<StringFilterInput>;
  sendDate?: InputMaybe<DateTimeFilterInput>;
  log?: InputMaybe<StringFilterInput>;
  from?: InputMaybe<StringFilterInput>;
  content?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<EmailFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<EmailFiltersInput>>>;
  not?: InputMaybe<EmailFiltersInput>;
};

export type EmailInput = {
  templateReferenceId?: InputMaybe<Scalars['Int']>;
  subject?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Enum_Email_To>;
  sendDate?: InputMaybe<Scalars['DateTime']>;
  log?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Enum_Email_From>;
  attachments?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  content?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type Email = {
  __typename?: 'Email';
  templateReferenceId: Scalars['Int'];
  subject?: Maybe<Scalars['String']>;
  to: Enum_Email_To;
  sendDate?: Maybe<Scalars['DateTime']>;
  log?: Maybe<Scalars['String']>;
  from: Enum_Email_From;
  attachments?: Maybe<UploadFileRelationResponseCollection>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailAttachmentsArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type EmailEntity = {
  __typename?: 'EmailEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Email>;
};

export type EmailEntityResponse = {
  __typename?: 'EmailEntityResponse';
  data?: Maybe<EmailEntity>;
};

export type EmailEntityResponseCollection = {
  __typename?: 'EmailEntityResponseCollection';
  data: Array<EmailEntity>;
  meta: ResponseCollectionMeta;
};

export enum Enum_Generalexam_Result {
  Passed = 'Passed',
  Fail = 'Fail',
  StepBack = 'Step_Back',
}

export type GeneralExamFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  result?: InputMaybe<StringFilterInput>;
  status?: InputMaybe<BooleanFilterInput>;
  resident?: InputMaybe<MemberFiltersInput>;
  attempt?: InputMaybe<IntFilterInput>;
  termsAndConditionsAccepted?: InputMaybe<BooleanFilterInput>;
  payments?: InputMaybe<PaymentFiltersInput>;
  privacyPolicyAccepted?: InputMaybe<BooleanFilterInput>;
  programmeDirectorLetterComment?: InputMaybe<StringFilterInput>;
  programmeDirectorLetterAccepted?: InputMaybe<BooleanFilterInput>;
  caseSummaryComment?: InputMaybe<StringFilterInput>;
  caseSummaryReviewer1Notes?: InputMaybe<StringFilterInput>;
  curriculumVitaeComment?: InputMaybe<StringFilterInput>;
  curriculumVitaeAccepted?: InputMaybe<BooleanFilterInput>;
  previousCorrespondenceComment?: InputMaybe<StringFilterInput>;
  previousCorrespondenceAccepted?: InputMaybe<BooleanFilterInput>;
  reviewer1?: InputMaybe<MemberFiltersInput>;
  reviewer2?: InputMaybe<MemberFiltersInput>;
  caseSummaryReviewer2Notes?: InputMaybe<StringFilterInput>;
  caseSummaryReviewer1Comment?: InputMaybe<StringFilterInput>;
  caseSummaryReviewer2Comment?: InputMaybe<StringFilterInput>;
  reviewer1Status?: InputMaybe<BooleanFilterInput>;
  reviewer2Status?: InputMaybe<BooleanFilterInput>;
  speciality?: InputMaybe<ComponentMasterdataSpecialityFiltersInput>;
  reviewStatus?: InputMaybe<BooleanFilterInput>;
  specialityString?: InputMaybe<StringFilterInput>;
  examYear?: InputMaybe<IntFilterInput>;
  confidentialityAgreementAccepted?: InputMaybe<BooleanFilterInput>;
  applicationResultPublished?: InputMaybe<BooleanFilterInput>;
  caseSummaryReviewStatus?: InputMaybe<BooleanFilterInput>;
  caseSummaryReviewPublished?: InputMaybe<BooleanFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<GeneralExamFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<GeneralExamFiltersInput>>>;
  not?: InputMaybe<GeneralExamFiltersInput>;
};

export type GeneralExamInput = {
  result?: InputMaybe<Enum_Generalexam_Result>;
  status?: InputMaybe<Scalars['Boolean']>;
  resident?: InputMaybe<Scalars['ID']>;
  attempt?: InputMaybe<Scalars['Int']>;
  caseSummary?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  curriculumVitae?: InputMaybe<Scalars['ID']>;
  programmeDirectorLetter?: InputMaybe<Scalars['ID']>;
  previousCorrespondence?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  termsAndConditionsAccepted?: InputMaybe<Scalars['Boolean']>;
  payments?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  privacyPolicyAccepted?: InputMaybe<Scalars['Boolean']>;
  programmeDirectorLetterComment?: InputMaybe<Scalars['String']>;
  programmeDirectorLetterAccepted?: InputMaybe<Scalars['Boolean']>;
  caseSummaryComment?: InputMaybe<Scalars['String']>;
  caseSummaryReviewer1Notes?: InputMaybe<Scalars['String']>;
  curriculumVitaeComment?: InputMaybe<Scalars['String']>;
  curriculumVitaeAccepted?: InputMaybe<Scalars['Boolean']>;
  previousCorrespondenceComment?: InputMaybe<Scalars['String']>;
  previousCorrespondenceAccepted?: InputMaybe<Scalars['Boolean']>;
  reviewer1?: InputMaybe<Scalars['ID']>;
  reviewer2?: InputMaybe<Scalars['ID']>;
  caseSummaryReviewer2Notes?: InputMaybe<Scalars['String']>;
  caseSummaryReviewer1Comment?: InputMaybe<Scalars['String']>;
  caseSummaryReviewer2Comment?: InputMaybe<Scalars['String']>;
  reviewer1Status?: InputMaybe<Scalars['Boolean']>;
  reviewer2Status?: InputMaybe<Scalars['Boolean']>;
  speciality?: InputMaybe<ComponentMasterdataSpecialityInput>;
  reviewStatus?: InputMaybe<Scalars['Boolean']>;
  applicationResultLetters?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  caseSummaryConclusionLetter?: InputMaybe<Scalars['ID']>;
  specialityString?: InputMaybe<Scalars['String']>;
  examYear?: InputMaybe<Scalars['Int']>;
  confidentialityAgreementAccepted?: InputMaybe<Scalars['Boolean']>;
  applicationResultPublished?: InputMaybe<Scalars['Boolean']>;
  caseSummaryReviewStatus?: InputMaybe<Scalars['Boolean']>;
  caseSummaryReviewPublished?: InputMaybe<Scalars['Boolean']>;
};

export type GeneralExam = {
  __typename?: 'GeneralExam';
  result?: Maybe<Enum_Generalexam_Result>;
  status?: Maybe<Scalars['Boolean']>;
  resident?: Maybe<MemberEntityResponse>;
  attempt: Scalars['Int'];
  caseSummary?: Maybe<UploadFileRelationResponseCollection>;
  curriculumVitae?: Maybe<UploadFileEntityResponse>;
  programmeDirectorLetter?: Maybe<UploadFileEntityResponse>;
  previousCorrespondence?: Maybe<UploadFileRelationResponseCollection>;
  termsAndConditionsAccepted?: Maybe<Scalars['Boolean']>;
  payments?: Maybe<PaymentRelationResponseCollection>;
  privacyPolicyAccepted?: Maybe<Scalars['Boolean']>;
  programmeDirectorLetterComment?: Maybe<Scalars['String']>;
  programmeDirectorLetterAccepted?: Maybe<Scalars['Boolean']>;
  caseSummaryComment?: Maybe<Scalars['String']>;
  caseSummaryReviewer1Notes?: Maybe<Scalars['String']>;
  curriculumVitaeComment?: Maybe<Scalars['String']>;
  curriculumVitaeAccepted?: Maybe<Scalars['Boolean']>;
  previousCorrespondenceComment?: Maybe<Scalars['String']>;
  previousCorrespondenceAccepted?: Maybe<Scalars['Boolean']>;
  reviewer1?: Maybe<MemberEntityResponse>;
  reviewer2?: Maybe<MemberEntityResponse>;
  caseSummaryReviewer2Notes?: Maybe<Scalars['String']>;
  caseSummaryReviewer1Comment?: Maybe<Scalars['String']>;
  caseSummaryReviewer2Comment?: Maybe<Scalars['String']>;
  reviewer1Status?: Maybe<Scalars['Boolean']>;
  reviewer2Status?: Maybe<Scalars['Boolean']>;
  speciality?: Maybe<ComponentMasterdataSpeciality>;
  reviewStatus?: Maybe<Scalars['Boolean']>;
  applicationResultLetters?: Maybe<UploadFileRelationResponseCollection>;
  caseSummaryConclusionLetter?: Maybe<UploadFileEntityResponse>;
  examYear: Scalars['Int'];
  confidentialityAgreementAccepted?: Maybe<Scalars['Boolean']>;
  applicationResultPublished: Scalars['Boolean'];
  caseSummaryReviewStatus?: Maybe<Scalars['Boolean']>;
  caseSummaryReviewPublished: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GeneralExamCaseSummaryArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type GeneralExamPreviousCorrespondenceArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type GeneralExamPaymentsArgs = {
  filters?: InputMaybe<PaymentFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type GeneralExamApplicationResultLettersArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type GeneralExamEntity = {
  __typename?: 'GeneralExamEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<GeneralExam>;
};

export type GeneralExamEntityResponse = {
  __typename?: 'GeneralExamEntityResponse';
  data?: Maybe<GeneralExamEntity>;
};

export type GeneralExamEntityResponseCollection = {
  __typename?: 'GeneralExamEntityResponseCollection';
  data: Array<GeneralExamEntity>;
  meta: ResponseCollectionMeta;
};

export type GeneralExamRelationResponseCollection = {
  __typename?: 'GeneralExamRelationResponseCollection';
  data: Array<GeneralExamEntity>;
};

export type GeneralExamToolInput = {
  publishApplicationResults?: InputMaybe<Scalars['Boolean']>;
  publishApplicationResultsLog?: InputMaybe<Scalars['String']>;
  examInfos?: InputMaybe<Array<InputMaybe<ComponentMasterdataGeneralExamInfoInput>>>;
  publishCaseSummaryReviewResults?: InputMaybe<Scalars['Boolean']>;
  publishCaseSummaryReviewResultsLog?: InputMaybe<Scalars['String']>;
};

export type GeneralExamTool = {
  __typename?: 'GeneralExamTool';
  publishApplicationResults: Scalars['Boolean'];
  examInfos?: Maybe<Array<Maybe<ComponentMasterdataGeneralExamInfo>>>;
  publishCaseSummaryReviewResults: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GeneralExamToolExamInfosArgs = {
  filters?: InputMaybe<ComponentMasterdataGeneralExamInfoFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type GeneralExamToolEntity = {
  __typename?: 'GeneralExamToolEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<GeneralExamTool>;
};

export type GeneralExamToolEntityResponse = {
  __typename?: 'GeneralExamToolEntityResponse';
  data?: Maybe<GeneralExamToolEntity>;
};

export type InstitutionFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  Address?: InputMaybe<ComponentMasterdataAddressFiltersInput>;
  EAEVEApproved?: InputMaybe<BooleanFilterInput>;
  Name?: InputMaybe<StringFilterInput>;
  Private?: InputMaybe<BooleanFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<InstitutionFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<InstitutionFiltersInput>>>;
  not?: InputMaybe<InstitutionFiltersInput>;
};

export type InstitutionInput = {
  Address?: InputMaybe<ComponentMasterdataAddressInput>;
  EAEVEApproved?: InputMaybe<Scalars['Boolean']>;
  Name?: InputMaybe<Scalars['String']>;
  Private?: InputMaybe<Scalars['Boolean']>;
};

export type Institution = {
  __typename?: 'Institution';
  Address?: Maybe<ComponentMasterdataAddress>;
  EAEVEApproved?: Maybe<Scalars['Boolean']>;
  Name?: Maybe<Scalars['String']>;
  Private?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InstitutionEntity = {
  __typename?: 'InstitutionEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Institution>;
};

export type InstitutionEntityResponse = {
  __typename?: 'InstitutionEntityResponse';
  data?: Maybe<InstitutionEntity>;
};

export type InstitutionEntityResponseCollection = {
  __typename?: 'InstitutionEntityResponseCollection';
  data: Array<InstitutionEntity>;
  meta: ResponseCollectionMeta;
};

export enum Enum_Member_Title {
  Mr = 'Mr',
  Ms = 'Ms',
  Mrs = 'Mrs',
  Mx = 'Mx',
  Dr = 'Dr',
  Prof = 'Prof',
}

export enum Enum_Member_Status {
  Active = 'Active',
  Deceased = 'Deceased',
  VotedOut = 'Voted_out',
}

export type MemberRoleDynamicZone = ComponentMasterdataResident | ComponentMasterdataDiplomat | Error;

export type MemberFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  Name?: InputMaybe<StringFilterInput>;
  Surname?: InputMaybe<StringFilterInput>;
  User?: InputMaybe<UsersPermissionsUserFiltersInput>;
  address?: InputMaybe<ComponentMasterdataAddressFiltersInput>;
  email?: InputMaybe<StringFilterInput>;
  Speciality?: InputMaybe<ComponentMasterdataSpecialityFiltersInput>;
  GraduationSchool?: InputMaybe<InstitutionFiltersInput>;
  Employer?: InputMaybe<InstitutionFiltersInput>;
  Fullname?: InputMaybe<StringFilterInput>;
  Comiteechairs?: InputMaybe<ComiteeFiltersInput>;
  ComiteeMembers?: InputMaybe<ComiteeFiltersInput>;
  payments?: InputMaybe<PaymentFiltersInput>;
  title?: InputMaybe<StringFilterInput>;
  middlename?: InputMaybe<StringFilterInput>;
  citizenship?: InputMaybe<StringFilterInput>;
  directorships?: InputMaybe<ProgrammeFiltersInput>;
  codirectorships?: InputMaybe<ProgrammeFiltersInput>;
  enrolment?: InputMaybe<ProgrammeFiltersInput>;
  role?: InputMaybe<StringFilterInput>;
  speciality?: InputMaybe<StringFilterInput>;
  certifying_exams?: InputMaybe<CertifyingExamFiltersInput>;
  general_exams?: InputMaybe<GeneralExamFiltersInput>;
  termsAndConditionsAccepted?: InputMaybe<BooleanFilterInput>;
  privacyPolicyAccepted?: InputMaybe<BooleanFilterInput>;
  certifyingExamsAsReviewer1?: InputMaybe<CertifyingExamFiltersInput>;
  certifyingExamsAsReviewer2?: InputMaybe<CertifyingExamFiltersInput>;
  generalExamsAsReviewer1?: InputMaybe<GeneralExamFiltersInput>;
  generalExamsAsReviewer2?: InputMaybe<GeneralExamFiltersInput>;
  diplomateReevaluations?: InputMaybe<DiplomateReevaluationFiltersInput>;
  diplomateReevaluationsAsReviewer1?: InputMaybe<DiplomateReevaluationFiltersInput>;
  diplomateReevaluationsAsReviewer2?: InputMaybe<DiplomateReevaluationFiltersInput>;
  residencyStart?: InputMaybe<DateFilterInput>;
  residencyEnd?: InputMaybe<DateFilterInput>;
  nextReevaluation?: InputMaybe<DateFilterInput>;
  status?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<MemberFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<MemberFiltersInput>>>;
  not?: InputMaybe<MemberFiltersInput>;
};

export type MemberInput = {
  Name?: InputMaybe<Scalars['String']>;
  Surname?: InputMaybe<Scalars['String']>;
  User?: InputMaybe<Scalars['ID']>;
  address?: InputMaybe<ComponentMasterdataAddressInput>;
  email?: InputMaybe<Scalars['String']>;
  Speciality?: InputMaybe<Array<InputMaybe<ComponentMasterdataSpecialityInput>>>;
  Role?: InputMaybe<Array<Scalars['MemberRoleDynamicZoneInput']>>;
  GraduationSchool?: InputMaybe<Scalars['ID']>;
  Employer?: InputMaybe<Scalars['ID']>;
  Fullname?: InputMaybe<Scalars['String']>;
  Comiteechairs?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  ComiteeMembers?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  payments?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  title?: InputMaybe<Enum_Member_Title>;
  middlename?: InputMaybe<Scalars['String']>;
  citizenship?: InputMaybe<Scalars['String']>;
  directorships?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  codirectorships?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  enrolment?: InputMaybe<Scalars['ID']>;
  role?: InputMaybe<Scalars['String']>;
  speciality?: InputMaybe<Scalars['String']>;
  certifying_exams?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  general_exams?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  termsAndConditionsAccepted?: InputMaybe<Scalars['Boolean']>;
  privacyPolicyAccepted?: InputMaybe<Scalars['Boolean']>;
  certifyingExamsAsReviewer1?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  certifyingExamsAsReviewer2?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  generalExamsAsReviewer1?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  generalExamsAsReviewer2?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  diplomateReevaluations?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  diplomateReevaluationsAsReviewer1?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  diplomateReevaluationsAsReviewer2?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  residencyStart?: InputMaybe<Scalars['Date']>;
  residencyEnd?: InputMaybe<Scalars['Date']>;
  nextReevaluation?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<Enum_Member_Status>;
};

export type Member = {
  __typename?: 'Member';
  Name: Scalars['String'];
  Surname: Scalars['String'];
  User?: Maybe<UsersPermissionsUserEntityResponse>;
  address?: Maybe<ComponentMasterdataAddress>;
  email: Scalars['String'];
  Speciality?: Maybe<Array<Maybe<ComponentMasterdataSpeciality>>>;
  Role?: Maybe<Array<Maybe<MemberRoleDynamicZone>>>;
  GraduationSchool?: Maybe<InstitutionEntityResponse>;
  Employer?: Maybe<InstitutionEntityResponse>;
  Comiteechairs?: Maybe<ComiteeRelationResponseCollection>;
  ComiteeMembers?: Maybe<ComiteeRelationResponseCollection>;
  payments?: Maybe<PaymentRelationResponseCollection>;
  title?: Maybe<Enum_Member_Title>;
  middlename?: Maybe<Scalars['String']>;
  citizenship?: Maybe<Scalars['String']>;
  directorships?: Maybe<ProgrammeRelationResponseCollection>;
  codirectorships?: Maybe<ProgrammeRelationResponseCollection>;
  enrolment?: Maybe<ProgrammeEntityResponse>;
  certifying_exams?: Maybe<CertifyingExamRelationResponseCollection>;
  general_exams?: Maybe<GeneralExamRelationResponseCollection>;
  termsAndConditionsAccepted?: Maybe<Scalars['Boolean']>;
  privacyPolicyAccepted?: Maybe<Scalars['Boolean']>;
  certifyingExamsAsReviewer1?: Maybe<CertifyingExamRelationResponseCollection>;
  certifyingExamsAsReviewer2?: Maybe<CertifyingExamRelationResponseCollection>;
  generalExamsAsReviewer1?: Maybe<GeneralExamRelationResponseCollection>;
  generalExamsAsReviewer2?: Maybe<GeneralExamRelationResponseCollection>;
  diplomateReevaluations?: Maybe<DiplomateReevaluationRelationResponseCollection>;
  diplomateReevaluationsAsReviewer1?: Maybe<DiplomateReevaluationRelationResponseCollection>;
  diplomateReevaluationsAsReviewer2?: Maybe<DiplomateReevaluationRelationResponseCollection>;
  status: Enum_Member_Status;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MemberSpecialityArgs = {
  filters?: InputMaybe<ComponentMasterdataSpecialityFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MemberComiteechairsArgs = {
  filters?: InputMaybe<ComiteeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MemberComiteeMembersArgs = {
  filters?: InputMaybe<ComiteeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MemberPaymentsArgs = {
  filters?: InputMaybe<PaymentFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MemberDirectorshipsArgs = {
  filters?: InputMaybe<ProgrammeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MemberCodirectorshipsArgs = {
  filters?: InputMaybe<ProgrammeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MemberCertifying_ExamsArgs = {
  filters?: InputMaybe<CertifyingExamFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MemberGeneral_ExamsArgs = {
  filters?: InputMaybe<GeneralExamFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MemberCertifyingExamsAsReviewer1Args = {
  filters?: InputMaybe<CertifyingExamFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MemberCertifyingExamsAsReviewer2Args = {
  filters?: InputMaybe<CertifyingExamFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MemberGeneralExamsAsReviewer1Args = {
  filters?: InputMaybe<GeneralExamFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MemberGeneralExamsAsReviewer2Args = {
  filters?: InputMaybe<GeneralExamFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MemberDiplomateReevaluationsArgs = {
  filters?: InputMaybe<DiplomateReevaluationFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MemberDiplomateReevaluationsAsReviewer1Args = {
  filters?: InputMaybe<DiplomateReevaluationFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MemberDiplomateReevaluationsAsReviewer2Args = {
  filters?: InputMaybe<DiplomateReevaluationFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MemberEntity = {
  __typename?: 'MemberEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Member>;
};

export type MemberEntityResponse = {
  __typename?: 'MemberEntityResponse';
  data?: Maybe<MemberEntity>;
};

export type MemberEntityResponseCollection = {
  __typename?: 'MemberEntityResponseCollection';
  data: Array<MemberEntity>;
  meta: ResponseCollectionMeta;
};

export type MemberRelationResponseCollection = {
  __typename?: 'MemberRelationResponseCollection';
  data: Array<MemberEntity>;
};

export type NewsitemFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  Title?: InputMaybe<StringFilterInput>;
  Content?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<NewsitemFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<NewsitemFiltersInput>>>;
  not?: InputMaybe<NewsitemFiltersInput>;
};

export type NewsitemInput = {
  Title?: InputMaybe<Scalars['String']>;
  Content?: InputMaybe<Scalars['String']>;
  Picture?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type Newsitem = {
  __typename?: 'Newsitem';
  Title?: Maybe<Scalars['String']>;
  Content?: Maybe<Scalars['String']>;
  Picture?: Maybe<UploadFileRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type NewsitemPictureArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type NewsitemEntity = {
  __typename?: 'NewsitemEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Newsitem>;
};

export type NewsitemEntityResponse = {
  __typename?: 'NewsitemEntityResponse';
  data?: Maybe<NewsitemEntity>;
};

export type NewsitemEntityResponseCollection = {
  __typename?: 'NewsitemEntityResponseCollection';
  data: Array<NewsitemEntity>;
  meta: ResponseCollectionMeta;
};

export type PageFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  content?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<PageFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<PageFiltersInput>>>;
  not?: InputMaybe<PageFiltersInput>;
};

export type PageInput = {
  title?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type Page = {
  __typename?: 'Page';
  title: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type PageEntity = {
  __typename?: 'PageEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Page>;
};

export type PageEntityResponse = {
  __typename?: 'PageEntityResponse';
  data?: Maybe<PageEntity>;
};

export type PageEntityResponseCollection = {
  __typename?: 'PageEntityResponseCollection';
  data: Array<PageEntity>;
  meta: ResponseCollectionMeta;
};

export enum Enum_Payment_Type {
  MembershipFee = 'Membership_Fee',
  MembershipFeeNonCertified = 'Membership_Fee_Non_certified',
  ResidentEnrolmentFee = 'Resident_Enrolment_Fee',
  AppealsFee = 'Appeals_Fee',
  AcvimCertificationFee = 'ACVIM_Certification_Fee',
  CredentialsFee = 'Credentials_Fee',
  CredentialsFeeResubmitter = 'Credentials_Fee_Resubmitter',
  CertifyingExamFee = 'Certifying_Exam_Fee',
  CertifyingExamRegistrationFee = 'Certifying_Exam_Registration_Fee',
  CertifyingExamFeeResitter = 'Certifying_Exam_Fee_Resitter',
  CertifyingExamRegistrationFeeResitter = 'Certifying_Exam_Registration_Fee_Resitter',
  GeneralExamFee = 'General_Exam_Fee',
  GeneralExamRegistrationFee = 'General_Exam_Registration_Fee',
  Other = 'Other',
}

export enum Enum_Payment_Currency {
  Eur = 'EUR',
}

export type PaymentFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  type?: InputMaybe<StringFilterInput>;
  paid?: InputMaybe<BooleanFilterInput>;
  payer?: InputMaybe<MemberFiltersInput>;
  subject?: InputMaybe<StringFilterInput>;
  duedate?: InputMaybe<DateFilterInput>;
  paymentdate?: InputMaybe<DateFilterInput>;
  displaytext?: InputMaybe<StringFilterInput>;
  paypalorderid?: InputMaybe<StringFilterInput>;
  currency?: InputMaybe<StringFilterInput>;
  amount?: InputMaybe<FloatFilterInput>;
  invoicenumber?: InputMaybe<StringFilterInput>;
  certifying_exam?: InputMaybe<CertifyingExamFiltersInput>;
  general_exam?: InputMaybe<GeneralExamFiltersInput>;
  paypalTransactionIds?: InputMaybe<StringFilterInput>;
  canceled?: InputMaybe<BooleanFilterInput>;
  paymentItems?: InputMaybe<ComponentMasterdataPaymentItemsFiltersInput>;
  totalAmount?: InputMaybe<FloatFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<PaymentFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<PaymentFiltersInput>>>;
  not?: InputMaybe<PaymentFiltersInput>;
};

export type PaymentInput = {
  type?: InputMaybe<Enum_Payment_Type>;
  paid?: InputMaybe<Scalars['Boolean']>;
  payer?: InputMaybe<Scalars['ID']>;
  subject?: InputMaybe<Scalars['String']>;
  duedate?: InputMaybe<Scalars['Date']>;
  paymentdate?: InputMaybe<Scalars['Date']>;
  displaytext?: InputMaybe<Scalars['String']>;
  paypalorderid?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Enum_Payment_Currency>;
  amount?: InputMaybe<Scalars['Float']>;
  invoicenumber?: InputMaybe<Scalars['String']>;
  invoice?: InputMaybe<Scalars['ID']>;
  certifying_exam?: InputMaybe<Scalars['ID']>;
  general_exam?: InputMaybe<Scalars['ID']>;
  paypalTransactionIds?: InputMaybe<Scalars['String']>;
  canceled?: InputMaybe<Scalars['Boolean']>;
  cancellationDetails?: InputMaybe<Scalars['ID']>;
  paymentItems?: InputMaybe<Array<InputMaybe<ComponentMasterdataPaymentItemsInput>>>;
  totalAmount?: InputMaybe<Scalars['Float']>;
};

export type Payment = {
  __typename?: 'Payment';
  type: Enum_Payment_Type;
  paid?: Maybe<Scalars['Boolean']>;
  payer?: Maybe<MemberEntityResponse>;
  subject: Scalars['String'];
  duedate?: Maybe<Scalars['Date']>;
  paymentdate?: Maybe<Scalars['Date']>;
  displaytext?: Maybe<Scalars['String']>;
  currency: Enum_Payment_Currency;
  amount: Scalars['Float'];
  invoicenumber?: Maybe<Scalars['String']>;
  invoice?: Maybe<UploadFileEntityResponse>;
  certifying_exam?: Maybe<CertifyingExamEntityResponse>;
  general_exam?: Maybe<GeneralExamEntityResponse>;
  paypalTransactionIds?: Maybe<Scalars['String']>;
  canceled?: Maybe<Scalars['Boolean']>;
  cancellationDetails?: Maybe<UploadFileEntityResponse>;
  paymentItems?: Maybe<Array<Maybe<ComponentMasterdataPaymentItems>>>;
  totalAmount?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentPaymentItemsArgs = {
  filters?: InputMaybe<ComponentMasterdataPaymentItemsFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PaymentEntity = {
  __typename?: 'PaymentEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Payment>;
};

export type PaymentEntityResponse = {
  __typename?: 'PaymentEntityResponse';
  data?: Maybe<PaymentEntity>;
};

export type PaymentEntityResponseCollection = {
  __typename?: 'PaymentEntityResponseCollection';
  data: Array<PaymentEntity>;
  meta: ResponseCollectionMeta;
};

export type PaymentRelationResponseCollection = {
  __typename?: 'PaymentRelationResponseCollection';
  data: Array<PaymentEntity>;
};

export enum Enum_Programme_Type {
  Standard = 'Standard',
  Alternative = 'Alternative',
}

export enum Enum_Programme_Status {
  Active = 'Active',
  Inactive = 'Inactive',
  Completed = 'Completed',
  Suspended = 'Suspended',
  Closed = 'Closed',
}

export type ProgrammeFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  Type?: InputMaybe<StringFilterInput>;
  Address?: InputMaybe<ComponentMasterdataAddressFiltersInput>;
  Speciality?: InputMaybe<ComponentMasterdataSpecialityFiltersInput>;
  Name?: InputMaybe<StringFilterInput>;
  status?: InputMaybe<StringFilterInput>;
  enrolees?: InputMaybe<MemberFiltersInput>;
  codirector?: InputMaybe<MemberFiltersInput>;
  director?: InputMaybe<MemberFiltersInput>;
  specialityString?: InputMaybe<StringFilterInput>;
  country?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ProgrammeFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<ProgrammeFiltersInput>>>;
  not?: InputMaybe<ProgrammeFiltersInput>;
};

export type ProgrammeInput = {
  Type?: InputMaybe<Enum_Programme_Type>;
  Address?: InputMaybe<ComponentMasterdataAddressInput>;
  Speciality?: InputMaybe<ComponentMasterdataSpecialityInput>;
  Name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Enum_Programme_Status>;
  enrolees?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  codirector?: InputMaybe<Scalars['ID']>;
  director?: InputMaybe<Scalars['ID']>;
  specialityString?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
};

export type Programme = {
  __typename?: 'Programme';
  Type?: Maybe<Enum_Programme_Type>;
  Address?: Maybe<ComponentMasterdataAddress>;
  Speciality?: Maybe<ComponentMasterdataSpeciality>;
  Name?: Maybe<Scalars['String']>;
  status?: Maybe<Enum_Programme_Status>;
  enrolees?: Maybe<MemberRelationResponseCollection>;
  codirector?: Maybe<MemberEntityResponse>;
  director?: Maybe<MemberEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProgrammeEnroleesArgs = {
  filters?: InputMaybe<MemberFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ProgrammeEntity = {
  __typename?: 'ProgrammeEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Programme>;
};

export type ProgrammeEntityResponse = {
  __typename?: 'ProgrammeEntityResponse';
  data?: Maybe<ProgrammeEntity>;
};

export type ProgrammeEntityResponseCollection = {
  __typename?: 'ProgrammeEntityResponseCollection';
  data: Array<ProgrammeEntity>;
  meta: ResponseCollectionMeta;
};

export type ProgrammeRelationResponseCollection = {
  __typename?: 'ProgrammeRelationResponseCollection';
  data: Array<ProgrammeEntity>;
};

export type GenericMorph =
  | ComponentMasterdataAddress
  | ComponentMasterdataCase
  | ComponentMasterdataCertifyingExamInfo
  | ComponentMasterdataCredential
  | ComponentMasterdataDate
  | ComponentMasterdataDiplomat
  | ComponentMasterdataGeneralExamInfo
  | ComponentMasterdataNote
  | ComponentMasterdataPaymentItems
  | ComponentMasterdataResident
  | ComponentMasterdataSpeciality
  | ComponentMasterdataStatuschange
  | ComponentMasterdataWorkSector
  | ComponentMasterdataYear
  | UploadFile
  | UploadFolder
  | EmailDesignerEmailTemplate
  | I18NLocale
  | UsersPermissionsPermission
  | UsersPermissionsRole
  | UsersPermissionsUser
  | CertifyingExam
  | CertifyingExamTool
  | Comitee
  | DataExportTool
  | DiplomateReevaluation
  | Email
  | GeneralExam
  | GeneralExamTool
  | Institution
  | Member
  | Newsitem
  | Page
  | Payment
  | Programme;

export type FileInfoInput = {
  name?: InputMaybe<Scalars['String']>;
  alternativeText?: InputMaybe<Scalars['String']>;
  caption?: InputMaybe<Scalars['String']>;
};

export type UsersPermissionsMe = {
  __typename?: 'UsersPermissionsMe';
  id: Scalars['ID'];
  username: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  blocked?: Maybe<Scalars['Boolean']>;
  role?: Maybe<UsersPermissionsMeRole>;
};

export type UsersPermissionsMeRole = {
  __typename?: 'UsersPermissionsMeRole';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type UsersPermissionsRegisterInput = {
  username: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};

export type UsersPermissionsLoginInput = {
  identifier: Scalars['String'];
  password: Scalars['String'];
  provider?: Scalars['String'];
};

export type UsersPermissionsPasswordPayload = {
  __typename?: 'UsersPermissionsPasswordPayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsLoginPayload = {
  __typename?: 'UsersPermissionsLoginPayload';
  jwt?: Maybe<Scalars['String']>;
  user: UsersPermissionsMe;
};

export type UsersPermissionsCreateRolePayload = {
  __typename?: 'UsersPermissionsCreateRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsUpdateRolePayload = {
  __typename?: 'UsersPermissionsUpdateRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsDeleteRolePayload = {
  __typename?: 'UsersPermissionsDeleteRolePayload';
  ok: Scalars['Boolean'];
};

export type NavigationItemRelated = Page;

export type NavigationItemRelatedData = {
  __typename?: 'NavigationItemRelatedData';
  id: Scalars['Int'];
  attributes?: Maybe<NavigationItemRelated>;
};

export type NavigationItem = {
  __typename?: 'NavigationItem';
  id: Scalars['Int'];
  title: Scalars['String'];
  type: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  externalPath?: Maybe<Scalars['String']>;
  uiRouterKey: Scalars['String'];
  menuAttached: Scalars['Boolean'];
  order: Scalars['Int'];
  parent?: Maybe<NavigationItem>;
  master?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<NavigationItem>>>;
  related?: Maybe<NavigationItemRelatedData>;
  created_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  audience?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum NavigationRenderType {
  Flat = 'FLAT',
  Tree = 'TREE',
  Rfr = 'RFR',
}

export type PaginationArg = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  uploadFile?: Maybe<UploadFileEntityResponse>;
  uploadFiles?: Maybe<UploadFileEntityResponseCollection>;
  uploadFolder?: Maybe<UploadFolderEntityResponse>;
  uploadFolders?: Maybe<UploadFolderEntityResponseCollection>;
  emailDesignerEmailTemplate?: Maybe<EmailDesignerEmailTemplateEntityResponse>;
  emailDesignerEmailTemplates?: Maybe<EmailDesignerEmailTemplateEntityResponseCollection>;
  i18NLocale?: Maybe<I18NLocaleEntityResponse>;
  i18NLocales?: Maybe<I18NLocaleEntityResponseCollection>;
  usersPermissionsRole?: Maybe<UsersPermissionsRoleEntityResponse>;
  usersPermissionsRoles?: Maybe<UsersPermissionsRoleEntityResponseCollection>;
  usersPermissionsUser?: Maybe<UsersPermissionsUserEntityResponse>;
  usersPermissionsUsers?: Maybe<UsersPermissionsUserEntityResponseCollection>;
  certifyingExam?: Maybe<CertifyingExamEntityResponse>;
  certifyingExams?: Maybe<CertifyingExamEntityResponseCollection>;
  certifyingExamTool?: Maybe<CertifyingExamToolEntityResponse>;
  comitee?: Maybe<ComiteeEntityResponse>;
  comitees?: Maybe<ComiteeEntityResponseCollection>;
  dataExportTool?: Maybe<DataExportToolEntityResponse>;
  diplomateReevaluation?: Maybe<DiplomateReevaluationEntityResponse>;
  diplomateReevaluations?: Maybe<DiplomateReevaluationEntityResponseCollection>;
  email?: Maybe<EmailEntityResponse>;
  emails?: Maybe<EmailEntityResponseCollection>;
  generalExam?: Maybe<GeneralExamEntityResponse>;
  generalExams?: Maybe<GeneralExamEntityResponseCollection>;
  generalExamTool?: Maybe<GeneralExamToolEntityResponse>;
  institution?: Maybe<InstitutionEntityResponse>;
  institutions?: Maybe<InstitutionEntityResponseCollection>;
  member?: Maybe<MemberEntityResponse>;
  members?: Maybe<MemberEntityResponseCollection>;
  newsitem?: Maybe<NewsitemEntityResponse>;
  newsitems?: Maybe<NewsitemEntityResponseCollection>;
  page?: Maybe<PageEntityResponse>;
  pages?: Maybe<PageEntityResponseCollection>;
  payment?: Maybe<PaymentEntityResponse>;
  payments?: Maybe<PaymentEntityResponseCollection>;
  programme?: Maybe<ProgrammeEntityResponse>;
  programmes?: Maybe<ProgrammeEntityResponseCollection>;
  me?: Maybe<UsersPermissionsMe>;
  renderNavigationChild: Array<Maybe<NavigationItem>>;
  renderNavigation: Array<Maybe<NavigationItem>>;
};

export type QueryUploadFileArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryUploadFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryUploadFolderArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryUploadFoldersArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryEmailDesignerEmailTemplateArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryEmailDesignerEmailTemplatesArgs = {
  filters?: InputMaybe<EmailDesignerEmailTemplateFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryI18NLocaleArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryI18NLocalesArgs = {
  filters?: InputMaybe<I18NLocaleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryUsersPermissionsRoleArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryUsersPermissionsRolesArgs = {
  filters?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryUsersPermissionsUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryUsersPermissionsUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryCertifyingExamArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryCertifyingExamsArgs = {
  filters?: InputMaybe<CertifyingExamFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryComiteeArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryComiteesArgs = {
  filters?: InputMaybe<ComiteeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryDiplomateReevaluationArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryDiplomateReevaluationsArgs = {
  filters?: InputMaybe<DiplomateReevaluationFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryEmailArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryEmailsArgs = {
  filters?: InputMaybe<EmailFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryGeneralExamArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryGeneralExamsArgs = {
  filters?: InputMaybe<GeneralExamFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryInstitutionArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryInstitutionsArgs = {
  filters?: InputMaybe<InstitutionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryMemberArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryMembersArgs = {
  filters?: InputMaybe<MemberFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryNewsitemArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryNewsitemsArgs = {
  filters?: InputMaybe<NewsitemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryPageArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryPagesArgs = {
  filters?: InputMaybe<PageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryPaymentArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryPaymentsArgs = {
  filters?: InputMaybe<PaymentFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryProgrammeArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryProgrammesArgs = {
  filters?: InputMaybe<ProgrammeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryRenderNavigationChildArgs = {
  id: Scalars['String'];
  childUiKey: Scalars['String'];
  type?: InputMaybe<NavigationRenderType>;
  menuOnly?: InputMaybe<Scalars['Boolean']>;
};

export type QueryRenderNavigationArgs = {
  navigationIdOrSlug: Scalars['String'];
  type?: InputMaybe<NavigationRenderType>;
  menuOnly?: InputMaybe<Scalars['Boolean']>;
  path?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createUploadFile?: Maybe<UploadFileEntityResponse>;
  updateUploadFile?: Maybe<UploadFileEntityResponse>;
  deleteUploadFile?: Maybe<UploadFileEntityResponse>;
  createUploadFolder?: Maybe<UploadFolderEntityResponse>;
  updateUploadFolder?: Maybe<UploadFolderEntityResponse>;
  deleteUploadFolder?: Maybe<UploadFolderEntityResponse>;
  createEmailDesignerEmailTemplate?: Maybe<EmailDesignerEmailTemplateEntityResponse>;
  updateEmailDesignerEmailTemplate?: Maybe<EmailDesignerEmailTemplateEntityResponse>;
  deleteEmailDesignerEmailTemplate?: Maybe<EmailDesignerEmailTemplateEntityResponse>;
  createCertifyingExam?: Maybe<CertifyingExamEntityResponse>;
  updateCertifyingExam?: Maybe<CertifyingExamEntityResponse>;
  deleteCertifyingExam?: Maybe<CertifyingExamEntityResponse>;
  updateCertifyingExamTool?: Maybe<CertifyingExamToolEntityResponse>;
  deleteCertifyingExamTool?: Maybe<CertifyingExamToolEntityResponse>;
  createComitee?: Maybe<ComiteeEntityResponse>;
  updateComitee?: Maybe<ComiteeEntityResponse>;
  deleteComitee?: Maybe<ComiteeEntityResponse>;
  updateDataExportTool?: Maybe<DataExportToolEntityResponse>;
  deleteDataExportTool?: Maybe<DataExportToolEntityResponse>;
  createDiplomateReevaluation?: Maybe<DiplomateReevaluationEntityResponse>;
  updateDiplomateReevaluation?: Maybe<DiplomateReevaluationEntityResponse>;
  deleteDiplomateReevaluation?: Maybe<DiplomateReevaluationEntityResponse>;
  createEmail?: Maybe<EmailEntityResponse>;
  updateEmail?: Maybe<EmailEntityResponse>;
  deleteEmail?: Maybe<EmailEntityResponse>;
  createGeneralExam?: Maybe<GeneralExamEntityResponse>;
  updateGeneralExam?: Maybe<GeneralExamEntityResponse>;
  deleteGeneralExam?: Maybe<GeneralExamEntityResponse>;
  updateGeneralExamTool?: Maybe<GeneralExamToolEntityResponse>;
  deleteGeneralExamTool?: Maybe<GeneralExamToolEntityResponse>;
  createInstitution?: Maybe<InstitutionEntityResponse>;
  updateInstitution?: Maybe<InstitutionEntityResponse>;
  deleteInstitution?: Maybe<InstitutionEntityResponse>;
  createMember?: Maybe<MemberEntityResponse>;
  updateMember?: Maybe<MemberEntityResponse>;
  deleteMember?: Maybe<MemberEntityResponse>;
  createNewsitem?: Maybe<NewsitemEntityResponse>;
  updateNewsitem?: Maybe<NewsitemEntityResponse>;
  deleteNewsitem?: Maybe<NewsitemEntityResponse>;
  createPage?: Maybe<PageEntityResponse>;
  updatePage?: Maybe<PageEntityResponse>;
  deletePage?: Maybe<PageEntityResponse>;
  createPayment?: Maybe<PaymentEntityResponse>;
  updatePayment?: Maybe<PaymentEntityResponse>;
  deletePayment?: Maybe<PaymentEntityResponse>;
  createProgramme?: Maybe<ProgrammeEntityResponse>;
  updateProgramme?: Maybe<ProgrammeEntityResponse>;
  deleteProgramme?: Maybe<ProgrammeEntityResponse>;
  upload: UploadFileEntityResponse;
  multipleUpload: Array<Maybe<UploadFileEntityResponse>>;
  updateFileInfo: UploadFileEntityResponse;
  removeFile?: Maybe<UploadFileEntityResponse>;
  /** Create a new role */
  createUsersPermissionsRole?: Maybe<UsersPermissionsCreateRolePayload>;
  /** Update an existing role */
  updateUsersPermissionsRole?: Maybe<UsersPermissionsUpdateRolePayload>;
  /** Delete an existing role */
  deleteUsersPermissionsRole?: Maybe<UsersPermissionsDeleteRolePayload>;
  /** Create a new user */
  createUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  /** Update an existing user */
  updateUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  /** Delete an existing user */
  deleteUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  login: UsersPermissionsLoginPayload;
  /** Register a user */
  register: UsersPermissionsLoginPayload;
  /** Request a reset password token */
  forgotPassword?: Maybe<UsersPermissionsPasswordPayload>;
  /** Reset user password. Confirm with a code (resetToken from forgotPassword) */
  resetPassword?: Maybe<UsersPermissionsLoginPayload>;
  /** Change user password. Confirm with the current password. */
  changePassword?: Maybe<UsersPermissionsLoginPayload>;
  /** Confirm an email users email address */
  emailConfirmation?: Maybe<UsersPermissionsLoginPayload>;
};

export type MutationCreateUploadFileArgs = {
  data: UploadFileInput;
};

export type MutationUpdateUploadFileArgs = {
  id: Scalars['ID'];
  data: UploadFileInput;
};

export type MutationDeleteUploadFileArgs = {
  id: Scalars['ID'];
};

export type MutationCreateUploadFolderArgs = {
  data: UploadFolderInput;
};

export type MutationUpdateUploadFolderArgs = {
  id: Scalars['ID'];
  data: UploadFolderInput;
};

export type MutationDeleteUploadFolderArgs = {
  id: Scalars['ID'];
};

export type MutationCreateEmailDesignerEmailTemplateArgs = {
  data: EmailDesignerEmailTemplateInput;
};

export type MutationUpdateEmailDesignerEmailTemplateArgs = {
  id: Scalars['ID'];
  data: EmailDesignerEmailTemplateInput;
};

export type MutationDeleteEmailDesignerEmailTemplateArgs = {
  id: Scalars['ID'];
};

export type MutationCreateCertifyingExamArgs = {
  data: CertifyingExamInput;
};

export type MutationUpdateCertifyingExamArgs = {
  id: Scalars['ID'];
  data: CertifyingExamInput;
};

export type MutationDeleteCertifyingExamArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateCertifyingExamToolArgs = {
  data: CertifyingExamToolInput;
};

export type MutationCreateComiteeArgs = {
  data: ComiteeInput;
};

export type MutationUpdateComiteeArgs = {
  id: Scalars['ID'];
  data: ComiteeInput;
};

export type MutationDeleteComiteeArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateDataExportToolArgs = {
  data: DataExportToolInput;
};

export type MutationCreateDiplomateReevaluationArgs = {
  data: DiplomateReevaluationInput;
};

export type MutationUpdateDiplomateReevaluationArgs = {
  id: Scalars['ID'];
  data: DiplomateReevaluationInput;
};

export type MutationDeleteDiplomateReevaluationArgs = {
  id: Scalars['ID'];
};

export type MutationCreateEmailArgs = {
  data: EmailInput;
};

export type MutationUpdateEmailArgs = {
  id: Scalars['ID'];
  data: EmailInput;
};

export type MutationDeleteEmailArgs = {
  id: Scalars['ID'];
};

export type MutationCreateGeneralExamArgs = {
  data: GeneralExamInput;
};

export type MutationUpdateGeneralExamArgs = {
  id: Scalars['ID'];
  data: GeneralExamInput;
};

export type MutationDeleteGeneralExamArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateGeneralExamToolArgs = {
  data: GeneralExamToolInput;
};

export type MutationCreateInstitutionArgs = {
  data: InstitutionInput;
};

export type MutationUpdateInstitutionArgs = {
  id: Scalars['ID'];
  data: InstitutionInput;
};

export type MutationDeleteInstitutionArgs = {
  id: Scalars['ID'];
};

export type MutationCreateMemberArgs = {
  data: MemberInput;
};

export type MutationUpdateMemberArgs = {
  id: Scalars['ID'];
  data: MemberInput;
};

export type MutationDeleteMemberArgs = {
  id: Scalars['ID'];
};

export type MutationCreateNewsitemArgs = {
  data: NewsitemInput;
};

export type MutationUpdateNewsitemArgs = {
  id: Scalars['ID'];
  data: NewsitemInput;
};

export type MutationDeleteNewsitemArgs = {
  id: Scalars['ID'];
};

export type MutationCreatePageArgs = {
  data: PageInput;
};

export type MutationUpdatePageArgs = {
  id: Scalars['ID'];
  data: PageInput;
};

export type MutationDeletePageArgs = {
  id: Scalars['ID'];
};

export type MutationCreatePaymentArgs = {
  data: PaymentInput;
};

export type MutationUpdatePaymentArgs = {
  id: Scalars['ID'];
  data: PaymentInput;
};

export type MutationDeletePaymentArgs = {
  id: Scalars['ID'];
};

export type MutationCreateProgrammeArgs = {
  data: ProgrammeInput;
};

export type MutationUpdateProgrammeArgs = {
  id: Scalars['ID'];
  data: ProgrammeInput;
};

export type MutationDeleteProgrammeArgs = {
  id: Scalars['ID'];
};

export type MutationUploadArgs = {
  refId?: InputMaybe<Scalars['ID']>;
  ref?: InputMaybe<Scalars['String']>;
  field?: InputMaybe<Scalars['String']>;
  info?: InputMaybe<FileInfoInput>;
  file: Scalars['Upload'];
};

export type MutationMultipleUploadArgs = {
  refId?: InputMaybe<Scalars['ID']>;
  ref?: InputMaybe<Scalars['String']>;
  field?: InputMaybe<Scalars['String']>;
  files: Array<InputMaybe<Scalars['Upload']>>;
};

export type MutationUpdateFileInfoArgs = {
  id: Scalars['ID'];
  info?: InputMaybe<FileInfoInput>;
};

export type MutationRemoveFileArgs = {
  id: Scalars['ID'];
};

export type MutationCreateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
};

export type MutationUpdateUsersPermissionsRoleArgs = {
  id: Scalars['ID'];
  data: UsersPermissionsRoleInput;
};

export type MutationDeleteUsersPermissionsRoleArgs = {
  id: Scalars['ID'];
};

export type MutationCreateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
};

export type MutationUpdateUsersPermissionsUserArgs = {
  id: Scalars['ID'];
  data: UsersPermissionsUserInput;
};

export type MutationDeleteUsersPermissionsUserArgs = {
  id: Scalars['ID'];
};

export type MutationLoginArgs = {
  input: UsersPermissionsLoginInput;
};

export type MutationRegisterArgs = {
  input: UsersPermissionsRegisterInput;
};

export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};

export type MutationResetPasswordArgs = {
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  code: Scalars['String'];
};

export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};

export type MutationEmailConfirmationArgs = {
  confirmation: Scalars['String'];
};
