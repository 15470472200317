import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import {
  CertifyingExamEntity,
  Enum_Certifyingexam_Applicationresult,
  Enum_Certifyingexam_Typeofsubmission,
  Enum_Generalexam_Result,
  Enum_Payment_Type,
  GeneralExamEntity,
  MemberInput,
  Page,
} from 'models/types';
import { Subject, takeUntil, tap } from 'rxjs';
import { CertifyingExamApplicationFormComponent } from 'src/app/components/exam-application/certifying-exam-application-form/certifying-exam-application-form.component';
import { CertifyingExamDataVerificationFormComponent } from 'src/app/components/exam-application/certifying-exam-data-verification-form/certifying-exam-data-verification-form.component';
import { MemberFormComponent } from 'src/app/components/member-form/member-form.component';
import { CertifyingExamService } from 'src/app/services/certifying-exam.service';
import { MemberService } from 'src/app/services/member.service';
import { ProfileService } from 'src/app/services/profile.service';
import {
  getCurrentCertifyingExamApplicationYear,
  isCertifyingExamApplicationActive,
  isCertifyingExamApplicationSubmitted,
} from 'src/app/utils/exam';

@Component({
  selector: 'app-certifying-exam-application-submission',
  templateUrl: './certifying-exam-application-submission.component.html',
  styleUrls: ['./certifying-exam-application-submission.component.scss'],
})
export class CertifyingExamApplicationSubmissionComponent implements OnDestroy {
  @ViewChild(MemberFormComponent)
  memberForm!: MemberFormComponent;

  @ViewChild(CertifyingExamDataVerificationFormComponent)
  certifyingExamDataVerficationForm: CertifyingExamDataVerificationFormComponent | undefined = undefined;

  @ViewChild(CertifyingExamApplicationFormComponent)
  certifyingExamApplicationForm: CertifyingExamApplicationFormComponent | undefined = undefined;

  cmsPage: Page | undefined = undefined;

  memberFormDisabled = true;
  loading = false;

  generalExams: GeneralExamEntity[] = [];
  certifyingExams: CertifyingExamEntity[] = [];

  get activeCertifyingExamEntity() {
    return this.certifyingExams.find((cee) => isCertifyingExamApplicationActive(cee));
  }

  set activeCertifyingExamEntity(activeCertifyingExamEntity: CertifyingExamEntity | undefined) {
    if (activeCertifyingExamEntity) {
      this.certifyingExams = this.certifyingExams.filter((cee) => cee !== this.activeCertifyingExamEntity);
      this.certifyingExams.push(activeCertifyingExamEntity);
    }
  }

  get relatedPayments() {
    return this.activeCertifyingExamEntity?.attributes?.payments?.data ?? [];
  }

  get credentialsFeePayments() {
    return this.relatedPayments.filter(
      (payment) =>
        payment.attributes?.type === Enum_Payment_Type.CredentialsFee ||
        payment.attributes?.type === Enum_Payment_Type.CredentialsFeeResubmitter
    );
  }

  get isCredentialsFeePaid() {
    return !this.credentialsFeePayments.find((payment) => !payment.attributes?.paid);
  }

  get member() {
    return this.memberService.currentMember!;
  }

  get memberEntity() {
    return this.memberService.currentMemberEntity!;
  }

  get activeCertifyingExamId() {
    return this.activeCertifyingExamEntity?.id;
  }

  get activeCertifyingExam() {
    return this.activeCertifyingExamEntity?.attributes;
  }

  get isFirstSubmission() {
    return this.activeCertifyingExam?.typeOfSubmission === Enum_Certifyingexam_Typeofsubmission.FirstSubmission;
  }

  get isResubmission() {
    return this.activeCertifyingExam?.typeOfSubmission === Enum_Certifyingexam_Typeofsubmission.ReSubmission;
  }

  get wasAlreadyAcceptedForExam() {
    return (
      this.activeCertifyingExamEntity?.attributes?.applicationResult ==
      Enum_Certifyingexam_Applicationresult.PreviouslyAccepted
    );
  }

  get isApplicationSubmitted() {
    return !!this.activeCertifyingExamEntity && isCertifyingExamApplicationSubmitted(this.activeCertifyingExamEntity);
  }

  get isApplicationLocked() {
    return !this.memberService.isResident || this.isApplicationSubmitted;
  }

  get passedGeneralExamEntity() {
    return this.generalExams.find((gee) => gee.attributes?.result === Enum_Generalexam_Result.Passed);
  }

  get passedGeneralExamYear() {
    return this.passedGeneralExamEntity?.attributes?.examYear ?? undefined;
  }

  get currentExamYear() {
    return getCurrentCertifyingExamApplicationYear();
  }

  private destroyed$ = new Subject<void>();

  constructor(
    private memberService: MemberService,
    private profileService: ProfileService,
    private snackbar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private certifyingExamService: CertifyingExamService
  ) {
    this.activatedRoute.data
      .pipe(
        takeUntil(this.destroyed$),
        tap((data) => {
          this.certifyingExams = data['certifyingExams'] as CertifyingExamEntity[];
          this.generalExams = data['generalExams'] as GeneralExamEntity[];
          this.cmsPage = data['page'] as Page | undefined;
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$.unsubscribe();
  }

  enableMemberFormEditMode(): void {
    this.memberFormDisabled = false;
    this.memberForm?.memberForm.markAllAsTouched();
  }

  saveMemberForm(): void {
    if (!this.memberForm.memberFormInvalid) {
      this.profileService
        .updateMember(this.memberService.currentMemberId!, this.memberForm?.memberFormValues as MemberInput)
        .subscribe({
          next: (result) => {
            this.loading = result.loading;
            if (result.data) {
              this.memberService.setMember(result.data.updateMember.data!);
              this.snackbar.open('Your profile was updated successfully!', undefined, { duration: 4000 });
            }
          },
        });
    }
  }

  submitCertifyingExamDataVerification() {
    if (!this.activeCertifyingExamEntity && this.certifyingExamDataVerficationForm?.isValid && this.memberEntity) {
      const certifyingExamInput = this.certifyingExamDataVerficationForm.values;

      this.certifyingExamService.createCertifyingExamForMember(this.memberEntity.id!, certifyingExamInput).subscribe({
        next: (result) => {
          this.loading = result.loading;

          if (result.data) {
            const certifyingExamEntity = result.data.createCertifyingExam.data ?? undefined;
            this.activeCertifyingExamEntity = certifyingExamEntity;
          }
        },
      });
    }
  }

  submitCertifyingExamApplicationForm(): void {
    if (this.activeCertifyingExamEntity && this.certifyingExamApplicationForm?.isValid) {
      const certifyingExamInput = this.certifyingExamApplicationForm.values;

      this.certifyingExamService.updateCertifyingExam(this.activeCertifyingExamId!, certifyingExamInput).subscribe({
        next: (result) => {
          this.loading = result.loading;

          if (result.data) {
            this.activeCertifyingExamEntity = result.data.updateCertifyingExam.data ?? undefined;
            this.snackbar.open('Your exam application was successfully submitted!', undefined, { duration: 4000 });
          }
        },
      });
    }
  }
}
