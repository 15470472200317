import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CertifyingExam, CertifyingExamEntity } from 'models/types';
import { getFullnameForMember } from 'src/app/utils/member';
import { enumValueToString } from 'src/app/utils/utils';

import { SimpleTableItem } from '../../simple-table/simple-table.component';

@Component({
  selector: 'app-certifying-exam-application-details',
  templateUrl: './certifying-exam-application-details.component.html',
  styleUrls: ['./certifying-exam-application-details.component.scss'],
})
export class CertifyingExamApplicationDetailsComponent implements OnInit {
  @Input()
  certifyingExam!: CertifyingExam;

  @Input()
  previousCertifyingExamEntities?: CertifyingExamEntity[] | null;

  get applicationDetailsTableData() {
    const applicationDetailsTableData: SimpleTableItem[] = [
      { key: 'Application Year', value: this.certifyingExam.examYear - 1 },
      { key: 'Exam Year', value: this.certifyingExam.examYear },
      { key: 'Resident', value: getFullnameForMember(this.certifyingExam.resident?.data?.attributes) },
      { key: 'Speciality', value: this.certifyingExam.speciality?.Name ?? '' },
      { key: 'Type of Submission', value: enumValueToString(this.certifyingExam.typeOfSubmission ?? '') },
    ];

    if (this.previousCertifyingExamEntities?.length) {
      applicationDetailsTableData.push({
        key: 'Previous Application Years',
        value: [...this.previousCertifyingExamEntities]
          .sort((ce1, ce2) => ce1.attributes!.examYear - ce2.attributes!.examYear)
          .map((previousCertifyingExamEntity) => ({
            value: previousCertifyingExamEntity.attributes!.examYear - 1,
            routerLink: `/committee-work/review-certifying-examination/${previousCertifyingExamEntity.id}/view`,
          })),
      });
    }

    return applicationDetailsTableData;
  }

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }
}
