import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { NavigationItem } from 'models/types';
import { map, Observable } from 'rxjs';

const GET_PAGE_BY_BATH = gql`
  query Navigation($path: String!) {
    renderNavigation(navigationIdOrSlug: "main-navigation", type: TREE, menuOnly: false, path: $path) {
      __typename
      id
      audience
      related {
        id
        attributes {
          __typename
          ... on Page {
            title
            content
            publishedAt
          }
        }
      }
    }
  }
`;

const GET_AUDIENCE_BY_PATH = gql`
  query Navigation($path: String!) {
    renderNavigation(navigationIdOrSlug: "main-navigation", type: TREE, menuOnly: false, path: $path) {
      __typename
      id
      audience
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class PageService {
  constructor(private apollo: Apollo) {}

  getAudienceByPath(path: string): Observable<string[] | undefined> {
    return this.apollo
      .query<{ renderNavigation: NavigationItem[] }>({
        query: GET_AUDIENCE_BY_PATH,
        variables: {
          path: path,
        },
      })
      .pipe(map((result) => result.data.renderNavigation?.[0]?.audience?.filter((a) => !!a).map((a) => a!)));
  }

  getNavigationItemByPath(path: string): Observable<NavigationItem | undefined> {
    return this.apollo
      .query<{ renderNavigation: NavigationItem[] }>({
        query: GET_PAGE_BY_BATH,
        variables: {
          path: path,
        },
      })
      .pipe(map((result) => result.data.renderNavigation?.[0] ?? undefined));
  }

  getPageByPath(path: string) {
    return this.getNavigationItemByPath(path).pipe(map((navigation) => navigation?.related?.attributes ?? undefined));
  }

  getPublishedPageByPath(path: string) {
    return this.getPageByPath(path).pipe(map((page) => (!!page?.publishedAt ? page : undefined)));
  }
}
