import { Maybe } from 'graphql/jsutils/Maybe';
import { MemberEntityResponse, Scalars } from 'models/types';

export enum WorkStatus {
  ToDo = 'To Do',
  InProgress = 'In Progress',
  Finished = 'Finished',
}

export enum ReviewType {
  First = 'first',
  Second = 'second',
  Final = 'final',
  View = 'view',
}

export interface Reviewable {
  reviewStatus?: Maybe<Scalars['Boolean']>;
  reviewer1?: Maybe<MemberEntityResponse>;
  reviewer2?: Maybe<MemberEntityResponse>;
  reviewer1Status?: Maybe<Scalars['Boolean']>;
  reviewer2Status?: Maybe<Scalars['Boolean']>;
}

export function getReviewTypeForReviewerNumber(reviewerNumber: number) {
  switch (reviewerNumber) {
    case 1:
      return ReviewType.First;
    case 2:
      return ReviewType.Second;
    default:
      throw new Error(`invalid reviewer number: ${reviewerNumber}`);
  }
}

export function isReviewer1(reviewable: Reviewable, memberId: string) {
  return memberId === reviewable.reviewer1?.data?.id;
}

export function isReviewer2(reviewable: Reviewable, memberId: string) {
  return memberId === reviewable.reviewer2?.data?.id;
}

export function getReviewerNumbers(reviewable: Reviewable, memberId: string) {
  return [isReviewer1(reviewable, memberId) ? 1 : [], isReviewer2(reviewable, memberId) ? 2 : []].flat();
}

export function getWorkStatusForFinalReviewer(reviewable: Reviewable) {
  if (isReviewCompleted(reviewable)) {
    return WorkStatus.Finished;
  }
  const reviewer1WorkStatus = getWorkStatusForReviewer(reviewable, 1);
  const reviewer2WorkStatus = getWorkStatusForReviewer(reviewable, 2);
  return reviewer1WorkStatus === WorkStatus.Finished && reviewer2WorkStatus === WorkStatus.Finished
    ? WorkStatus.ToDo
    : WorkStatus.InProgress;
}

export function getWorkStatusForReviewer(reviewable: Reviewable, reviewerNumber: number): WorkStatus {
  if (reviewerNumber < 1 || reviewerNumber > 2) {
    throw new Error(`invalid reviewer number: ${reviewerNumber}`);
  }
  const reviewerStatusKey = `reviewer${reviewerNumber}Status` as 'reviewer1Status' | 'reviewer2Status';
  const reviewerStatus = reviewable[reviewerStatusKey];
  if (reviewerStatus === undefined) {
    throw new Error(`${reviewerStatusKey} is not defined`);
  }
  return isReviewCompleted(reviewable) || reviewerStatus ? WorkStatus.Finished : WorkStatus.ToDo;
}

function isReviewCompleted(reviewable: Reviewable) {
  if (reviewable.reviewStatus === undefined) {
    throw new Error(`reviewStatus is not defined`);
  }
  return reviewable.reviewStatus === true;
}
