import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { GeneralExamInput, MemberEntity } from 'models/types';
import { getCurrentGeneralExamApplicationYear } from 'src/app/utils/exam';
import { getFormByPath, isFormRequired } from 'src/app/utils/form';
import { enumValueToString } from 'src/app/utils/utils';

@Component({
  selector: 'app-general-exam-data-verification-form',
  templateUrl: './general-exam-data-verification-form.component.html',
  styleUrls: ['./general-exam-data-verification-form.component.scss'],
})
export class GeneralExamDataVerificationFormComponent implements OnInit, OnChanges {
  @Input()
  memberEntity!: MemberEntity;

  @Input()
  disabled = true;

  loading = false;

  dataVerificationForm = this.fb.group({
    attempt: this.fb.control<number | null>(1, [Validators.required, Validators.min(1), Validators.max(4)]),
  });

  get isValid() {
    return this.dataVerificationForm.valid;
  }

  get values(): GeneralExamInput {
    const values: GeneralExamInput = this.dataVerificationForm.value;
    values.examYear = getCurrentGeneralExamApplicationYear();
    values.speciality = { Name: this.member.Speciality![0]!.Name };
    return values;
  }

  get member() {
    return this.memberEntity.attributes!;
  }

  get genericErrorMessage() {
    return 'This field is required!';
  }

  get attemptOptions() {
    return [1, 2, 3, 4];
  }

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    if (this.disabled) {
      this.dataVerificationForm.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled'] && !changes['disabled'].firstChange) {
      const disabled = changes['disabled'].currentValue;
      if (disabled) {
        this.dataVerificationForm.disable();
      } else {
        this.dataVerificationForm.enable();
      }
    }
  }

  getFormByPath(path: string) {
    return getFormByPath(this.dataVerificationForm, path);
  }

  isFormRequired(path: string) {
    return isFormRequired(this.dataVerificationForm, path);
  }
}
