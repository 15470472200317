import { Component } from '@angular/core';

@Component({
  selector: 'app-submit-later-alert',
  templateUrl: './submit-later-alert.component.html',
  styleUrls: ['./submit-later-alert.component.scss'],
})
export class SubmitLaterAlertComponent {
  constructor() {}
}
