import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { CertifyingExamEntity } from 'models/types';
import { map } from 'rxjs';
import { CertifyingExamService } from 'src/app/services/certifying-exam.service';
import { MemberService } from 'src/app/services/member.service';

@Injectable({
  providedIn: 'root',
})
export class CertifyingExamsForMemberResolver implements Resolve<CertifyingExamEntity[]> {
  constructor(private memberService: MemberService, private certifyingExamService: CertifyingExamService) {
    //pass
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.certifyingExamService
      .getCertifyingExamsForMember(this.memberService.currentMemberId!)
      .pipe(map((res) => res.data.certifyingExams.data));
  }
}
