import { animate, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaymentEntity } from 'models/types';
import { filter, firstValueFrom, map, Subject, takeUntil, tap } from 'rxjs';
import { PaymentsService } from 'src/app/services/payments.service';
import { environment } from 'src/environments/environment';

declare let paypal: any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
  animations: [trigger('fade', [transition(':enter', [style({ opacity: 0 }), animate(1000, style({ opacity: 1 }))])])],
})
export class CheckoutComponent implements OnInit, OnDestroy {
  script: HTMLScriptElement | null = null;
  payment: PaymentEntity | null = null;
  success = false;

  private destroyed$ = new Subject<void>();

  get paymentItems() {
    return [
      {
        description: this.payment!.attributes!.type,
        subNote: this.payment!.attributes!.subject,
        amount: this.payment!.attributes!.amount,
      },
      ...(this.payment!.attributes!.paymentItems ?? []),
    ];
  }

  paymentItemsColumns = ['description', 'amount'];

  constructor(
    private activatedRoute: ActivatedRoute,
    private payments: PaymentsService,
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.activatedRoute.data
      .pipe(
        map((data) => data['payment']),
        filter((p) => !!p),
        takeUntil(this.destroyed$)
      )
      .subscribe({
        next: (payment) => (this.payment = payment),
      });
  }

  ngOnInit(): void {
    const script = this.renderer2.createElement('script');
    if (script) {
      script.src = `https://www.paypal.com/sdk/js?client-id=${environment.paypal.clientId}&currency=EUR`;
      script.type = 'text/javascript';
      script.async = true;
      script.charset = 'utf-8';
      this.renderer2.appendChild(this.document.head, script);
      this.script = script;

      script.onload = () => {
        paypal
          .Buttons({
            style: {
              // https://developer.paypal.com/docs/checkout/integration-features/customize-button/
              layout: 'vertical',
              color: 'blue',
              shape: 'rect',
              label: 'paypal',
            },
            createOrder: async () => {
              return firstValueFrom(this.payments.createPaypalOrder(this.payment?.id!).pipe(map((order) => order.id)));
            },
            onCancel: () => {
              // Show a cancel page, or return to cart
              // https://developer.paypal.com/docs/checkout/integration-features/cancellation-page/
            },
            onShippingChange: () => {
              // https://developer.paypal.com/docs/checkout/integration-features/shipping-callback/
              // ...
            },
            onApprove: async (data: any) => {
              return firstValueFrom(
                this.payments.approvePaypalOrder(data.orderID).pipe(tap(() => (this.success = true)))
              );
            },
            onError: (err: any) => {
              // https://developer.paypal.com/docs/checkout/integration-features/handle-errors/
              console.log(err);
            },
          })
          .render('#paypal-button');
      };
    }
  }

  ngOnDestroy(): void {
    this.renderer2.removeChild(this.document.head, this.script);
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$.unsubscribe();
  }

  prettify(text: string | undefined | null) {
    return text?.replace('_', ' ') ?? '';
  }
}
