import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { GeneralExamEntity, GeneralExamInput, MemberEntity, UploadFileEntity } from 'models/types';
import { GENERAL_EXAM_DIRECTORY_PATH, GENERAL_EXAM_REF } from 'src/app/utils/exam';
import { getBaseFileName, getFormByPath, isFormRequired } from 'src/app/utils/form';

@Component({
  selector: 'app-general-exam-application-form',
  templateUrl: './general-exam-application-form.component.html',
  styleUrls: ['./general-exam-application-form.component.scss'],
})
export class GeneralExamApplicationFormComponent implements OnInit, OnChanges {
  @Input()
  memberEntity: MemberEntity | undefined = undefined;

  @Input()
  generalExamEntity: GeneralExamEntity | undefined | null = undefined;

  @Input()
  disabled = true;

  loading = false;

  examApplicationForm = this.fb.group({
    caseSummary: this.fb.control<UploadFileEntity[]>([], [Validators.required, Validators.minLength(1)]),
    programmeDirectorLetter: this.fb.control<UploadFileEntity[]>([], [Validators.required, Validators.minLength(1)]),
    previousCorrespondence: this.fb.control<UploadFileEntity[]>([], [Validators.required, Validators.minLength(1)]),
    curriculumVitae: this.fb.control<UploadFileEntity[]>([], [Validators.required, Validators.minLength(1)]),

    termsAndConditionsAccepted: this.fb.control(false, Validators.requiredTrue),
    privacyPolicyAccepted: this.fb.control(false, Validators.requiredTrue),
    confidentialityAgreementAccepted: this.fb.control(false, Validators.requiredTrue),
  });

  get member() {
    return this.memberEntity?.attributes;
  }
  get isValid() {
    return this.examApplicationForm.valid;
  }

  get values(): GeneralExamInput {
    const values = Object.fromEntries(
      Object.entries(this.examApplicationForm.controls)
        .map(([key, control]) => [key, control.value])
        .filter(([_, value]) => !Array.isArray(value))
    );
    return values;
  }

  get genericErrorMessage() {
    return 'This field is required!';
  }

  get generalExamRef() {
    return GENERAL_EXAM_REF;
  }

  get generalExamId() {
    return this.generalExamEntity?.id ?? undefined;
  }

  get generalExam() {
    return this.generalExamEntity?.attributes ?? undefined;
  }

  get generalExamDirectoryPath() {
    return GENERAL_EXAM_DIRECTORY_PATH;
  }

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    if (this.member && this.generalExam) {
      const ge = this.generalExam;

      if (ge.attempt > 1) {
        const caseSummary = this.examApplicationForm.get('caseSummary');
        caseSummary?.setValidators(null);
        caseSummary?.disable();

        const curriculumVitae = this.examApplicationForm.get('curriculumVitae');
        curriculumVitae?.setValidators(null);
        curriculumVitae?.disable();
      }

      this.examApplicationForm.patchValue({
        programmeDirectorLetter: [ge.programmeDirectorLetter?.data].filter((uf) => !!uf) as UploadFileEntity[],
        previousCorrespondence: ge.previousCorrespondence?.data ?? [],
        curriculumVitae: [ge.curriculumVitae?.data].filter((uf) => !!uf) as UploadFileEntity[],
        caseSummary: ge.caseSummary?.data ?? [],

        termsAndConditionsAccepted: ge.termsAndConditionsAccepted ?? false,
        privacyPolicyAccepted: ge.privacyPolicyAccepted ?? false,
        confidentialityAgreementAccepted: ge.confidentialityAgreementAccepted ?? false,
      });
    }

    if (this.disabled) {
      this.examApplicationForm.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled'] && !changes['disabled'].firstChange) {
      const disabled = changes['disabled'].currentValue;
      if (disabled) {
        this.examApplicationForm.disable();
      } else {
        this.examApplicationForm.enable();
      }
    }
  }

  getBaseFileName(field: string) {
    return getBaseFileName('general-exam', field, this.member);
  }

  getFormByPath(path: string) {
    return getFormByPath(this.examApplicationForm, path);
  }

  isFormRequired(path: string) {
    return isFormRequired(this.examApplicationForm, path);
  }
}
