import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import {
  CertifyingExamEntityResponse,
  CertifyingExamEntityResponseCollection,
  CertifyingExamInput,
  MemberEntityResponse,
} from 'models/types';
import { map, switchMap } from 'rxjs';

import { unique } from '../utils/utils';
import { CommitteeService } from './committee.service';
import { UPLOAD_FILE_ENTITY_DATA_EXPAND } from './file-upload.service';
import { PAYMENT_DATA_EXPAND } from './payments.service';
import { MEMBER_BASIC_ATTRIBUTES, MEMBER_BASIC_DATA_EXPAND, MEMBER_COMMITTEE_EXPAND } from './profile.service';

export const MEMBER_REVIEWER_DATA_EXPAND = `
  data {
    __typename
    id
    attributes {
      ${MEMBER_BASIC_ATTRIBUTES}
      ${MEMBER_COMMITTEE_EXPAND}
    }
  }
`;

const CERTIFYING_EXAM_DATA_FOR_REVIEW = `
  applicationResultComment

  fullCredentialsFeeNextApplication

  resident {
    ${MEMBER_BASIC_DATA_EXPAND}
  }

  reviewer1 {
    ${MEMBER_REVIEWER_DATA_EXPAND}
  }

  reviewer2 {
    ${MEMBER_REVIEWER_DATA_EXPAND}
  }

  reviewStatus
  reviewer1Status
  reviewer2Status

  geResultComment
  geResultAccepted
  geResultReviewer1Comment
  geResultReviewer2Comment
  geResultReviewer1Notes
  geResultReviewer2Notes
  geResultReviewer1Accepted
  geResultReviewer2Accepted

  verificationOfTrainingComment
  verificationOfTrainingAccepted
  verificationOfTrainingReviewer1Comment
  verificationOfTrainingReviewer2Comment
  verificationOfTrainingReviewer1Notes
  verificationOfTrainingReviewer2Notes
  verificationOfTrainingReviewer1Accepted
  verificationOfTrainingReviewer2Accepted

  caseLogSummaryComment
  caseLogSummaryAccepted
  caseLogSummaryReviewer1Comment
  caseLogSummaryReviewer2Comment
  caseLogSummaryReviewer1Notes
  caseLogSummaryReviewer2Notes
  caseLogSummaryReviewer1Accepted
  caseLogSummaryReviewer2Accepted

  caseSummaryReviewStatus
  caseSummaryComment
  caseSummaryReviewer1Comment
  caseSummaryReviewer2Comment
  caseSummaryReviewer1Notes
  caseSummaryReviewer2Notes

  caseLogComment
  caseLogAccepted
  caseLogReviewer1Comment
  caseLogReviewer2Comment
  caseLogReviewer1Notes
  caseLogReviewer2Notes
  caseLogReviewer1Accepted
  caseLogReviewer2Accepted

  presentationLogComment
  presentationLogAccepted
  presentationLogReviewer1Comment
  presentationLogReviewer2Comment
  presentationLogReviewer1Notes
  presentationLogReviewer2Notes
  presentationLogReviewer1Accepted
  presentationLogReviewer2Accepted

  multipleChoiceQuestionnaireComment
  multipleChoiceQuestionnaireAccepted
  multipleChoiceQuestionnaireReviewer1Comment
  multipleChoiceQuestionnaireReviewer2Comment
  multipleChoiceQuestionnaireReviewer1Notes
  multipleChoiceQuestionnaireReviewer2Notes
  multipleChoiceQuestionnaireReviewer1Accepted
  multipleChoiceQuestionnaireReviewer2Accepted

  publicationsComment
  publicationsAccepted
  publicationsReviewer1Comment
  publicationsReviewer2Comment
  publicationsReviewer1Notes
  publicationsReviewer2Notes
  publicationsReviewer1Accepted
  publicationsReviewer2Accepted

  programmeDirectorLetterComment
  programmeDirectorLetterAccepted
  programmeDirectorLetterReviewer1Comment
  programmeDirectorLetterReviewer2Comment
  programmeDirectorLetterReviewer1Notes
  programmeDirectorLetterReviewer2Notes
  programmeDirectorLetterReviewer1Accepted
  programmeDirectorLetterReviewer2Accepted

  previousCorrespondenceComment
  previousCorrespondenceAccepted
  previousCorrespondenceReviewer1Comment
  previousCorrespondenceReviewer2Comment
  previousCorrespondenceReviewer1Notes
  previousCorrespondenceReviewer2Notes
  previousCorrespondenceReviewer1Accepted
  previousCorrespondenceReviewer2Accepted

  procedureLogComment
  procedureLogAccepted
  procedureLogReviewer1Comment
  procedureLogReviewer2Comment
  procedureLogReviewer1Notes
  procedureLogReviewer2Notes
  procedureLogReviewer1Accepted
  procedureLogReviewer2Accepted

  credentialsSubmissionSummaryFormComment
  credentialsSubmissionSummaryFormAccepted
  credentialsSubmissionSummaryFormReviewer1Comment
  credentialsSubmissionSummaryFormReviewer2Comment
  credentialsSubmissionSummaryFormReviewer1Notes
  credentialsSubmissionSummaryFormReviewer2Notes
  credentialsSubmissionSummaryFormReviewer1Accepted
  credentialsSubmissionSummaryFormReviewer2Accepted

`;

const CERTIFYING_EXAM_DATA_FOR_RESIDENT = `
  resident {
    data {
      id
    }
  }
`;

const CERTIFYING_EXAM_DATA_BASIC = `
  applicationResult
  applicationResultPublished
  applicationResultLetters {
    ${UPLOAD_FILE_ENTITY_DATA_EXPAND}
  }

  caseSummaryReviewPublished
  caseSummaryConclusionLetter {
    ${UPLOAD_FILE_ENTITY_DATA_EXPAND}
  }

  examYear

  speciality {
    id
    Name
  }

  attempt
  examParts
  typeOfSubmission

  termsAndConditionsAccepted
  privacyPolicyAccepted
  confidentialityAgreementAccepted
  
  publicationLinks
  titlePreviousCaseSummary
  
  payments {
    ${PAYMENT_DATA_EXPAND}
  }

  geResult {
    ${UPLOAD_FILE_ENTITY_DATA_EXPAND}
  }

  verificationOfTrainingSubmitted
  verificationOfTraining {
    ${UPLOAD_FILE_ENTITY_DATA_EXPAND}
  }

  caseLogSummary {
    ${UPLOAD_FILE_ENTITY_DATA_EXPAND}
  }

  caseSummary {
    ${UPLOAD_FILE_ENTITY_DATA_EXPAND}
  }

  caseLog {
    ${UPLOAD_FILE_ENTITY_DATA_EXPAND}
  }

  presentationLogSubmitted
  presentationLog {
    ${UPLOAD_FILE_ENTITY_DATA_EXPAND}
  }

  multipleChoiceQuestionnaire {
    ${UPLOAD_FILE_ENTITY_DATA_EXPAND}
  }

  publicationsSubmitted
  publications {
    ${UPLOAD_FILE_ENTITY_DATA_EXPAND}
  }

  programmeDirectorLetter {
    ${UPLOAD_FILE_ENTITY_DATA_EXPAND}
  }

  previousCorrespondence {
    ${UPLOAD_FILE_ENTITY_DATA_EXPAND}
  }

  procedureLog {
    ${UPLOAD_FILE_ENTITY_DATA_EXPAND}
  }

  credentialsSubmissionSummaryForm {
    ${UPLOAD_FILE_ENTITY_DATA_EXPAND}
  }

`;

const CERTIFYING_EXAM_DATA_REVIEW_EXPAND = `
  data {
    id
    attributes {
      ${CERTIFYING_EXAM_DATA_BASIC}
      ${CERTIFYING_EXAM_DATA_FOR_REVIEW}
    }
  }
`;

const CERTIFYING_EXAM_DATA_RESIDENT_EXPAND = `
  data {
    id
    attributes {
      ${CERTIFYING_EXAM_DATA_BASIC}
      ${CERTIFYING_EXAM_DATA_FOR_RESIDENT}
    }
  }
`;

const GET_CERTIFYING_EXAM_FOR_REVIEW_BY_ID = gql`
  query ($certifyingExamId: ID!) {
    certifyingExam(id: $certifyingExamId) {
        ${CERTIFYING_EXAM_DATA_REVIEW_EXPAND}
    }
  }
`;

const UPDATE_CERTIFYING_EXAM = gql`
  mutation updateCertifyingExam($certifyingExamId: ID!, $data: CertifyingExamInput!) {
    updateCertifyingExam(id: $certifyingExamId, data: $data) {
      ${CERTIFYING_EXAM_DATA_RESIDENT_EXPAND}
    }
  }
`;

const UPDATE_CERTIFYING_EXAM_REVIEW = gql`
  mutation updateCertifyingExam($certifyingExamId: ID!, $data: CertifyingExamInput!) {
    updateCertifyingExam(id: $certifyingExamId, data: $data) {
      ${CERTIFYING_EXAM_DATA_REVIEW_EXPAND}
    }
  }
`;

const GET_CERTIFYING_EXAMS_FOR_MEMBER = gql`
  query ($memberId: ID!) {
    certifyingExams(filters: {  resident: {id: {eq: $memberId}} }) {
        ${CERTIFYING_EXAM_DATA_RESIDENT_EXPAND}
    }
  }
`;

const GET_CERTIFYING_EXAMS_FOR_REVIEW_BY_RESIDENT = gql`
  query ($memberId: ID!, $beforeExamYear: Int) {
    certifyingExams(
      filters: {
        and: [
          { resident: { id: { eq: $memberId } } }
          { termsAndConditionsAccepted: { eq: true } }
          { examYear: { lt: $beforeExamYear } }
        ]
      }
    ) {
      ${CERTIFYING_EXAM_DATA_REVIEW_EXPAND}
    }
  }
`;

const GET_CERTIFYING_EXAMS_FOR_REVIEW_BY_REVIEWER = gql`
  query($memberId: ID!, $examYear: Int) {
    member(id: $memberId) {
      data {
        id
        attributes {
          certifyingExamsAsReviewer1(
            filters: { 
              and: [
                {
                  or: [
                    { applicationResult: null }
                    { applicationResult: { ne: "Previously Accepted" } }
                  ]
                }
                { termsAndConditionsAccepted: { eq: true } }
                { examYear: { eq: $examYear } }
              ]
            }
          ) {
            ${CERTIFYING_EXAM_DATA_REVIEW_EXPAND}
          }
          certifyingExamsAsReviewer2(
            filters: { 
              and: [
                {
                  or: [
                    { applicationResult: null }
                    { applicationResult: { ne: "Previously Accepted" } }
                  ]
                }
                { termsAndConditionsAccepted: { eq: true } }
                { examYear: { eq: $examYear } }
              ]
            }
          ) {
            ${CERTIFYING_EXAM_DATA_REVIEW_EXPAND}
          }
        }
      }
    }
  }
`;

const GET_CERTIFYING_EXAMS_FOR_REVIEW_BY_SPECIALITIES = gql`
  query($specialityNames: [String]!, $examYear: Int) {
    certifyingExams(
      filters: {
        and: [
          {
            or: [
              { applicationResult: null }
              { applicationResult: { ne: "Previously Accepted" } }
            ]
          }
          { termsAndConditionsAccepted: { eq: true } }
          { speciality: { Name: { in: $specialityNames } } }
          { examYear: { eq: $examYear } }
        ]
      }
    ) {
      ${CERTIFYING_EXAM_DATA_REVIEW_EXPAND}
    }
  }
`;

const CREATE_CERTIFYING_EXAM = gql`
  mutation createCertifyingExam($data: CertifyingExamInput!) {
    createCertifyingExam(data: $data) {
      ${CERTIFYING_EXAM_DATA_RESIDENT_EXPAND}
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CertifyingExamService {
  constructor(private apollo: Apollo, private committeeService: CommitteeService) {
    // pass
  }

  getCertifyingExamsForMember(memberId: string) {
    return this.apollo.query<{ certifyingExams: CertifyingExamEntityResponseCollection }>({
      query: GET_CERTIFYING_EXAMS_FOR_MEMBER,
      variables: {
        memberId: memberId,
      },
      fetchPolicy: 'network-only',
    });
  }

  updateCertifyingExam(certifyingExamId: string, data: CertifyingExamInput) {
    return this.apollo.mutate<{ updateCertifyingExam: CertifyingExamEntityResponse }>({
      mutation: UPDATE_CERTIFYING_EXAM,
      variables: {
        certifyingExamId: certifyingExamId,
        data: data,
      },
    });
  }

  createCertifyingExam(data: CertifyingExamInput) {
    return this.apollo.mutate<{ createCertifyingExam: CertifyingExamEntityResponse }>({
      mutation: CREATE_CERTIFYING_EXAM,
      variables: {
        data: data,
      },
    });
  }

  createCertifyingExamForMember(memberId: string, data: CertifyingExamInput = {}) {
    data.resident = memberId;
    return this.createCertifyingExam(data);
  }

  // REVIEWS

  getCertifyingExamForReviewById(certifyingExamId: string) {
    return this.apollo.query<{ certifyingExam: CertifyingExamEntityResponse }>({
      query: GET_CERTIFYING_EXAM_FOR_REVIEW_BY_ID,
      variables: {
        certifyingExamId,
      },
      fetchPolicy: 'network-only',
    });
  }

  getCertifyingExamsForReviewByResident(residentMemberId: string, beforeExamYear?: number) {
    return this.apollo.query<{ certifyingExams: CertifyingExamEntityResponseCollection }>({
      query: GET_CERTIFYING_EXAMS_FOR_REVIEW_BY_RESIDENT,
      variables: {
        memberId: residentMemberId,
        beforeExamYear,
      },
      fetchPolicy: 'network-only',
    });
  }

  getCertifyingExamsForReviewByReviewer(reviewerMemberId: string, examYear?: number) {
    return this.apollo
      .query<{ member: MemberEntityResponse }>({
        query: GET_CERTIFYING_EXAMS_FOR_REVIEW_BY_REVIEWER,
        variables: {
          memberId: reviewerMemberId,
          examYear,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((res) =>
          unique(
            [
              ...(res.data.member.data?.attributes?.certifyingExamsAsReviewer1?.data ?? []),
              ...(res.data.member.data?.attributes?.certifyingExamsAsReviewer2?.data ?? []),
            ],
            (cee) => cee.id
          )
        )
      );
  }

  getCertifyingExamsForReviewBySpecialities(specialityNames: string[], examYear?: number) {
    return this.apollo.query<{ certifyingExams: CertifyingExamEntityResponseCollection }>({
      query: GET_CERTIFYING_EXAMS_FOR_REVIEW_BY_SPECIALITIES,
      variables: {
        specialityNames,
        examYear,
      },
      fetchPolicy: 'network-only',
    });
  }

  getCertifyingExamsForReviewAsCredentialsChair(memberId: string, examYear?: number) {
    return this.committeeService.getCredentialsCommitteesAsChair(memberId).pipe(
      switchMap((res) => {
        const committees = res?.data?.comitees?.data ?? [];
        const specialities = committees
          .map((committee) => committee.attributes?.speciality?.Name)
          .filter((s) => !!s)
          .map((s) => s!.replace('_', ' '));
        return this.getCertifyingExamsForReviewBySpecialities(specialities, examYear);
      }),
      map((res) => res.data.certifyingExams.data)
    );
  }

  updateCertifyingExamReview(certifyingExamId: string, data: CertifyingExamInput) {
    return this.apollo.mutate<{ updateCertifyingExam: CertifyingExamEntityResponse }>({
      mutation: UPDATE_CERTIFYING_EXAM_REVIEW,
      variables: {
        certifyingExamId: certifyingExamId,
        data: data,
      },
    });
  }
}
