<app-grid class="p-2">
  <h1>Documents</h1>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>General Exam</mat-panel-title>
      </mat-expansion-panel-header>
      <app-document-list
        *ngIf="generalExamDocuments$ | async as generalExamDocuments; else loading"
        [documents]="generalExamDocuments"></app-document-list>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>Certifying Exam</mat-panel-title>
      </mat-expansion-panel-header>
      <app-document-list
        *ngIf="certifyingExamDocuments$ | async as certifyingExamDocuments; else loading"
        [documents]="certifyingExamDocuments"></app-document-list>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>Diplomate Reevaluation</mat-panel-title>
      </mat-expansion-panel-header>
      <app-document-list
        *ngIf="diplomateReevaluationDocuments$ | async as diplomateReevaluationDocuments; else loading"
        [documents]="diplomateReevaluationDocuments"></app-document-list>
    </mat-expansion-panel>

    <ng-template #loading>
      <mat-spinner></mat-spinner>
    </ng-template>
  </mat-accordion>
</app-grid>
