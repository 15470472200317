<h2>Certifying Exam Application</h2>
<p>
  This is the online application form to {{ certifyingExam.attempt > 1 ? "re-sit" : "sit" }} the ECVIM-CA Certifying
  Examination in March {{ currentExamYear }}.
</p>
<p *ngIf="!wasPreviouslyAccepted">
  Residents are only permitted to sit for the Certifying Examination after having satisfactorily completed 3 years (36
  months) of training by 31st March {{ currentExamYear }}.
</p>
<p *ngIf="isResubmission">
  Please upload those documents that were not approved by the Credentials Committee or could not be completed in your
  previous submission. Please upload your credentials result letter in the “previous correspondence” field.
</p>

<form [formGroup]="examApplicationForm">
  <div class="form-entry">
    <mat-form-field appearance="fill">
      <mat-label>Choose your Exam Parts</mat-label>
      <mat-select formControlName="examParts">
        <mat-option *ngFor="let examPartsOption of examPartsOptions" [value]="examPartsOption">{{
          displayExamPartsOption(examPartsOption)
        }}</mat-option>
      </mat-select>
      <mat-error *ngIf="!getFormByPath('examParts')?.valid">{{ genericErrorMessage }}</mat-error>
    </mat-form-field>
  </div>

  <ng-container *ngIf="!areCredentialsNecessary">
    <div class="form-entry">
      <label
        >Reapplication Programme Director Letter<span *ngIf="isFormRequired('programmeDirectorLetter')"> *</span></label
      >
      <p>
        A scanned copy of an original signed letter from your Programme Director, or any ECVIM-CA Diplomate who knows
        you.
      </p>
      <div class="form-inputs">
        <app-file-upload
          formControlName="programmeDirectorLetter"
          allowedTypes="application/pdf"
          [ref]="certifyingExamRef"
          [refId]="certifyingExamId"
          [maxNumberFiles]="1"
          field="programmeDirectorLetter"
          [path]="certifyingExamDirectoryPath"
          [baseFileName]="getBaseFileName('programmeDirectorLetter')"></app-file-upload>
      </div>
    </div>

    <div class="form-entry">
      <label>Previous Correspondence<span *ngIf="isFormRequired('previousCorrespondence')"> *</span></label>
      <p>
        Please include your Credentials acceptance letter and – if available – relevant correspondence since your last
        exam {{ certifyingExam.attempt > 1 ? "attempt" : "application" }}.
      </p>
      <div class="form-inputs">
        <app-file-upload
          formControlName="previousCorrespondence"
          allowedTypes="application/pdf"
          [ref]="certifyingExamRef"
          [refId]="certifyingExamId"
          field="previousCorrespondence"
          [path]="certifyingExamDirectoryPath"
          [baseFileName]="getBaseFileName('previousCorrespondence')"></app-file-upload>
      </div>
    </div>
  </ng-container>

  <app-credentials-submission-form
    *ngIf="areCredentialsNecessary"
    [memberEntity]="memberEntity"
    [certifyingExamEntity]="certifyingExamEntity"
    [disabled]="disabled"></app-credentials-submission-form>

  <mat-checkbox formControlName="termsAndConditionsAccepted" color="primary">
    <label>
      I have read and accept the
      <a target="_blank" routerLink="/terms-and-conditions-certifying-examination"
        >terms and conditions of the ECVIM-CA Certifying Examination</a
      ><span *ngIf="isFormRequired('termsAndConditionsAccepted')"> *</span></label
    >
  </mat-checkbox>

  <mat-checkbox formControlName="confidentialityAgreementAccepted" color="primary">
    <label>
      I have read and accept the <a target="_blank" routerLink="/confidentiality-agreement">confidentiality agreement</a
      ><span *ngIf="isFormRequired('confidentialityAgreementAccepted')"> *</span>
    </label>
  </mat-checkbox>

  <mat-checkbox formControlName="privacyPolicyAccepted" color="primary">
    <label>
      I have read and accept the <a target="_blank" routerLink="/privacypolicy">privacy policy</a
      ><span *ngIf="isFormRequired('privacyPolicyAccepted')"> *</span>
    </label>
  </mat-checkbox>
</form>
