import dayjs from 'dayjs';
import { CertifyingExamEntity, GeneralExamEntity } from 'models/types';

// GENERAL EXAM

export const GENERAL_EXAM_REF = 'api::general-exam.general-exam';
export const GENERAL_EXAM_DIRECTORY_PATH = `General Exam Application/${new Date().getFullYear()}`;

export function getCurrentGeneralExamApplicationYear() {
  return dayjs().subtract(2, 'month').year() + 1;
}

export function getCurrentGeneralExamReviewYear() {
  return dayjs().subtract(5, 'month').year() + 1;
}

export function isGeneralExamApplicationActive(generalExamEntity: GeneralExamEntity) {
  return generalExamEntity?.attributes?.examYear == getCurrentGeneralExamApplicationYear();
}

export function isGeneralExamApplicationSubmitted(generalExamEntity: GeneralExamEntity) {
  return !!generalExamEntity.attributes?.termsAndConditionsAccepted;
}

// CERTIFYING EXAM

export const CERTIFYING_EXAM_REF = 'api::certifying-exam.certifying-exam';
export const CERTIFYING_EXAM_DIRECTORY_PATH = `Certifying Exam Application/${new Date().getFullYear()}`;

export function getCurrentCertifyingExamApplicationYear() {
  return dayjs().subtract(2, 'month').year() + 1;
}

export function getCurrentCertifyingExamReviewYear() {
  return dayjs().subtract(5, 'month').year() + 1;
}

export function isCertifyingExamApplicationActive(certifyingExamEntity: CertifyingExamEntity) {
  return certifyingExamEntity?.attributes?.examYear == getCurrentCertifyingExamApplicationYear();
}

export function isCertifyingExamApplicationSubmitted(certifyingExamEntity: CertifyingExamEntity) {
  return !!certifyingExamEntity.attributes?.termsAndConditionsAccepted;
}
