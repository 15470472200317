import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Page } from 'models/types';
import { EMPTY, mergeMap, Observable, of } from 'rxjs';

import { PageService } from '../services/page.service';

@Injectable({
  providedIn: 'root',
})
export class PageResolverService implements Resolve<Page | undefined> {
  private pageService = inject(PageService);
  private router = inject(Router);

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Page | undefined> {
    const allowUndefinedPage: boolean = !!route.data['allowUndefinedPage'];

    return this.pageService.getPublishedPageByPath(state.url).pipe(
      mergeMap((page) => {
        if (allowUndefinedPage || page) {
          return of(page);
        }
        this.router.navigate(['']);
        return EMPTY;
      })
    );
  }
}
