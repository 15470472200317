import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient, private router: Router) {}

  private get _redirectUrl() {
    return localStorage.getItem('redirectUrl') ?? undefined;
  }

  private set _redirectUrl(val: string | undefined) {
    if (val === undefined) {
      localStorage.removeItem('redirectUrl');
    } else {
      localStorage.setItem('redirectUrl', val);
    }
  }

  get user() {
    return localStorage.getItem('user') ?? undefined;
  }

  set user(val: string | undefined) {
    if (val === undefined) {
      localStorage.removeItem('user');
    } else {
      localStorage.setItem('user', val);
    }
  }

  get token() {
    return localStorage.getItem('jwt') ?? undefined;
  }

  set token(val: string | undefined) {
    if (val === undefined) {
      localStorage.removeItem('jwt');
    } else {
      localStorage.setItem('jwt', val);
    }
  }

  get isLoggedIn(): boolean {
    if (!this.token) {
      return false;
    }
    const decoded = jwtDecode(this.token);
    return decoded.exp !== undefined && decoded.exp >= Date.now() / 1000;
  }

  login(redirectUrl?: string) {
    this._redirectUrl = redirectUrl;
    window.location.replace(`${environment.strapiBackendUrl}/api/connect/cognito`);
  }

  logout() {
    this.user = undefined;
    this.token = undefined;
    window.location.href = environment.logoutUrl;
  }

  handleLoginCallback(params: HttpParams) {
    return this.http
      .get<{ jwt: string; user: string }>(`${environment.strapiBackendUrl}/api/auth/cognito/callback`, { params })
      .subscribe({
        next: (response) => {
          this.token = response.jwt;
          this.user = response.user;
          this.router.navigate([this._redirectUrl || '/home']);
        },
        error: () => {
          this.login();
        },
        complete: () => {
          this._redirectUrl = undefined;
        },
      });
  }
}
