<h2>Diplomate Reevaluation Data Verification</h2>

<p>
  The ECVIM-CA is required by the European Board of Veterinary Specialisation to re-evaluate every Diplomate every 5
  years.
</p>

<form [formGroup]="dataVerificationForm">
  <div class="form-row">
    <label>Please confirm your Reevaluation Year<span *ngIf="isFormRequired('reevaluationYear')"> *</span></label>
    <p>
      The Re-evaluation year is typically your certification year plus five when submitting your re-evaluation for the
      first time. For subsequent submissions, it should be the year of your most recent re-evaluation plus five. Please
      ensure that it does not overlap with any previous submissions.
    </p>
    <mat-form-field appearance="fill">
      <mat-label>Reevaluation Year</mat-label>
      <input matInput formControlName="reevaluationYear" type="number" />
      <mat-error *ngIf="!getFormByPath('reevaluationYear')!.valid">{{ genericErrorMessage }}</mat-error>
    </mat-form-field>
  </div>
</form>
