import { Component } from '@angular/core';
import { DiplomateReevaluation } from 'models/types';
import { defer, map, Observable, of } from 'rxjs';
import { DocumentListItem } from 'src/app/components/document-list/document-list.component';
import { CertifyingExamService } from 'src/app/services/certifying-exam.service';
import { DiplomateReevaluationService } from 'src/app/services/diplomate-reevaluation.service';
import { GeneralExamService } from 'src/app/services/general-exam.service';
import { MemberService } from 'src/app/services/member.service';
import { argmax } from 'src/app/utils/utils';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent {
  certifyingExams$ = defer(() => {
    if (!this.memberService.currentMemberId) {
      throw new Error('No active member');
    }
    return this.certifyingExamService
      .getCertifyingExamsForMember(this.memberService.currentMemberId)
      .pipe(map((result) => result.data.certifyingExams.data));
  });

  certifyingExamDocuments$: Observable<Record<string, DocumentListItem[]>> = this.certifyingExams$.pipe(
    map((certifyingExams) => {
      const documents: Record<string, DocumentListItem[]> = {
        'Application Result Letters': [],
        'Case Summary Conclusion Letters': [],
      };

      for (const certifyingExam of certifyingExams) {
        if (
          certifyingExam.attributes?.applicationResultPublished &&
          certifyingExam.attributes.applicationResultLetters?.data.length
        ) {
          const currentApplicationResultLetter = argmax(
            certifyingExam.attributes.applicationResultLetters.data,
            (file) => (file.id ? parseInt(file.id) : 0)
          );

          documents['Application Result Letters'].push({
            title: certifyingExam.attributes.examYear.toString(),
            url: currentApplicationResultLetter.attributes?.url ?? '',
          });
        }

        if (
          certifyingExam.attributes?.caseSummaryReviewPublished &&
          certifyingExam.attributes.caseSummaryConclusionLetter?.data
        ) {
          documents['Case Summary Conclusion Letters'].push({
            title: certifyingExam.attributes.examYear.toString(),
            url: certifyingExam.attributes.caseSummaryConclusionLetter.data.attributes?.url ?? '',
          });
        }
      }

      return documents;
    })
  );

  generalExams$ = defer(() => {
    if (!this.memberService.currentMemberId) {
      throw new Error('No active member');
    }
    return this.generalExamService
      .getGeneralExamsForMember(this.memberService.currentMemberId)
      .pipe(map((result) => result.data.generalExams.data));
  });

  generalExamDocuments$: Observable<Record<string, DocumentListItem[]>> = this.generalExams$.pipe(
    map((generalExams) => {
      const documents: Record<string, DocumentListItem[]> = {
        'Application Result Letters': [],
        'Case Summary Conclusion Letters': [],
      };

      for (const generalExam of generalExams) {
        if (
          generalExam.attributes?.applicationResultPublished &&
          generalExam.attributes.applicationResultLetters?.data.length
        ) {
          const currentApplicationResultLetter = argmax(generalExam.attributes.applicationResultLetters.data, (file) =>
            file.id ? parseInt(file.id) : 0
          );

          documents['Application Result Letters'].push({
            title: generalExam.attributes.examYear.toString(),
            url: currentApplicationResultLetter.attributes?.url ?? '',
          });
        }

        if (
          generalExam.attributes?.caseSummaryReviewPublished &&
          generalExam.attributes.caseSummaryConclusionLetter?.data
        ) {
          documents['Case Summary Conclusion Letters'].push({
            title: generalExam.attributes.examYear.toString(),
            url: generalExam.attributes.caseSummaryConclusionLetter.data.attributes?.url ?? '',
          });
        }
      }

      return documents;
    })
  );

  diplomateReevaluations$ = defer(() => {
    if (!this.memberService.currentMemberId) {
      throw new Error('No active member');
    }
    return this.diplomateReevaluationService
      .getDiplomateReevaluationsForMember(this.memberService.currentMemberId)
      .pipe(map((result) => result.data.diplomateReevaluations.data));
  });

  diplomateReevaluationDocuments$: Observable<Record<string, DocumentListItem[]>> = this.diplomateReevaluations$.pipe(
    map((diplomateReevaluations) => {
      const documents: Record<string, DocumentListItem[]> = {
        'Result Letters': [],
      };

      for (const diplomateReevaluation of diplomateReevaluations) {
        if (
          diplomateReevaluation.attributes?.reviewStatus &&
          diplomateReevaluation.attributes.resultLetters?.data.length
        ) {
          const currentResultLetter = argmax(diplomateReevaluation.attributes.resultLetters.data, (file) =>
            file.id ? parseInt(file.id) : 0
          );

          documents['Result Letters'].push({
            title: diplomateReevaluation.attributes.reevaluationYear.toString(),
            url: currentResultLetter.attributes?.url ?? '',
          });
        }
      }

      return documents;
    })
  );

  constructor(
    private memberService: MemberService,
    private certifyingExamService: CertifyingExamService,
    private generalExamService: GeneralExamService,
    private diplomateReevaluationService: DiplomateReevaluationService
  ) {
    // pass
  }
}
