import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';

import { GeneralExamForReviewByIdResolver } from '../resolvers/exam-application/general-exam-for-review-by-id.resolver';
import { MemberResolverService } from '../resolvers/member.resolver.service';
import { CommitteeService } from '../services/committee.service';
import { GeneralExamService } from '../services/general-exam.service';
import { isReviewer1, isReviewer2, ReviewType } from '../utils/review';

@Injectable({
  providedIn: 'root',
})
export class GeneralExamReviewGuard implements CanActivate {
  constructor(
    private router: Router,
    private committeeService: CommitteeService,
    private memberResolver: MemberResolverService,
    private generalExamService: GeneralExamService,
    private generalExamResolver: GeneralExamForReviewByIdResolver
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!Object.values(ReviewType).includes(route.paramMap.get('type') as any)) {
      this.router.navigate(['/committee-work/credentials-review']);
      return false;
    }

    const reviewType = route.paramMap.get('type') as ReviewType;

    return this.memberResolver.resolve(route, state).pipe(
      switchMap(({ member: memberEntity }) => {
        const memberId = memberEntity?.id ?? undefined;
        if (memberId === undefined) {
          return of(false);
        }
        return forkJoin([
          this.committeeService.getCredentialsCommitteesAsChair(memberId),
          this.generalExamResolver.resolve(route, state),
          this.generalExamService.getGeneralExamsForReviewByReviewer(memberId),
        ]).pipe(
          map(([committeesAsChairRes, generalExamEntity, allGeneralExamEntitiesForReview]) => {
            const committeesAsChair = committeesAsChairRes?.data?.comitees?.data ?? [];
            const specialitiesAsChair = committeesAsChair
              .map((committee) => committee.attributes?.speciality?.Name)
              .filter((s) => !!s)
              .map((s) => s!.replace('_', ' '));

            const allGeneralExamsForReview = allGeneralExamEntitiesForReview.map((gee) => gee.attributes!);
            const ge = generalExamEntity.attributes;

            const canActivate =
              !!ge &&
              ((reviewType == ReviewType.First && isReviewer1(ge, memberId)) ||
                (reviewType == ReviewType.Second && isReviewer2(ge, memberId)) ||
                ((reviewType == ReviewType.Final || reviewType == ReviewType.View) &&
                  specialitiesAsChair.includes(ge?.speciality?.Name?.replace('_', ' ') ?? '')) ||
                (reviewType == ReviewType.View &&
                  allGeneralExamsForReview.some(
                    (otherGE) =>
                      otherGE.speciality?.Name === ge.speciality?.Name &&
                      otherGE.examYear > ge.examYear &&
                      otherGE.resident?.data?.id == ge.resident?.data?.id
                  )));

            if (!canActivate) {
              this.router.navigate(['/committee-work/credentials-review']);
            }

            return canActivate;
          })
        );
      })
    );
  }
}
