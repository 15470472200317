import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Member } from 'models/types';

import { MemberFormComponent } from '../member-form/member-form.component';

@Component({
  selector: 'app-member-dialog',
  templateUrl: './member-dialog.component.html',
  styleUrls: ['./member-dialog.component.scss'],
})
export class MemberDialogComponent {
  @ViewChild(MemberFormComponent)
  memberForm: MemberFormComponent | null = null;

  data: Partial<Member> | undefined = undefined;

  constructor(@Inject(MAT_DIALOG_DATA) private dialogData?: Partial<Member>) {
    this.data = dialogData;
  }
}
