import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { NavigationItem } from 'models/types';
import { forkJoin, map, Observable, of } from 'rxjs';

import { MemberResolverService } from '../resolvers/member.resolver.service';
import { PageService } from '../services/page.service';
import { getAudienceForMember } from '../utils/member';

@Injectable({
  providedIn: 'root',
})
export class AudienceGuard implements CanActivate {
  constructor(
    private router: Router,
    private pageService: PageService,
    private memberResolver: MemberResolverService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return forkJoin([this.memberResolver.resolve(route, state), this.getRequiredAudience(route, state)]).pipe(
      map(([{ member: memberEntity }, requiredAudience]) => {
        const forbiddenAudience = this.getForbiddenAudienceFromRoute(route);
        const memberAudience = getAudienceForMember(memberEntity?.attributes);
        if (
          requiredAudience?.some((rA) => memberAudience.includes(rA)) &&
          !forbiddenAudience?.some((fA) => memberAudience.includes(fA))
        ) {
          return true;
        }
        this.router.navigate(['/']);
        return false;
      })
    );
  }

  getRequiredAudience(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const requiredAudience =
      this.getRequiredAudienceFromRoute(route) || this.getRequiredAudienceFromNavigationItem(route);
    if (requiredAudience === undefined) {
      return this.getRequiredAudienceForPath(state.url);
    }
    return of(requiredAudience);
  }

  getRequiredAudienceFromRoute(route: ActivatedRouteSnapshot): string[] | undefined {
    return route.data['audience'];
  }

  getForbiddenAudienceFromRoute(route: ActivatedRouteSnapshot): string[] | undefined {
    return route.data['forbiddenAudience'];
  }

  getNavigationItemFromRoute(route: ActivatedRouteSnapshot): NavigationItem | undefined {
    return route.data['navigationItem'];
  }

  getRequiredAudienceForPath(path: string) {
    return this.pageService.getAudienceByPath(path);
  }

  getRequiredAudienceFromNavigationItem(route: ActivatedRouteSnapshot) {
    return this.getNavigationItemFromRoute(route)
      ?.audience?.filter((a) => !!a)
      .map((a) => a!);
  }
}
