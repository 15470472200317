import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { DiplomateReevaluationEntity } from 'models/types';
import { map, Observable, of } from 'rxjs';
import { DiplomateReevaluationService } from 'src/app/services/diplomate-reevaluation.service';
import { MemberService } from 'src/app/services/member.service';

@Injectable({
  providedIn: 'root',
})
export class DiplomateReevaluationsForMemberResolver implements Resolve<DiplomateReevaluationEntity[]> {
  constructor(
    private memberService: MemberService,
    private diplomateReevaluationService: DiplomateReevaluationService
  ) {
    //pass
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DiplomateReevaluationEntity[]> {
    return this.diplomateReevaluationService
      .getDiplomateReevaluationsForMember(this.memberService.currentMemberId!)
      .pipe(map((res) => res.data.diplomateReevaluations.data));
  }
}
