// code is based on https://github.com/jaydenseric/extract-files

function isPlainObject(value: unknown) {
  if (typeof value !== 'object' || value === null) {
    return false;
  }

  const prototype = Object.getPrototypeOf(value);
  return (
    (prototype === null || prototype === Object.prototype || Object.getPrototypeOf(prototype) === null) &&
    !(Symbol.toStringTag in value) &&
    !(Symbol.iterator in value)
  );
}

type ExtractableFile = File | Blob;
export function isExtractableFile(value: unknown): value is ExtractableFile {
  return (
    (typeof File !== 'undefined' && value instanceof File) || (typeof Blob !== 'undefined' && value instanceof Blob)
  );
}

type ObjectPath = string;
type Cloneable = Array<unknown> | FileList | { [key: PropertyKey]: unknown };
type Clone = Exclude<Cloneable, FileList>;
interface Extraction<Extractable> {
  clone: unknown;
  files: Map<Extractable, string[]>;
}

export function extractFiles<Extractable>(
  value: unknown,
  isExtractable: (value: unknown) => value is Extractable,
  path: ObjectPath = ''
): Extraction<Extractable> {
  const clones: Map<Cloneable, Clone> = new Map();
  const files: Extraction<Extractable>['files'] = new Map();

  function recurse(value: unknown, path: ObjectPath, recursed: Set<Cloneable>): unknown {
    if (isExtractable(value)) {
      const filePaths = files.get(value);

      filePaths ? filePaths.push(path) : files.set(value, [path]);

      return null;
    }

    const valueIsList = Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
    const valueIsPlainObject = isPlainObject(value);

    if (valueIsList || valueIsPlainObject) {
      let clone = clones.get(value as Cloneable);

      const uncloned = !clone;

      if (uncloned) {
        clone = valueIsList
          ? []
          : // Replicate if the plain object is an `Object` instance.
          value instanceof Object
          ? {}
          : Object.create(null);

        clones.set(value as Cloneable, clone!);
      }

      if (!recursed.has(value as Cloneable)) {
        const pathPrefix = path ? `${path}.` : '';
        const recursedDeeper = new Set(recursed).add(value as Cloneable);

        if (valueIsList) {
          let index = 0;

          for (const item of value as unknown[]) {
            const itemClone = recurse(item, pathPrefix + index++, recursedDeeper);

            if (uncloned) (clone as unknown[]).push(itemClone);
          }
        } else
          for (const key in value as { [key: PropertyKey]: unknown }) {
            const propertyClone = recurse(
              (value as { [key: PropertyKey]: unknown })[key],
              pathPrefix + key,
              recursedDeeper
            );

            if (uncloned) (clone as { [key: PropertyKey]: unknown })[key] = propertyClone;
          }
      }

      return clone;
    }

    return value;
  }

  return {
    clone: recurse(value, path, new Set()),
    files,
  };
}
