import { Component, Input } from '@angular/core';

export interface DocumentListItem {
  title: string;
  subtitle?: string;
  url: string;
}

interface Category {
  name: string;
  documents: DocumentListItem[];
}

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss'],
})
export class DocumentListComponent {
  @Input()
  documents!: DocumentListItem[] | Record<string, DocumentListItem[]>;

  get categories(): Category[] {
    if (Array.isArray(this.documents)) {
      return [
        {
          name: '',
          documents: this.documents,
        },
      ];
    }
    return Object.entries(this.documents).flatMap(([name, documents]) => (documents.length ? { name, documents } : []));
  }

  constructor() {}
}
