<h2>Certifying Exam Data Verification</h2>

<form [formGroup]="dataVerificationForm">
  <div class="form-row">
    <label id="label-attempt">Please confirm your attempt <span *ngIf="isFormRequired('attempt')"> *</span></label>
    <mat-radio-group aria-labelledby="label-attempt" formControlName="attempt">
      <mat-radio-button color="primary" *ngFor="let attempt of attemptOptions" [value]="attempt"
        >Attempt {{ attempt }}</mat-radio-button
      >
      <mat-error *ngIf="getFormByPath('attempt')?.invalid">{{ genericErrorMessage }}</mat-error>
    </mat-radio-group>
  </div>

  <div class="form-row" *ngIf="!isCredentialsTypeNecessary">
    <mat-form-field appearance="fill">
      <mat-label>Credentials Type</mat-label>

      <mat-select formControlName="credentialsType">
        <mat-option *ngFor="let credentialsTypeOption of credentialsTypeOptions" [value]="credentialsTypeOption">{{
          displayCredentialsTypeOption(credentialsTypeOption)
        }}</mat-option>
      </mat-select>

      <mat-error *ngIf="!getFormByPath('credentialsType')?.valid">{{ genericErrorMessage }}</mat-error>
    </mat-form-field>
  </div>
</form>
