<mat-card>
  <mat-card-header *ngIf="showHeader">
    <div mat-card-avatar *ngIf="workStatus">
      <app-work-status-icon [workStatus]="workStatus"></app-work-status-icon>
    </div>
    <mat-card-title>{{ title }}</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <app-review-form
      [disabled]="disabled"
      [field]="field"
      [comment]="comment"
      [commentLabel]="commentLabel"
      [commentHiddenOnAccepted]="commentHiddenOnAccepted"
      [notes]="notes"
      [notesLabel]="notesLabel"
      [accepted]="accepted"></app-review-form>
  </mat-card-content>
</mat-card>
