import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { PaymentEntity } from 'models/types';
import { filter, first, map, Observable, tap } from 'rxjs';

import { PaymentsService } from '../services/payments.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentResolverService implements Resolve<PaymentEntity | null> {
  constructor(private payments: PaymentsService, private router: Router) {
    // pass
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): (PaymentEntity | null) | Observable<PaymentEntity | null> | Promise<PaymentEntity | null> {
    if (route.paramMap.has('id')) {
      return this.payments.getPayment(route.paramMap.get('id')!).pipe(
        filter((res) => !!res.data),
        map((res) => res.data.payment.data ?? null),
        tap((data) => {
          if (!data || data.attributes?.paid) {
            this.router.navigate(['/home']);
          }
        }),
        first()
      );
    }
    this.router.navigate(['/home']);
    return null;
  }
}
