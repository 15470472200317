<table mat-table [dataSource]="residentDetailsTableData">
  <!-- title Column -->
  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef>Title</th>
    <td mat-cell *matCellDef="let element">{{ element.title }}</td>
  </ng-container>

  <!-- value Column -->
  <ng-container matColumnDef="value">
    <th mat-header-cell *matHeaderCellDef>Value</th>
    <td mat-cell *matCellDef="let element">{{ element.value }}</td>
  </ng-container>
  <!-- <tr mat-header-row *matHeaderRowDef="residentDetailsTableDisplayedColumns"></tr> -->
  <tr mat-row *matRowDef="let row; columns: residentDetailsTableDisplayedColumns"></tr>
</table>
