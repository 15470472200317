import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-review-text',
  templateUrl: './review-text.component.html',
  styleUrls: ['./review-text.component.scss'],
})
export class ReviewTextComponent {
  @Input()
  label: string = '';

  @Input()
  text: string = '';

  constructor() {}
}
