import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DiplomateReevaluationInput, Enum_Diplomatereevaluation_Result } from 'models/types';

@Component({
  selector: 'app-diplomate-reevaluation-result-form',
  templateUrl: './diplomate-reevaluation-result-form.component.html',
  styleUrls: ['./diplomate-reevaluation-result-form.component.scss'],
})
export class DiplomateReevaluationResultFormComponent implements OnInit, OnChanges {
  @Input()
  result: string | undefined | null = undefined;

  @Input()
  resultNotes: string | undefined | null = undefined;

  @Input()
  resultComment: string | undefined | null = undefined;

  @Input()
  resultOptions: string[] = [];

  @Input()
  disabled = true;

  resultForm: FormGroup = this.fb.group({
    result: this.fb.control('', Validators.required),
    resultNotes: this.fb.control(''),
    resultComment: this.fb.control(''),
  });

  get values() {
    const values: DiplomateReevaluationInput = this.resultForm.value;

    if (this.isCommentHidden) {
      values.resultComment = '';
    }

    return values;
  }

  get isValid() {
    return !this.resultForm.invalid;
  }

  get isCommentHidden() {
    const result = this.resultForm.get('result')?.value;
    return result !== Enum_Diplomatereevaluation_Result.Successful;
  }

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.resultForm.patchValue({
      result: this.result,
      resultNotes: this.resultNotes,
      resultComment: this.resultComment,
    });

    this.resultForm.get('result')?.updateValueAndValidity();

    if (this.disabled) {
      this.resultForm.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled'] && !changes['disabled'].firstChange) {
      const disabled = changes['disabled'].currentValue;
      if (disabled) {
        this.resultForm.disable();
      } else {
        this.resultForm.enable();
      }
    }
  }
}
