import { animate, state, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input } from '@angular/core';
import { PaymentEntity } from 'models/types';
import { defer, map, shareReplay } from 'rxjs';

@Component({
  selector: 'app-payment-list',
  templateUrl: './payment-list.component.html',
  styleUrls: ['./payment-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0', minHeight: '0' })),
      state('expanded', style({ height: '*', minHeight: '72px' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PaymentListComponent {
  @Input()
  payments: PaymentEntity[] = [];

  @Input()
  disableActions = false;

  @Input()
  disableStatus = false;

  mobile$ = this.breakpointObserver.observe(['(max-width: 600px)']).pipe(
    map((res) => res.matches),
    shareReplay()
  );

  columns$ = defer(() =>
    this.mobile$.pipe(
      map((mobile) =>
        mobile
          ? ['expand', 'type', 'duedate']
          : [
              'type',
              'amount',
              'duedate',
              ...(this.disableStatus ? [] : ['status']),
              ...(this.disableActions ? [] : ['actions']),
            ]
      )
    )
  );

  expandedPayment: PaymentEntity | null = null;

  constructor(private breakpointObserver: BreakpointObserver) {
    // pass
  }

  prettify(text: string | undefined | null) {
    return text?.replaceAll('_', ' ') ?? '';
  }
}
