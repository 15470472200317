<div class="container" *ngIf="!!cmsPage">
  <div class="mat-display-1">{{ cmsPage.title }}</div>
  <div [innerHTML]="cmsPage.content | keepHtml"></div>
</div>

<app-grid class="p-2" *ngIf="!cmsPage">
  <app-grid-row>
    <div>
      <mat-card>
        <h2>Information</h2>
        <p>This is the application form for the General Examination in {{ currentExamYear }}.</p>
        <p>
          Deadline for applications is 12:00 (noon) Central European Time on the 15th June {{ currentExamYear - 1 }}.
        </p>
      </mat-card>

      <mat-card>
        <app-member-form [data]="member" [disabled]="memberFormDisabled"></app-member-form>
        <button
          mat-raised-button
          *ngIf="!memberFormDisabled"
          [disabled]="loading || memberForm.memberFormInvalid"
          (click)="saveMemberForm()">
          save <mat-icon *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
        </button>
        <button mat-raised-button *ngIf="memberFormDisabled" (click)="enableMemberFormEditMode()">edit</button>
      </mat-card>

      <div *ngIf="!activeGeneralExamEntity">
        <mat-card>
          <app-general-exam-data-verification-form
            [memberEntity]="memberEntity!"
            [disabled]="isApplicationLocked"></app-general-exam-data-verification-form>
          <button
            class="data-verification-btn"
            mat-raised-button
            (click)="submitGeneralExamDataVerification()"
            [disabled]="loading || isApplicationLocked || !generalExamDataVerficationForm?.isValid">
            register <mat-icon *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
          </button>
        </mat-card>
      </div>

      <div *ngIf="!!activeGeneralExamEntity">
        <mat-card>
          <h2>Payments</h2>
          <p>The fees and dates are due as follows:</p>
          <ul>
            <li>
              General Examination registration fee (NON-Refundable) - due on/before 1st November
              {{ currentExamYear - 1 }}: 140 Euro
            </li>
            <li>
              General Examination fee - due on/before 31st January {{ currentExamYear }} (Refundable if canceled for
              medical reasons after January 31st {{ currentExamYear }}): 560 EUR
            </li>
          </ul>
          <p>
            Exam payments will appear in your member account as soon as you receive the acceptance letter from the
            Credentials Committee (typically early in October). We will remind you in the acceptance letter to pay your
            exam fees.
          </p>
          <app-payment-list [payments]="relatedPayments"></app-payment-list>
        </mat-card>

        <mat-card>
          <h2>Resident Details</h2>
          <p>
            Please check if your data is correct. If not please contact Eva Frohnwieser (<a
              href="mailto:residency@ecvim-ca.college"
              >residency@ecvim-ca.college</a
            >).
          </p>
          <app-resident-details [member]="member"></app-resident-details>
        </mat-card>

        <mat-card>
          <app-general-exam-application-form
            [memberEntity]="memberEntity"
            [generalExamEntity]="activeGeneralExamEntity"
            [disabled]="isApplicationLocked"></app-general-exam-application-form>

          <app-alert *ngIf="isApplicationSubmitted">Your exam application was successfully submitted!</app-alert>

          <ng-container *ngIf="!isApplicationSubmitted">
            <p>Please review your application and make sure you uploaded all necessary data.</p>
            <p>After clicking the submit Button the General Examination application is closed.</p>
            <button
              class="general-exam-application-submit-btn"
              mat-raised-button
              (click)="submitGeneralExamApplicationForm()"
              [disabled]="loading || isApplicationLocked || !generalExamApplicationForm?.isValid">
              submit <mat-icon *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
            </button>
          </ng-container>
        </mat-card>
      </div>
    </div>
  </app-grid-row>
</app-grid>
