import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-review-large-text',
  templateUrl: './review-large-text.component.html',
  styleUrls: ['./review-large-text.component.scss'],
})
export class ReviewLargeTextComponent {
  @Input()
  label: string = '';

  @Input()
  text: string = '';

  get paragraphs() {
    return this.text.split('\n');
  }

  constructor() {}
}
