import { inject, Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
// import FormData from 'form-data';
import { UploadFileEntityResponse } from 'models/types';
import { environment } from 'src/environments/environment';

import { AuthService } from './auth.service';

export const UPLOAD_FILE_ENTITY_DATA_EXPAND = `
  data {
    id
    attributes {
      name
      url
      mime
      size
      alternativeText
    }
  }
`;

const UPLOAD = gql`
  mutation ($refId: ID, $ref: String, $field: String, $file: Upload!) {
    upload(refId: $refId, ref: $ref, field: $field, file: $file) {
      ${UPLOAD_FILE_ENTITY_DATA_EXPAND}
    }
  }
`;

const MUTIPLE_UPLOAD = gql`
  mutation($refId: ID, $ref: String, $field: String, $files: [Upload]!) {
    multipleUpload(refId: $refId, ref: $ref, field:$field, files: $files) {
      ${UPLOAD_FILE_ENTITY_DATA_EXPAND}
    }
  }
`;

const REMOVE_FILE = gql`
  mutation ($id: ID!) {
    removeFile(id: $id) {
      ${UPLOAD_FILE_ENTITY_DATA_EXPAND}
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  private authService = inject(AuthService);

  constructor(private apollo: Apollo, authService: AuthService) {}

  uploadFile(file: File, ref?: string, refId?: string, field?: string) {
    return this.apollo.mutate<{ upload: UploadFileEntityResponse }>({
      mutation: UPLOAD,
      variables: {
        file: file,
        ref: ref,
        refId: refId,
        field: field,
      },
      context: {
        useMultipart: true,
      },
    });
  }

  uploadMultipleFiles(files: File[], ref?: string, refId?: string, field?: string) {
    return this.apollo.mutate<{ multipleUpload: UploadFileEntityResponse[] }>({
      mutation: MUTIPLE_UPLOAD,
      variables: {
        files: files,
        ref: ref,
        refId: refId,
        field: field,
      },
      context: {
        useMultipart: true,
      },
    });
  }

  async uploadFileWithPost(file: File, ref: string, refId: string, field: string, path?: string, fileName?: string) {
    const formData = new FormData();

    formData.append('files', file, fileName);
    formData.append('ref', ref);
    formData.append('refId', refId);
    formData.append('field', field);

    if (path) {
      formData.append('path', path);
    }

    if (fileName) {
      formData.append('fileInfo', JSON.stringify({ alternativeText: file.name }));
    }

    return fetch(`${environment.strapiBackendUrl}/api/upload`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${this.authService.token}`,
      },
    });
  }

  removeFile(id: string) {
    return this.apollo.mutate<{ removeFile: UploadFileEntityResponse }>({
      mutation: REMOVE_FILE,
      variables: {
        id: id,
      },
    });
  }
}
