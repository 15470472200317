import { Component, Input } from '@angular/core';
import { WorkStatus } from 'src/app/utils/review';

@Component({
  selector: 'app-work-status-icon',
  templateUrl: './work-status-icon.component.html',
  styleUrls: ['./work-status-icon.component.scss'],
})
export class WorkStatusIconComponent {
  @Input()
  workStatus: WorkStatus | undefined = undefined;

  workStatusEnum = WorkStatus; // Expose enum in component class

  constructor() {}
}
