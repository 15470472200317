import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { NavigationItem, NavigationItemRelatedData } from 'models/types';
import { filter, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MenuResolverService implements Resolve<NavigationItem[]> {
  private readonly query = gql`
    query {
      renderNavigation(navigationIdOrSlug: "main-navigation", type: TREE, menuOnly: true, path: null) {
        __typename
        id
        title
        path
        audience
        order
        related {
          id
          attributes {
            __typename
            ... on Page {
              title
              publishedAt
            }
          }
        }
        items {
          id
          title
          audience
          path
          uiRouterKey
          related {
            id
            attributes {
              __typename
              ... on Page {
                title
                publishedAt
              }
            }
          }
        }
      }
    }
  `;

  constructor(private apollo: Apollo) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): NavigationItem[] | Observable<NavigationItem[]> | Promise<NavigationItem[]> {
    return this.getMenu();
  }

  getMenu() {
    return this.apollo
      .watchQuery<{ renderNavigation: NavigationItem[] }>({
        query: this.query,
      })
      .valueChanges.pipe(map((menu) => menu.data?.renderNavigation));
  }
}
