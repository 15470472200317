<form [formGroup]="applicationResultForm">
  <div class="form-row">
    <mat-form-field appearance="fill">
      <mat-label>Application Result</mat-label>
      <mat-select formControlName="applicationResult">
        <mat-option *ngFor="let opt of applicationResultOptions" [value]="opt">{{ opt }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="form-row">
    <mat-form-field appearance="fill">
      <mat-label>Application Result Comment</mat-label>
      <textarea matInput formControlName="applicationResultComment"></textarea>
    </mat-form-field>
  </div>
  <mat-checkbox
    *ngIf="!isFullCredentialsFeeNextApplicationHidden"
    formControlName="fullCredentialsFeeNextApplication"
    color="primary">
    <label>Apply full Credentials Fee for next application of resident</label>
  </mat-checkbox>
</form>
