import { Component, Input } from '@angular/core';

export type SimpleTableValueBasic = string | number;

export interface SimpleTableValueObject {
  value: SimpleTableValueBasic;
  routerLink?: string;
}

export type SimpleTableValue = SimpleTableValueBasic | SimpleTableValueObject;

export interface SimpleTableHeader {
  key: string;
  value: string | number;
}

export interface SimpleTableItem {
  key: string;
  value: SimpleTableValue | SimpleTableValue[];
}

@Component({
  selector: 'app-simple-table',
  templateUrl: './simple-table.component.html',
  styleUrls: ['./simple-table.component.scss'],
})
export class SimpleTableComponent {
  @Input()
  header: SimpleTableHeader | undefined = undefined;

  @Input()
  data: SimpleTableItem[] = [];

  @Input()
  footer: SimpleTableItem | undefined = undefined;

  @Input()
  centerValues: boolean = false;

  displayedColumns: string[] = ['key', 'value'];

  get parsedData() {
    return this.data
      .map(({ key, value }) => ({ key, value: Array.isArray(value) ? value : [value] }))
      .map(({ key, value: values }) => ({
        key,
        value: values.map((v) => (this.isSimpleTableValueObject(v) ? v : { value: v })),
      }));
  }

  constructor() {}

  isSimpleTableValueObject(obj: any): obj is SimpleTableValueObject {
    return (
      typeof obj === 'object' &&
      obj !== null &&
      (typeof obj.value === 'string' || typeof obj.value === 'number') &&
      (obj.routerLink === undefined || typeof obj.routerLink === 'string')
    );
  }
}
