<form [formGroup]="resultForm">
  <div class="form-row">
    <mat-form-field appearance="fill">
      <mat-label>Result</mat-label>
      <mat-select formControlName="result">
        <mat-option *ngFor="let opt of resultOptions" [value]="opt">{{ opt }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="form-row">
    <mat-form-field appearance="fill">
      <mat-label>Notes for Admin Team</mat-label>
      <textarea matInput formControlName="resultNotes"></textarea>
    </mat-form-field>
  </div>
  <div class="form-row" *ngIf="!isCommentHidden">
    <mat-form-field appearance="fill">
      <mat-label>Additional Letter Text</mat-label>
      <textarea matInput formControlName="resultComment"></textarea>
    </mat-form-field>
  </div>
</form>
