<app-grid class="p-2">
  <h1>Payments</h1>

  <mat-card>
    <ng-container *ngIf="payments$ | async as payments; else loading">
      <app-payment-list [payments]="payments"></app-payment-list>
    </ng-container>

    <ng-template #loading>
      <mat-spinner></mat-spinner>
    </ng-template>
  </mat-card>
</app-grid>
