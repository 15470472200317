import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { ComiteeEntityResponseCollection } from 'models/types';

const COMMITTEE_DATA_EXPAND = `
  data {
    id
    attributes {
      Name
      Chair {
        data {
          id
        }
      }
      speciality {
        id
        Name
      }
      Members {
        data {
          id
        }
      }
    }
  }
`;

const GET_CREDENTIALS_COMMITTEES_AS_CHAIR = gql`
  query($memberId: ID!) {
    comitees(
      filters: {
        and: [
          { Chair: { id: { eq: $memberId } } }
          { Name: { containsi: "credential" } }
        ]
      }
    ) {
      ${COMMITTEE_DATA_EXPAND}
    }
  }
`;

const GET_DIPLOMATE_REEVALUATION_COMMITTEES_AS_CHAIR = gql`
  query($memberId: ID!) {
    comitees(
      filters: {
        and: [
          { Chair: { id: { eq: $memberId } } }
          { Name: { containsi: "re-evaluation" } }
        ]
      }
    ) {
      ${COMMITTEE_DATA_EXPAND}
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CommitteeService {
  constructor(private apollo: Apollo) {
    // pass
  }

  getCredentialsCommitteesAsChair(memberId: string) {
    return this.apollo.query<{ comitees: ComiteeEntityResponseCollection }>({
      query: GET_CREDENTIALS_COMMITTEES_AS_CHAIR,
      variables: {
        memberId,
      },
    });
  }

  getDiplomateReevaluationCommitteesAsChair(memberId: string) {
    return this.apollo.query<{ comitees: ComiteeEntityResponseCollection }>({
      query: GET_DIPLOMATE_REEVALUATION_COMMITTEES_AS_CHAIR,
      variables: {
        memberId,
      },
    });
  }
}
