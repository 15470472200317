<app-grid class="p-2">
  <mat-card>
    <mat-card-title>Diplomate Re-evaluation Review</mat-card-title>
    <mat-card-content>
      <p>Dear Reviewer,</p>
      <p>
        please find below the submission of {{ diplomateFullname }} for your review. Evaluate each of the four sections
        using either "adequate" or "inadequate." When selecting "inadequate," please provide a comment to the committee
        chair in the "comment box."
      </p>
      <p>
        Please find the guidelines and descriptions
        <a
          href="https://ecvim-memberportal.s3.eu-central-1.amazonaws.com/Diplomates_Re_evaluation_Guidelines_fd7d533f76_111fba8634.pdf"
          target="_blank"
          >here</a
        >! (PDF)
      </p>
      <p>
        You can initiate the review, save your progress, and resume at a later time by clicking "save" at the bottom of
        the page. When you complete the review and wish to submit it, click on "submit." Note that once you submit, the
        review will no longer be accessible to you. If you need to revisit the submission, please contact the ECVIM-CA
        secretariat. The system will not allow submission if any information is missing, and it will prompt you
        accordingly.
      </p>

      <b>Comment-Box</b>
      <p>The “Comment” box is for your comments, which only you and the Committee Chair can see.</p>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title>1. Re-evaluation summary information</mat-card-title>
    <mat-card-content>
      <app-simple-table [data]="infoSummaryTableData"></app-simple-table>
      <div class="flex-container">
        <app-review-card
          class="flex-item"
          *ngIf="isFinalReviewer || isReviewer1"
          [showHeader]="isFinalReviewer"
          [workStatus]="reviewer1WorkStatus"
          title="Reviewer 1"
          [disabled]="loading || isReview1Locked || isFinalReviewer"
          field="summaryInfoReviewer1"
          [comment]="this.diplomateReevaluation.summaryInfoReviewer1Comment"
          [commentHiddenOnAccepted]="false"
          [accepted]="this.diplomateReevaluation.summaryInfoReviewer1Accepted"></app-review-card>

        <app-review-card
          class="flex-item"
          *ngIf="isFinalReviewer || isReviewer2"
          [showHeader]="isFinalReviewer"
          [workStatus]="reviewer2WorkStatus"
          title="Reviewer 2"
          [disabled]="loading || isReview2Locked || isFinalReviewer"
          field="summaryInfoReviewer2"
          [comment]="this.diplomateReevaluation.summaryInfoReviewer2Comment"
          [commentHiddenOnAccepted]="false"
          [accepted]="this.diplomateReevaluation.summaryInfoReviewer2Accepted"></app-review-card>

        <app-review-card
          class="flex-item"
          *ngIf="isFinalReviewer"
          [showHeader]="true"
          title="Final Review"
          [disabled]="loading || isReviewCompleted || !isReview1Locked || !isReview2Locked"
          field="summaryInfo"
          [comment]="this.diplomateReevaluation.summaryInfoComment"
          commentLabel="Text for Letter"
          [accepted]="this.diplomateReevaluation.summaryInfoAccepted"></app-review-card>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title>2. Working hours and AGM attendance</mat-card-title>

    <mat-card-content>
      <app-simple-table [data]="ebvsRequirementsTableData"></app-simple-table>
      <app-review-large-text
        label="Work Description"
        [text]="this.diplomateReevaluation.workDescription ?? ''"></app-review-large-text>

      <app-review-large-text
        *ngIf="this.diplomateReevaluation.unfulfillmentReasons?.trim()"
        label="Unfulfillment Reasons (AGM Attendance)"
        [text]="this.diplomateReevaluation.unfulfillmentReasons ?? ''"></app-review-large-text>

      <div class="flex-container">
        <app-review-card
          class="flex-item"
          *ngIf="isFinalReviewer || isReviewer1"
          [showHeader]="isFinalReviewer"
          [workStatus]="reviewer1WorkStatus"
          title="Reviewer 1"
          [disabled]="loading || isReview1Locked || isFinalReviewer"
          field="ebvsRequirementsReviewer1"
          [comment]="this.diplomateReevaluation.ebvsRequirementsReviewer1Comment"
          [commentHiddenOnAccepted]="false"
          [accepted]="this.diplomateReevaluation.ebvsRequirementsReviewer1Accepted"></app-review-card>

        <app-review-card
          class="flex-item"
          *ngIf="isFinalReviewer || isReviewer2"
          [showHeader]="isFinalReviewer"
          [workStatus]="reviewer2WorkStatus"
          title="Reviewer 2"
          [disabled]="loading || isReview2Locked || isFinalReviewer"
          field="ebvsRequirementsReviewer2"
          [comment]="this.diplomateReevaluation.ebvsRequirementsReviewer2Comment"
          [commentHiddenOnAccepted]="false"
          [accepted]="this.diplomateReevaluation.ebvsRequirementsReviewer2Accepted"></app-review-card>

        <app-review-card
          class="flex-item"
          *ngIf="isFinalReviewer"
          [showHeader]="true"
          title="Final Review"
          [disabled]="loading || isReviewCompleted || !isReview1Locked || !isReview2Locked"
          field="ebvsRequirements"
          [comment]="this.diplomateReevaluation.ebvsRequirementsComment"
          commentLabel="Text for Letter"
          [accepted]="this.diplomateReevaluation.ebvsRequirementsAccepted"></app-review-card>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title>3. Points</mat-card-title>

    <mat-card-content>
      <app-simple-table
        [centerValues]="true"
        [header]="{ key: 'Category', value: 'Points' }"
        [data]="reevaluationPointsTableData"
        [footer]="{ key: 'Total', value: diplomateReevaluation.totalPoints ?? '' }"></app-simple-table>

      <app-review-large-text
        *ngIf="diplomateReevaluation.additionalActivityPointsExplanation"
        label="Additional Activity Points Explanation"
        [text]="diplomateReevaluation.additionalActivityPointsExplanation"></app-review-large-text>

      <div class="flex-container">
        <app-review-card
          class="flex-item"
          *ngIf="isFinalReviewer || isReviewer1"
          [showHeader]="isFinalReviewer"
          [workStatus]="reviewer1WorkStatus"
          title="Reviewer 1"
          [disabled]="loading || isReview1Locked || isFinalReviewer"
          field="reevaluationPointsReviewer1"
          [comment]="this.diplomateReevaluation.reevaluationPointsReviewer1Comment"
          [commentHiddenOnAccepted]="false"
          [accepted]="this.diplomateReevaluation.reevaluationPointsReviewer1Accepted"></app-review-card>

        <app-review-card
          class="flex-item"
          *ngIf="isFinalReviewer || isReviewer2"
          [showHeader]="isFinalReviewer"
          [workStatus]="reviewer2WorkStatus"
          title="Reviewer 2"
          [disabled]="loading || isReview2Locked || isFinalReviewer"
          field="reevaluationPointsReviewer2"
          [comment]="this.diplomateReevaluation.reevaluationPointsReviewer2Comment"
          [commentHiddenOnAccepted]="false"
          [accepted]="this.diplomateReevaluation.reevaluationPointsReviewer2Accepted"></app-review-card>

        <app-review-card
          class="flex-item"
          *ngIf="isFinalReviewer"
          [showHeader]="true"
          title="Final Review"
          [disabled]="loading || isReviewCompleted || !isReview1Locked || !isReview2Locked"
          field="reevaluationPoints"
          [comment]="this.diplomateReevaluation.reevaluationPointsComment"
          commentLabel="Text for Letter"
          [accepted]="this.diplomateReevaluation.reevaluationPointsAccepted"></app-review-card>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title>4. Excel Sheet</mat-card-title>

    <mat-card-content>
      <app-review-file-upload
        [files]="convertFileToArray(diplomateReevaluation.reevaluationPointDetails?.data)"></app-review-file-upload>

      <div class="flex-container">
        <app-review-card
          class="flex-item"
          *ngIf="isFinalReviewer || isReviewer1"
          [showHeader]="isFinalReviewer"
          [workStatus]="reviewer1WorkStatus"
          title="Reviewer 1"
          [disabled]="loading || isReview1Locked || isFinalReviewer"
          field="reevaluationPointDetailsReviewer1"
          [comment]="this.diplomateReevaluation.reevaluationPointDetailsReviewer1Comment"
          [commentHiddenOnAccepted]="false"
          [accepted]="this.diplomateReevaluation.reevaluationPointDetailsReviewer1Accepted"></app-review-card>

        <app-review-card
          class="flex-item"
          *ngIf="isFinalReviewer || isReviewer2"
          [showHeader]="isFinalReviewer"
          [workStatus]="reviewer2WorkStatus"
          title="Reviewer 2"
          [disabled]="loading || isReview2Locked || isFinalReviewer"
          field="reevaluationPointDetailsReviewer2"
          [comment]="this.diplomateReevaluation.reevaluationPointDetailsReviewer2Comment"
          [commentHiddenOnAccepted]="false"
          [accepted]="this.diplomateReevaluation.reevaluationPointDetailsReviewer2Accepted"></app-review-card>

        <app-review-card
          class="flex-item"
          *ngIf="isFinalReviewer"
          [showHeader]="true"
          title="Final Review"
          [disabled]="loading || isReviewCompleted || !isReview1Locked || !isReview2Locked"
          field="reevaluationPointDetails"
          [comment]="this.diplomateReevaluation.reevaluationPointDetailsComment"
          commentLabel="Text for Letter"
          [accepted]="this.diplomateReevaluation.reevaluationPointDetailsAccepted"></app-review-card>
      </div>
    </mat-card-content>
  </mat-card>

  <!-- BUTTONS AND RESULT -->

  <mat-card>
    <div *ngIf="isFinalReviewer">
      <app-review-file-upload [files]="diplomateReevaluation.resultLetters?.data ?? []"></app-review-file-upload>

      <app-diplomate-reevaluation-result-form
        [disabled]="loading || isReviewCompleted || !isReview1Locked || !isReview2Locked"
        [resultOptions]="this.resultOptions"
        [result]="this.diplomateReevaluation.result"
        [resultNotes]="this.diplomateReevaluation.resultNotes"
        [resultComment]="this.diplomateReevaluation.resultComment"></app-diplomate-reevaluation-result-form>
    </div>

    <button
      mat-raised-button
      (click)="saveReview()"
      [disabled]="
        loading ||
        isReviewCompleted ||
        (!isFinalReviewer && isReviewer1 && isReview1Locked) ||
        (!isFinalReviewer && isReviewer2 && isReview2Locked) ||
        (isFinalReviewer && (!isReview1Locked || !isReview2Locked))
      ">
      Save <mat-icon *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
    </button>

    <button
      mat-raised-button
      (click)="saveReview(true)"
      [disabled]="
        loading ||
        !isReviewValid ||
        isReviewCompleted ||
        (!isFinalReviewer && isReviewer1 && isReview1Locked) ||
        (!isFinalReviewer && isReviewer2 && isReview2Locked) ||
        (isFinalReviewer && (!isReview1Locked || !isReview2Locked))
      ">
      Submit <mat-icon *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
    </button>
  </mat-card>
</app-grid>
