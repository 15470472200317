<app-grid class="p-2">
  <mat-card>
    <mat-card-title>Credentials Review Overview</mat-card-title>
    <mat-card-content>
      <p>Please find here below the list of credentials for you to review.</p>
      <p>
        The deadline for reviewing Cardiology and Internal Medicine applications is 31.08.{{ currentYear }}, and for
        Oncology applications, it is 15.09.{{ currentYear }}.
      </p>
      <p>
        Please click on the name to review the submission by candidate. Two reviewers will review each submission
        independently.
      </p>
      <p>The work status in the overview will change according to your work progress.</p>
      <p>
        Once you have evaluated all items, your work status will change to
        <app-work-status-icon [workStatus]="workStatusEnum.Finished"></app-work-status-icon> and the Credentials
        Committee chair will then set the final application status depending on your results.
      </p>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <div id="table-container">
      <table mat-table [dataSource]="examsForReviewTableData">
        <ng-container matColumnDef="examType">
          <th mat-header-cell *matHeaderCellDef>Exam Type</th>
          <td mat-cell *matCellDef="let element">{{ element.examType }}</td>
        </ng-container>

        <ng-container matColumnDef="reviewType">
          <th mat-header-cell *matHeaderCellDef>Review Type</th>
          <td mat-cell *matCellDef="let element">{{ element.reviewType }}</td>
        </ng-container>

        <ng-container matColumnDef="resident">
          <th mat-header-cell *matHeaderCellDef>Resident</th>
          <td mat-cell *matCellDef="let element">
            <a [routerLink]="element.routerLink">{{ element.resident }}</a>
          </td>
        </ng-container>

        <ng-container matColumnDef="reviewers">
          <th mat-header-cell *matHeaderCellDef>Responsible Reviewers</th>
          <td mat-cell *matCellDef="let element">
            <div>{{ element.reviewer1 }}</div>
            <div>{{ element.reviewer2 }}</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="reviewStatus">
          <th mat-header-cell *matHeaderCellDef>Work Status</th>
          <td mat-cell *matCellDef="let element">
            <app-work-status-icon [workStatus]="element.reviewStatus"></app-work-status-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="result">
          <th mat-header-cell *matHeaderCellDef>Application Result</th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.result">{{ element.result }}</div>
            <div *ngIf="element.result === null">Undecided</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="resultLetter">
          <th mat-header-cell *matHeaderCellDef>Letters</th>
          <td mat-cell *matCellDef="let element">
            <div *ngFor="let letter of element.resultLetters">
              <a [href]="letter.link" target="_blank">{{ letter.name }}</a>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="examsForReviewTableDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: examsForReviewTableDisplayedColumns"></tr>
      </table>
    </div>
    <div class="empty-table-msg" *ngIf="examsForReviewTableData.length === 0">No credentials to review!</div>
  </mat-card>
</app-grid>
