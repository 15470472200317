import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DiplomateReevaluationEntity, DiplomateReevaluationInput, MemberEntity } from 'models/types';
import { getNextReevaluationYearForMember } from 'src/app/utils/diplomate-reevaluation';
import { getFormByPath, isFormRequired } from 'src/app/utils/form';

@Component({
  selector: 'app-diplomate-reevaluation-data-verification-form',
  templateUrl: './diplomate-reevaluation-data-verification-form.component.html',
  styleUrls: ['./diplomate-reevaluation-data-verification-form.component.scss'],
})
export class DiplomateReevaluationDataVerificationFormComponent implements OnInit, OnChanges {
  @Input()
  memberEntity!: MemberEntity;

  @Input()
  disabled = true;

  @Input()
  diplomateReevaluationsEntities: DiplomateReevaluationEntity[] | undefined = undefined;

  loading = false;

  dataVerificationForm = this.fb.group({
    reevaluationYear: this.fb.control<number>(this.currentYear, [
      Validators.required,
      Validators.min(this.currentYear),
    ]),
  });

  get isValid() {
    return this.dataVerificationForm.valid;
  }

  get values(): DiplomateReevaluationInput {
    const values: DiplomateReevaluationInput = this.dataVerificationForm.value;
    values.speciality = { Name: this.member.Speciality![0]!.Name };
    return values;
  }

  get member() {
    return this.memberEntity.attributes!;
  }

  get genericErrorMessage() {
    return 'This field is required!';
  }

  get currentYear() {
    return new Date().getFullYear();
  }

  get diplomateReevaluations() {
    return this.diplomateReevaluationsEntities?.map((dre) => dre.attributes!);
  }

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    if (this.diplomateReevaluations && this.member) {
      const nextReevaluationYear = getNextReevaluationYearForMember(this.member, this.diplomateReevaluations);
      this.dataVerificationForm.get('reevaluationYear')?.setValue(nextReevaluationYear ?? this.currentYear);
    }

    if (this.disabled) {
      this.dataVerificationForm.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled'] && !changes['disabled'].firstChange) {
      const disabled = changes['disabled'].currentValue;
      if (disabled) {
        this.dataVerificationForm.disable();
      } else {
        this.dataVerificationForm.enable();
      }
    }
  }

  getFormByPath(path: string) {
    return getFormByPath(this.dataVerificationForm, path);
  }

  isFormRequired(path: string) {
    return isFormRequired(this.dataVerificationForm, path);
  }
}
