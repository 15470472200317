import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { GeneralExamEntity } from 'models/types';
import { Observable } from 'rxjs';
import { GeneralExamService } from 'src/app/services/general-exam.service';
import { MemberService } from 'src/app/services/member.service';
import { getCurrentGeneralExamReviewYear } from 'src/app/utils/exam';

@Injectable({
  providedIn: 'root',
})
export class GeneralExamsAsReviewerResolver implements Resolve<GeneralExamEntity[]> {
  constructor(private memberService: MemberService, private generalExamService: GeneralExamService) {
    //pass
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<GeneralExamEntity[]> {
    return this.generalExamService.getGeneralExamsForReviewByReviewer(
      this.memberService.currentMemberId!,
      getCurrentGeneralExamReviewYear()
    );
  }
}
