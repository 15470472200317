import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import {
  ComponentMasterdataYearInput,
  DiplomateReevaluationEntity,
  DiplomateReevaluationInput,
  Enum_Componentmasterdataworksector_Name,
  Enum_Diplomatereevaluation_Requesteddiplomatestatus,
  MemberEntity,
  UploadFileEntity,
} from 'models/types';
import {
  DIPLOMATE_REEVALUATION_DIRECTORY_PATH,
  DIPLOMATE_REEVALUATION_REF,
  getMaxPointsNote,
  REEVALUATION_POINTS_FIELDS,
} from 'src/app/utils/diplomate-reevaluation';
import { getBaseFileName, getFormByPath, isFormRequired } from 'src/app/utils/form';
import { getDate, getDateString } from 'src/app/utils/utils';

@Component({
  selector: 'app-diplomate-reevaluation-application-form',
  templateUrl: './diplomate-reevaluation-application-form.component.html',
  styleUrls: ['./diplomate-reevaluation-application-form.component.scss'],
})
export class DiplomateReevaluationApplicationFormComponent implements OnInit, OnChanges {
  @Input()
  memberEntity: MemberEntity | undefined = undefined;

  @Input()
  diplomateReevaluationEntity: DiplomateReevaluationEntity | undefined | null = undefined;

  @Input()
  disabled = true;

  loading = false;

  reevaluationApplicationForm = this.fb.group({
    requestedDiplomateStatus: this.fb.control<Enum_Diplomatereevaluation_Requesteddiplomatestatus | null>(
      null,
      Validators.required
    ),
    reevaluationPeriodStart: this.fb.control<Date | null>(null, Validators.required),
    reevaluationPeriodEnd: this.fb.control<Date | null>(null, Validators.required),
    workHoursPerWeek: this.fb.control<number | null>(null, [
      Validators.required,
      Validators.min(0),
      Validators.max(168),
    ]),
    workTypes: this.fb.control<Enum_Componentmasterdataworksector_Name[]>(
      [],
      [Validators.required, Validators.minLength(1)]
    ),
    workDescription: this.fb.control<string | null>(null, Validators.required),
    agmAttendanceYears: this.fb.array(
      [
        this.fb.control<boolean>(false),
        this.fb.control<boolean>(false),
        this.fb.control<boolean>(false),
        this.fb.control<boolean>(false),
        this.fb.control<boolean>(false),
      ],
      Validators.required
    ),
    unfulfillmentReasons: this.fb.control<string | null>(null),

    agmAttendancePoints: this.fb.control<number | null>(null),
    examQuestionContributionPoints: this.fb.control<number | null>(null),
    committeeWorkPoints: this.fb.control<number | null>(null),
    residentSupervisionPoints: this.fb.control<number | null>(null),
    conferenceOrWebinarAttendancePoints: this.fb.control<number | null>(null),
    presentationPoints: this.fb.control<number | null>(null),
    publicationPoints: this.fb.control<number | null>(null),
    peerReviewingPoints: this.fb.control<number | null>(null),
    collegeActivityParticipationPoints: this.fb.control<number | null>(null),
    additionalActivityPoints: this.fb.control<number | null>(null),
    additionalActivityPointsExplanation: this.fb.control<string | null>(null),
    reevaluationPointDetails: this.fb.control<UploadFileEntity[]>([], [Validators.required, Validators.minLength(1)]),

    declarationAccepted: this.fb.control(false, Validators.requiredTrue),
  });

  private points = REEVALUATION_POINTS_FIELDS;
  pointsTableDisplayedColumns = ['label', 'points'];
  pointsTableData = Object.entries(REEVALUATION_POINTS_FIELDS).map(([key, value]) => ({
    key,
    ...value,
    formControl: this.getFormByPath(key)!,
  }));

  get member() {
    return this.memberEntity?.attributes!;
  }
  get isValid() {
    return this.reevaluationApplicationForm.valid;
  }

  get values() {
    const values: DiplomateReevaluationInput = Object.fromEntries(
      Object.entries(this.reevaluationApplicationForm.controls)
        .map(([key, control]) => [key, control.value])
        .filter(([_, value]) => !Array.isArray(value))
    );
    values.workTypes = (this.reevaluationApplicationForm.get('workTypes')!.value ?? []).map((name) => ({ name }));
    values.agmAttendanceYears = (this.reevaluationApplicationForm.get('agmAttendanceYears')!.value ?? []).flatMap(
      (checked, index) =>
        checked ? [{ year: this.agmAttendanceYearOptions[index] } as ComponentMasterdataYearInput] : []
    );
    if (values.reevaluationPeriodStart) {
      values.reevaluationPeriodStart = getDateString(new Date(values.reevaluationPeriodStart));
    }
    if (values.reevaluationPeriodEnd) {
      values.reevaluationPeriodEnd = getDateString(new Date(values.reevaluationPeriodEnd));
    }
    return values;
  }

  get pointsFormControls() {
    return Object.entries(this.reevaluationApplicationForm.controls)
      .filter(([key, _]) => key.endsWith('Points'))
      .map(([_, control]) => control as FormControl<number | null>);
  }

  get totalPoints() {
    return this.pointsFormControls.reduce(
      (totalPoints, control) => (control.value ? totalPoints + control.value : totalPoints),
      0
    );
  }

  get genericErrorMessage() {
    return 'This field is required!';
  }

  get diplomateReevaluationRef() {
    return DIPLOMATE_REEVALUATION_REF;
  }

  get diplomateReevaluationId() {
    return this.diplomateReevaluationEntity?.id ?? undefined;
  }

  get diplomateReevaluation() {
    return this.diplomateReevaluationEntity?.attributes ?? undefined;
  }

  get diplomateReevaluationDirectoryPath() {
    return DIPLOMATE_REEVALUATION_DIRECTORY_PATH;
  }

  get workTypeOptions() {
    return Object.values(Enum_Componentmasterdataworksector_Name);
  }

  get diplomateReevaluationYear() {
    return this.diplomateReevaluation?.reevaluationYear;
  }

  get agmAttendanceYearOptions() {
    if (!this.diplomateReevaluationYear) {
      return [];
    }
    return [
      this.diplomateReevaluationYear - 4,
      this.diplomateReevaluationYear - 3,
      this.diplomateReevaluationYear - 2,
      this.diplomateReevaluationYear - 1,
      this.diplomateReevaluationYear,
    ];
  }

  get requestedDiplomateStatusOptions() {
    return Object.values(Enum_Diplomatereevaluation_Requesteddiplomatestatus);
  }

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    const dr = this.diplomateReevaluation;

    // set validators
    Object.entries(this.points).forEach(([key, value]) =>
      this.reevaluationApplicationForm
        .get(key)
        ?.setValidators([Validators.min(0)].concat(value.max ? [Validators.max(value.max)] : []))
    );

    if (dr) {
      this.reevaluationApplicationForm.patchValue({
        requestedDiplomateStatus: dr.requestedDiplomateStatus ?? null,
        reevaluationPeriodStart:
          getDate(dr.reevaluationPeriodStart) ?? new Date(this.diplomateReevaluationYear! - 5, 9, 32),
        reevaluationPeriodEnd: getDate(dr.reevaluationPeriodEnd) ?? new Date(this.diplomateReevaluationYear!, 9, 32),
        workHoursPerWeek: dr.workHoursPerWeek,
        workTypes: dr.workTypes?.map((cmws) => cmws!.name) ?? [],
        workDescription: dr.workDescription,
        unfulfillmentReasons: dr.unfulfillmentReasons,

        agmAttendancePoints: dr.agmAttendancePoints ?? null,
        examQuestionContributionPoints: dr.examQuestionContributionPoints ?? null,
        committeeWorkPoints: dr.committeeWorkPoints ?? null,
        residentSupervisionPoints: dr.residentSupervisionPoints ?? null,
        conferenceOrWebinarAttendancePoints: dr.conferenceOrWebinarAttendancePoints ?? null,
        presentationPoints: dr.presentationPoints ?? null,
        publicationPoints: dr.publicationPoints ?? null,
        peerReviewingPoints: dr.peerReviewingPoints ?? null,
        collegeActivityParticipationPoints: dr.collegeActivityParticipationPoints ?? null,
        additionalActivityPoints: dr.additionalActivityPoints ?? null,
        additionalActivityPointsExplanation: dr.additionalActivityPointsExplanation ?? null,
        reevaluationPointDetails: [dr.reevaluationPointDetails?.data].filter((uf) => !!uf) as UploadFileEntity[],

        declarationAccepted: dr.declarationAccepted,
      });

      dr.agmAttendanceYears?.forEach((cmy) => {
        const year = cmy!.year;
        const index = this.getAgmAttendanceIndexForYear(year);
        if (index !== undefined) {
          const formControl = this.reevaluationApplicationForm.get('agmAttendanceYears')!.get(index.toString());
          formControl?.patchValue(true);
        }
      });
    }

    if (this.disabled) {
      this.reevaluationApplicationForm.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled'] && !changes['disabled'].firstChange) {
      const disabled = changes['disabled'].currentValue;
      if (disabled) {
        this.reevaluationApplicationForm.disable();
      } else {
        this.reevaluationApplicationForm.enable();
      }
    }
  }

  getAgmAttendanceIndexForYear(year: number) {
    const index = this.agmAttendanceYearOptions.findIndex((agmAttYear) => agmAttYear == year);
    if (index == -1) {
      return undefined;
    }
    return index;
  }

  getBaseFileName(field: string) {
    return getBaseFileName('diplomate-reevaluation', field, this.member);
  }

  getFormByPath(path: string) {
    return getFormByPath(this.reevaluationApplicationForm, path) as FormControl;
  }

  isFormRequired(path: string) {
    return isFormRequired(this.reevaluationApplicationForm, path);
  }

  getMaxPointsNote(max: number | undefined) {
    return getMaxPointsNote(max);
  }
}
