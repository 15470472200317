import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { GeneralExamEntity } from 'models/types';
import { map, Observable } from 'rxjs';
import { GeneralExamService } from 'src/app/services/general-exam.service';
import { MemberService } from 'src/app/services/member.service';

@Injectable({
  providedIn: 'root',
})
export class GeneralExamsForMemberResolver implements Resolve<GeneralExamEntity[]> {
  constructor(private memberService: MemberService, private generalExamService: GeneralExamService) {
    //pass
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<GeneralExamEntity[]> {
    return this.generalExamService
      .getGeneralExamsForMember(this.memberService.currentMemberId!)
      .pipe(map((res) => res.data.generalExams.data));
  }
}
