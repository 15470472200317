<app-grid class="p-2" *ngIf="hasRole; else newUserInfo">
  <div class="mat-display-1">Recent news</div>
  <ng-container *ngIf="newsItems$ | async as newsItems; else noData" width="75%">
    <app-grid-row *ngFor="let newsitem of newsItems">
      <mat-card>
        <mat-card-title>{{ newsitem.attributes?.Title }}</mat-card-title>
        <div class="container">
          <ng-container *ngIf="(newsitem.attributes?.Picture?.data?.length ?? 0) > 0">
            <img
              mat-card-lg-image
              class="picture"
              *ngFor="let pictures of newsitem.attributes?.Picture?.data ?? []"
              src="{{ pictures.attributes?.url }}" />
          </ng-container>
          <span>
            <mat-card-content markdown ngPreserveWhitespaces>
              {{ newsitem.attributes?.Content }}
            </mat-card-content>
          </span>
        </div>
      </mat-card>
    </app-grid-row>
  </ng-container>
  <ng-template #noData> No data </ng-template>
</app-grid>

<ng-template #newUserInfo>
  <app-grid class="p-2">
    <app-new-user-info></app-new-user-info>
  </app-grid>
</ng-template>
