<div id="layout" [ngClass]="{ mobile }">
  <header>
    <a routerLink="/">
      <div id="logos">
        <img src="assets/images/Aeskulapstab.png" alt="logo" />
        <img src="assets/images/ecvim_logo_medium-00687d3a.webp" alt="logo" />
      </div>
    </a>

    <div>
      <nav id="navbar">
        <button *ngIf="mobile" class="navbar-burger" type="button" mat-icon-button (click)="sidenav.toggle()">
          <mat-icon *ngIf="!sidenav.opened && (hasNavItems$ | async)" aria-hidden="false" aria-label="menu icon"
            >menu</mat-icon
          >
          <mat-icon *ngIf="sidenav.opened" aria-hidden="false" aria-label="close icon">close</mat-icon>
        </button>

        <ul *ngIf="!mobile" class="navbar-links">
          <ng-container *ngIf="navItems$ | async as navItems">
            <li *ngFor="let navItem of navItems">
              <!-- If the item has sub-items, render a dropdown -->
              <ng-container *ngIf="navItem.items?.length; else singleItem">
                <app-auto-open-menu #menu>
                  <a trigger>
                    <span>{{ navItem.title }}</span>
                    <mat-icon>arrow_drop_down</mat-icon>
                  </a>

                  <a content mat-menu-item *ngFor="let subNavItem of navItem.items" [routerLink]="subNavItem!.path">
                    {{ subNavItem!.title }}
                  </a>
                </app-auto-open-menu>
              </ng-container>

              <!-- Else, render a normal link -->
              <ng-template #singleItem>
                <a [routerLink]="navItem.path">
                  {{ navItem.title }}
                </a>
              </ng-template>
            </li>
          </ng-container>
        </ul>

        <div class="navbar-profile" *ngIf="member" color="accent">
          <button *ngIf="!mobile" mat-flat-button [matMenuTriggerFor]="menu">
            <mat-icon aria-hidden="false" aria-label="person icon"> person </mat-icon>
            <div>
              <div>{{ memberName }}</div>
              <div>{{ memberEmail }}</div>
            </div>
          </button>

          <button *ngIf="mobile" mat-mini-fab color="white" [matMenuTriggerFor]="menu">
            <mat-icon aria-hidden="false" aria-label="person icon"> person </mat-icon>
          </button>

          <mat-menu #menu="matMenu">
            <button mat-menu-item routerLink="/user/profile">
              <mat-icon aria-hidden="false" aria-label="person icon">person</mat-icon>
              Profile Settings
            </button>
            <button mat-menu-item routerLink="/user/documents">
              <mat-icon aria-hidden="false" aria-label="documents icon">folder</mat-icon>
              Documents
            </button>
            <button mat-menu-item routerLink="/user/payments">
              <mat-icon aria-hidden="false" aria-label="payments icon">payments</mat-icon>
              Payments
            </button>
            <button mat-menu-item (click)="signOut()" style="border-top: 1px solid lightgray">
              <mat-icon aria-hidden="false" aria-label="logout icon">logout</mat-icon>
              Sign Out
            </button>
          </mat-menu>
        </div>
      </nav>
    </div>
  </header>

  <mat-sidenav-container class="mobile-sidenav-container">
    <mat-sidenav #sidenav mode="over" [opened]="false">
      <mat-accordion>
        <ng-container *ngFor="let navItem of navItems$ | async">
          <!-- If the item has sub-items, render collapsible -->
          <ng-container *ngIf="navItem.items?.length; else mobileSingleItem">
            <mat-expansion-panel #multiPanel>
              <mat-expansion-panel-header>
                {{ navItem.title }}
              </mat-expansion-panel-header>
              <mat-nav-list>
                <a
                  mat-list-item
                  *ngFor="let subNavItem of navItem.items"
                  [routerLink]="subNavItem!.path"
                  (click)="multiPanel.close(); sidenav.close()">
                  {{ subNavItem?.title }}
                </a>
              </mat-nav-list>
            </mat-expansion-panel>
          </ng-container>

          <!-- Else, render a simplified collapsible with no content -->
          <ng-template #mobileSingleItem>
            <mat-expansion-panel
              #singlePanel
              [routerLink]="navItem.path"
              routerLinkActive="active"
              (click)="singlePanel.close(); sidenav.close()"
              hideToggle>
              <mat-expansion-panel-header>
                {{ navItem.title }}
              </mat-expansion-panel-header>
            </mat-expansion-panel>
          </ng-template>
        </ng-container>
      </mat-accordion>
    </mat-sidenav>

    <mat-sidenav-content>
      <app-content [mobile]="mobile"></app-content>
      <app-footer></app-footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
