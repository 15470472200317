<h1 mat-dialog-title>Welcome to the new member portal!</h1>
<p>We need to validate your data, so please check and complete your data in the following form</p>
<app-member-form
  mat-dialog-content
  #memberForm
  [data]="data"
  [withDetails]="true"
  [withCheckboxes]="true"></app-member-form>

<p>* These fields are mandatory</p>
<div mat-dialog-actions align="end">
  <button mat-raised-button [disabled]="memberForm.memberFormInvalid" [matDialogClose]="memberForm.memberFormValues">
    Save
  </button>
</div>
