// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  strapiBackendUrl: 'https://memberportal-api-test.ecvim-ca.college',
  logoutUrl: ' https://login-test.ecvim-ca.college/logout?client_id=4a6k2b9846ntsh47vsnlfum9e4&logout_uri=https://ecvim-ca.college',
  paypal: {
    clientId: 'AXjrTAmVU6acrbNmgRLsc9Fkp_KRY8ezWrfMq2bd3tMCjRkVxcVEsFe087SZqk2TQZioKChcFi6V5XT3',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
