<app-grid class="p-2">
  <app-grid-row>
    <ng-container *ngIf="payment; else loading">
      <table mat-table [dataSource]="paymentItems">
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>Description</th>
          <td mat-cell *matCellDef="let item">
            <div>
              {{ prettify(item.description) }}
            </div>
            <div *ngIf="item.subNote" class="mat-small">
              {{ item.subNote }}
            </div>
          </td>
          <td mat-footer-cell *matFooterCellDef>Total</td>
        </ng-container>

        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef>Amount</th>
          <td mat-cell *matCellDef="let item">{{ item.amount.toFixed(2) }} {{ payment.attributes?.currency }}</td>
          <td mat-footer-cell *matFooterCellDef>
            {{ payment.attributes?.totalAmount?.toFixed(2) }} {{ payment.attributes?.currency }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="paymentItemsColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: paymentItemsColumns"></tr>
        <tr mat-footer-row *matFooterRowDef="paymentItemsColumns"></tr>
      </table>
    </ng-container>
    <ng-template #loading>
      <mat-spinner></mat-spinner>
    </ng-template>
    <div *ngIf="!success" id="paypal-button"></div>
    <div *ngIf="success" class="success" [@fade]="true">
      <div class="icon-container">
        <mat-icon fontIcon="check_circle" inline class="success-icon"></mat-icon>
      </div>
      <h2>Payment was successful!</h2>
    </div>
  </app-grid-row>
</app-grid>
