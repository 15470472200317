import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-review-form',
  templateUrl: './review-form.component.html',
  styleUrls: ['./review-form.component.scss'],
})
export class ReviewFormComponent implements OnInit, OnChanges {
  @Input()
  field: string = '';

  @Input()
  comment: string | undefined | null = undefined;

  @Input()
  notes: string | undefined | null = undefined;

  @Input()
  accepted: boolean | undefined | null = undefined;

  @Input()
  commentLabel: string = 'Comment';

  @Input()
  notesLabel: string = 'Notes';

  @Input()
  commentHiddenOnAccepted: boolean = true;

  @Input()
  disabled = true;

  reviewForm: FormGroup = new FormGroup({});

  get isAccepted() {
    return this.reviewForm.get('accepted')?.value as boolean | null | undefined;
  }

  get fieldComment() {
    return this.field + 'Comment';
  }

  get fieldNotes() {
    return this.field + 'Notes';
  }

  get fieldAccepted() {
    return this.field + 'Accepted';
  }

  get values() {
    const values: any = {};
    if (this.isCommentSet) {
      values[this.fieldComment] = this.isCommentHidden ? '' : this.reviewForm.get('comment')?.value;
    }
    if (this.isNotesSet) {
      values[this.fieldNotes] = this.reviewForm.get('notes')?.value;
    }
    if (this.isAcceptedSet) {
      values[this.fieldAccepted] = this.reviewForm.get('accepted')?.value;
    }
    return values;
  }

  get isValid() {
    return !this.reviewForm.invalid;
  }

  get isAcceptedSet() {
    return this.accepted !== undefined;
  }

  get isNotesSet() {
    return this.notes !== undefined;
  }

  get isCommentSet() {
    return this.comment !== undefined;
  }

  get isCommentHidden() {
    return (
      !this.isCommentSet ||
      (this.isAcceptedSet && this.commentHiddenOnAccepted && !!(this.reviewForm.get('accepted')?.value ?? true))
    );
  }

  get errorMessageRequired() {
    return 'This field is required!';
  }

  constructor() {}

  ngOnInit(): void {
    if (this.isCommentSet) {
      this.reviewForm.addControl('comment', new FormControl(this.comment, Validators.required));
    }

    if (this.isNotesSet) {
      this.reviewForm.addControl('notes', new FormControl(this.notes));
    }

    if (this.isAcceptedSet) {
      this.reviewForm.addControl('accepted', new FormControl(this.accepted, Validators.required));

      this.reviewForm.get('accepted')?.valueChanges.subscribe((accepted) => {
        const commentControl = this.reviewForm.get('comment');
        if (accepted) {
          commentControl?.clearValidators();
        } else {
          commentControl?.setValidators(Validators.required);
        }
        commentControl?.updateValueAndValidity();
      });

      this.reviewForm.get('accepted')?.setValue(this.accepted);
    }

    if (this.disabled) {
      this.reviewForm.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled'] && !changes['disabled'].firstChange) {
      const disabled = changes['disabled'].currentValue;
      if (disabled) {
        this.reviewForm.disable();
      } else {
        this.reviewForm.enable();
      }
    }
  }
}
