<div class="container" *ngIf="!!cmsPage">
  <div class="mat-display-1">{{ cmsPage.title }}</div>
  <div [innerHTML]="cmsPage.content | keepHtml"></div>
</div>

<app-grid class="p-2" *ngIf="!cmsPage">
  <app-grid-row>
    <div>
      <mat-card>
        <h2>Information</h2>
        <p>Welcome to the Credentials online submission and Certifying Examination application form.</p>
      </mat-card>

      <mat-card>
        <app-member-form [data]="member" [disabled]="memberFormDisabled"></app-member-form>
        <button
          mat-raised-button
          *ngIf="!memberFormDisabled"
          [disabled]="loading || memberForm.memberFormInvalid"
          (click)="saveMemberForm()">
          save <mat-icon *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
        </button>
        <button mat-raised-button *ngIf="memberFormDisabled" (click)="enableMemberFormEditMode()">edit</button>
      </mat-card>

      <div *ngIf="!activeCertifyingExam">
        <mat-card>
          <app-certifying-exam-data-verification-form
            [disabled]="isApplicationLocked"
            [memberEntity]="memberEntity!"></app-certifying-exam-data-verification-form>
          <button
            class="data-verification-btn"
            mat-raised-button
            (click)="submitCertifyingExamDataVerification()"
            [disabled]="loading || isApplicationLocked || !certifyingExamDataVerficationForm?.isValid">
            register <mat-icon *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
          </button>
        </mat-card>
      </div>

      <div *ngIf="activeCertifyingExam">
        <mat-card>
          <h2>Payments</h2>
          <p>The fees and dates are due as follows:</p>
          <ng-container *ngIf="activeCertifyingExam.attempt === 1 && isFirstSubmission">
            <ul>
              <li>1st Submission of Credentials: 250 Euro - due on/before 15th June {{ currentExamYear - 1 }}</li>
              <li>
                Certifying Examination registration fee (NON Refundable) due on/before 1st November
                {{ currentExamYear - 1 }}: 240 Euro (all parts)
              </li>
              <li>
                Certifying Examination fee - due on/before 31st January {{ currentExamYear }} (Refundable if canceled
                for medical reasons after January 31st {{ currentExamYear - 1 }}): 960 EUR (all parts)
              </li>
            </ul>
          </ng-container>

          <ng-container *ngIf="activeCertifyingExam.attempt === 1 && isResubmission">
            <ul>
              <li>
                Re-submission of Credentials: Routine re-evaluation of previous incomplete applications (e.g. those with
                outstanding presentation log, publications) will be charged at 125 Euro - due on/before 15th June
                {{ currentExamYear - 1 }}. However, the Credentials Committee reserve the right to apply the full
                submission fee (250 Euros) if the previously submitted application was deemed inadequate and a new
                submission is required. This will have been communicated in the Credentials results letter.
              </li>
              <li>
                Certifying Examination registration fee (NON Refundable) due on/before 1st November
                {{ currentExamYear - 1 }}: 240 Euro (all parts)
              </li>
              <li>
                Certifying Examination fee - due on/before 31st January {{ currentExamYear }} (Refundable if canceled
                for medical reasons after January 31st {{ currentExamYear - 1 }}): 960 EUR (all parts)
              </li>
            </ul>
          </ng-container>

          <ng-container *ngIf="activeCertifyingExam.attempt === 1 && wasAlreadyAcceptedForExam">
            <ul>
              <li>
                Certifying Examination registration fee (NON Refundable) due on/before 1st November
                {{ currentExamYear - 1 }}: 240 Euro (all parts)
              </li>
              <li>
                Certifying Examination fee - due on/before 31st January {{ currentExamYear }} (Refundable if canceled
                for medical reasons after January 31st): 960 EUR (all parts)
              </li>
            </ul>
          </ng-container>

          <ng-container *ngIf="activeCertifyingExam.attempt > 1">
            <div>
              Certifying Examination registration fee (NON Refundable) due on/before 1st November
              {{ currentExamYear - 1 }}:
            </div>
            <ul>
              <li>240 Euro (all parts)</li>
              <li>120 Euro (theory or practical part)</li>
            </ul>
            <div>
              Certifying Examination fee - due on/before 31st January {{ currentExamYear }} (Refundable if canceled for
              medical reasons after January 31st):
            </div>
            <ul>
              <li>960 EUR (all parts)</li>
              <li>480 EUR (theory or practical part)</li>
            </ul>
          </ng-container>

          <p>
            Exam payments will appear in your member account as soon as you receive the acceptance letter from the
            Credentials Committee (typically early in October). We will remind you in the acceptance letter to pay your
            exam fees.
          </p>

          <p *ngIf="!wasAlreadyAcceptedForExam">
            <b>Please note:</b> No application will be evaluated or processed, and no examination entry will be
            permitted if the respective fee has not been paid to the college.
          </p>

          <p *ngIf="!isCredentialsFeePaid">
            <mat-error>Your Credentials fee is currently not paid!</mat-error>
          </p>

          <app-payment-list [payments]="relatedPayments"></app-payment-list>
        </mat-card>

        <mat-card>
          <h2>Resident Details</h2>
          <p>
            Please check if your data is correct. If not please contact Eva Frohnwieser (<a
              href="mailto:residency@ecvim-ca.college"
              >residency@ecvim-ca.college</a
            >).
          </p>
          <app-resident-details [member]="member"></app-resident-details>
        </mat-card>

        <mat-card>
          <app-certifying-exam-application-form
            [memberEntity]="memberEntity"
            [certifyingExamEntity]="activeCertifyingExamEntity!"
            [disabled]="isApplicationLocked"></app-certifying-exam-application-form>

          <app-alert *ngIf="isApplicationSubmitted">Your exam application was successfully submitted!</app-alert>

          <ng-container *ngIf="!isApplicationSubmitted">
            <p>Please review your uploads and make sure your submission is complete.</p>
            <p>After clicking the “Submit” button the Credentials online submission is closed for you.</p>
            <p *ngIf="!isCredentialsFeePaid">
              <mat-error
                ><b>Please note:</b> You cannot submit any Credentials until your Credentials fee is paid!</mat-error
              >
            </p>
            <button
              class="credentials-submit-btn"
              mat-raised-button
              (click)="submitCertifyingExamApplicationForm()"
              [disabled]="
                loading || isApplicationLocked || !isCredentialsFeePaid || !certifyingExamApplicationForm?.isValid
              ">
              submit <mat-icon *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
            </button>
          </ng-container>
        </mat-card>
      </div>
    </div>
  </app-grid-row>
</app-grid>
