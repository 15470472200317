import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { MemberEntityResponse, MemberEntityResponseCollection, MemberInput, UsersPermissionsMe } from 'models/types';
import { map, Observable, of, switchMap } from 'rxjs';

export const MEMBER_BASIC_ATTRIBUTES = `
  title
  Name
  middlename
  Surname
  Role {
    __typename
  }
`;

export const MEMBER_BASIC_DATA_EXPAND = `
  data {
    __typename
    id
    attributes {
      ${MEMBER_BASIC_ATTRIBUTES}
    }
  }
`;

const MEMBER_ROLE_EXPAND = `
  Role {
    __typename
    ... on ComponentMasterdataDiplomat {
      id
      since
      reevaluationdate
      PathToDiplomacy
      worksector
      residency {
        __typename
        data {
          id
        }
      }
      internship {
        __typename
        data {
          id
        }
      }
    }
    ... on ComponentMasterdataResident {
      id
      Since
      Until
      Status
      expectedge
      Adviser {
        ${MEMBER_BASIC_DATA_EXPAND}
      }

      secondaryadvisor {
        ${MEMBER_BASIC_DATA_EXPAND}
      }

      Cases{
        Title
      }
    }
  }
`;

const MEMBER_SPECIALITY_EXPAND = `
  Speciality {
    __typename
    id
    Name
  }
`;

const MEMBER_GRADUATION_SCHOOL_EXPAND = `
  GraduationSchool {
    __typename
    data {
      id
    }
  }
`;

const MEMBER_ADDRESS_EXPAND = `
  address {
    City
    Country
    Number
    Postcode
    Street
    province
  }
`;

const MEMBER_EMPLOYER_EXPAND = `
  Employer {
    data {
      id
      attributes {
        Name
      }
    }
  }
`;

const MEMBER_ENROLMENT_EXPAND = `
  enrolment {
    data {
      id
      attributes {
        Name
        director {
          ${MEMBER_BASIC_DATA_EXPAND}
        }
      }
    }
  }
`;

const MEMBER_USER_EXPAND = `
  User {
    data {
      attributes {
        email
        username
      }
    }
  }
`;

export const MEMBER_COMMITTEE_EXPAND = `
  ComiteeMembers {
    data {
      id
    }
  }
  Comiteechairs {
    data {
      id
    }
  }
`;

const MEMBER_DATA_EXPAND = `
  data {
    id
    attributes {
      status
      title
      Surname
      Name
      email
      middlename
      citizenship
      termsAndConditionsAccepted
      privacyPolicyAccepted
      ${MEMBER_SPECIALITY_EXPAND}
      ${MEMBER_GRADUATION_SCHOOL_EXPAND}
      ${MEMBER_ADDRESS_EXPAND}
      ${MEMBER_ROLE_EXPAND}
      ${MEMBER_EMPLOYER_EXPAND}
      ${MEMBER_ENROLMENT_EXPAND}
      ${MEMBER_USER_EXPAND}
      ${MEMBER_COMMITTEE_EXPAND}
    }
  }
`;

const CURRENT_USER = gql`
  query {
    me {
      id
      username
      email
      confirmed
      blocked
      role {
        id
        name
        description
        type
      }
    }
  }
`;

const MEMBER_BY_EMAIL = gql`
  query($email: String!) {
    members(pagination: { page: 1, pageSize: 1 }, filters: { email: { eqi: $email } }) {
      ${MEMBER_DATA_EXPAND}
    }
  }
`;

const UPDATE_MEMBER = gql`
  mutation updateMember($id: ID!, $data: MemberInput!) {
    updateMember(id: $id, data: $data) {
      ${MEMBER_DATA_EXPAND}
    }
  }
`;

const MAP_MEMBER_TO_USER = gql`
  mutation ($memberId: ID!, $userId: ID!) {
    updateMember(id: $memberId, data: { User: $userId }) {
      ${MEMBER_DATA_EXPAND}
    }
  }
`;

const CREATE_MEMBER = gql`
  mutation createMember($data: MemberInput!) {
    createMember(data: $data) {
      ${MEMBER_DATA_EXPAND}
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private apollo: Apollo) {
    // pass
  }

  // queries

  getCurrentMember() {
    return this._getCurrentUser().pipe(
      switchMap((user) => {
        if (!user?.email) {
          return of({ member: null, user });
        }
        return this._getMemberByEmail(user.email).pipe(map((member) => ({ member, user })));
      })
    );
  }

  // mutations
  mapMemberToUser(memberId: string, userId: string) {
    return this.apollo.mutate<{ updateMember: MemberEntityResponse }>({
      mutation: MAP_MEMBER_TO_USER,
      variables: {
        memberId: memberId,
        userId: userId,
      },
    });
  }

  updateMember(id: string, params: MemberInput) {
    return this.apollo.mutate<{ updateMember: MemberEntityResponse }>({
      mutation: UPDATE_MEMBER,
      variables: {
        id: id,
        data: params,
      },
    });
  }

  createMember(params: MemberInput) {
    return this.apollo.mutate<{ createMember: MemberEntityResponse }>({
      mutation: CREATE_MEMBER,
      variables: {
        data: params,
      },
    });
  }

  private _getCurrentUser(): Observable<UsersPermissionsMe | null> {
    return this.apollo
      .query<{ me: UsersPermissionsMe }>({
        query: CURRENT_USER,
        errorPolicy: 'ignore',
      })
      .pipe(map((res) => res.data.me));
  }

  private _getMemberByEmail(email: string) {
    return this.apollo
      .query<{ members: MemberEntityResponseCollection }>({
        query: MEMBER_BY_EMAIL,
        variables: {
          email: email,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(map((res) => (res.data.members.data.length > 0 ? res.data.members.data[0] : null)));
  }
}
