import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import {
  DiplomateReevaluationEntityResponse,
  DiplomateReevaluationEntityResponseCollection,
  DiplomateReevaluationInput,
  Enum_Diplomatereevaluation_Requesteddiplomatestatus,
  MemberEntityResponse,
} from 'models/types';
import { map, of, switchMap } from 'rxjs';

import { argmax } from '../utils/utils';
import { MEMBER_REVIEWER_DATA_EXPAND } from './certifying-exam.service';
import { CommitteeService } from './committee.service';
import { UPLOAD_FILE_ENTITY_DATA_EXPAND } from './file-upload.service';
import { MEMBER_BASIC_DATA_EXPAND } from './profile.service';

const DIPLOMATE_REEVALUATION_DATA_FOR_REVIEW = `
  result
  resultComment
  resultNotes
  
  reevaluationDate

  diplomate {
    ${MEMBER_BASIC_DATA_EXPAND}
  }

  reviewer1 {
    ${MEMBER_REVIEWER_DATA_EXPAND}
  }

  reviewer2 {
    ${MEMBER_REVIEWER_DATA_EXPAND}
  }

  reviewer1Status
  reviewer2Status

  summaryInfoComment
  summaryInfoAccepted
  summaryInfoReviewer1Comment
  summaryInfoReviewer2Comment
  summaryInfoReviewer1Accepted
  summaryInfoReviewer2Accepted

  ebvsRequirementsComment
  ebvsRequirementsAccepted
  ebvsRequirementsReviewer1Comment
  ebvsRequirementsReviewer2Comment
  ebvsRequirementsReviewer1Accepted
  ebvsRequirementsReviewer2Accepted

  reevaluationPointsComment
  reevaluationPointsAccepted
  reevaluationPointsReviewer1Comment
  reevaluationPointsReviewer2Comment
  reevaluationPointsReviewer1Accepted
  reevaluationPointsReviewer2Accepted

  reevaluationPointDetailsComment
  reevaluationPointDetailsAccepted
  reevaluationPointDetailsReviewer1Comment
  reevaluationPointDetailsReviewer2Comment
  reevaluationPointDetailsReviewer1Accepted
  reevaluationPointDetailsReviewer2Accepted

`;

const DIPLOMATE_REEVALUATION_DATA_FOR_DIPLOMATE = `
  diplomate {
    data {
      id
    }
  }
`;

const DIPLOMATE_REEVALUATION_DATA_BASIC = `
  reviewStatus
  resultLetters {
    ${UPLOAD_FILE_ENTITY_DATA_EXPAND}
  }

  speciality {
    id
    Name
  }

  reevaluationYear
  reevaluationSubmissionDate
  reevaluationPeriodStart
  reevaluationPeriodEnd

  requestedDiplomateStatus

  declarationAccepted

  workHoursPerWeek
  workTypes {
    name
  }
  workDescription
  agmAttendanceYears {
    year
  }
  unfulfillmentReasons

  agmAttendancePoints
  examQuestionContributionPoints
  committeeWorkPoints
  residentSupervisionPoints
  conferenceOrWebinarAttendancePoints
  presentationPoints
  publicationPoints
  peerReviewingPoints
  collegeActivityParticipationPoints
  additionalActivityPoints
  additionalActivityPointsExplanation
  totalPoints

  reevaluationPointDetails {
    ${UPLOAD_FILE_ENTITY_DATA_EXPAND}
  }

`;

const DIPLOMATE_REEVALUATION_DATA_REVIEW_EXPAND = `
  data {
    id
    attributes {
      ${DIPLOMATE_REEVALUATION_DATA_BASIC}
      ${DIPLOMATE_REEVALUATION_DATA_FOR_REVIEW}
    }
  }
`;

const DIPLOMATE_REEVALUATION_DATA_DIPLOMATE_EXPAND = `
  data {
    id
    attributes {
      ${DIPLOMATE_REEVALUATION_DATA_BASIC}
      ${DIPLOMATE_REEVALUATION_DATA_FOR_DIPLOMATE}
    }
  }
`;

const GET_DIPLOMATE_REEVALUATIONS_FOR_MEMBER = gql`
  query ($memberId: ID!) {
    diplomateReevaluations(filters: {  diplomate: {id: {eq: $memberId}} }) {
        ${DIPLOMATE_REEVALUATION_DATA_DIPLOMATE_EXPAND}
    }
  }
`;

const GET_DIPLOMATE_REEVALUATIONS_FOR_REVIEW_BY_REVIEWER = gql`
  query($memberId: ID!, $reevaluationYear: Int) {
    member(id: $memberId) {
      data {
        id
        attributes {
          diplomateReevaluationsAsReviewer1(
            filters: { reevaluationYear: { eq: $reevaluationYear } }
          ) {
            ${DIPLOMATE_REEVALUATION_DATA_REVIEW_EXPAND}
          }
          diplomateReevaluationsAsReviewer2(
            filters: { reevaluationYear: { eq: $reevaluationYear } }
          ) {
            ${DIPLOMATE_REEVALUATION_DATA_REVIEW_EXPAND}
          }
        }
      }
    }
  }
`;

const UPDATE_DIPLOMATE_REEVALUATION = gql`
  mutation updateDiplomateReevaluation($diplomateReevaluationId: ID!, $data: DiplomateReevaluationInput!) {
    updateDiplomateReevaluation(id: $diplomateReevaluationId, data: $data) {
      ${DIPLOMATE_REEVALUATION_DATA_DIPLOMATE_EXPAND}
    }
  }
`;

const UPDATE_DIPLOMATE_REEVALUATION_REVIEW = gql`
  mutation updateDiplomateReevaluation($diplomateReevaluationId: ID!, $data: DiplomateReevaluationInput!) {
    updateDiplomateReevaluation(id: $diplomateReevaluationId, data: $data) {
      ${DIPLOMATE_REEVALUATION_DATA_REVIEW_EXPAND}
    }
  }
`;

const GET_DIPLOMATE_REEVALUATIONS_FOR_REVIEW = gql`
  query($reevaluationYear: Int, $requestedDiplomateStatus: [String]) {
    diplomateReevaluations(
      filters: {
        and: [
          { reevaluationSubmissionDate: {  notNull: true} }
          { reevaluationYear: { eq: $reevaluationYear } }
          { requestedDiplomateStatus: { in: $requestedDiplomateStatus } }
        ]
      }
    ){
      ${DIPLOMATE_REEVALUATION_DATA_REVIEW_EXPAND}
    }
  }
`;

const CREATE_DIPLOMATE_REEVALUATION = gql`
  mutation createDiplomateReevaluation($data: DiplomateReevaluationInput!) {
    createDiplomateReevaluation(data: $data) {
      ${DIPLOMATE_REEVALUATION_DATA_DIPLOMATE_EXPAND}
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class DiplomateReevaluationService {
  constructor(private apollo: Apollo, private committeeService: CommitteeService) {
    // pass
  }

  getDiplomateReevaluationsForMember(memberId: string) {
    return this.apollo.query<{ diplomateReevaluations: DiplomateReevaluationEntityResponseCollection }>({
      query: GET_DIPLOMATE_REEVALUATIONS_FOR_MEMBER,
      variables: {
        memberId,
      },
      fetchPolicy: 'network-only',
    });
  }

  createDiplomateReevaluation(data: DiplomateReevaluationInput) {
    return this.apollo.mutate<{ createDiplomateReevaluation: DiplomateReevaluationEntityResponse }>({
      mutation: CREATE_DIPLOMATE_REEVALUATION,
      variables: {
        data: data,
      },
    });
  }

  createDiplomateReevaluationForMember(memberId: string, data: DiplomateReevaluationInput = {}) {
    data.diplomate = memberId;
    return this.createDiplomateReevaluation(data);
  }

  updateDiplomateReevaluation(diplomateReevaluationId: string, data: DiplomateReevaluationInput) {
    return this.apollo.mutate<{ updateDiplomateReevaluation: DiplomateReevaluationEntityResponse }>({
      mutation: UPDATE_DIPLOMATE_REEVALUATION,
      variables: {
        diplomateReevaluationId: diplomateReevaluationId,
        data: data,
      },
    });
  }

  getLastDiplomateReevaluationForMember(memberId: string, beforeYear?: number) {
    return this.getDiplomateReevaluationsForMember(memberId).pipe(
      map((res) => res.data.diplomateReevaluations.data),
      map((diplomateReevaluations) => {
        if (beforeYear !== undefined) {
          diplomateReevaluations = diplomateReevaluations.filter(
            (dre) => dre.attributes!.reevaluationYear < beforeYear
          );
        }
        if (diplomateReevaluations.length == 0) {
          return undefined;
        }
        return argmax(diplomateReevaluations, (dre) => dre.attributes!.reevaluationYear!);
      })
    );
  }

  // REVIEWS

  getDiplomateReevaluationsForReview(reevaluationYear?: number) {
    return this.apollo.query<{ diplomateReevaluations: DiplomateReevaluationEntityResponseCollection }>({
      query: GET_DIPLOMATE_REEVALUATIONS_FOR_REVIEW,
      variables: {
        reevaluationYear,
        requestedDiplomateStatus: [Enum_Diplomatereevaluation_Requesteddiplomatestatus.Certified],
      },
      fetchPolicy: 'network-only',
    });
  }

  getDiplomateReevaluationsForReviewAsReevaluationsChair(memberId: string, reevaluationYear?: number) {
    return this.committeeService.getDiplomateReevaluationCommitteesAsChair(memberId).pipe(
      switchMap((res) => {
        const committees = res?.data?.comitees?.data ?? [];

        if (committees.length == 0) {
          return of([]);
        }

        return this.getDiplomateReevaluationsForReview(reevaluationYear).pipe(
          map((res) => res.data.diplomateReevaluations.data)
        );
      })
    );
  }

  getDiplomateReevaluationsForReviewByReviewer(reviewerMemberId: string, reevaluationYear?: number) {
    return this.apollo
      .query<{ member: MemberEntityResponse }>({
        query: GET_DIPLOMATE_REEVALUATIONS_FOR_REVIEW_BY_REVIEWER,
        variables: {
          memberId: reviewerMemberId,
          reevaluationYear: reevaluationYear,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((res) => [
          ...(res.data.member.data?.attributes?.diplomateReevaluationsAsReviewer1?.data ?? []),
          ...(res.data.member.data?.attributes?.diplomateReevaluationsAsReviewer2?.data ?? []),
        ])
      );
  }

  updateDiplomateReevaluationReview(diplomateReevaluationId: string, data: DiplomateReevaluationInput) {
    return this.apollo.mutate<{ updateDiplomateReevaluation: DiplomateReevaluationEntityResponse }>({
      mutation: UPDATE_DIPLOMATE_REEVALUATION_REVIEW,
      variables: {
        diplomateReevaluationId: diplomateReevaluationId,
        data: data,
      },
    });
  }
}
