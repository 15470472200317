import { Component, Input, ViewChild } from '@angular/core';
import { WorkStatus } from 'src/app/utils/review';

import { ReviewFormComponent } from '../review-form/review-form.component';

@Component({
  selector: 'app-review-card',
  templateUrl: './review-card.component.html',
  styleUrls: ['./review-card.component.scss'],
})
export class ReviewCardComponent {
  @ViewChild(ReviewFormComponent)
  reviewForm!: ReviewFormComponent;

  @Input()
  showHeader: boolean = false;

  @Input()
  workStatus: WorkStatus | undefined = undefined;

  @Input()
  title: string | undefined = undefined;

  @Input()
  field: string = '';

  @Input()
  disabled: boolean = false;

  @Input()
  comment: string | undefined | null = undefined;

  @Input()
  notes: string | undefined | null = undefined;

  @Input()
  accepted: boolean | null | undefined;

  @Input()
  commentLabel: string = 'Comment';

  @Input()
  commentHiddenOnAccepted: boolean = true;

  @Input()
  notesLabel: string = 'Notes';

  constructor() {}
}
