import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { NewsitemEntity } from 'models/types';
import { filter, map, Observable } from 'rxjs';

import { NewsService } from '../services/news.service';

@Injectable({
  providedIn: 'root',
})
export class NewsResolverService implements Resolve<NewsitemEntity[]> {
  constructor(private newsService: NewsService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): NewsitemEntity[] | Observable<NewsitemEntity[]> | Promise<NewsitemEntity[]> {
    return this.newsService.getNewsItems().pipe(
      filter((result) => !!result.data),
      map((result) => result.data.newsitems.data ?? []),
      map((newsItems) =>
        newsItems
          .sort((n1, n2) => {
            return n1.attributes?.publishedAt.localeCompare(n2.attributes?.publishedAt);
          })
          .reverse()
      )
    );
  }
}
