import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Enum_Member_Status, Member, MemberEntity, MemberInput } from 'models/types';
import { filter, map, Observable, of, switchMap, tap } from 'rxjs';

import { MemberDialogComponent } from '../components/member-dialog/member-dialog.component';
import { MemberResolverService } from '../resolvers/member.resolver.service';
import { MemberService } from '../services/member.service';
import { ProfileService } from '../services/profile.service';
import { getDiplomateRole, isDiplomate } from '../utils/member';

@Injectable({
  providedIn: 'root',
})
export class MemberActiveGuard implements CanActivate {
  constructor(
    private memberResolver: MemberResolverService,
    private snackbar: MatSnackBar,
    private dialogService: MatDialog,
    private memberService: MemberService,
    private profileService: ProfileService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const dialog$ = (data?: Member) => {
      const dialogRef = this.dialogService.open<MemberDialogComponent, Member, MemberInput>(MemberDialogComponent, {
        disableClose: true,
        width: '900px',
        data,
      });
      return dialogRef.afterClosed();
    };

    return this.memberResolver.resolve(route, state).pipe(
      switchMap(({ member, user }) => {
        if (user) {
          // we need to create a new member
          if (!member) {
            return dialog$().pipe(
              filter((data) => !!data),
              switchMap((data) =>
                this.profileService.createMember({
                  ...data,
                  email: user.email,
                  User: user.id,
                } as MemberInput)
              ),
              filter((res) => !!res.data),
              map((res) => ({ dialogShown: true, created: true, member: res.data!.createMember.data! }))
            );
          }
          if (this.allFieldsFilled(member)) {
            // already registered and all fields filled
            return of({ dialogShown: false, created: false, member });
          }
          if (!this.allFieldsFilled(member)) {
            // ask for member updates
            return dialog$(member.attributes!).pipe(
              filter((data) => !!data),
              switchMap((data) => this.profileService.updateMember(member.id!, data!)),
              filter((res) => !!res.data),
              map((res) => ({ dialogShown: true, created: false, member: res.data!.updateMember.data! }))
            );
          }
          // map
          return this.profileService.mapMemberToUser(member.id!, user.id).pipe(
            filter((res) => !!res.data),
            map((res) => res.data!.updateMember.data!),
            switchMap((member) => {
              // ask for member updates
              return dialog$(member.attributes!).pipe(
                filter((data) => !!data),
                switchMap((data) =>
                  this.profileService.updateMember(member.id!, data!).pipe(
                    filter((member) => !!member.data),
                    map((member) => ({ dialogShown: true, created: false, member: member.data!.updateMember.data! }))
                  )
                )
              );
            })
          );
        }
        return of({ dialogShown: false, created: false, member: null });
      }),
      tap(({ member }) => this.memberService.setMember(member)),
      tap(({ dialogShown, created }) => {
        if (dialogShown && !created) {
          this.snackbar.open('Thanks for confirming your data!', undefined, { duration: 4000 });
        }
      }),
      map(({ member }) => !!member?.attributes?.status && member.attributes.status === Enum_Member_Status.Active)
    );
  }

  private allFieldsFilled(member: MemberEntity) {
    // first basic fields
    const fieldsToValidate = [
      member.attributes?.Name,
      member.attributes?.Surname,
      member.attributes?.address?.Street,
      member.attributes?.address?.Number,
      member.attributes?.address?.Postcode,
      member.attributes?.address?.City,
      // member.attributes?.address?.province,
      member.attributes?.address?.Country,
      member.attributes?.title,
      member.attributes?.citizenship,

      member.attributes?.termsAndConditionsAccepted,
      member.attributes?.privacyPolicyAccepted,
    ];

    // if (isResident(member.attributes) && !isDiplomat(member.attributes)) {
    //   const roleFields = getResidentRole(member.attributes)!;
    //   fieldsToValidate.push(...[roleFields.Adviser?.data?.id, roleFields.Since, roleFields.Status]);
    // }

    if (isDiplomate(member.attributes)) {
      const roleFields = getDiplomateRole(member.attributes)!;
      fieldsToValidate.push(...[roleFields.PathToDiplomacy, roleFields.worksector]);
    }

    return fieldsToValidate.every((value) => !!value);
  }
}
