import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import {
  GeneralExamEntity,
  GeneralExamEntityResponse,
  GeneralExamEntityResponseCollection,
  GeneralExamInput,
  MemberEntityResponse,
} from 'models/types';
import { map, switchMap } from 'rxjs';

import { unique } from '../utils/utils';
import { MEMBER_REVIEWER_DATA_EXPAND } from './certifying-exam.service';
import { CommitteeService } from './committee.service';
import { UPLOAD_FILE_ENTITY_DATA_EXPAND } from './file-upload.service';
import { PAYMENT_DATA_EXPAND } from './payments.service';
import { MEMBER_BASIC_DATA_EXPAND } from './profile.service';

const GENERAL_EXAM_DATA_FOR_REVIEW = `
  resident {
    ${MEMBER_BASIC_DATA_EXPAND}
  }

  reviewer1 {
    ${MEMBER_REVIEWER_DATA_EXPAND}
  }

  reviewer2 {
    ${MEMBER_REVIEWER_DATA_EXPAND}
  }

  status

  reviewStatus
  reviewer1Status
  reviewer2Status

  caseSummaryReviewStatus
  caseSummaryComment
  caseSummaryReviewer1Comment
  caseSummaryReviewer2Comment
  caseSummaryReviewer1Notes
  caseSummaryReviewer2Notes

  programmeDirectorLetterComment
  programmeDirectorLetterAccepted

  curriculumVitaeComment
  curriculumVitaeAccepted

  previousCorrespondenceComment
  previousCorrespondenceAccepted
`;

const GENERAL_EXAM_DATA_FOR_RESIDENT = `
  resident {
    data {
      id
    }
  }
`;

const GENERAL_EXAM_DATA_BASIC = `
  applicationResultPublished
  applicationResultLetters {
    ${UPLOAD_FILE_ENTITY_DATA_EXPAND}
  }

  caseSummaryReviewPublished
  caseSummaryConclusionLetter {
    ${UPLOAD_FILE_ENTITY_DATA_EXPAND}
  }

  attempt
  result
  examYear

  speciality {
    id
    Name
  }

  termsAndConditionsAccepted
  privacyPolicyAccepted
  confidentialityAgreementAccepted

  payments {
    ${PAYMENT_DATA_EXPAND}
  }

  caseSummary {
    ${UPLOAD_FILE_ENTITY_DATA_EXPAND}
  }

  programmeDirectorLetter {
    ${UPLOAD_FILE_ENTITY_DATA_EXPAND}
  }

  curriculumVitae {
    ${UPLOAD_FILE_ENTITY_DATA_EXPAND}
  }

  previousCorrespondence {
    ${UPLOAD_FILE_ENTITY_DATA_EXPAND}
  }
`;

const GENERAL_EXAM_DATA_REVIEW_EXPAND = `
  data {
    id
    attributes {
     ${GENERAL_EXAM_DATA_BASIC}
     ${GENERAL_EXAM_DATA_FOR_REVIEW}
    }
  }
`;

const GENERAL_EXAM_DATA_RESIDENT_EXPAND = `
  data {
    id
    attributes {
      ${GENERAL_EXAM_DATA_BASIC}
      ${GENERAL_EXAM_DATA_FOR_RESIDENT}
    }
  }
`;

const GET_GENERAL_EXAM_FOR_REVIEW_BY_ID = gql`
  query ($generalExamId: ID!) {
    generalExam(id: $generalExamId) {
        ${GENERAL_EXAM_DATA_REVIEW_EXPAND}
    }
  }
`;

const GET_GENERAL_EXAMS_FOR_MEMBER = gql`
  query ($memberId: ID!) {
    generalExams(filters: {  resident: {id: {eq: $memberId}} }) {
        ${GENERAL_EXAM_DATA_RESIDENT_EXPAND}
    }
  }
`;

const GET_GENERAL_EXAMS_FOR_REVIEW_BY_REVIEWER = gql`
  query($memberId: ID!, $examYear: Int) {
    member(id: $memberId) {
      data {
        id
        attributes {
          generalExamsAsReviewer1(
            filters: { examYear: { eq: $examYear } }
          ) {
            ${GENERAL_EXAM_DATA_REVIEW_EXPAND}
          }
          generalExamsAsReviewer2(
            filters: { examYear: { eq: $examYear } }
          ) {
            ${GENERAL_EXAM_DATA_REVIEW_EXPAND}
          }
        }
      }
    }
  }
`;

const GET_GENERAL_EXAMS_FOR_REVIEW_BY_SPECIALITIES = gql`
  query($specialityNames: [String]!, $examYear: Int) {
    generalExams(
      filters: {
        and: [
          { termsAndConditionsAccepted: { eq: true } }
          { speciality: { Name: { in: $specialityNames } } }
          { examYear: { eq: $examYear } }
        ]
      }
    ) {
      ${GENERAL_EXAM_DATA_REVIEW_EXPAND}
    }
  }
`;

const UPDATE_GENERAL_EXAM = gql`
  mutation updateGeneralExam($generalExamId: ID!, $data: GeneralExamInput!) {
    updateGeneralExam(id: $generalExamId, data: $data) {
      ${GENERAL_EXAM_DATA_RESIDENT_EXPAND}
    }
  }
`;

const UPDATE_GENERAL_EXAM_REVIEW = gql`
  mutation updateGeneralExam($generalExamId: ID!, $data: GeneralExamInput!) {
    updateGeneralExam(id: $generalExamId, data: $data) {
      ${GENERAL_EXAM_DATA_REVIEW_EXPAND}
    }
  }
`;
const CREATE_GENERAL_EXAM = gql`
  mutation createGeneralExam($data: GeneralExamInput!) {
    createGeneralExam(data: $data) {
      ${GENERAL_EXAM_DATA_RESIDENT_EXPAND}
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GeneralExamService {
  constructor(private apollo: Apollo, private committeeService: CommitteeService) {
    // pass
  }

  getGeneralExamsForMember(memberId: string) {
    return this.apollo.query<{ generalExams: GeneralExamEntityResponseCollection }>({
      query: GET_GENERAL_EXAMS_FOR_MEMBER,
      variables: {
        memberId,
      },
      fetchPolicy: 'network-only',
    });
  }

  createGeneralExam(data: GeneralExamInput) {
    return this.apollo.mutate<{ createGeneralExam: GeneralExamEntityResponse }>({
      mutation: CREATE_GENERAL_EXAM,
      variables: {
        data: data,
      },
    });
  }

  createGeneralExamForMember(memberId: string, data: GeneralExamInput = {}) {
    data.resident = memberId;
    return this.createGeneralExam(data);
  }

  updateGeneralExam(generalExamId: string, data: GeneralExamInput) {
    return this.apollo.mutate<{ updateGeneralExam: GeneralExamEntityResponse }>({
      mutation: UPDATE_GENERAL_EXAM,
      variables: {
        generalExamId: generalExamId,
        data: data,
      },
    });
  }

  // REVIEWS

  getGeneralExamForReviewById(generalExamId: string) {
    return this.apollo.query<{ generalExam: GeneralExamEntityResponse }>({
      query: GET_GENERAL_EXAM_FOR_REVIEW_BY_ID,
      variables: {
        generalExamId,
      },
      fetchPolicy: 'network-only',
    });
  }

  getGeneralExamsForReviewByReviewer(reviewerMemberId: string, examYear?: number) {
    return this.apollo
      .query<{ member: MemberEntityResponse }>({
        query: GET_GENERAL_EXAMS_FOR_REVIEW_BY_REVIEWER,
        variables: {
          memberId: reviewerMemberId,
          examYear,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((res) =>
          unique(
            [
              ...(res.data.member.data?.attributes?.generalExamsAsReviewer1?.data ?? []),
              ...(res.data.member.data?.attributes?.generalExamsAsReviewer2?.data ?? []),
            ],
            (gee) => gee.id
          )
        )
      );
  }

  getGeneralExamsForReviewBySpecialities(specialityNames: string[], examYear?: number) {
    return this.apollo.query<{ generalExams: GeneralExamEntityResponseCollection }>({
      query: GET_GENERAL_EXAMS_FOR_REVIEW_BY_SPECIALITIES,
      variables: {
        specialityNames,
        examYear,
      },
      fetchPolicy: 'network-only',
    });
  }

  getGeneralExamsForReviewAsCredentialsChair(memberId: string, examYear?: number) {
    return this.committeeService.getCredentialsCommitteesAsChair(memberId).pipe(
      switchMap((res) => {
        const committees = res?.data?.comitees?.data ?? [];
        const specialities = committees
          .map((committee) => committee.attributes?.speciality?.Name)
          .filter((s) => !!s)
          .map((s) => s!.replace('_', ' '));
        return this.getGeneralExamsForReviewBySpecialities(specialities, examYear);
      }),
      map((res) => res.data.generalExams.data)
    );
  }

  updateGeneralExamReview(generalExamId: string, data: GeneralExamInput) {
    return this.apollo.mutate<{ updateGeneralExam: GeneralExamEntityResponse }>({
      mutation: UPDATE_GENERAL_EXAM_REVIEW,
      variables: {
        generalExamId: generalExamId,
        data: data,
      },
    });
  }
}
