import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CertifyingExam, CertifyingExamEntity, GeneralExam, GeneralExamEntity } from 'models/types';
import { Subject, takeUntil, tap } from 'rxjs';
import { MemberService } from 'src/app/services/member.service';
import { getFullnameForMember } from 'src/app/utils/member';
import {
  getReviewerNumbers,
  getReviewTypeForReviewerNumber,
  getWorkStatusForFinalReviewer,
  getWorkStatusForReviewer,
  WorkStatus,
} from 'src/app/utils/review';
import { capitalize } from 'src/app/utils/utils';

interface Letter {
  name: string;
  link: string;
}

interface TableDataItem {
  examType: string;
  reviewType: string;
  resident: string;
  reviewer1: string;
  reviewer2: string;
  reviewStatus: WorkStatus;
  result: string | null;
  resultLetters: Letter[];
  routerLink: string;
}

@Component({
  selector: 'app-exam-review-overview',
  templateUrl: './exam-review-overview.component.html',
  styleUrls: ['./exam-review-overview.component.scss'],
})
export class ExamReviewOverviewComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<void>();

  certifyingExamsAsReviewer: CertifyingExamEntity[] = [];
  certifyingExamsAsCredentialsChair: CertifyingExamEntity[] = [];
  generalExamsAsReviewer: GeneralExamEntity[] = [];
  generalExamsAsCredentialsChair: GeneralExamEntity[] = [];

  workStatusEnum = WorkStatus; // Expose enum in component class

  examsForReviewTableDisplayedColumns: string[] = [
    'examType',
    'reviewType',
    'resident',
    'reviewers',
    'reviewStatus',
    'result',
    'resultLetter',
  ];
  examsForReviewTableData: TableDataItem[] = [];

  get currentYear() {
    return new Date().getFullYear();
  }

  get memberId() {
    const memberId = this.memberService.currentMemberId;
    if (memberId === null) {
      throw new Error(`no member`);
    }
    return memberId;
  }

  constructor(private activatedRoute: ActivatedRoute, private memberService: MemberService) {
    this.activatedRoute.data
      .pipe(
        takeUntil(this.destroyed$),
        tap((data) => {
          this.certifyingExamsAsReviewer = (data['certifyingExamsAsReviewer'] ?? []) as CertifyingExamEntity[];
          this.certifyingExamsAsCredentialsChair = (data['certifyingExamsAsCredentialsChair'] ??
            []) as CertifyingExamEntity[];
          this.generalExamsAsReviewer = (data['generalExamsAsReviewer'] ?? []) as GeneralExamEntity[];
          this.generalExamsAsCredentialsChair = (data['generalExamsAsCredentialsChair'] ?? []) as GeneralExamEntity[];
        })
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.examsForReviewTableData = [
      ...this.certifyingExamsAsReviewer.flatMap((cee) => this.getTableDataFromCertifyingExamAsReviewer(cee)),
      ...this.generalExamsAsReviewer.flatMap((gee) => this.getTableDataFromGeneralExamAsReviewer(gee)),
      ...this.certifyingExamsAsCredentialsChair.map((cee) =>
        this.getTableDataFromCertifyingExamAsCredentialsChair(cee)
      ),
      ...this.generalExamsAsCredentialsChair.map((gee) => this.getTableDataFromGeneralExamAsCredentialsChair(gee)),
    ];
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$.unsubscribe();
  }

  getTableDataFromExam(examEntity: CertifyingExamEntity | GeneralExamEntity) {
    return {
      resident: getFullnameForMember(examEntity.attributes?.resident?.data?.attributes),
      reviewer1: getFullnameForMember(examEntity.attributes?.reviewer1?.data?.attributes),
      reviewer2: getFullnameForMember(examEntity.attributes?.reviewer2?.data?.attributes),
      resultLetters: this.getExamResultLetters(examEntity.attributes),
    };
  }

  getTableDataFromCertifyingExam(certifyingExamEntity: CertifyingExamEntity) {
    const tableData = this.getTableDataFromExam(certifyingExamEntity);
    return {
      ...tableData,
      examType: 'Certifying Exam',
      result: this.getCertifyingExamResult(certifyingExamEntity.attributes) ?? null,
    };
  }

  getTableDataFromGeneralExam(generalExamEntity: GeneralExamEntity) {
    const tableData = this.getTableDataFromExam(generalExamEntity);
    return {
      ...tableData,
      examType: 'General Exam',
      result: this.getGeneralExamResult(generalExamEntity.attributes) ?? null,
    };
  }

  getTableDataFromCertifyingExamAsReviewer(certifyingExamEntity: CertifyingExamEntity): TableDataItem[] {
    const certifyingExam = certifyingExamEntity.attributes!;
    return getReviewerNumbers(certifyingExam, this.memberId).map((reviewerNumber) => {
      const reviewType = getReviewTypeForReviewerNumber(reviewerNumber);
      return {
        ...this.getTableDataFromCertifyingExam(certifyingExamEntity),
        reviewType: capitalize(reviewType),
        routerLink: `/committee-work/review-certifying-examination/${certifyingExamEntity.id!}/${reviewType}`,
        reviewStatus: getWorkStatusForReviewer(certifyingExam, reviewerNumber),
      };
    });
  }

  getTableDataFromCertifyingExamAsCredentialsChair(certifyingExamEntity: CertifyingExamEntity): TableDataItem {
    const tableData = this.getTableDataFromCertifyingExam(certifyingExamEntity);
    const reviewType = 'Final';
    return {
      ...tableData,
      reviewType,
      routerLink: `/committee-work/review-certifying-examination/${certifyingExamEntity.id!}/${reviewType.toLowerCase()}`,
      reviewStatus: getWorkStatusForFinalReviewer(certifyingExamEntity.attributes!),
    };
  }

  getTableDataFromGeneralExamAsReviewer(generalExamEntity: GeneralExamEntity): TableDataItem[] {
    const generalExam = generalExamEntity.attributes!;
    return getReviewerNumbers(generalExam, this.memberId).map((reviewerNumber) => {
      const reviewType = getReviewTypeForReviewerNumber(reviewerNumber);
      return {
        ...this.getTableDataFromGeneralExam(generalExamEntity),
        reviewType: capitalize(reviewType),
        routerLink: `/committee-work/review-general-examination/${generalExamEntity.id!}/${reviewType}`,
        reviewStatus: getWorkStatusForReviewer(generalExam, reviewerNumber),
      };
    });
  }

  getTableDataFromGeneralExamAsCredentialsChair(generalExamEntity: GeneralExamEntity): TableDataItem {
    const tableData = this.getTableDataFromGeneralExam(generalExamEntity);
    const reviewType = 'Final';
    return {
      ...tableData,
      reviewType,
      routerLink: `/committee-work/review-general-examination/${generalExamEntity.id!}/${reviewType.toLowerCase()}`,
      reviewStatus: getWorkStatusForFinalReviewer(generalExamEntity.attributes!),
    };
  }

  getExamResultLetters(exam: CertifyingExam | GeneralExam | undefined | null) {
    const letters: Letter[] = [];
    const applicationResultLetters = exam?.applicationResultLetters?.data ?? [];
    for (const applicationResultLetter of applicationResultLetters) {
      letters.push({ name: 'Credentials Result Letter', link: applicationResultLetter.attributes?.url ?? '' });
    }

    const caseSummaryConclusionLetter = exam?.caseSummaryConclusionLetter?.data?.attributes;
    if (caseSummaryConclusionLetter) {
      letters.push({ name: 'Case Summary Conclusion', link: caseSummaryConclusionLetter.url });
    }

    return letters;
  }

  getCertifyingExamResult(certifyingExam: CertifyingExam | undefined | null) {
    if (!certifyingExam) {
      return null;
    }
    return certifyingExam.applicationResult ?? null;
  }

  getGeneralExamResult(generalExam: GeneralExam | undefined | null) {
    if (!generalExam || generalExam.status === null) {
      return null;
    }
    return generalExam.status ? 'Accepted' : 'Not Accepted';
  }
}
