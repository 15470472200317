<form [formGroup]="memberForm">
  <h2>Personal data</h2>
  <p>
    Please check that all of the information on this page is correct. You can edit the personal information yourself.
  </p>
  <div class="form-row">
    <mat-form-field appearance="fill" class="flex-grow-0">
      <mat-label>Title</mat-label>
      <mat-select formControlName="title">
        <mat-option *ngFor="let title of titleOptions" [value]="title">{{ title }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="form-row">
    <mat-form-field appearance="fill">
      <mat-label>First name</mat-label>
      <input matInput formControlName="Name" type="text" />
      <mat-error *ngIf="!getFormByPath('Name')?.valid">{{ genericErrorMessage }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Middle name(s)</mat-label>
      <input matInput formControlName="middlename" type="text" />
      <mat-error *ngIf="!getFormByPath('middlename')?.valid">{{ genericErrorMessage }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Surname(s)</mat-label>
      <input matInput formControlName="Surname" type="text" />
      <mat-error *ngIf="!getFormByPath('Surname')?.valid">{{ genericErrorMessage }}</mat-error>
    </mat-form-field>
  </div>
  <div class="form-row">
    <ng-container *ngIf="institutions$ | async as institutions">
      <mat-form-field appearance="fill">
        <mat-label>Graduation school</mat-label>
        <mat-select formControlName="GraduationSchool" #singleSelect>
          <mat-option>
            <ngx-mat-select-search [formControl]="graduationSchoolFilterCtrl"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let option of filteredGraduationSchools$ | async; trackBy: trackById" [value]="option.id">
            {{ institutionDisplayFn(institutions)(option) }}
          </mat-option>
        </mat-select>
        <button
          *ngIf="isClearable('GraduationSchool')"
          type="button"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="clear('GraduationSchool')">
          <mat-icon>close</mat-icon>
        </button>
        <mat-error *ngIf="!getFormByPath('GraduationSchool')?.valid">{{ genericErrorMessage }}</mat-error>
      </mat-form-field>
    </ng-container>
  </div>
  <h2>Address data</h2>
  <div formGroupName="address">
    <div class="form-row">
      <mat-form-field appearance="fill">
        <mat-label>Street</mat-label>
        <input matInput formControlName="Street" type="text" />
        <mat-error *ngIf="!getFormByPath('address.Street')?.valid">{{ genericErrorMessage }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>House number</mat-label>
        <input matInput formControlName="Number" type="text" />
        <mat-error *ngIf="!getFormByPath('address.Number')?.valid">{{ genericErrorMessage }}</mat-error>
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field appearance="fill">
        <mat-label>Postal code</mat-label>
        <input matInput formControlName="Postcode" type="text" />
        <mat-error *ngIf="!getFormByPath('address.Postcode')?.valid">{{ genericErrorMessage }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>City</mat-label>
        <input matInput formControlName="City" type="text" />
        <mat-error *ngIf="!getFormByPath('address.City')?.valid">{{ genericErrorMessage }}</mat-error>
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field appearance="fill">
        <mat-label>Province/District</mat-label>
        <input matInput formControlName="province" type="text" />
        <mat-error *ngIf="!getFormByPath('address.province')?.valid">{{ genericErrorMessage }}</mat-error>
      </mat-form-field>

      <ng-container *ngIf="countries$ | async as countries">
        <mat-form-field appearance="fill">
          <mat-label>Country</mat-label>
          <mat-select formControlName="Country" #singleSelect>
            <mat-option>
              <ngx-mat-select-search [formControl]="countryFilterCtrl"></ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngFor="let option of filteredCountries$ | async; trackBy: countryTrackByFn"
              [value]="option.name">
              {{ countryDisplayFn(countries)(option) }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="!getFormByPath('address.Country')?.valid">{{ genericErrorMessage }}</mat-error>
        </mat-form-field>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="countries$ | async as citizenships">
    <mat-form-field appearance="fill">
      <mat-label>Citizenship</mat-label>
      <mat-select formControlName="citizenship" #singleSelect>
        <mat-option>
          <ngx-mat-select-search [formControl]="citizenshipFilterCtrl"></ngx-mat-select-search>
        </mat-option>
        <mat-option
          *ngFor="let option of filteredCitizenships$ | async; trackBy: countryTrackByFn"
          [value]="option.value">
          {{ countryDisplayFn(citizenships)(option) }}
        </mat-option>
      </mat-select>
      <button
        *ngIf="isClearable('citizenship')"
        type="button"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="clear('citizenship')">
        <mat-icon>close</mat-icon>
      </button>
      <mat-error *ngIf="!getFormByPath('citizenship')?.valid">{{ genericErrorMessage }}</mat-error>
    </mat-form-field>
  </ng-container>
  <ng-container *ngIf="withDetails && isDiplomat">
    <h2>Diplomate Details</h2>
    <ng-container formArrayName="Role">
      <ng-container [formGroupName]="diplomatRoleIndex!.toString()">
        <div class="form-row">
          <mat-form-field appearance="fill">
            <mat-label>Since</mat-label>
            <input matInput formControlName="since" [matDatepicker]="diplomateSincePicker" placeholder="YYYY-MM-DD" />
            <mat-datepicker-toggle matSuffix [for]="diplomateSincePicker"></mat-datepicker-toggle>
            <mat-datepicker #diplomateSincePicker></mat-datepicker>
            <mat-error *ngIf="!getFormByPath('Role.Diplomat.since')?.valid">{{ genericErrorMessage }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>How became Diplomate</mat-label>
            <mat-select formControlName="PathToDiplomacy">
              <mat-option *ngFor="let opt of pathToDiplomacyOptions" [value]="opt">{{ opt }}</mat-option>
            </mat-select>
            <mat-error *ngIf="!getFormByPath('Role.Diplomat.PathToDiplomacy')?.valid">{{
              genericErrorMessage
            }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Work sector</mat-label>
            <mat-select formControlName="worksector">
              <mat-option *ngFor="let w of worksectorOptions" [value]="w">{{ w }}</mat-option>
            </mat-select>
            <mat-error *ngIf="!getFormByPath('Role.Diplomat.worksector')?.valid">{{ genericErrorMessage }}</mat-error>
          </mat-form-field>
        </div>
        <div class="form-row" *ngIf="institutions$ | async as institutions">
          <ng-container formGroupName="residency">
            <mat-form-field appearance="fill">
              <mat-label>Residency Training Programme (location)</mat-label>
              <mat-select formControlName="id" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search [formControl]="residenciesFilterCtrl"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let option of filteredResidencies$ | async; trackBy: trackById" [value]="option.id">
                  {{ institutionDisplayFn(institutions)(option) }}
                </mat-option>
              </mat-select>
              <button
                *ngIf="isClearable('Role.Diplomat.residency.id')"
                type="button"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="clear('Role.Diplomat.residency.id')">
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="!getFormByPath('Role.Diplomat.residency.id')?.valid">{{
                genericErrorMessage
              }}</mat-error>
            </mat-form-field>
          </ng-container>
          <ng-container formGroupName="internship">
            <mat-form-field appearance="fill">
              <mat-label>Internship (location)</mat-label>
              <mat-select formControlName="id" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search [formControl]="internshipFilterCtrl"></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let option of filteredInternshipLocations$ | async; trackBy: trackById"
                  [value]="option.id">
                  {{ institutionDisplayFn(institutions)(option) }}
                </mat-option>
              </mat-select>
              <button
                *ngIf="isClearable('Role.Diplomat.internship.id')"
                type="button"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="clear('Role.Diplomat.internship.id')">
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="!getFormByPath('Role.Diplomat.internship.id')?.valid">{{
                genericErrorMessage
              }}</mat-error>
            </mat-form-field>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="withDetails && isResident && !isDiplomat">
    <h2>Resident Details</h2>
    <p>
      Please check if your data is correct. If not please contact Eva Frohnwieser (<a
        href="mailto:residency@ecvim-ca.college"
        >residency@ecvim-ca.college</a
      >).
    </p>
    <div formArrayName="Role">
      <div [formGroupName]="residentRoleIndex!.toString()">
        <div class="form-row">
          <mat-form-field appearance="fill">
            <mat-label>Residency start date</mat-label>
            <input matInput formControlName="Since" [matDatepicker]="residentSincePicker" placeholder="YYYY-MM-DD" />
            <mat-datepicker-toggle matSuffix [for]="residentSincePicker"></mat-datepicker-toggle>
            <mat-datepicker #residentSincePicker></mat-datepicker>
            <mat-error *ngIf="!getFormByPath('Role.Resident.Since')?.valid">{{ genericErrorMessage }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Current Resident status</mat-label>
            <mat-select formControlName="Status">
              <mat-option *ngFor="let opt of residentStatusOptions" [value]="opt">{{ opt }}</mat-option>
            </mat-select>
            <mat-error *ngIf="!getFormByPath('Role.Resident.Status')?.valid">{{ genericErrorMessage }}</mat-error>
          </mat-form-field>
          <ng-container formGroupName="Adviser" *ngIf="diplomats$ | async as diplomats">
            <mat-form-field appearance="fill">
              <mat-label>Resident adviser</mat-label>
              <mat-select formControlName="id" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search [formControl]="adviserFilterCtrl"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let option of filteredAdvisers$ | async; trackBy: trackById" [value]="option.id">
                  {{ diplomateDisplayFn(diplomats)(option) }}
                </mat-option>
              </mat-select>
              <button
                *ngIf="isClearable('Role.Resident.Adviser.id')"
                type="button"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="clear('Role.Resident.Adviser.id')">
                <mat-icon>close</mat-icon>
              </button>

              <mat-error *ngIf="!getFormByPath('Role.Resident.Adviser.id')?.valid">{{ genericErrorMessage }}</mat-error>
            </mat-form-field>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="withCheckboxes">
    <div class="form-row">
      <mat-checkbox formControlName="termsAndConditionsAccepted" color="primary">
        <label>
          I have read and accept the <a target="_blank" routerLink="/termsandconditions">terms and conditions</a> *
        </label>
      </mat-checkbox>
    </div>
    <div class="form-row">
      <mat-checkbox formControlName="privacyPolicyAccepted" color="primary">
        <label>
          I have read and accept the <a target="_blank" routerLink="/privacypolicy">privacy policy</a> *
        </label></mat-checkbox
      >
    </div>
  </ng-container>
</form>
