<div class="container" *ngIf="!!cmsPage">
  <div class="mat-display-1">{{ cmsPage.title }}</div>
  <div [innerHTML]="cmsPage.content | keepHtml"></div>
</div>

<app-grid class="p-2" *ngIf="!cmsPage">
  <app-grid-row>
    <div>
      <mat-card>
        <h2>Information</h2>
        <p>
          The ECVIM-CA is required by the European Board of Veterinary Specialisation to re-evaluate every Diplomate
          every 5 years. This process ensures that the College has documentary evidence of the continuing commitment of
          the Diplomate to maintaining their knowledge and skills.
        </p>
        <p>
          The process relies on the honesty of the Diplomate; however, the Board of the college reserves the right to
          require Diplomates to provide documentary proof of their activities that are relevant to re-evaluation and
          does not have to provide a reason for this request. Random spot checks may be made.
        </p>
      </mat-card>

      <mat-card>
        <app-member-form [data]="member" [disabled]="memberFormDisabled"></app-member-form>
        <button
          mat-raised-button
          *ngIf="!memberFormDisabled"
          [disabled]="loading || memberForm.memberFormInvalid"
          (click)="saveMemberForm()">
          save <mat-icon *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
        </button>
        <button mat-raised-button *ngIf="memberFormDisabled" (click)="enableMemberFormEditMode()">edit</button>
      </mat-card>

      <div *ngIf="!activeDiplomateReevaluationEntity">
        <mat-card>
          <app-diplomate-reevaluation-data-verification-form
            [disabled]="isApplicationLocked"
            [memberEntity]="memberEntity!"
            [diplomateReevaluationsEntities]="
              this.diplomateReevaluations
            "></app-diplomate-reevaluation-data-verification-form>
          <button
            class="data-verification-btn"
            mat-raised-button
            (click)="submitDiplomateReevaluationDataVerification()"
            [disabled]="loading || isApplicationLocked || !diplomateReevaluationDataVerficationForm?.isValid">
            register <mat-icon *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
          </button>
        </mat-card>
      </div>

      <div *ngIf="activeDiplomateReevaluationEntity">
        <mat-card>
          <h2>Diplomate Details</h2>
          <table mat-table [dataSource]="diplomateDetailsTableData">
            <!-- title Column -->
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef>Title</th>
              <td mat-cell *matCellDef="let element">{{ element.title }}</td>
            </ng-container>

            <!-- value Column -->
            <ng-container matColumnDef="value">
              <th mat-header-cell *matHeaderCellDef>Value</th>
              <td mat-cell *matCellDef="let element">{{ element.value }}</td>
            </ng-container>
            <!-- <tr mat-header-row *matHeaderRowDef="residentDetailsTableDisplayedColumns"></tr> -->
            <tr mat-row *matRowDef="let row; columns: diplomateDetailsTableDisplayedColumns"></tr>
          </table>
        </mat-card>

        <mat-card>
          <app-diplomate-reevaluation-application-form
            [memberEntity]="memberEntity"
            [diplomateReevaluationEntity]="activeDiplomateReevaluationEntity"
            [disabled]="isApplicationLocked"></app-diplomate-reevaluation-application-form>

          <app-alert *ngIf="isApplicationSubmitted">Your diplomate reevaluation was successfully submitted!</app-alert>

          <ng-container *ngIf="!isApplicationSubmitted">
            <p>Please review your application and make sure you uploaded all necessary data.</p>
            <p>After clicking the submit Button the diplomate reevaluation application is closed.</p>
            <button
              class="diplomate-reevaluation-application-submit-btn"
              mat-raised-button
              (click)="saveDiplomateReevaluationApplicationForm()"
              [disabled]="loading || isApplicationLocked">
              save <mat-icon *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
            </button>

            <button
              class="diplomate-reevaluation-application-submit-btn"
              mat-raised-button
              (click)="saveDiplomateReevaluationApplicationForm(true)"
              [disabled]="loading || isApplicationLocked || !diplomateReevaluationApplicationForm?.isValid">
              submit <mat-icon *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
            </button>
          </ng-container>
        </mat-card>
      </div>
    </div>
  </app-grid-row>
</app-grid>
