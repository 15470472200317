<table mat-table [dataSource]="parsedData">
  <!-- key Column -->
  <ng-container matColumnDef="key">
    <th mat-header-cell *matHeaderCellDef>{{ header?.key }}</th>
    <td mat-cell *matCellDef="let item">{{ item.key }}</td>
    <td mat-footer-cell *matFooterCellDef>{{ footer?.key }}</td>
  </ng-container>

  <!-- value Column -->
  <ng-container matColumnDef="value">
    <th mat-header-cell *matHeaderCellDef class="{{ centerValues ? 'center' : '' }}">{{ header?.value }}</th>
    <td mat-cell *matCellDef="let item" class="{{ centerValues ? 'center' : '' }}">
      <ng-container *ngFor="let obj of item.value; let i = index">
        <ng-container *ngIf="obj.routerLink">
          <a [routerLink]="obj.routerLink">{{ obj.value }}</a>
        </ng-container>
        <ng-container *ngIf="!obj.routerLink">
          {{ obj.value }}
        </ng-container>
        <span *ngIf="i < item.value.length - 1">,&nbsp;</span>
      </ng-container>
    </td>
    <td mat-footer-cell *matFooterCellDef class="{{ centerValues ? 'center' : '' }}">{{ footer?.value }}</td>
  </ng-container>

  <ng-container *ngIf="!!header">
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  </ng-container>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  <ng-container *ngIf="!!footer">
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
  </ng-container>
</table>
