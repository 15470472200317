import { Component } from '@angular/core';

@Component({
  selector: 'app-grid-row',
  templateUrl: './grid-row.component.html',
  styleUrls: ['./grid-row.component.scss'],
})
export class GridRowComponent {
  constructor() {
    // nothing
  }
}
