import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
  @Input()
  type: string = 'task-done';

  get iconName() {
    switch (this.type) {
      case 'task-done':
        return 'task';
      case 'info':
      default:
        return 'info';
    }
  }

  constructor() {}
}
