import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralExam } from 'models/types';
import { getFullnameForMember } from 'src/app/utils/member';

import { SimpleTableItem } from '../../simple-table/simple-table.component';

@Component({
  selector: 'app-general-exam-application-details',
  templateUrl: './general-exam-application-details.component.html',
  styleUrls: ['./general-exam-application-details.component.scss'],
})
export class GeneralExamApplicationDetailsComponent implements OnInit {
  @Input()
  generalExam!: GeneralExam;

  get applicationDetailsTableData(): SimpleTableItem[] {
    return [
      { key: 'Application Year', value: this.generalExam.examYear - 1 },
      { key: 'Exam Year', value: this.generalExam.examYear },
      { key: 'Resident', value: getFullnameForMember(this.generalExam.resident?.data?.attributes) },
      { key: 'Speciality', value: this.generalExam.speciality?.Name ?? '' },
    ];
  }

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }
}
