import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import {
  InstitutionEntityResponseCollection,
  MemberEntityResponseCollection,
  ProgrammeEntityResponseCollection,
} from 'models/types';
import { filter, map } from 'rxjs';

import { isDiplomate } from '../utils/member';
import { MEMBER_BASIC_DATA_EXPAND } from './profile.service';

const PROGRAMMES_QUERY = gql`
  query {
    programmes {
      data {
        id
        attributes {
          Name
        }
      }
    }
  }
`;

const INSTITUTIONS_QUERY = gql`
  query {
    institutions {
      data {
        id
        attributes {
          Name
        }
      }
    }
  }
`;

const GET_ALL_MEMBERS_QUERY = gql`
  query {
    members {
      __typename
      ${MEMBER_BASIC_DATA_EXPAND}
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class BaseDataService {
  constructor(private apollo: Apollo) {
    // pass
  }

  getProgrammes() {
    return this.apollo.query<{ programmes: ProgrammeEntityResponseCollection }>({
      query: PROGRAMMES_QUERY,
    });
  }

  getInstitutions() {
    return this.apollo.query<{ institutions: InstitutionEntityResponseCollection }>({
      query: INSTITUTIONS_QUERY,
    });
  }

  getDiplomats() {
    // TODO maybe filtering in query is faster
    return this.apollo
      .query<{ members: MemberEntityResponseCollection }>({
        query: GET_ALL_MEMBERS_QUERY,
      })
      .pipe(
        filter((result) => !!result.data),
        map((result) => result.data.members?.data?.filter((item) => isDiplomate(item.attributes)))
      );
  }
}
