<h2>General Exam Data Verification</h2>

<form [formGroup]="dataVerificationForm">
  <div class="form-row">
    <label id="label-attempt">Please confirm your attempt <span *ngIf="isFormRequired('attempt')"> *</span></label>
    <mat-radio-group aria-labelledby="label-attempt" formControlName="attempt">
      <mat-radio-button color="primary" *ngFor="let attempt of attemptOptions" [value]="attempt"
        >Attempt {{ attempt }}</mat-radio-button
      >
      <mat-error *ngIf="getFormByPath('attempt')?.invalid">{{ genericErrorMessage }}</mat-error>
    </mat-radio-group>
  </div>
</form>
