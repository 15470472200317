import { Component } from '@angular/core';
import { defer, filter, map } from 'rxjs';
import { MemberService } from 'src/app/services/member.service';
import { PaymentsService } from 'src/app/services/payments.service';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
})
export class PaymentsComponent {
  payments$ = defer(() => {
    if (!this.memberService.currentMemberId) {
      throw new Error('No active member');
    }
    return this.paymentsService.getPaymentsForMember(this.memberService.currentMemberId).pipe(
      filter((result) => !!result.data),
      map((result) => result.data.payments.data)
    );
  });

  constructor(private memberService: MemberService, private paymentsService: PaymentsService) {
    // pass
  }
}
