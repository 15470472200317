import { Component, forwardRef, inject, Injector, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  NgControl,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UploadFileEntity } from 'models/types';
import { FileUploadService } from 'src/app/services/file-upload.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true,
    },
  ],
})
export class FileUploadComponent implements OnInit, ControlValueAccessor {
  @Input()
  disabled = false;

  @Input()
  ref: string | undefined = undefined;

  @Input()
  refId: string | undefined = undefined;

  @Input()
  field: string | undefined = undefined;

  @Input()
  path: string | undefined = undefined;

  @Input()
  allowedTypes: string | undefined = undefined;

  @Input()
  baseFileName: string | undefined = undefined;

  @Input()
  maxNumberFiles: number = Number.MAX_SAFE_INTEGER;

  // TODO think about type Set to prevent duplicates
  files: UploadFileEntity[] = [];

  loading = false;

  formControl!: FormControl;

  private fileUploadService = inject(FileUploadService);
  private snackbar = inject(MatSnackBar);

  onChange = (files: UploadFileEntity[]) => {};
  onTouched = () => {};

  get allowMultipleSelect() {
    return this.maxNumberFiles > 1;
  }

  get fileNames() {
    return this.files.map((f) => f.attributes?.name);
  }

  get isInvalid() {
    return this.formControl.touched && this.formControl.invalid;
  }

  constructor(private injector: Injector) {}

  addFile(file: UploadFileEntity) {
    this.files = [...this.files, file];
    this.onChange(this.files);
  }

  removeFile(fileId: string) {
    if (this.files.find((file) => file.id === fileId)) {
      this.fileUploadService.removeFile(fileId).subscribe({
        next: (result) => {
          this.loading = result.loading;
          if (result.data) {
            const removedFileId = result.data?.removeFile.data?.id;
            if (removedFileId) {
              this.files = this.files.filter((file) => file.id !== removedFileId);
              this.onChange(this.files);
            }
          }
        },
      });
    }
  }

  writeValue(newFiles: UploadFileEntity[]): void {
    const removeFiles = this.files.filter((file) => !newFiles.some((newFile) => newFile.id == file.id));

    // adding files is currently not supported

    removeFiles.forEach((removeFile) => this.removeFile(removeFile.id!));

    this.files = newFiles;
  }

  registerOnChange(fn: (files: UploadFileEntity[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
    const ngControl = this.injector.get(NgControl);

    if (ngControl instanceof FormControlName) {
      this.formControl = this.injector.get(FormGroupDirective).getControl(ngControl);
    } else {
      this.formControl = (ngControl as FormControlDirective).form as FormControl;
    }
  }

  openFile(file: UploadFileEntity) {
    const url = file.attributes?.url;
    if (url) {
      window.open(url, '_blank');
    }
  }

  getBasename(file: File): string {
    const filename = file.name;
    const lastDotIndex = filename.lastIndexOf('.');
    if (lastDotIndex === -1) {
      // File doesn't have an extension
      return filename;
    }
    return filename.slice(0, lastDotIndex);
  }

  getFileExtension(file: File): string {
    const filename = file.name;
    const lastDotIndex = filename.lastIndexOf('.');
    if (lastDotIndex === -1) {
      // File doesn't have an extension
      return '';
    }
    return filename.slice(lastDotIndex + 1);
  }

  onBlur() {
    this.onTouched();
  }

  onFileChange(event: any) {
    const newFiles = Array.from(event.target.files) as File[];
    if (newFiles) {
      if (this.files.length + newFiles.length > this.maxNumberFiles) {
        this.snackbar.open(`You can only upload ${this.maxNumberFiles} file(s) here!`, undefined, { duration: 4000 });
        return;
      }

      // TODO check file types

      this.loading = true;

      const uploadFilePromises = newFiles.map((newFile) =>
        this.fileUploadService
          .uploadFileWithPost(
            newFile,
            this.ref!,
            this.refId!,
            this.field!,
            this.path,
            this.baseFileName + '.' + this.getFileExtension(newFile)
          )
          .then(async (response) => {
            const responseJson = (await response.json())[0];
            const newUploadFileEntity: UploadFileEntity = { id: String(responseJson.id), attributes: responseJson };
            this.addFile(newUploadFileEntity);
          })
          .catch((reason) => console.error(reason))
      );

      Promise.all(uploadFilePromises).finally(() => (this.loading = false));

      // for (let newFile of newFiles) {
      //   this.fileUploadService
      //     .uploadFileWithPost(newFile, this.ref, this.refId, this.field, this.path, this.baseFileName)
      //     .then(async (response) => {
      //       const responseJson: Array<any> = await response.json();

      //       const newUploadFileEntities = responseJson.map(
      //         (responsePart) =>
      //           ({
      //             id: String(responsePart.id),
      //             attributes: responsePart,
      //           } as UploadFileEntity)
      //       );

      //       this.writeValue(this.files.concat(newUploadFileEntities));
      //     })
      //     .finally(() => (this.loading = false));
      // }

      // this.fileUploadService
      //   .uploadMultipleFilesWithPost(newFiles, this.ref, this.refId, this.field, this.path)
      //   .then(async (response) => {
      //     const responseJson: Array<any> = await response.json();

      //     const newUploadFileEntities = responseJson.map(
      //       (responsePart) =>
      //         ({
      //           id: String(responsePart.id),
      //           attributes: responsePart,
      //         } as UploadFileEntity)
      //     );

      //     this.writeValue(this.files.concat(newUploadFileEntities));
      //   })
      //   .finally(() => (this.loading = false));
    }
  }
}
