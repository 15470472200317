import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { GeneralExamEntity } from 'models/types';
import { map, Observable } from 'rxjs';
import { GeneralExamService } from 'src/app/services/general-exam.service';

@Injectable({
  providedIn: 'root',
})
export class GeneralExamForReviewByIdResolver implements Resolve<GeneralExamEntity> {
  constructor(private generalExamService: GeneralExamService, private router: Router) {
    // pass
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<GeneralExamEntity> {
    if (route.paramMap.has('id')) {
      return this.generalExamService.getGeneralExamForReviewById(route.paramMap.get('id')!).pipe(
        map((res) => {
          const generalExamEntity = res.data.generalExam.data;
          if (!generalExamEntity) {
            this.router.navigate(['/committee-work/credentials-review']);
            throw new Error('no data received');
          }
          return generalExamEntity;
        })
      );
    }
    this.router.navigate(['/home']);
    throw new Error('not allowed path');
  }
}
