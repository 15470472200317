import { Component, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import {
  Enum_Componentmasterdataspeciality_Name,
  GeneralExamEntity,
  GeneralExamInput,
  UploadFileEntity,
} from 'models/types';
import { Subject } from 'rxjs';
import { ReviewCardComponent } from 'src/app/components/review/review-card/review-card.component';
import { GeneralExamService } from 'src/app/services/general-exam.service';
import { getFullnameForMember } from 'src/app/utils/member';
import { getWorkStatusForFinalReviewer, getWorkStatusForReviewer, ReviewType, WorkStatus } from 'src/app/utils/review';

@Component({
  selector: 'app-general-exam-review',
  templateUrl: './general-exam-review.component.html',
  styleUrls: ['./general-exam-review.component.scss'],
})
export class GeneralExamReviewComponent implements OnDestroy {
  @ViewChildren(ReviewCardComponent)
  reviewCards: QueryList<ReviewCardComponent> | undefined;

  private destroyed$ = new Subject<void>();

  generalExamEntity!: GeneralExamEntity;

  loading = false;

  get generalExam() {
    return this.generalExamEntity.attributes!;
  }

  get residentFullname() {
    return getFullnameForMember(this.generalExam?.resident?.data?.attributes);
  }

  get reviewType() {
    return this.activatedRoute.snapshot.paramMap.get('type') as ReviewType;
  }

  get isViewer() {
    return this.reviewType === ReviewType.View;
  }

  get isFinalReviewer() {
    return this.reviewType === ReviewType.Final;
  }

  get isReviewer1() {
    return this.reviewType === ReviewType.First;
  }

  get isReviewer2() {
    return this.reviewType === ReviewType.Second;
  }

  get isReview1Visible() {
    return this.isFinalReviewer || this.isReviewer1 || this.isViewer;
  }

  get isReview2Visible() {
    return this.isFinalReviewer || this.isReviewer2 || this.isViewer;
  }

  get isReview2CaseSummaryVisible() {
    return (
      this.isReview2Visible &&
      ![
        Enum_Componentmasterdataspeciality_Name.Oncology,
        Enum_Componentmasterdataspeciality_Name.InternalMedicine,
      ].includes(this.generalExamSpeciality)
    );
  }

  get isFinalReviewVisible() {
    return this.isFinalReviewer || this.isViewer;
  }

  get isReview1Disabled() {
    return this.loading || this.isReview1Completed || this.isFinalReviewer || this.isViewer;
  }

  get isReview2Disabled() {
    return this.loading || this.isReview2Completed || this.isFinalReviewer || this.isViewer;
  }

  get isFinalReviewDisabled() {
    return (
      this.loading || this.isReviewCompleted || !this.isReview1Completed || !this.isReview2Completed || this.isViewer
    );
  }

  get isReviewCompleted() {
    return this.finalReviewerWorkStatus === WorkStatus.Finished;
  }

  private get isReview1Completed() {
    return this.reviewer1WorkStatus === WorkStatus.Finished;
  }

  private get isReview2Completed() {
    return this.reviewer2WorkStatus === WorkStatus.Finished;
  }

  get reviewer1WorkStatus() {
    return getWorkStatusForReviewer(this.generalExam, 1);
  }

  get reviewer2WorkStatus() {
    return getWorkStatusForReviewer(this.generalExam, 2);
  }

  get finalReviewerWorkStatus() {
    return getWorkStatusForFinalReviewer(this.generalExam);
  }

  get reviewForms() {
    return this.reviewCards ? this.reviewCards.map((reviewCard) => reviewCard.reviewForm) : [];
  }

  get isReviewValid() {
    return this.reviewForms?.map((reviewForm) => reviewForm.isValid).every((b) => !!b) ?? false;
  }

  get generalExamSpeciality() {
    const speciality = this.generalExam.speciality?.Name;
    if (!speciality) {
      throw new Error('speciality is not set');
    }
    return speciality;
  }

  get reviewFormsValues(): GeneralExamInput {
    return Object.assign({}, ...this.reviewForms.filter((rfc) => !rfc.disabled).map((rfc) => rfc.values));
  }

  get errorMessageRequired() {
    return 'This field is required!';
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private generalExamService: GeneralExamService,
    private snackbar: MatSnackBar
  ) {
    this.generalExamEntity = this.activatedRoute.snapshot.data['generalExam'] as GeneralExamEntity;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$.unsubscribe();
  }

  convertFileToArray(file: UploadFileEntity | undefined | null) {
    return [file].filter((f) => !!f) as UploadFileEntity[];
  }

  saveReview(lock = false): void {
    if (!this.generalExamEntity?.id) {
      return;
    }

    let generalExamInput = this.reviewFormsValues;

    if (lock) {
      if (this.isFinalReviewer) {
        generalExamInput.caseSummaryReviewStatus = true;
      } else {
        // these fields also reference to the review status of the case summary
        const reviewStatusField = this.isReviewer1 ? 'reviewer1Status' : 'reviewer2Status';
        generalExamInput[reviewStatusField] = true;
      }
    }

    this.generalExamService.updateGeneralExamReview(this.generalExamEntity.id, generalExamInput).subscribe({
      next: (result) => {
        this.loading = result.loading;
        if (result.data) {
          this.generalExamEntity = result.data.updateGeneralExam.data!;
          this.snackbar.open('Your review data was updated successfully!', undefined, { duration: 4000 });
        }
      },
    });
  }
}
