import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CertifyingExamEntity,
  CertifyingExamInput,
  Enum_Certifyingexam_Typeofsubmission,
  MemberEntity,
  UploadFileEntity,
} from 'models/types';
import {
  Credentials,
  getCredentialsForCertifyingExam,
  getSubmitLaterFieldName,
  isSubmitLaterEnabled,
  Required,
  SUBMIT_LATER_ENDING,
} from 'src/app/utils/credentials';
import {
  CERTIFYING_EXAM_DIRECTORY_PATH,
  CERTIFYING_EXAM_REF,
  getCurrentCertifyingExamApplicationYear,
} from 'src/app/utils/exam';
import { getBaseFileName } from 'src/app/utils/form';
import { getDiplomateRole, getResidentRole, isDiplomate, isResident } from 'src/app/utils/member';

@Component({
  selector: 'app-credentials-submission-form',
  templateUrl: './credentials-submission-form.component.html',
  styleUrls: ['./credentials-submission-form.component.scss'],
})
export class CredentialsSubmissionFormComponent implements OnInit, OnChanges {
  @Input()
  memberEntity!: MemberEntity;

  @Input()
  certifyingExamEntity!: CertifyingExamEntity;

  @Input()
  disabled = true;

  loading = false;

  credentialsSubmissionForm!: FormGroup<Record<string, FormControl>>;

  get member() {
    return this.memberEntity.attributes!;
  }

  get memberId() {
    return this.memberEntity.id!;
  }

  get values(): CertifyingExamInput {
    const values = Object.fromEntries(
      Object.entries(this.credentialsSubmissionForm.controls)
        .map(([key, control]) => [key, control.value])
        .filter(([_, value]) => !Array.isArray(value))
    );

    // submitted fields are used for submitLater checkboxes so logic must be inverted
    Object.entries(values).forEach(([key, value]) => {
      if (key.endsWith(SUBMIT_LATER_ENDING)) {
        values[key] = !value;
      }
    });

    return values;
  }

  get invalid() {
    return this.credentialsSubmissionForm.invalid;
  }

  get genericErrorMessage() {
    return 'This field is required!';
  }

  get isResident() {
    return isResident(this.member);
  }

  get residentRole() {
    return getResidentRole(this.member);
  }

  get isDiplomat() {
    return isDiplomate(this.member);
  }

  get diplomatRole() {
    return getDiplomateRole(this.member);
  }

  get currentExamYear() {
    return getCurrentCertifyingExamApplicationYear();
  }

  get certifyingExamRef() {
    return CERTIFYING_EXAM_REF;
  }

  get certifyingExamId() {
    return this.certifyingExamEntity.id!;
  }

  get certifyingExam() {
    return this.certifyingExamEntity.attributes!;
  }

  get credentials() {
    return getCredentialsForCertifyingExam(this.certifyingExam);
  }

  get isFirstSubmission() {
    return this.certifyingExam?.typeOfSubmission === Enum_Certifyingexam_Typeofsubmission.FirstSubmission;
  }

  get isResubmission() {
    return this.certifyingExam?.typeOfSubmission === Enum_Certifyingexam_Typeofsubmission.ReSubmission;
  }

  get certifyingExamDirectoryPath() {
    return CERTIFYING_EXAM_DIRECTORY_PATH;
  }

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    const ce = this.certifyingExam;

    this.credentialsSubmissionForm = this.fb.group(
      Object.entries(this.credentials).reduce<Record<string, FormControl<any>>>(
        (formControls, [credentialName, credential]) => {
          const credentialControl = this.fb.control(credential.default, credential.validators);

          if (credential.required == Required.Later) {
            const submitLaterKey = this.getSubmitLaterFieldName(credential.name);
            // submitted field is used for submit later so logic must be inverted
            const submitLaterValue =
              ce[submitLaterKey] === null || ce[submitLaterKey] === undefined || ce[submitLaterKey] ? false : true;
            const submitLaterControl = this.fb.control(submitLaterValue);

            submitLaterControl.valueChanges.subscribe((submitLaterSelected) => {
              if (submitLaterSelected) {
                credentialControl.disable();
                credentialControl.setValidators(null);
                credentialControl.setValue(credential.default);
              } else {
                credentialControl.enable();
                credentialControl.setValidators(credential.validators);
              }
              credentialControl.updateValueAndValidity();
            });

            credentialControl.updateValueAndValidity();
            submitLaterControl.updateValueAndValidity();

            formControls[submitLaterKey] = submitLaterControl;
          }

          formControls[credentialName] = credentialControl;
          return formControls;
        },
        {}
      )
    );

    this.credentialsSubmissionForm.patchValue({
      titlePreviousCaseSummary: ce.titlePreviousCaseSummary ?? '',

      geResult: [ce.geResult?.data].filter((uf) => !!uf) as UploadFileEntity[],
      verificationOfTraining: ce.verificationOfTraining?.data ?? [],
      caseLogSummary: [ce.caseLogSummary?.data].filter((uf) => !!uf) as UploadFileEntity[],
      caseSummary: ce.caseSummary?.data ?? [],
      caseLog: [ce.caseLog?.data].filter((uf) => !!uf) as UploadFileEntity[],
      credentialsSubmissionSummaryForm: [ce.credentialsSubmissionSummaryForm?.data].filter(
        (uf) => !!uf
      ) as UploadFileEntity[],
      presentationLog: [ce.presentationLog?.data].filter((uf) => !!uf) as UploadFileEntity[],
      publications: ce.publications?.data ?? [],
      programmeDirectorLetter: [ce.programmeDirectorLetter?.data].filter((uf) => !!uf) as UploadFileEntity[],
      procedureLog: [ce.procedureLog?.data].filter((uf) => !!uf) as UploadFileEntity[],
      previousCorrespondence: ce.previousCorrespondence?.data ?? [],

      publicationLinks: ce.publicationLinks ?? '',
      multipleChoiceQuestionnaire: [ce.multipleChoiceQuestionnaire?.data].filter((uf) => !!uf) as UploadFileEntity[],
    });

    if (this.disabled) {
      this.credentialsSubmissionForm.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled'] && !changes['disabled'].firstChange) {
      const disabled = changes['disabled'].currentValue;
      if (disabled) {
        this.credentialsSubmissionForm.disable();
      } else {
        this.credentialsSubmissionForm.enable();
      }
    }
  }

  getCredential(fieldName: string) {
    if (!(fieldName in this.credentials)) {
      return undefined;
    }
    return this.credentials[fieldName as keyof Credentials];
  }

  getSubmitLaterFieldName = getSubmitLaterFieldName;

  isSubmitLaterEnabled(fieldName: keyof Credentials) {
    return isSubmitLaterEnabled(this.credentials, fieldName);
  }

  isSubmitLaterSelected(fieldName: keyof Credentials) {
    return !!this.credentialsSubmissionForm.get(this.getSubmitLaterFieldName(fieldName))?.value;
  }

  getBaseFileName(fieldName: string) {
    return getBaseFileName('certifying-exam', fieldName, this.member);
  }

  getFormByPath(path: string) {
    const parts = path.split('.');
    const name = parts.shift();
    let form = this.credentialsSubmissionForm.get(name!);
    while (parts.length > 0) {
      form = form!.get(parts.shift()!);
    }
    return form;
  }

  isFormRequired(path: string) {
    const form = this.getFormByPath(path);
    if (form) {
      return form.hasValidator(Validators.required) || form.hasValidator(Validators.requiredTrue);
    }
    return false;
  }
}
