import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { CertifyingExamEntity } from 'models/types';
import { Observable } from 'rxjs';
import { CertifyingExamService } from 'src/app/services/certifying-exam.service';
import { MemberService } from 'src/app/services/member.service';
import { getCurrentCertifyingExamReviewYear } from 'src/app/utils/exam';

@Injectable({
  providedIn: 'root',
})
export class CertifyingExamsAsReviewerResolver implements Resolve<CertifyingExamEntity[]> {
  constructor(private memberService: MemberService, private certifyingExamService: CertifyingExamService) {
    //pass
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CertifyingExamEntity[]> {
    return this.certifyingExamService.getCertifyingExamsForReviewByReviewer(
      this.memberService.currentMemberId!,
      getCurrentCertifyingExamReviewYear()
    );
  }
}
