<input
  type="file"
  class="file-input"
  (change)="onFileChange($event)"
  [accept]="allowedTypes"
  [multiple]="allowMultipleSelect"
  [disabled]="disabled"
  #fileUpload />

<div class="file-upload" [ngClass]="{ invalid: isInvalid }">
  <div class="no-files" *ngIf="files.length === 0">No files uploaded yet</div>

  <div class="file" *ngFor="let file of files">
    <button class="file-btn" mat-raised-button color="secondary" (click)="openFile(file)">
      {{ file.attributes?.alternativeText || file.attributes?.name }}

      <button
        *ngIf="!disabled"
        class="file-delete-btn"
        mat-mini-fab
        color="warn"
        (click)="$event.stopPropagation(); removeFile(file.id!)"
        [disabled]="disabled">
        <mat-icon class="file-delete-icon">clear</mat-icon>
      </button>
    </button>
  </div>

  <button
    *ngIf="!loading && files.length < maxNumberFiles"
    class="file-add-btn"
    mat-mini-fab
    color="secondary"
    (click)="fileUpload.click()"
    (blur)="onBlur()"
    [disabled]="disabled">
    <mat-icon>{{ files.length === 0 ? "attach_file" : "add" }}</mat-icon>
  </button>

  <div *ngIf="loading" class="file-loading">
    <mat-spinner diameter="30"></mat-spinner>
  </div>

  <mat-divider></mat-divider>
</div>
