import { Injectable } from '@angular/core';
import { Member, MemberEntity } from 'models/types';

import { getAudienceForMember, getDiplomateRole, getResidentRole } from '../utils/member';

@Injectable({
  providedIn: 'root',
})
export class MemberService {
  private _memberEntity: MemberEntity | null = null;
  private member: Member | null = null;
  private memberId: string | null = null;

  get isResident() {
    return !!this.residentRole;
  }

  get isDiplomate() {
    return !!this.diplomatRole;
  }

  get residentRole() {
    return getResidentRole(this.member);
  }

  get diplomatRole() {
    return getDiplomateRole(this.member);
  }

  get hasRole() {
    return this.isDiplomate || this.isResident;
  }

  get currentMember() {
    return this.member;
  }

  get currentMemberId() {
    return this.memberId;
  }

  get currentMemberEntity() {
    return this._memberEntity;
  }

  get audience() {
    return getAudienceForMember(this.member);
  }

  constructor() {
    // pass
  }

  setMember(member: MemberEntity | null) {
    this._memberEntity = member;
    this.member = member?.attributes ?? null;
    this.memberId = member?.id ?? null;
  }
}
